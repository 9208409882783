import { FormikProvider, useFormik } from 'formik'
import { Link, Redirect, useHistory, useParams } from 'react-router-dom'
import Anchor from 'components/Anchor'
import Modal from 'components/Modal'

import * as Yup from 'yup'
import {
  ContractType,
  ProfileWorkExperience,
  useCurrentProfileQuery,
  useUpdateWorkExperienceMutation,
} from 'api'
import WorkExperienceForm, {
  WorkExperienceFormValues,
} from './WorkExperienceForm'

const WorkExperienceUpdateModal = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, called } = useCurrentProfileQuery({
    returnPartialData: true,
  })

  // TODO: create workExperienceQuery on the backend
  const currentWorkExperience = data?.currentProfile?.workExperiences.find(
    (exp) => exp._id === id
  )

  const formik = useForm(currentWorkExperience)

  // TODO: create loading skeleton
  if (loading) return null

  if (called && !currentWorkExperience) {
    return <Redirect to='/profile/work-experience' />
  }

  return (
    <div
      className='w-screen max-w-4xl p-6 pt-10 overflow-y-scroll bg-white rounded-lg scrollbar'
      style={{ maxHeight: '90vh' }}>
      <div className='space-y-8'>
        <div className='space-y-6'>
          <div className='space-y-2'>
            <Modal.Title as='h3' className='text-1.5xl font-bold'>
              Work Experience
            </Modal.Title>
            <p className='text-xs'>
              Or you can provide a{' '}
              <Anchor
                component={Link}
                className='text-xs'
                to='/profile/personal-projects'>
                personal project
              </Anchor>{' '}
              instead.
            </p>
          </div>
        </div>
        <FormikProvider value={formik}>
          <WorkExperienceForm />
        </FormikProvider>
      </div>
    </div>
  )
}

const validationSchema = Yup.object({
  jobTitle: Yup.string().required(),
  company: Yup.string().required(),
  // location field
  location: Yup.string().required(),
  remote: Yup.boolean().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
  jobType: Yup.mixed<ContractType>()
    .oneOf(Object.values(ContractType))
    .required(),
  description: Yup.string().required(),
  techStack: Yup.array()
    .min(1, 'Techstacks should not be empty')
    .of(Yup.string())
    .required(),
})

const useForm = (initialValues?: Partial<ProfileWorkExperience>) => {
  const history = useHistory()
  const [updateWorkExperience] = useUpdateWorkExperienceMutation()
  const { id } = useParams<{ id: string }>()

  const formik = useFormik<WorkExperienceFormValues>({
    validationSchema: () => validationSchema,
    enableReinitialize: true,
    initialValues: {
      jobTitle: initialValues?.jobTitle || '',
      company: initialValues?.company || '',
      location: initialValues?.location || '',
      remote: !!initialValues?.remote,
      // We set default fallback to empty string
      // but will be validated on submit
      startDate: initialValues?.startDate || '',
      endDate: initialValues?.endDate,
      ongoing: !initialValues?.endDate,
      jobType: initialValues?.jobType || ContractType.FullTime,
      techStack: initialValues?.techStack || [],
      description: initialValues?.description || '',
      ignore: initialValues?.ignore || false,
    },
    onSubmit: async ({ ongoing, endDate, ...values }) => {
      try {
        await updateWorkExperience({
          variables: {
            data: {
              ...values,
              // TODO: this needs to be handled properly
              // endDate should accept 'null'
              endDate: !ongoing ? endDate : null,
            },
            id,
          },
        })
        // TODO: handle success
        history.push('/profile')
      } catch (error) {
        // TODO handle errors
      }
    },
  })

  return formik
}
export default WorkExperienceUpdateModal
