import clsx from 'clsx'
import Portal from 'depricated-components/Portal'
import {
  createContext,
  Dispatch,
  ElementType,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { PolymorphicComponentProps } from 'types/polymorphic'

const DROPDOWN_ID = 'dropdown'

let dropdownRoot = document.getElementById(DROPDOWN_ID)
if (!dropdownRoot) {
  dropdownRoot = document.createElement('div')
  dropdownRoot.setAttribute('id', DROPDOWN_ID)
  document.body.appendChild(dropdownRoot)
}

const ToggleContext = createContext<ToggleContextType | null>(null)
const useToggleDropdown = () => {
  const context = useContext(ToggleContext)
  if (!context) {
    throw new Error(
      'useToggleDropdown must be used within a ToggleContext provide.'
    )
  }
  return context
}

const Dropdown = ({ children, ...props }: DropdownProps) => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen((open) => !open)
  const close = () => setOpen(false)

  let toggleEl, contentEl

  if (Array.isArray(children)) {
    toggleEl = children.filter((child) => child.type !== DropdownContent)
    contentEl = children.find((child) => child.type === DropdownContent)
  } else {
    throw new Error('Dropdown must have two or more children')
  }

  return (
    <ToggleContext.Provider value={{ open, setOpen, toggle, close }}>
      <div {...props}>
        {toggleEl}
        {open ? contentEl : null}
      </div>
      {open ? (
        <>
          {/** double the button to avoid stacking order issues */}
          <button
            onClick={() => setOpen(false)}
            className='absolute inset-0 w-full cursor-auto focus:outline-none'
          />
          <Portal id={DROPDOWN_ID}>
            <button
              onClick={() => setOpen(false)}
              className='absolute inset-0 w-full cursor-auto focus:outline-none'
            />
          </Portal>
        </>
      ) : null}
    </ToggleContext.Provider>
  )
}

export const DropdownToggle = ({
  className,
  ...props
}: DropdownToggleProps) => {
  const { toggle } = useToggleDropdown()
  return (
    <button
      className={clsx('relative z-10', className)}
      onClick={toggle}
      {...props}
    />
  )
}

export const DropdownContent = <Polymorphic extends ElementType = 'div'>({
  component,
  className,
  ...props
}: PolymorphicComponentProps<Polymorphic, DropdownContentProps>) => {
  const Component = component || 'div'
  return <Component className={clsx('absolute z-10', className)} {...props} />
}

export const DropdownClose = <Polymorphic extends ElementType = 'button'>({
  component,
  onClick,
  ...props
}: PolymorphicComponentProps<Polymorphic, DropdownButtonProps>) => {
  if (!onClick) {
    throw new Error('DropdownClose must have an onClick prop')
  }
  const Component = component || 'button'

  const { close } = useToggleDropdown()
  const handleClick = () => {
    onClick()
    close()
  }
  return <Component onClick={handleClick} {...props} />
}

interface ToggleContextType {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  toggle: () => void
  close: () => void
}

interface DropdownProps {
  className?: string
  children: ReactElement[]
}

interface DropdownToggleProps {
  className?: string
  children: ReactNode
}

interface DropdownContentProps {
  className?: string
  children: ReactNode
}

interface DropdownButtonProps {
  onClick: () => void
}

export default Dropdown
