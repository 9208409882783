import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

const MIN_YEAR = 1975

const DateInput = ({
  placeholder = 'Date (MM/YYYY)',
  value,
  onChange,
}: DateInputProps) => {
  const [month, setMonth] = useState<string | undefined>(
    value ? getPaddedMonth(value.getMonth() + 1) : undefined
  )
  const [year, setYear] = useState<string | undefined>(
    value ? `${value.getFullYear()}` : undefined
  )
  const monthInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (
      // we check for a valid month
      !month ||
      !/^(0?[1-9]|1[012])$/.test(month) ||
      // we check for valid year
      !year ||
      !/^\d{4}$/.test(year)
    ) {
      onChange(undefined)
      return
    }

    onChange(new Date(+year, +month - 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month])

  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value.length < 2) {
      setMonth(value)
      return
    }

    // we make sure month is within 1-12
    const formattedValue = Math.min(Math.max(+value, 1), 12)
    setMonth(`${formattedValue}`)
  }
  const handleMonthBlur = () => {
    if (month) {
      setMonth(getPaddedMonth(+month))
    }
  }

  const handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if (value.length < 4) {
      setYear(value)
      return
    }

    // we make sure year is within MIN_YEAR and current date
    const formattedValue = Math.min(
      Math.max(+value, MIN_YEAR),
      new Date().getFullYear()
    )
    setYear(`${formattedValue}`)
  }

  return (
    <fieldset className='date-input relative'>
      <label>
        <legend
          className={clsx(
            ' text-cardGray-50',
            // this will prevent the legend from showing if the
            // input has value, since we can't detect "input-within"
            !month && !year ? 'date-input__legend' : 'invisible'
          )}>
          {placeholder}
        </legend>
        <span
          className={clsx(
            'absolute top-0 min-w-min',
            // we keep the inputs shown if they have some value
            !month && !year ? 'date-input__fields' : ''
          )}>
          <input
            ref={monthInputRef}
            size={2}
            className='text-right w-3 placeholder-cardGray-50'
            type='number'
            min='01'
            max='12'
            placeholder='MM'
            aria-label='month'
            value={month}
            onChange={handleMonthChange}
            onBlur={handleMonthBlur}
          />
          <span className='text-cardGray-50 mx-1'>/</span>
          <input
            onFocus={() => {
              // we always focus the month input first (if it's empty)
              if (!month) {
                monthInputRef.current?.focus()
              }
            }}
            className='placeholder-cardGray-50'
            size={4}
            type='number'
            min={MIN_YEAR}
            max={new Date().getFullYear()}
            placeholder='YYYY'
            aria-label='year'
            value={year}
            onChange={handleYearChange}
          />
        </span>
      </label>
    </fieldset>
  )
}

type DateInputProps = {
  placeholder?: string
  value?: Date
  /**
   * onChange event, sends 'date' if input is valid
   * or 'undefined' if not
   */
  onChange: (date: Date | undefined) => void
}

/**
 * adds leading zero to given month
 */
const getPaddedMonth = (month: number): string =>
  Math.abs(month) < 10 ? `0${month}` : `${month}`

export default DateInput
