import { GemographyLoadingSVG, FocusLoaderSVG } from 'assets/icons'

const SplashScreen = () => {
  return (
    <div className='flex items-center justify-center w-full h-screen'>
      <GemographyLoadingSVG className='mr-3' />
      <div className='animate-spin'>
        <FocusLoaderSVG />
      </div>
    </div>
  )
}

export default SplashScreen
