import { gql } from '@apollo/client'
import { CANDIDATE_FRAGMENT } from './fragments'

export const REAPPLY_MUTATION = gql`
  mutation reapply {
    reapply {
      ...Candidate
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const UPDATE_CANDIDATE_MUTATION = gql`
  mutation UpdateCandidate(
    $country: LimitedString
    $phone: PhoneNumber
    $firstName: LimitedString
    $lastName: LimitedString
  ) {
    updateCandidate(
      country: $country
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    ) {
      ...Candidate
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const RESEND_VERIFICATION = gql`
  mutation {
    resendVerification {
      timestamp
      emailSent
    }
  }
`

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($verificationCode: String!) {
    verifyEmail(verificationCode: $verificationCode) {
      _id
      name
      verified
    }
  }
`
