import React, { ElementType, ReactElement } from 'react'
import clsx from 'clsx'
import { Link, matchPath, useLocation } from 'react-router-dom'

import { GemographyUpdatedSVG } from 'assets/icons'
import { useAuthentication, useLogout } from 'actions/auth'
import Anchor from 'components/Anchor'
import { PolymorphicComponentProps } from 'types/polymorphic'

const Header = <Polymorphic extends ElementType = 'nav'>({
  children,
  ...props
}: PolymorphicComponentProps<Polymorphic>) => {
  return (
    <header className='relative border-b border-brand-100'>
      <nav
        className='flex justify-between items-center py-3.5 px-4 md:px-20 mx-auto max-w-screen-xl'
        {...props}>
        {children}
      </nav>
    </header>
  )
}

const Body = <Polymorphic extends ElementType = 'main'>({
  children,
  component,
  ...props
}: PolymorphicComponentProps<Polymorphic>) => {
  const Component = component || 'main'
  return (
    <Component role='main' {...props}>
      {children}
    </Component>
  )
}

export const BaseLayout = <Polymorphic extends ElementType = 'div'>({
  children,
}: PolymorphicComponentProps<Polymorphic>) => (
  <div className='flex flex-col min-h-screen'>{children}</div>
)

BaseLayout.Body = Body
BaseLayout.Header = Header

function Layout({
  children,
  className = 'relative flex-grow max-w-screen-xl mx-auto',
  progress,
  steps,
  step,
  ...props
}: LayoutProps) {
  const { pathname } = useLocation()
  const logout = useLogout()
  const { authenticated } = useAuthentication()

  /**
   * Logo should link to `gemography.com` on onboarding pages
   */
  const isOnboardingPage = matchPath(pathname, {
    path: ['/onboarding', '/signup', '/signin', '/reset-password'],
    exact: true,
  })

  let right, middle, rest

  if (Array.isArray(children)) {
    middle = children.find((child) => child.type === Middle)
    right =
      children.find((child) => child.type === Right) ||
      children.find((child) => child.props.children.type === Right)
    rest = children.filter(
      (child) => child.type !== Right && child.type !== Middle
    )
  }
  let progressbarWidth: number | undefined
  if (progress) {
    progressbarWidth = progress
  } else if (steps && step) {
    progressbarWidth = 100 / (steps / step) - 5
  }

  if (!right && authenticated) {
    right = (
      <Anchor
        component='button'
        className='text-tiny text-brand-900 uppercase'
        onClick={() => logout()}>
        Logout
      </Anchor>
    )
  }

  return (
    <BaseLayout>
      <Header {...props}>
        <div className='md:w-1/3 text-base font-bold select-none'>
          {isOnboardingPage ? (
            <a href='http://gemography.com'>
              <GemographyUpdatedSVG aria-label='home page' height='22' />
            </a>
          ) : (
            <Link to='/'>
              <GemographyUpdatedSVG aria-label='back' height='22' />
            </Link>
          )}
        </div>
        {middle}
        {right}
      </Header>
      {progressbarWidth ? (
        <svg className='absolute w-full text-brand-500' height='2'>
          <line
            x1='0'
            y1='1'
            x2={`${progress}%`}
            y2='1'
            strokeWidth='2'
            stroke='currentColor'
            strokeLinecap='round'
          />
        </svg>
      ) : null}
      <Body className={className}>{rest || children}</Body>
    </BaseLayout>
  )
}

export function Right<Polymorphic extends ElementType = 'div'>({
  component,
  className,
  ...props
}: PolymorphicComponentProps<Polymorphic, RightProps>) {
  const Component = component || 'div'
  return (
    <Component
      className={clsx(
        'flex justify-end md:w-1/3 text-tiny uppercase whitespace-nowrap',
        className
      )}
      {...props}
    />
  )
}

export function Middle<Polymorphic extends ElementType = 'div'>({
  component,
  className,
  ...props
}: PolymorphicComponentProps<Polymorphic, MiddleProps>) {
  const Component = component || 'div'
  return (
    <Component
      className={clsx(
        'flex md:justify-center md:w-1/3 text-tiny whitespace-nowrap',
        className
      )}
      {...props}
    />
  )
}

interface LayoutProps {
  children: ReactElement | ReactElement[]
  /**
   * className for layout content
   */
  className?: string
  /**
   * progress bar width in percentage
   */
  progress?: number
  /**
   * Should be used with `step`.
   * Used to calculate progress bar width.
   */
  steps?: number
  /**
   * Should be used with `steps`
   * Used to calculate progress bar width.
   */
  step?: number
}

interface RightProps {
  className?: string
}

interface MiddleProps {
  className?: string
}

export default Layout
