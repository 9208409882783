import { InMemoryCache } from '@apollo/client'
import { authenticationVars } from 'actions/auth'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        authentication: {
          read() {
            return authenticationVars()
          },
        },
      },
    },
    Assessment: {
      keyFields: ['stage'],
    },
    QuizSubmission: {
      keyFields: ['quizId'],
    },
  },
})

export default cache
