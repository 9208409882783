// import { PersonalProjectFragment } from 'api';
import { BadgeGroup } from 'components/Badge'
import Anchor from 'components/Anchor'
import { formatDate } from 'lib/helpers/date'
import { InfoBlock, MoreButton } from './Components'
import { ExternalLinkSVG } from 'assets/icons'
import { PersonalProjectFragment, useDeletePersonalProjectMutation } from 'api'
import { MenuItem } from 'components/Menu'
import { useHistory } from 'react-router-dom'
import { useDeleteConfirmation } from 'components/Confirmation'

const PersonalProject = ({ data }: PersonalProjectProp) => {
  const history = useHistory()
  const {
    title,
    description,
    startDate,
    endDate,
    sourceLink,
    demoLink,
    techStack,
  } = data

  const [
    deletePersonalProject,
    { loading },
  ] = useDeletePersonalProjectMutation()
  const confirmDelete = useDeleteConfirmation()

  // TODO: handle delete loading animation
  const handleDeletePersonalProject = () => {
    if (loading) return
    try {
      confirmDelete({
        onDelete: () => deletePersonalProject({ variables: { id: data._id } }),
        heading: 'Delete personal project',
        subHeading: 'Are you sure you want to permanently delete this item?',
      })
    } catch (err) {}
  }

  return (
    <div className='relative p-6'>
      <MoreButton label={title}>
        <MenuItem
          onClick={() =>
            history.push(`/profile/personal-projects/${data._id}`)
          }>
          Update project
        </MenuItem>
        <MenuItem className='text-alert' onClick={handleDeletePersonalProject}>
          Delete project
        </MenuItem>
      </MoreButton>
      <div className='space-y-6'>
        <div className='space-y-4'>
          <h3 className='text-lg font-bold text-brand-700'>{title}</h3>
          <ul className='grid items-center justify-between grid-cols-3 gap-x-2'>
            <InfoBlock title='Date'>
              {formatDate(startDate) +
                ' - ' +
                formatDate(endDate, { presentText: 'To date' })}
            </InfoBlock>
            {demoLink || sourceLink ? (
              <InfoBlock title='Links'>
                <span className='space-x-4'>
                  {demoLink ? (
                    <Anchor
                      className='space-x-1'
                      aria-label={'Live demo of ' + title}
                      href={demoLink}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>Live demo</span>
                      <ExternalLinkSVG
                        className='inline'
                        width='12'
                        height='12'
                      />
                    </Anchor>
                  ) : null}
                  {sourceLink ? (
                    <Anchor
                      className='space-x-1'
                      aria-label={'Source code of ' + title}
                      href={sourceLink}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <span>Source code</span>
                      <ExternalLinkSVG
                        className='inline'
                        width='12'
                        height='12'
                      />
                    </Anchor>
                  ) : null}
                </span>
              </InfoBlock>
            ) : null}
          </ul>
        </div>

        <div className='space-y-4'>
          <div
            className='text-xs richtext'
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <BadgeGroup
            className='bg-brand-80 !text-gray-600'
            values={techStack}
          />
        </div>
      </div>
    </div>
  )
}

interface PersonalProjectProp {
  data: PersonalProjectFragment
}
export default PersonalProject
