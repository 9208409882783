import { Route, Redirect, Switch } from 'react-router-dom'
import { useCurrentProfileQuery, WalkthroughStatus } from 'api'
import ProfileWalkthrough from './Walktrhough'
import ProfileIntroduction from './Introduction'
import ProfileHome from './Home'
import SplashScreen from 'layouts/SplashScreen'
import { isMobileDevice } from 'lib/helpers/isMobile'
import useMediaQuery from 'lib/hooks/useMediaQuery'
import UnsupportedScreen from './UnsupportedScreen'

const ProfileView = () => {
  const { data, error, loading } = useCurrentProfileQuery({
    returnPartialData: true,
  })

  //we should render a profile dashboard skeleton here
  if (loading) return <SplashScreen />

  if (error || !data?.currentProfile) {
    const err = error || new Error('Could not find current profile.')
    throw err
  }
  if (data?.currentProfile?.walkthroughStatus === WalkthroughStatus.Pending) {
    return (
      <Switch>
        <Route path='/profile/walkthrough'>
          <ProfileWalkthrough />
        </Route>
        <Route path='/profile/introduction'>
          <ProfileIntroduction />
        </Route>
        <Redirect to='/profile/walkthrough' />
      </Switch>
    )
  }

  return <ProfileHome />
}

const isMobile = isMobileDevice()

const Profile = () => {
  const isUnderMD = useMediaQuery('(max-width: 768px)')
  return isMobile && isUnderMD ? <UnsupportedScreen /> : <ProfileView />
}

export default Profile
