import clsx from 'clsx'
import { ElementType, ReactNode } from 'react'
import { PolymorphicComponentProps } from 'types/polymorphic'

const Anchor = <Polymorphic extends ElementType = 'a'>({
  className,
  component,
  ...props
}: PolymorphicComponentProps<Polymorphic, Props>) => {
  const Component = component || 'a'
  return (
    <Component
      className={clsx(
        'underline',
        'focus-visible:ring-2 ring-gray-600 focus:outline-none',
        'cursor-pointer disabled:cursor-not-allowed',
        className
      )}
      {...props}
    />
  )
}

interface Props {
  className?: string
  children: ReactNode
}

export default Anchor
