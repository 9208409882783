import Section from './Section'
import WorkExperience from './Sections/WorkExperience'
import PersonalProject from './Sections/PersonalProject'
import Education from './Sections/Education'
import { useCurrentProfileQuery } from 'api'
import ProfileModals from './Modals'
import ProgressCard from '../Shared/ProgressCard'
import HowToCard from '../Shared/HowToCard'

const ProfilePage = () => {
  const { data, error, loading } = useCurrentProfileQuery({
    returnPartialData: true,
  })
  if (loading) {
    // TODO: add loading page
    return null
  }

  if (!data?.currentProfile) {
    const err = error || new Error("currentProfile doesn't exist")
    throw err
  }
  const { workExperiences, personalProjects, educations } = data.currentProfile

  return (
    <main className='space-y-10'>
      <div className='flex lg:flex-row flex-col lg:items-stretch items-center lg:space-x-8 space-y-4 lg:space-y-0'>
        <ProgressCard />
        <div style={{ maxWidth: '292px' }}>
          <HowToCard
            title='How to build your profile'
            link='https://www.notion.so/hiddenpole/Profile-Material-31ba37d683234c37970268f4d5fc84e4'>
            <p className='text-xxs'>
              To build your profile, we would need you to give us all the
              relevant details about your previous experiences. We will be
              helping you with questions so please make sure you answer all of
              them
            </p>
          </HowToCard>
        </div>
      </div>
      <div className='space-y-8'>
        <Section link='/profile/work-experience' title='Work experience'>
          {workExperiences.length > 0
            ? workExperiences.map((data) => (
                <WorkExperience data={data} key={data._id} />
              ))
            : null}
        </Section>
        <Section link='/profile/personal-projects' title='Personal projects'>
          {personalProjects.length > 0
            ? personalProjects.map((data) => (
                <PersonalProject data={data} key={data._id} />
              ))
            : null}
        </Section>
        <Section link='/profile/education' title='Education'>
          {educations.length > 0 ? (
            <ol
              className='grid grid-cols-2 p-6 px-20'
              style={{ gridTemplateColumns: 'auto 1fr' }}>
              {educations.map((data, index) => (
                <Education
                  data={data}
                  key={data._id}
                  isLast={index === educations.length - 1}
                />
              ))}
            </ol>
          ) : null}
        </Section>

        {/* profile page modals */}
        <ProfileModals />
      </div>
    </main>
  )
}
export default ProfilePage
