import React from 'react'
import Skeleton from 'components/Skeleton'
import { BaseLayout } from 'layouts'

const HeaderLayoutSkeleton = () => {
  return (
    <BaseLayout>
      <BaseLayout.Header>
        <Skeleton className='ml-auto' width='w-20' height='h-4' />
      </BaseLayout.Header>
      <BaseLayout.Body>
        <div className='max-w-xl px-16 pt-10 mx-auto'>
          <Skeleton className='mt-3 max-w-full' width='w-80' height='h-4' />
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  )
}

export default HeaderLayoutSkeleton
