/**
 * Adds a border, a shadow, and a divider between children.
 */
import { PolymorphicComponentProps } from 'types/polymorphic'
import clsx from 'clsx'

const Card = <Polymorphic extends React.ElementType = 'div'>({
  className,
  component,
  ...props
}: PolymorphicComponentProps<Polymorphic>) => {
  const Component = component || 'div'
  return (
    <Component
      className={clsx(
        'shadow-card border flex flex-col border-brand-100 divide-y divide-brand-100 divide-opacity-70 rounded-lg',
        className
      )}
      {...props}
    />
  )
}

export default Card
