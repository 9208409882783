import Skeleton from 'components/Skeleton'
import Card from 'components/Card'
import { BaseLayout } from 'layouts/Sidebar'

const SidebarLayoutSkeleton = () => {
  return (
    <BaseLayout>
      <BaseLayout.Sidebar>
        <Card className='w-full mx-auto bg-white'>
          <Card.Body>
            <Skeleton width='w-28' height='h-4' />
            <div className='flex mt-3'>
              <Skeleton width='w-2' height='h-2' className='mr-2' />
              <Skeleton width='w-36' height='h-2' />
            </div>
          </Card.Body>
        </Card>
        <Card className='w-full mx-auto bg-white h-32 row-span-full'>
          <Card.Body>
            <Skeleton width='w-28' height='h-3' />
            <Skeleton width='w-16' height='h-4' className='mt-3' />
          </Card.Body>
        </Card>
      </BaseLayout.Sidebar>
      <BaseLayout.Body>
        <div className='h-screen'>
          <Skeleton width='w-48' height='h-4' className='my-2' />
          <Skeleton
            width='w-full'
            height='h-24'
            rounded='rounded-md'
            className='mt-12'
          />
        </div>
      </BaseLayout.Body>
    </BaseLayout>
  )
}

export default SidebarLayoutSkeleton
