import { AssessmentStatus } from './generated'

export enum StageType {
  Focus = 'focus',
  Funnel = 'funnel',
}

export enum StageTitle {
  onboarding = 'Create your account',
  quiz = 'Technical quiz',
  codingChallenge = 'Coding challenge',
  videoask = 'Video question',
  technicalInterview = 'Technical interview',
  cultureFitInterview = 'Soft-skills interview',
  preOffer = 'Application complete!',
  buildingProfile = 'Profile building',
  matching = 'Matching',
  discussing = 'Discussing',
  hired = 'Hired',
}

export const StageStatus = {
  ...AssessmentStatus,
  Failed: 'failed',
} as const

export enum Months {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}
