import { useProfileData } from 'lib/hooks/useEditProfileInfo'
import Multiselect from 'components/Multiselect'

const COUNTRIES = [
  'English 🇺🇸',
  'French 🇫🇷',
  'Arabic 🇸🇦',
  'Spanish 🇪🇸',
  'German 🇩🇪',
  'Portuguese 🇧🇷',
]

const withFlags = (countries: string[] | undefined) => {
  if (!countries || !countries.length) return null

  return countries.map((country) => {
    let lanIdx = COUNTRIES.findIndex((search) => search.includes(country))
    // handle case where users create languages
    if (lanIdx === -1) return country
    return COUNTRIES[lanIdx]
  })
}

const Languages = () => {
  const { values, setFieldValue } = useProfileData()

  const handleChange = (values: string[]) => {
    const countries = values.map((value) => value.split(' ')[0])
    setFieldValue('languages', countries)
  }

  const handleBadgeRemove = (language: string) => {
    const index = values.languages?.findIndex((search) =>
      language.includes(search)
    )

    setFieldValue('languages', [
      ...values.languages?.slice(0, index)!,
      ...values.languages?.slice(index! + 1)!,
    ])
  }
  return (
    <Multiselect
      placeholder='Add languages...'
      searchPlaceHolder='e.g., French, English'
      values={withFlags(values['languages']) || []}
      onChange={handleChange}
      options={COUNTRIES}
      listStyle={{
        maxHeight: '170px',
        overflow: 'scroll',
      }}
      selectButtonClassName='border border-brand-100 shadow-onboardingCard py-2.5 px-4 rounded-lg text-left'
      selectListLabel='Select a language'
      renderSelectListBadge={(params) => (
        <div className='text-sm py-1 px-2 bg-brand-80 w-max rounded'>
          {params.value}
        </div>
      )}
      renderCreateListBadge={(params) => (
        <div className='text-sm py-1 px-2 rounded bg-brand-80 w-max'>
          {params.value}
        </div>
      )}
      renderSelectedValueBadge={(params) => (
        <div className='text-sm py-1 px-2 pr-4 bg-brand-80 rounded relative'>
          {params.value}
          {params.removable && params.onRemove && (
            <button
              onClick={() => handleBadgeRemove(params.value)}
              className='leading-4 cursor-pointer text-xtiny focus:outline-none absolute'
              style={{ top: '.5px', right: '2px' }}>
              ✕
            </button>
          )}
        </div>
      )}
    />
  )
}

export default Languages
