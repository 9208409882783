import { useState } from 'react'
import Button from 'components/Button'
import { forwardRef } from 'react'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import * as Sentry from '@sentry/react'
import { WarningEmoji } from 'assets/emojis'

const ConfirmDelete = forwardRef<HTMLDivElement, ConfirmDeleteProps>(
  ({ onClose, onDelete, heading, subHeading }, ref) => {
    const [loading, setLoading] = useState(false)
    const showSnackbar = useSnackbar()
    const handleDelete = async () => {
      setLoading(true)
      try {
        await onDelete()
        onClose()
      } catch (err: any) {
        setLoading(false)
        Sentry.captureException(err)
        showSnackbar({
          key: 'delete_confirmation_fail',
          text: 'Failed to delete. Please, try again.',
          icon: WarningEmoji,
        })
      }
    }

    return (
      <div
        ref={ref}
        className='bg-white align-middle min-w-[28rem] text-left rounded-lg divide-y divide-brand-100 divide-opacity-70'>
        <div className='px-6 py-6 pb-7'>
          <h3 className='text-lg leading-6 font-medium'>{heading}</h3>
          <div className='mt-2'>
            <p className='text-brand-150 text-sm'>{subHeading}</p>
          </div>
        </div>

        <div className='px-6 py-3 space-x-2 flex justify-end'>
          <Button
            type='button'
            variant='custom'
            className='py-3 px-9 hover:bg-brand-50'
            onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            type='button'
            variant='custom'
            className='text-white bg-alert py-3 px-9'
            onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </div>
    )
  }
)

export type ConfirmDeleteProps = {
  onClose: () => void
  onDelete: () => Promise<any>
  heading: string
  subHeading: string
}

export default ConfirmDelete
