import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const sentryConfig = () => {
  Sentry.init({
    dsn:
      'https://900bb004a4e94ec5a1c59206df404fd9@o174230.ingest.sentry.io/5508560',
    // this will be dynamic once we add semantic versioning
    release: process.env.REACT_APP_RELEASE,
    integrations: [new BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.3,
    // we separate the environments
    environment: process.env.REACT_APP_DEPLOY_ENV,
    ignoreErrors: [
      'Network request failed',
      'Failed to fetch',
      'NetworkError',
      'withrealtime/messaging',
      'ChunkLoadError',
      /^Loading chunk \d+ failed\.$/,
      /^Illegal invocation$/,
    ],
    // this will integrate logRecket sessionUrl with sentry
    // more details: https://docs.logrocket.com/docs/frontend-error-reproduction
    // NOTE: The sessionURL will only be available after LogRocket.init()
    beforeSend: (options) => {
      const logRocketSession = LogRocket.sessionURL
      if (logRocketSession !== null) {
        return {
          ...options,
          extra: {
            ...options.extra,
            LogRocket: logRocketSession,
          },
        }
      }
      return options
    },
  })
}
