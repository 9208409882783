import clsx from 'clsx'
import { ProfileDataType, useProfileData } from 'lib/hooks/useEditProfileInfo'
import Card from 'components/Card'
import TechStackMultiselect from 'components/TechStackMultiselect'
import ToolTip from 'components/ToolTip'

interface TechStackFragmentProps {
  title: string
  tooltip?: string
  type: keyof Omit<NonNullable<ProfileDataType['techStacks']>, '__typename'>
  isPreview: boolean
}

export function TechStackFragment({
  title,
  tooltip,
  type,
  isPreview,
}: TechStackFragmentProps) {
  const {
    values: { techStacks = { experienced: [], familiar: [], proficient: [] } },
    setFieldValue,
  } = useProfileData()

  const onTechStacksUpdated = (values: string[]) => {
    setFieldValue('techStacks', {
      ...techStacks,
      [type]: values,
    })
  }

  return (
    <div className='space-y-1'>
      <div className='flex flex-row space-x-1 items-center'>
        <label
          className={clsx(
            'font-normal text-tiny',
            isPreview ? 'text-brand-gray-200' : 'text-brand-900 text-opacity-70'
          )}>
          {title}
        </label>
        <ToolTip text={tooltip} />
      </div>
      <TechStackMultiselect
        values={techStacks[type]}
        onChange={onTechStacksUpdated}
      />
    </div>
  )
}

const TechStackStep = () => {
  return (
    <Card className={'px-6 py-6 bg-white w-full shadow-onboardingCard'}>
      <div className='flex flex-col space-y-4'>
        <label className='text-sm font-semibold text-brand-900'>
          Tech stacks
        </label>
        <div className='space-y-4'>
          <TechStackFragment
            title='PROFICIENT IN?'
            tooltip='The technologies you feel you are mastering (have experience with and know how it works under the hood).'
            type='proficient'
            isPreview={false}
          />
          <TechStackFragment
            title='EXPERIENCED WITH?'
            tooltip='The technologies you used in at least 1 or 2 projects.'
            type='experienced'
            isPreview={false}
          />
          <TechStackFragment
            title='FAMILIAR WITH?'
            tooltip='The technologies you read about and you have slightly basics of it'
            type='familiar'
            isPreview={false}
          />
        </div>
      </div>
    </Card>
  )
}
export default TechStackStep
