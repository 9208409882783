import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
// NOTE: these skeletons need to be in their respective layout files
import {
  HeaderLayoutSkeleton,
  SidebarLayoutSkeleton,
} from 'layouts/dashboard/Skeleton'
import { PublicPageLayoutSkeleton } from 'layouts/PublicPageLayout'

const headerLayoutSkeleton = <HeaderLayoutSkeleton />
const sidebarLayoutSkeleton = <SidebarLayoutSkeleton />
const publicLayoutSkeletonn = <PublicPageLayoutSkeleton />

export const loadableHeaderLayout = (toLoad: () => Promise<any>) =>
  loadable(() => pMinDelay(toLoad(), 200), { fallback: headerLayoutSkeleton })

export const loadableSidebarLayout = (toLoad: () => Promise<any>) =>
  loadable(() => pMinDelay(toLoad(), 200), { fallback: sidebarLayoutSkeleton })

export const loadablePublicLayout = (toLoad: () => Promise<any>) =>
  loadable(() => pMinDelay(toLoad(), 200), { fallback: publicLayoutSkeletonn })
