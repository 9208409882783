import React, { createRef, useEffect, useState } from 'react'

const useProfilePictureUpload = (props: {
  userImage?: string | null
  defaultImage: string
}) => {
  const imageRef = createRef<HTMLInputElement>()
  const [userImage, setUserImage] = useState(
    props.userImage || props.defaultImage
  )
  const [selectedFile, setSelectedFile] = useState<File>()

  const showOpenFileDialog = () => {
    imageRef.current?.click()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files?.[0])
  }

  // Clean up the selection to avoid memory leak
  useEffect(() => {
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile)
      setUserImage(objectURL)
      return () => URL.revokeObjectURL(objectURL)
    }
  }, [selectedFile])

  const clearImage = () => {
    setUserImage(props.defaultImage)
  }

  return {
    imageRef,
    selectedFile,
    userImage,
    showOpenFileDialog,
    handleChange,
    clearImage,
  }
}

export default useProfilePictureUpload
