import React, { ComponentProps, ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'
import SidebarDropdown from './SidebarDropdown'
import { DropdownButton } from 'components/UserDropdown'
import { OutSVG } from 'assets/icons'
import { useLogout as useLogoutHook } from 'actions/auth'
import Steps from './Steps'
import {
  ApplicationStatus,
  CurrentUserQuery,
  StageTitle,
  useReadUserQuery as useReadUserQueryHook,
  Stage,
} from 'api'
import { GemographyUpdatedSVG } from 'assets/icons'
import Modal from 'components/Modal'
import EditProfile from './EditProfile'
import { PropsWithChildren } from 'react-router/node_modules/@types/react'
import { APPLICATION_ROUTES } from 'pages/Application/routes'
import Status from 'components/Status'
import clsx from 'clsx'

const Sidebar = ({ children }: PropsWithChildren<{}>) => (
  <nav className='relative col-span-full col-start-3 border-b md:border-l border-brand-100 pointer-events-none'>
    <div className='py-6 px-4 sm:px-8 space-y-11 pointer-events-auto'>
      <div className='space-y-7.5'>{children}</div>
    </div>
  </nav>
)

const Body = ({ children }: PropsWithChildren<{}>) => (
  <main className='col-span-2 col-start-1 bg-white flex-grow'>
    <div className='pt-7.5 pb-16 px-4 sm:px-5 md:pr-12 ml-auto max-w-6xl'>
      {children}
    </div>
  </main>
)

export const BaseLayout = ({ children }: BaseLayoutProps) => {
  return (
    <div
      className='md:grid grid-flow-col-dense min-h-screen bg-brand-50 border-t-3 border-brand-500'
      style={{
        // 56rem is max-w-4xl and 20rem is max-w-xs
        // defined as the max-width of content and sidebar, respectively
        gridTemplateColumns: '1fr minmax(auto, 56rem) 21rem',
      }}>
      {children}
    </div>
  )
}

BaseLayout.Sidebar = Sidebar
BaseLayout.Body = Body

type BaseLayoutProps = {
  children: ReactNode
}

const PageHeader = ({
  stage,
  canApplyIn,
  className,
}: {
  stage: Stage
  canApplyIn?: number
  className?: string
}) => {
  const status = !canApplyIn
    ? 'Application in progress'
    : `Retry in ${Math.ceil(canApplyIn)} day${
        Math.ceil(canApplyIn) === 1 ? '' : 's'
      }`
  const statusType = canApplyIn ? 'secondary' : 'primary'
  return (
    <div className={clsx('bg-white border-b border-brand-100', className)}>
      <div className='flex items-center justify-between ml-auto  py-3.5 px-4 md:px-8 max-w-6xl'>
        <h1 className='text-lg font-semibold text-black'>
          {StageTitle[stage]}
        </h1>
        <Status value={status} variant={statusType} />
      </div>
    </div>
  )
}

const Footer = () => (
  <footer className='w-full space-y-7.5'>
    <div className='flex flex-col items-start space-y-2'>
      <h4 className='uppercase text-xxs text-brand-900'>
        Learning & Resources
      </h4>
      <SidebarExtLink href='https://www.gemography.com/resources'>
        Resource center
      </SidebarExtLink>
      <SidebarExtLink href='https://gemography.com/job-directory'>
        Job directory
      </SidebarExtLink>
      <SidebarExtLink href='https://gemography.webflow.io/developer-stories'>
        Developer stories
      </SidebarExtLink>
      <SidebarExtLink href='https://gemography.webflow.io/newsletter'>
        Newsletter
      </SidebarExtLink>
    </div>

    <div className='flex flex-col items-start space-y-2'>
      <div className='text-xxs font-medium leading-4.5'>
        Need help? Email us at{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='mailto:hello@gemography.com'
          className='bg-none p-0 font-bold text-[#4D3BD8] text-xxs'>
          hello@gemography.com
        </a>
      </div>
    </div>
    <div className='flex items-center space-x-1.5 mt-10'>
      <GemographyUpdatedSVG height='24px' />
      <span className='text-xxs font-bold text-gray-400'>
        &copy; {new Date().getFullYear()}
      </span>
    </div>
  </footer>
)

const SidebarExtLink = ({
  className,
  children,
  ...props
}: ComponentProps<'a'>) => (
  <a
    className={clsx('text-[#6A737D] text-xxs font-medium', className)}
    target='_blank'
    rel='noopener noreferrer'
    {...props}>
    {children}
  </a>
)

function DashboardLayout({
  children,
  useLogout = useLogoutHook,
  useReadUserQuery = useReadUserQueryHook,
}: Props) {
  const logout = useLogout()
  const { push } = useHistory()
  const user = useReadUserQuery()
  if (!user || !user.me.currentApplication.assessments) {
    throw new Error('failed to read current user')
  }

  const {
    me: {
      name,
      canApplyIn,
      currentApplication: { assessments, status, currentAssessment },
    },
  } = user
  const [isEditProfileOpen, setEditProfileOpen] = useState(false)

  return (
    <>
      <Modal
        className='mt-16'
        open={isEditProfileOpen}
        onClose={() => setEditProfileOpen(false)}>
        <EditProfile />
      </Modal>
      <BaseLayout>
        <div className='md:hidden'>
          <PageHeader
            stage={currentAssessment?.stage as Stage}
            canApplyIn={canApplyIn || undefined}
          />
        </div>
        <Sidebar>
          <SidebarDropdown username={name} canApplyIn={canApplyIn}>
            <ul className='p-2'>
              <li>
                <DropdownButton onClick={() => setEditProfileOpen(true)}>
                  Edit profile
                </DropdownButton>
              </li>
              {!canApplyIn && (
                <li>
                  <DropdownButton
                    onClick={() => push(APPLICATION_ROUTES.offboarding)}>
                    Application
                  </DropdownButton>
                </li>
              )}
            </ul>
            <div className='p-2'>
              <DropdownButton
                className='w-full text-tiny underline'
                onClick={() => logout()}>
                <OutSVG className='inline-block p-px mr-1 w-3 h-3' />
                Logout
              </DropdownButton>
            </div>
          </SidebarDropdown>
          <Steps
            assessments={assessments}
            isRejected={status === ApplicationStatus.Rejected}
          />

          <div className='hidden md:block'>
            <Footer />
          </div>
        </Sidebar>
        <div className='flex flex-col col-span-2 col-start-1'>
          <div className='hidden md:block'>
            <PageHeader
              stage={currentAssessment?.stage as Stage}
              canApplyIn={canApplyIn || undefined}
            />
          </div>

          <Body>{children}</Body>
          <div className='md:hidden border-t border-brand-100 md:border-none py-6 px-4'>
            <Footer />
          </div>
        </div>
      </BaseLayout>
    </>
  )
}

interface Props {
  children: ReactNode
  /**
   * For testing and storybook, only.
   */
  useLogout?: () => Function
  /**
   * For testing and storybook, only.
   */
  useReadUserQuery?: () => CurrentUserQuery | undefined
}

export default DashboardLayout
