import Skeleton, { SkeletonProps } from '../Skeleton'
import { ProfileDataType } from 'lib/hooks/useEditProfileInfo'
import { TechStackFragment } from 'pages/Profile/Introduction/Steps/TechStack'

// different defaults
const PreviewSkeleton = ({ rounded = 'rounded', ...props }: SkeletonProps) => (
  <Skeleton
    animate={false}
    rounded={rounded}
    color='bg-brand-500 bg-opacity-5'
    {...props}
  />
)

const TechStackPreview = ({ widths }: TechStackPreviewProps) => (
  <div className='space-y-2.5'>
    <PreviewSkeleton width='w-16' height='h-2.5' />
    <div className='flex space-x-2'>
      {widths.map((width, index) => (
        <PreviewSkeleton key={`${width}_${index}`} width={width} height='h-6' />
      ))}
    </div>
  </div>
)

const Preview = ({
  step,
  country = 'morocco',
  role,
  bio,
  flag,
  techStacks,
}: PreviewProps) => {
  return (
    <div className='px-2 py-4 space-y-5 bg-brand-50'>
      {/* role */}
      <div className='flex justify-between px-2 space-x-16'>
        <div className='mt-1.5 w-48 space-y-2.5'>
          <PreviewSkeleton height='h-4' />
          <div className='flex items-center space-x-2'>
            <PreviewSkeleton width='w-4' height='h-3' rounded='rounded-none' />

            {role ? (
              <span className='text-sm'>{role}</span>
            ) : (
              <PreviewSkeleton
                width='w-40'
                height='h-2.5'
                style={{ marginTop: '5px', marginBottom: '5px' }}
              />
            )}
          </div>
        </div>
        <div className='flex flex-shrink-0 space-x-1.5'>
          <PreviewSkeleton width='w-10' height='h-10' rounded='rounded-full' />
          <PreviewSkeleton
            width='w-2.5'
            height='h-2'
            rounded='rounded-none'
            className='mt-2.5'
          />
        </div>
      </div>
      {/* card */}
      <PreviewSkeleton
        className='py-6 pl-4 pr-6 space-y-4'
        height='h-auto'
        rounded='rounded-lg'
        width='w-full'>
        <div className='flex items-center justify-between'>
          <PreviewSkeleton width='w-48' height='h-4' />
          <PreviewSkeleton
            width='w-10'
            height='h-5'
            rounded='rounded-full'
            className='flex p-0.5 justify-end'>
            <div className='w-4 h-4 bg-white rounded-full' />
          </PreviewSkeleton>
        </div>
        <PreviewSkeleton width='w-60' height='h-2' />
        <PreviewSkeleton width='w-40' height='h-2' />
      </PreviewSkeleton>
      <div className='space-y-9'>
        {/* bio */}
        {bio || step === 'bio' ? (
          <div className='space-y-4 mx-2'>
            <div className='text-sm font-semibold text-brand-900'>Bio</div>
            {bio ? (
              <div className='text-sm text-brand-gray-200'>{bio}</div>
            ) : (
              <div className='space-y-2.5'>
                <PreviewSkeleton height='h-2.5' width='w-72' />
                <PreviewSkeleton height='h-2.5' width='w-60' />
                <PreviewSkeleton height='h-2.5' width='w-64' />
              </div>
            )}
          </div>
        ) : (
          <div className='space-y-6'>
            <PreviewSkeleton width='w-8' height='h-3' />
            <div className='space-y-2.5'>
              <PreviewSkeleton height='h-2.5' width='w-72' />
              <PreviewSkeleton height='h-2.5' width='w-60' />
              <PreviewSkeleton height='h-2.5' width='w-64' />
            </div>
          </div>
        )}
        {/* tech stack */}
        {techStacks?.experienced.length ||
        techStacks?.familiar.length ||
        techStacks?.proficient.length ||
        step === 'techStacks' ? (
          <div className='space-y-4 mx-2'>
            <div className='text-sm font-semibold text-brand-900'>
              Tech stacks
            </div>
            <div className='text-sm text-brand-gray-200'>
              <div className='space-y-4 pointer-events-none'>
                <TechStackFragment
                  title='PROFICIENT IN?'
                  type='proficient'
                  isPreview={true}
                />
                <TechStackFragment
                  title='PEXPERIENCED WITH?'
                  type='experienced'
                  isPreview={true}
                />
                <TechStackFragment
                  title='FAMILIAR WITH?'
                  type='familiar'
                  isPreview={true}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='space-y-3'>
            <PreviewSkeleton width='w-20' height='h-3' />
            <TechStackPreview widths={['w-16', 'w-12', 'w-16', 'w-9']} />
            <TechStackPreview widths={['w-16', 'w-12']} />
            <TechStackPreview widths={['w-16', 'w-12', 'w-16']} />
          </div>
        )}
      </div>
      <div className='space-y-7'>
        <div className='space-y-4'>
          <PreviewSkeleton width='w-20' height='h-3' />
          <div className='flex space-x-2'>
            <PreviewSkeleton width='w-16' height='h-2.5' />
            <PreviewSkeleton width='w-16' height='h-2.5' />
            <PreviewSkeleton width='w-16' height='h-2.5' />
          </div>
        </div>
        <div className='space-y-2'>
          {Array.from({ length: 2 }).map((_, index) => (
            <div className='flex items-center justify-between' key={index}>
              <PreviewSkeleton width='w-28' height='h-3' />
              <PreviewSkeleton width='w-6' height='h-6' />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

interface PreviewProps
  extends Pick<ProfileDataType, 'role' | 'techStacks' | 'bio'> {
  /**
   * indicate the section to not render and to
   * display `children` instead
   */
  step: 'role' | 'techStacks' | 'bio'
  country: string
  children: React.ReactNode
  flag?: string
}

interface TechStackPreviewProps {
  widths: string[]
}

export default Preview
