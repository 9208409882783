import styled from 'styled-components'

export const StyleBase = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e2e1e9;
  z-index: 99;
`

export const Nav = styled.nav`
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0 auto;
`

export const MidWrapper = styled.div`
  position: relative;
  flex: 1;
`

export const RightWrapper = styled.div`
  font-size: 11px;
  color: #0b0826;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.4px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
