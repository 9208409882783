/**
 * This file holds emojis in SVG format imported from Twitter's twemoji font.
 * To add an emoji, find the common name for that emoji (e.g., `tada` is actually named `party popper`),
 * go to https://unicode-table.com/en/ (or alternative) and search
 * using the emoji name and retrive it's unicode (e.g., 1F389 for 🎉)
 * export that emoji SVG using that unicode
 * (i.e., export { ReactComponent as TadaEmoji } from 'twemoji-emojis/vendor/svg/1f389.svg')
 */

export { ReactComponent as LightningEmoji } from 'twemoji-emojis/vendor/svg/26a1.svg'
export { ReactComponent as TadaEmoji } from 'twemoji-emojis/vendor/svg/1f389.svg'
export { ReactComponent as LightBulbEmoji } from 'twemoji-emojis/vendor/svg/1f4a1.svg'
export { ReactComponent as WarningEmoji } from 'twemoji-emojis/vendor/svg/26a0.svg'
export { ReactComponent as CheckMarkEmoji } from 'twemoji-emojis/vendor/svg/2705.svg'
export { ReactComponent as CrossMarkEmoji } from 'twemoji-emojis/vendor/svg/274c.svg'
export { ReactComponent as WaveHandEmoji } from 'twemoji-emojis/vendor/svg/1f44b.svg'
