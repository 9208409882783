import { Stage } from 'api'
import {
  loadableHeaderLayout,
  loadableSidebarLayout,
  loadablePublicLayout,
} from 'routes/loadableRoutes'

// List of routes we do not allow
// redirection from
export const redirectWhiteList = [
  '/application/offboarding',
  '/application/verify',
]

/**
 * @example
 * getScopePath('') // '/application'
 * getScopePath('/something') // '/application/something'
 * getScopePath('something') // '/applicationsomething'
 */
const getScopePath = (path: string) => `/application${path}`

/**
 * Dictionary of paths of the application scope
 */
export const APPLICATION_ROUTES = {
  onboarding: getScopePath('/introduction'),
  quizzes: getScopePath(''),
  quiz: getScopePath('/quizzes/:quizId'),
  // this route is just plain wrong and needs to be removed
  quizStart: getScopePath('/quizzes/start/:quizId'),
  rejection: getScopePath('/no-luck'),
  offboarding: getScopePath('/offboarding'),
  codingChallenge: getScopePath('/coding-challenge'),
  videoask: getScopePath('/videoask'),
  interview: getScopePath('/interview'),
  preOffer: getScopePath('/pre-offer'),
}

/**
 * Map of stages to their respective paths
 */
export const stagePathname: { [key in Stage]: string } = {
  [Stage.Onboarding]: '/application/introduction',
  [Stage.Quiz]: '/application',
  [Stage.CodingChallenge]: '/application/coding-challenge',
  [Stage.Videoask]: '/application/videoask',
  [Stage.TechnicalInterview]: '/application/interview',
  [Stage.CultureFitInterview]: '/application/interview',
  [Stage.Discussing]: '/application/interview',
  [Stage.PreOffer]: '/application/pre-offer',
  [Stage.BuildingProfile]: '/profile',
  [Stage.Matching]: '/profile',
  [Stage.Hired]: '/profile',
}

export const RejectionPage = loadableHeaderLayout(
  () =>
    import(/* webpackChunkName: "Rejection" */ 'pages/Application/Rejection')
)

export const applicationSharedRoutes = [
  {
    component: loadableHeaderLayout(
      () =>
        import(
          /* webpackChunkName: "Offboarding" */ 'pages/Application/Offboarding'
        )
    ),
    path: APPLICATION_ROUTES.offboarding,
    exact: true,
  },
]

export const applicationRoutes = [
  {
    component: loadablePublicLayout(
      () =>
        import(
          /* webpackChunkName: "Application Onboarding" */ 'pages/Application/Onboarding'
        )
    ),
    path: APPLICATION_ROUTES.onboarding,
    stage: Stage.Onboarding,
  },
  {
    component: loadableSidebarLayout(
      () =>
        import(/* webpackChunkName: "Quizzes" */ 'pages/Application/Quizzes')
    ),
    path: APPLICATION_ROUTES.quizzes,
    stage: Stage.Quiz,
  },
  {
    component: loadableSidebarLayout(
      () =>
        import(
          /* webpackChunkName: "CodingChallenge" */ 'pages/Application/CodingChallenge'
        )
    ),
    path: APPLICATION_ROUTES.codingChallenge,
    stage: Stage.CodingChallenge,
    exact: true,
  },
  {
    component: loadableSidebarLayout(
      () =>
        import(/* webpackChunkName: "VideoAsk" */ 'pages/Application/VideoAsk')
    ),
    path: APPLICATION_ROUTES.videoask,
    stage: Stage.Videoask,
    exact: true,
  },
  {
    component: loadableHeaderLayout(
      () =>
        import(
          /* webpackChunkName: "RecordVideoAsk" */ 'pages/Application/RecordVideoAsk'
        )
    ),
    path: `${APPLICATION_ROUTES.videoask}/record`,
    stage: Stage.Videoask,
    exact: true,
  },
  {
    component: loadableHeaderLayout(
      () =>
        import(
          /* webpackChunkName: "SubmitVideoAsk" */ 'pages/Application/SubmitVideoAsk'
        )
    ),
    path: `${APPLICATION_ROUTES.videoask}/submit`,
    stage: Stage.Videoask,
    exact: true,
  },
  {
    component: loadableSidebarLayout(
      () =>
        import(
          /* webpackChunkName: "Interview" */ 'pages/Application/Interview'
        )
    ),
    path: APPLICATION_ROUTES.interview,
    stage: Stage.TechnicalInterview,
  },
  {
    component: loadableSidebarLayout(
      () =>
        import(
          /* webpackChunkName: "Interview" */ 'pages/Application/Interview'
        )
    ),
    path: APPLICATION_ROUTES.interview,
    stage: Stage.CultureFitInterview,
  },
  {
    component: loadableHeaderLayout(
      () =>
        import(/* webpackChunkName: "PreOffer" */ 'pages/Application/PreOffer')
    ),
    path: APPLICATION_ROUTES.preOffer,
    stage: Stage.PreOffer,
    exact: true,
  },
]
