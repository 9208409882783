import {
  ProfileDashboardIllustration,
  ProfileExperienceIllustration,
  ProfileVisibleIllustration,
} from 'assets/illustrations'

export const WALKTHROUGH_INTRO_STEPS = [
  {
    title: 'Introduction',
    description:
      'You succeeded our hiring process and you are now ready to build your profile and get matched with our Startup network.',
    Illustration: ProfileDashboardIllustration,
  },
  {
    title: 'Build Your Profile',
    description:
      'The profile encapsulates information about you as well as our assessment from the hiring process.',
    Illustration: ProfileExperienceIllustration,
  },
  {
    title: 'Get Matched',
    description:
      'Once your profile is built, you can opt-in to share your profile with our Startup network.',
    Illustration: ProfileVisibleIllustration,
  },
]
