export const createStorage = (key: string, defaultValue?: string) => {
  return {
    /**
     * value getter
     */
    get: () => {
      try {
        return localStorage.getItem(key) || defaultValue
      } catch (err) {
        // TODO: probablu show a UI to the user suggesting
        // to allow access to localStorage
        return defaultValue || null
      }
    },
    /**
     * clears value from localStorage
     */
    clear: () => {
      try {
        localStorage.removeItem(key)
      } catch (err) {}
    },
    /**
     * sets value to localStorage
     * @param {string} value - value
     */
    set: (value: string) => {
      if (!value) {
        throw new Error(
          `Value was not provided to localStorage.set with key=${key}`
        )
      }
      try {
        localStorage.setItem(key, value)
      } catch (err) {}
    },
  }
}

export const authStorage = createStorage('token')

/** Store user id in localStorage */
export const idStorage = createStorage('_id')

export const walkthroughStorage = {
  ...createStorage('hasSeenWalkthrough'),
  hide() {
    this.set('true')
  },
}

type UTMTags =
  | 'utm_source'
  | 'utm_campaign'
  | 'utm_medium'
  | 'utm_term'
  | 'utm_content'

export const UTMStorage = {
  set: (userId: string, utmTags: Record<UTMTags, unknown>): void => {
    localStorage.setItem(
      `utm_tags_${userId}`,
      JSON.stringify(
        Object.assign(
          {},
          JSON.parse(localStorage.getItem(`utm_tags_${userId}`) ?? '{}'),
          JSON.parse(JSON.stringify(utmTags))
        )
      )
    )
  },
  get: (userId: string | undefined): Record<UTMTags, unknown> | undefined => {
    return JSON.parse(localStorage.getItem(`utm_tags_${userId}`) ?? '{}')
  },
}
