import { gql } from '@apollo/client'
import { QUIZ_SUBMISSION_FRAGMENT } from '../quiz/fragments'

export const INTERVIEW_FRAGMENT = gql`
  fragment Interview on Interview {
    _id
    interviewer {
      _id
      name
      avatar
    }
    from
    to
    inviteeCalendlyId
    status
  }
`

export const ASSESSMENT_FRAGMENT = gql`
  fragment Assessment on Assessment {
    _id
    type
    stage
    status
    interview {
      ...Interview
    }
    submissions {
      ...QuizSubmission
    }
  }
  ${QUIZ_SUBMISSION_FRAGMENT}
  ${INTERVIEW_FRAGMENT}
`

export const APPLICATIONFORM_FRAGMENT = gql`
  fragment CandidatePreferences on CandidatePreferences {
    primaryRole
    availability
    secondaryRoles
    contractType
    englishProficiency
    frenchProficiency
    techStack
    experience
  }
`

export const APPLICATION_FRAGMENT = gql`
  fragment Application on Application {
    _id
    progress
    status
    track
    seenRejection
    rejectionReason
    rejectedAt
    prevAssessment {
      stage
    }
    currentAssessment {
      ...Assessment
    }
    assessments {
      ...Assessment
    }
    applicationForm: preferences {
      ...CandidatePreferences
    }
  }
  ${ASSESSMENT_FRAGMENT}
  ${APPLICATIONFORM_FRAGMENT}
`
