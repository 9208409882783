import { Legend } from './Legend'

export const Fieldset = ({ legend, children }: FieldsetProps) => {
  return (
    <fieldset className='space-y-2'>
      <Legend>{legend}</Legend>
      <div>{children}</div>
    </fieldset>
  )
}

interface FieldsetProps {
  className?: string
  legend: string | React.ReactElement
  children: React.ReactNode
}
