import React, { useState } from 'react'
import Dot from 'components/Dot'
import Button from 'components/Button'

import { WALKTHROUGH_INTRO_STEPS } from './data'
import { Link } from 'react-router-dom'

const WalkthroughSteps = () => {
  const [walkthroughStep, setWalkthroughStep] = useState(0)

  const WalkthroughLength = WALKTHROUGH_INTRO_STEPS.length - 1
  const isLast = walkthroughStep === WalkthroughLength

  const handleNext = () => {
    setWalkthroughStep(walkthroughStep + 1)
  }

  const handleBack = () => {
    setWalkthroughStep(walkthroughStep - 1)
  }

  // Array Iterator to iterate through the number of steps
  const WalkthroughIterator = Array(WalkthroughLength + 1).keys()

  const { title, description, Illustration } = WALKTHROUGH_INTRO_STEPS[
    walkthroughStep
  ]

  return (
    <div className='border border-gray-100 rounded-md'>
      <div className='h-80'>
        <Illustration className='mx-auto my-6' width='320' height='250' />
      </div>

      <div className='h-56 bg-brand-50 text-center py-6 px-20'>
        <div>
          <h1 className='text-2xl font-bold'>{title}</h1>
          <p className='text-xs mt-2'>{description}</p>
        </div>

        <div className='w-full flex justify-center space-x-1 my-6 mx-auto'>
          {Array.from(WalkthroughIterator).map((_, index) => (
            <Dot active={walkthroughStep === index} key={index} />
          ))}
        </div>

        <div className='flex justify-center space-x-2.5'>
          {walkthroughStep > 0 && (
            <Button
              variant='custom'
              className='w-24'
              hasIcon={false}
              onClick={handleBack}>
              Back
            </Button>
          )}
          {isLast ? (
            <Button
              component={Link}
              to='/profile/introduction'
              variant='secondary'
              hasIcon={false}>
              Start build profile
            </Button>
          ) : (
            <Button variant='secondary' hasIcon={false} onClick={handleNext}>
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default WalkthroughSteps
