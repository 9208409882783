import clsx from 'clsx'

import { EducationFragment, useDeleteEducationMutation } from 'api'
import { formatDate } from 'lib/helpers/date'
import { MoreButton } from './Components'
import { MenuItem } from 'components/Menu'
import { useHistory } from 'react-router-dom'
import { useDeleteConfirmation } from 'components/Confirmation'

const Education = ({ data, isLast }: EducationProps) => {
  const history = useHistory()
  const { _id, startDate, endDate, diploma, school, location, remote } = data
  const [deleteEducation, { loading }] = useDeleteEducationMutation()
  const confirmDelete = useDeleteConfirmation()

  // TODO: handle delete loading animation
  const handleDeleteEducation = () => {
    if (loading) return
    try {
      confirmDelete({
        onDelete: () => deleteEducation({ variables: { id: data._id } }),
        heading: 'Delete education',
        subHeading: 'Are you sure you want to permanently delete this item?',
      })
    } catch (err) {}
  }

  return (
    <li className='space-x-4 contents'>
      <span className='text-right text-brand-150 text-tiny'>
        <time
          className='block'
          dateTime={endDate || new Date().toISOString().substring(0, 10)}>
          {formatDate(endDate)}
        </time>
        <time className='block' dateTime={startDate}>
          {formatDate(startDate)}
        </time>
      </span>
      <div className='relative'>
        <MoreButton className='top-0 -right-2' label={diploma}>
          <MenuItem
            onClick={() => {
              history.push(`/profile/education/${_id}`)
            }}>
            Update education
          </MenuItem>
          <MenuItem className='text-alert' onClick={handleDeleteEducation}>
            Delete education
          </MenuItem>
        </MoreButton>
        <svg
          aria-hidden='true'
          className={clsx(
            'absolute z-0 h-full top-3 left-0.5 text-brand-gray-150',
            isLast && 'hidden'
          )}
          width='1'
          height='100%'>
          <line stroke='currentColor' x1='1' x2='1' y1='0' y2='100%' />
        </svg>
        <div className='flex items-start w-11/12 space-x-4'>
          <svg
            aria-hidden='true'
            className='absolute z-10 flex-grow-0 flex-shrink-0 mt-2 rounded-full ring-1 ring-white text-brand-gray-150'
            viewBox='0 0 5 5'
            fill='currentColor'
            width='5'>
            {/* <circle className='text-white' cx='3' cy='3' r='3' /> */}
            <circle cx='2.5' cy='2.5' r='2.5' />
          </svg>
          <div className={clsx('space-y-2', !isLast && 'pb-7')}>
            <h4 className='text-sm font-bold text-brand-700'>{diploma}</h4>
            <div className='space-y-0.5'>
              <h5 className='text-tiny text-brand-900 text-opacity-70'>
                {school}
              </h5>
              <h6 className='text-tiny text-brand-gray-200'>
                {remote ? `${location} (Remote)` : location}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

interface EducationProps {
  data: EducationFragment
  isLast: boolean
}
export default Education
