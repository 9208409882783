import { CANDIDATE_FRAGMENT } from '../user'
import { gql } from '@apollo/client'

export const GITHUB_AUTH_MUTATION = gql`
  mutation GithubAuth($code: LimitedString!, $utmSource: LimitedString) {
    githubAuth(code: $code, utmSource: $utmSource) {
      token
      me {
        ...Candidate
      }
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const GOOGLE_AUTH_MUTATION = gql`
  mutation GoogleAuth($code: LimitedString!, $utmSource: LimitedString) {
    googleAuth(code: $code, utmSource: $utmSource) {
      token
      me {
        ...Candidate
      }
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const SIGN_UP_MUTATION = gql`
  mutation SignUp(
    $email: Email!
    $password: Password!
    $firstName: LimitedString!
    $lastName: LimitedString!
    $country: LimitedString!
    $utmSource: LimitedString
  ) {
    signUp(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      country: $country
      utmSource: $utmSource
    ) {
      token
      me {
        ...Candidate
      }
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const SIGN_IN_MUTATION = gql`
  mutation SignIn($email: Email!, $password: Password!) {
    signIn(email: $email, password: $password) {
      token
      me {
        ...Candidate
      }
    }
  }
  ${CANDIDATE_FRAGMENT}
`

export const SEND_PASSWORD_RESET_EMAIL_MUTATION = gql`
  mutation SendPasswordResetEmail($email: Email!) {
    sendPasswordResetEmail(email: $email) {
      timestamp
      emailSent
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($password: Password!, $code: LimitedString!) {
    resetPassword(password: $password, code: $code)
  }
`
