import { useMemo } from 'react'
import { Field, FormField } from 'components/Inputs'
import Anchor from 'components/Anchor'
import Button from 'components/Button'
import {
  countryToFlag,
  getCountries,
  getCountryByCode,
} from 'lib/helpers/getCountries'
import PhoneNumber from 'components/PhoneNumber'
import { useEditBasicInfo } from './useForm'
import { useReadUserQuery } from 'api'
import { openGrooveConversation } from 'lib/configs/monitoring/groove'

const ChangeBasicInfo = () => {
  const {
    me: { country, firstName, lastName, phone },
  } = useReadUserQuery()

  // Injecting formik utils
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    isSubmitting,
    touched,
    values,
    errors,
    isValid,
  } = useEditBasicInfo({
    firstName,
    lastName,
    phone,
    country,
  })

  const phoneCountry = useMemo(() => getCountryByCode(values.phone.code), [
    values.phone,
  ])

  const countryFlag = useMemo(() => {
    const countryObj = getCountries(country)[0]
    return countryObj ? countryToFlag(countryObj.code) : ''
  }, [country])

  return (
    <form className='mb-6' onSubmit={handleSubmit}>
      <p className='mb-6'>Personal Information</p>
      <div className='mb-4'>
        <label className='text-xs text-brand-150'>First name</label>
        <FormField
          id='firstName'
          name='firstName'
          onChange={handleChange}
          onBlur={handleBlur}
          className='sm:w-4/6'
          value={values.firstName}
          error={
            errors.firstName && touched.firstName ? errors.firstName : undefined
          }
        />
      </div>

      <div className='mb-4'>
        <label className='text-xs text-brand-150'>Last name</label>
        <FormField
          id='lastName'
          name='lastName'
          onChange={handleChange}
          onBlur={handleBlur}
          className='sm:w-4/6'
          value={values.lastName}
          error={
            errors.lastName && touched.lastName ? errors.lastName : undefined
          }
        />
      </div>

      <div className='mb-4'>
        <label className='text-xs text-brand-150'>Phone number</label>
        <Field className='sm:w-4/6'>
          <PhoneNumber>
            <PhoneNumber.Dial
              value={phoneCountry}
              onChange={(value) => {
                setValues({
                  ...values,
                  phone: {
                    ...values.phone,
                    code: value.code,
                    dial: value.dial,
                  },
                })
              }}
            />
            <PhoneNumber.Input
              value={values.phone.localNumber}
              hasError={!!errors.phone}
              onChange={(event) => {
                setValues({
                  ...values,
                  phone: {
                    ...values.phone,
                    localNumber: event.target.value,
                  },
                })
              }}
            />
          </PhoneNumber>
        </Field>
      </div>

      <div className='mt-2'>
        <label className='text-xs text-brand-150'>Country of residence</label>

        <div className='flex justify-between space-x-4 mt-4'>
          {country ? (
            <>
              <p className='text-sm capitalize whitespace-nowrap sm:leading-none'>
                {`${countryFlag} ${country}`}{' '}
              </p>
              <span className='text-xxs text-brand-150'>
                Changed country of residence?{' '}
                <Anchor
                  onClick={openGrooveConversation}
                  type='button'
                  component='button'>
                  Contact us
                </Anchor>
              </span>
            </>
          ) : (
            <p className='text-xxs text-brand-150'>
              No country has been selected. We will ask you to select a country
              of residence once the quiz step is submitted.{' '}
            </p>
          )}
        </div>
      </div>

      <div className='mt-6 w-full flex justify-end'>
        <Button
          style={{ minWidth: '110px' }}
          type='submit'
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
          hasIcon={false}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default ChangeBasicInfo
