import React from 'react'
import PropTypes from 'prop-types'
import FocusBox from './Focus'
import { applicationStageType } from 'lib/enums'

const DashboardLayout = ({ type, children }) => {
  switch (type) {
    case applicationStageType.focus:
      return (
        <FocusBox>
          <FocusBox.Wrapper>{children}</FocusBox.Wrapper>
        </FocusBox>
      )
    default:
      return children
  }
}

DashboardLayout.propTypes = {
  /**
   * the wrapper type of the page
   */
  type: PropTypes.oneOf([
    applicationStageType.focus,
    applicationStageType.funnel,
  ]),
}

export { default as FocusBox } from './Focus'

export default DashboardLayout
