import { ReactNode } from 'react'
import { ExternalLinkSVG, InfoSVG } from 'assets/icons'
import clsx from 'clsx'

const HowToCard = ({ title, link, className, children }: HowToCardProps) => {
  return (
    <div
      className={clsx(
        'bg-brand-900 rounded-lg py-6 px-4 text-white space-y-8',
        className
      )}>
      <div className='space-y-3.5'>
        <div className='flex justify-between items-center'>
          <h3 className='font-semibold text-md'>{title}</h3>
          <div>
            <InfoSVG color='white' width='22px' height='22px' />
          </div>
        </div>

        {children}
      </div>
      <div className='flex items-baseline justify-end space-x-2.5'>
        <a
          className='font-medium text-xxs'
          target='_blank'
          rel='noopener noreferrer'
          href={link}>
          Open profile example
        </a>
        <span>
          <ExternalLinkSVG
            className='inline-block'
            width='16px'
            height='16px'
            color='white'
          />
        </span>
      </div>
    </div>
  )
}

interface HowToCardProps {
  title: string
  link: string
  className?: string
  children: ReactNode
}

export default HowToCard
