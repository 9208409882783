import {
  Fragment,
  PropsWithChildren,
  useState,
  forwardRef,
  ComponentProps,
} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Input } from 'components/Inputs'
import { Option } from 'components/Select/SearchSelect'
import {
  countryToFlag,
  CountryType,
  getCountries,
} from 'lib/helpers/getCountries'

const countries = getCountries()

const searchCountriesByCode = (search: string) => {
  const adjustedSearch = search.toLowerCase().replace('+', '')
  return countries
    .filter(
      (country) =>
        country.dial.toLowerCase().toLowerCase().includes(adjustedSearch) ||
        country.name.toLowerCase().includes(adjustedSearch)
    )
    .sort()
}

const borderStyles = 'border-brand-100 ring-brand-300'

const errorStyles = 'ring-alert ring-opacity-30 border-alert'

const PhoneNumberDial = ({
  value,
  onChange,
  hasError = false,
}: PhoneNumberDialProps) => {
  const [search, setSearch] = useState('')
  return (
    <Combobox value={value} onChange={onChange}>
      {({ open }) => (
        <div className='relative'>
          <Combobox.Input
            autoComplete='off'
            type='text'
            placeholder='Dial'
            className={clsx(
              'w-full p-2.5 pl-3.5 font-inter text-md font-medium text-left cursor-pointer placeholder-brand-900 placeholder-opacity-40',
              'border rounded-l-lg shadow-sm hover:bg-brand-40',
              'focus:outline-none focus-visible:ring-2',
              hasError ? errorStyles : borderStyles
            )}
            displayValue={(country?: CountryType) =>
              country ? `+${country.dial}` : ''
            }
            onChange={(event) => setSearch(event.target.value)}
          />

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-in duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options
              className={clsx(
                'absolute z-10 max-h-60 py-1 mt-1 w-full rounded-md bg-white shadow-lg',
                'text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5'
              )}>
              {searchCountriesByCode(search).map((country: CountryType) => (
                <Option key={country.dial} value={country}>
                  {countryToFlag(country.code)} +{country.dial}
                </Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  )
}

interface PhoneNumberDialProps {
  value?: CountryType
  onChange: (value: CountryType) => void
  hasError?: boolean
  placeholder?: string
}

const PhoneNumberInput = forwardRef<
  HTMLInputElement,
  ComponentProps<typeof Input>
>((props, ref) => {
  return (
    <Input
      {...props}
      ref={ref}
      type='tel'
      inputMode='tel'
      className='rounded-tl-none rounded-bl-none focus:z-10'
    />
  )
})

const PhoneNumber = ({ children }: PropsWithChildren<{}>) => (
  <div className='flex text-brand-900'>{children}</div>
)

PhoneNumber.Dial = PhoneNumberDial
PhoneNumber.Input = PhoneNumberInput

export default PhoneNumber
