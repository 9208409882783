import { useEffect, useState } from 'react'
import { InfoSVG } from 'assets/icons'

import Button from 'components/Button'
import Modal from 'components/Modal'
import clsx from 'clsx'
import { ModalStateType as StagesModalProps } from 'lib/contexts/modal'
import { LightningEmoji } from 'assets/emojis'
import { walkthroughStorage } from 'lib/helpers/localStorage'
import Dot from 'components/Dot'
import { useReadUser } from 'actions/user'
import { Assessment } from 'api'
import { intersectionBy } from 'lodash'
import { STAGES_STEP_COTENT } from './data'

const StagesInfo = ({
  onClose,
  open = false,
}: Pick<StagesModalProps, 'onClose' | 'open'>) => {
  const data = useReadUser()
  const assessments: Assessment[] =
    data?.me.currentApplication.assessments || []

  const stages = intersectionBy(STAGES_STEP_COTENT, assessments, 'stage')

  const [step, setStep] = useState(0)
  const isLastStep = step >= stages.length - 1
  const isFirstStep = step > 0

  useEffect(() => {
    if (walkthroughStorage.get() !== 'true') {
      walkthroughStorage.hide()
    }
  }, [])

  const closeModal = () => {
    onClose()
    setTimeout(() => setStep(0), 1000)
  }

  const handleNextStep = () => {
    if (isLastStep) {
      closeModal()
      return
    }
    setStep(step + 1)
  }

  const handleBack = () => {
    if (step < 0) return
    setStep(step - 1)
  }

  return (
    <Modal open={open} onClose={onClose} className='mt-28'>
      <div className='max-w-xl bg-white rounded-lg'>
        {stages.map(
          (
            {
              title,
              subtitle,
              hint,
              illustration: { icon: SVGIllustration, height, width },
            },
            index
          ) => (
            <ol
              className={clsx(
                'px-12',
                index === step ? 'inline-block' : 'hidden'
              )}
              key={index}>
              <li
                className={clsx(
                  'relative pb-6 pl-6',
                  index > 0 &&
                    'border-l border-brand-100 last:border-transparent'
                )}>
                <div className='flex justify-between'>
                  <div className='flex mt-6 space-x-2'>
                    <InfoSVG className='self-center w-4 h-4 text-brand-900' />
                    <h3 className='font-inter text-sm font-medium text-brand-900'>
                      Gemography application process
                    </h3>
                  </div>
                </div>
              </li>
              <li className='relative pb-7 pl-6 border-l border-brand-100 last:border-transparent'>
                <span className='absolute top-0 -left-2.5 w-5 h-5 bg-white rounded-full border-7 border-brand-500' />
                <h5 className='block relative -top-1 font-inter text-xl font-medium text-black'>
                  {title}
                </h5>
              </li>
              <li
                className={clsx(
                  'relative pb-16 pl-6 border-l border-brand-100'
                )}>
                <div className='flex'>
                  <SVGIllustration
                    height={height}
                    width={width}
                    className='items-center w-1/2'
                  />
                  <div
                    className={clsx(
                      'flex w-1/2',
                      hint && 'flex-col space-y-2',
                      'text-brand-gray-200'
                    )}>
                    <p className='self-center px-4 font-inter text-sm font-normal'>
                      {subtitle}
                    </p>
                    {hint && (
                      <p
                        className={clsx(
                          'relative py-3 px-2 ml-4 rounded-sxl',
                          'text-brand-yellow-800 bg-brand-yellow-200',
                          'font-roboto text-tiny font-normal leading-4.5'
                        )}>
                        {hint}
                        <LightningEmoji
                          width='10'
                          className='absolute right-4 bottom-3.5'
                        />
                      </p>
                    )}
                  </div>
                </div>
              </li>
            </ol>
          )
        )}
        <div className='flex justify-between items-center py-6 px-8 shadow-footer'>
          <div className='flex items-center space-x-1'>
            {[...Array(stages.length)].map((_, index) => (
              <Dot active={step === index} key={index} />
            ))}
          </div>
          <div>
            {isFirstStep && !isLastStep && (
              <Button
                variant='custom'
                className='py-3 px-8 text-sm text-brand-900'
                onClick={handleBack}>
                Back
              </Button>
            )}
            <Button
              center={isLastStep}
              hasIcon={!isLastStep}
              onClick={handleNextStep}>
              {isLastStep ? 'Got it' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StagesInfo
