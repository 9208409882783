import React, { useState } from 'react'
import Button from 'components/Button'
import { FormField } from 'components/Inputs'
import Callout from 'components/Callout'
import { useChangePassword } from './useForm'
import Anchor from 'components/Anchor'

const ChangePassword = () => {
  const [editMode, setEditMode] = useState(false)
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useChangePassword(() => setEditMode(false))

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e)
        if (!(touched.password && errors.password) && isSubmitting) {
          setEditMode(false)
        }
      }}>
      <div className='flex flex-col space-y-4 my-4'>
        <div className='flex justify-between'>
          <label className='text-xs text-brand-150'>Password</label>
          {editMode && (
            <Anchor
              className='text-xs'
              component='span'
              onClick={() => setEditMode(false)}>
              Cancel
            </Anchor>
          )}
        </div>

        {editMode ? (
          <div className='mt-4'>
            <div className='my-4'>
              <label className='text-xs text-brand-150'>Old Password</label>
              <FormField
                name='password'
                id='password'
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                className='w-4/6'
                value={values.password}
                error={
                  errors.password && touched.password
                    ? errors.password
                    : undefined
                }
              />
            </div>
            <div className='mb-4'>
              <label className='text-xs text-brand-150'>New Password</label>
              <FormField
                name='newPassword'
                id='newPassword'
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                className='w-4/6'
                value={values.newPassword}
                error={
                  errors.newPassword && touched.newPassword
                    ? errors.newPassword
                    : undefined
                }
              />
            </div>
            <div className='mb-4'>
              <label className='text-xs text-brand-150'>Confirm Password</label>
              <FormField
                name='confirmPassword'
                id='confirmPassword'
                type='password'
                onChange={handleChange}
                onBlur={handleBlur}
                className='w-4/6'
                value={values.confirmPassword}
                error={
                  errors.confirmPassword && touched.confirmPassword
                    ? errors.confirmPassword
                    : undefined
                }
              />
            </div>
          </div>
        ) : (
          <Anchor
            className='text-xs'
            component='span'
            onClick={() => {
              setEditMode(true)
            }}>
            Change password
          </Anchor>
        )}
      </div>

      {!editMode && (
        <Callout icon='💡'>
          <Callout.Body>
            <span>
              You can always reset your password in case you signed up via
              Google / Github or if you don't remember your password.
            </span>
          </Callout.Body>
        </Callout>
      )}

      <div className='mt-6 w-full flex justify-end'>
        <Button
          style={{ minWidth: '110px' }}
          type='submit'
          disabled={isSubmitting || !isValid}
          loading={isSubmitting}
          hasIcon={false}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default ChangePassword
