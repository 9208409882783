import {
  ComponentPropsWithoutRef,
  ReactElement,
  Fragment,
  useState,
} from 'react'
import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { CaretDownSVG, CheckMarkSVG } from 'assets/icons'

const SearchSelect = <T,>({
  value,
  onChange,
  children,
  options,
  placeholder,
  displayValue,
  className,
}: SelectProps<T>) => {
  const [search, setSearch] = useState('')

  return (
    <Combobox value={value} onChange={onChange}>
      {({ open }) => (
        <div className={clsx('relative', className)}>
          <Combobox.Input
            autoComplete='off'
            placeholder={placeholder}
            className={clsx(
              'relative w-full p-3.5 text-md text-left cursor-pointer',
              'bg-white border border-brand-100 rounded-lg shadow-card',
              'hover:bg-brand-40',
              'focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm'
            )}
            onChange={(event) => setSearch(event.target.value)}
            displayValue={displayValue}
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center px-3.5 rounded-r-md focus:outline-none'>
            <CaretDownSVG
              className='w-4 h-4 ml-2 -mr-1 text-brand-900'
              aria-hidden='true'
            />
          </Combobox.Button>

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-in duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options
              className={clsx(
                'absolute z-10 max-h-60 py-1 mt-1 w-full rounded-md bg-white shadow-lg',
                'text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5'
              )}>
              {children({ options, search, value })}
            </Combobox.Options>
          </Transition>
        </div>
      )}
    </Combobox>
  )
}

export const Option = <T,>({
  children,
  className,
  ...props
}: SelectOptionProps<T>) => {
  return (
    <Combobox.Option
      className={({ active }) =>
        clsx(
          'py-2 px-4',
          active && 'bg-brand-50',
          'cursor-default select-none relative'
        )
      }
      {...props}>
      {({ selected }) => (
        <>
          <span
            className={clsx(
              selected ? 'font-semibold' : 'font-normal',
              'text-sm block truncate'
            )}>
            {children}
          </span>
          {selected && (
            <span className='absolute inset-y-0 right-0 flex items-center pr-3.5 pointer-events-none'>
              <CheckMarkSVG className='w-5 h-5 ml-2 -mr-1 text-brand-500' />
            </span>
          )}
        </>
      )}
    </Combobox.Option>
  )
}

type SelectOptionProps<T> = ComponentPropsWithoutRef<typeof Combobox.Option> & {
  value: T
}

type SelectProps<T> = {
  value?: T
  options: T[]
  onChange: (value: T) => void
  /**
   * Display value for selected option
   */
  displayValue?: (value: T) => string

  placeholder: string
  className?: string
  children: (options: {
    search: string
    options: T[]
    value: T | undefined
  }) => ReactElement<SelectOptionProps<T>, typeof Option>[]
}

SearchSelect.Option = Option

export default SearchSelect
