import { Menu } from '@headlessui/react'
import clsx from 'clsx'

export const MenuButton = Menu.Button

export const MenuItems = ({ className, ...props }: MenuItemsProps) => (
  <Menu.Items
    as='ul'
    className={clsx(
      'absolute z-10',
      'bg-white rounded-sxl shadow-sidebar',
      'max-w-xxs w-screen text-tiny',
      'border border-brand-100',
      'focus:outline-none focus-visible:ring-1 ring-brand-300 ring-offset-4',
      'divide-y divide-brand-70',
      className
    )}
    {...props}
  />
)

export const MenuItem = ({ className, ...props }: MenuItemProps) => (
  <Menu.Item as='li'>
    {({ active }) => (
      // TODO: make this polymorphic
      <button
        className={clsx(
          'px-3 py-2.5 w-full rounded-md',
          'text-xxs text-left',
          'hover:bg-brand-50',
          active && 'bg-brand-70',
          'focus:outline-none focus-within:bg-brand-70',
          className
        )}
        {...props}
      />
    )}
  </Menu.Item>
)

interface MenuItemsProps {
  className?: string
  children?: React.ReactNode
}

type MenuItemProps = React.HTMLAttributes<HTMLButtonElement>

export default Menu
