import React, { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'

const baseStyles =
  'pl-3.5 text-md text-brand-900 py-2.5 w-full border shadow-card rounded-lg shadow-sm font-medium focus:outline-none focus-visible:ring-2 placeholder-brand-900 placeholder-opacity-40 font-inter'
const borderStyles = 'ring-brand-300 border-brand-100'
const disabledStyles =
  'disabled:bg-transparent disabled:border-transparent disabled:text-opacity-20 disabled:cursor-not-allowed disabled:bg-disabledInptBg disabled:shadow-card'
const borderErrorStyles = 'ring-alert ring-opacity-30 border-alert'

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, hasError, focusStyle = true, ...props }, ref) => (
    <input
      className={clsx(
        baseStyles,
        disabledStyles,
        hasError ? borderErrorStyles : borderStyles,
        // if focusStyle is false (i.e., we don't want the input to use focus styles ⏎
        // -- use case: provide focus styles form the parent with focus-within) ⏎
        // disable ring. NOTE: ring-none is defined in src/styles/index.css.
        // This will let border-alert be set if hasError. In my use case that was useful.
        { 'ring-none': !focusStyle },
        className
      )}
      ref={ref}
      {...props}
    />
  )
)

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, hasError, ...props }, ref) => (
    <textarea
      className={clsx(
        baseStyles,
        hasError ? borderErrorStyles : borderStyles,
        className
      )}
      ref={ref}
      {...props}
    />
  )
)

export const FieldMessage = ({ className, ...props }: FieldMessageProps) => (
  <span className={clsx('text-xxs ml-3.5', className)} {...props} />
)

/**
 * checks is element is part of inputs
 * @param {React.ReactNode} child
 */
const isChildInput = (child: ReactElement) =>
  child && (child.type === Input || child.type === TextArea)

export const Field = React.forwardRef<HTMLInputElement, FieldProps>(
  ({ children, component: Component = 'div', hasError, ...props }, ref) => {
    return (
      <Component ref={ref} {...props}>
        {React.Children.map(children as ReactElement, (child: ReactElement) => {
          return isChildInput(child)
            ? React.cloneElement(child, { hasError, ...child.props })
            : child
        })}
      </Component>
    )
  }
)

export const FormField = React.forwardRef<HTMLInputElement, FormFieldProps>(
  ({ label, error, className, disabled, ...props }, ref) => {
    const hasError = !!error
    return (
      <Field
        component='label'
        className={clsx(
          'block space-y-2 text-brand-900',
          disabled && 'text-opacity-20',
          className
        )}>
        <span>{label}</span>
        <Input hasError={hasError} ref={ref} {...props} disabled={disabled} />
        {hasError ? (
          <FieldMessage className='text-alert'>{error}</FieldMessage>
        ) : null}
      </Field>
    )
  }
)

interface TextFieldProps {
  hasError?: boolean
  focusStyle?: boolean
  error?: string
}

interface InputProps
  extends TextFieldProps,
    React.ComponentPropsWithRef<'input'> {}

interface TextAreaProps
  extends TextFieldProps,
    React.ComponentPropsWithRef<'textarea'> {}

interface FormFieldProps extends React.ComponentPropsWithRef<'input'> {
  label?: string
  error?: string
  className?: string
}

interface FieldMessageProps {
  className?: string
  children?: ReactNode
}

interface FieldProps {
  children: ReactNode[]
  component?: React.ElementType
  className?: string
  hasError?: boolean
}
