import LogRocket from 'logrocket'

/**
 *
 * @param {object} condidate - candidate object
 */
export const setLogrocketUser = (condidate) => {
  // only the id is necessary, other details are just to
  // have a more readable issue tracking.
  LogRocket.identify(condidate._id, {
    name: condidate.name,
    email: condidate.email,
  })
}

export const logRocketConfig = () => {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
    // This will be dynamic once we add semantic versioning
    release: process.env.REACT_APP_RELEASE,
    network: {
      requestSanitizer: (request) => {
        if (request.url !== process.env.REACT_APP_API_URI) return
        // we remove the authorization header from
        // the captured requests
        request.headers.authorization = undefined

        // we remove sensitive data from the request (i.e. password)
        if (request.body.includes('signUp')) {
          request.body = ''
        }
        return request
      },
    },
  })
}
