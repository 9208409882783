import { sentryConfig } from './sentry'
import {
  logRocketConfig,
  setLogrocketUser,
} from 'lib/configs/monitoring/logrocket'
import { Candidate } from 'api'
import * as Sentry from '@sentry/react'
import { setGrooveUserProperties } from 'lib/configs/monitoring/groove'
import { useSetMixpanelUserProps } from 'lib/contexts/Mixpanel'
/**
 * initializes application error monitoring
 */
export const setupMonitoring = () => {
  // logrocket needs to be setup before sentry
  // because we use logrocket session to connect both
  logRocketConfig()
  sentryConfig()
}

/**
 * Returns a function that identifies the user
 * in the third party services
 */
export const useIdentifyUser = () => {
  // set mixpanel user properties
  const identifyUser = useSetMixpanelUserProps()

  return (candidate: Partial<Candidate>) => {
    // we link the user to logrocket session
    setLogrocketUser(candidate)
    // we identify mixpanel profile
    identifyUser(candidate)
    // We set the candidate info to Groove
    setGrooveUserProperties(candidate)
    // identify sentry user
    Sentry.setUser({ id: candidate._id, email: candidate.email })
  }
}
