import clsx from 'clsx'
import React from 'react'

const variants = {
  primary: 'bg-badge-primary-500',
  secondary: 'bg-brand-900',
}

const Status = ({ value, variant, className }: StatusProps) => {
  return (
    <div
      className={clsx(
        'flex gap-x-1.5 items-center text-xxs text-[#6A737D] font-medium',
        className
      )}>
      <span
        className={clsx(
          'inline-block w-2.5 h-2.5 rounded-full',
          variants[variant]
        )}
        aria-hidden='true'
      />
      {value}
    </div>
  )
}

export interface StatusProps {
  className?: string
  value: string
  variant: 'primary' | 'secondary'
}

export default Status
