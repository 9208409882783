import 'focus-visible'
import 'react-app-polyfill/stable'
import 'promise-polyfill/src/polyfill'
import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import TagManager from 'react-gtm-module'
import './styles/tailwind.css'
import App from './App'
import ErrorPage from 'pages/Error'
import GraphqlProvider from 'lib/contexts/GraphqlProvider'
import { setupMonitoring } from 'lib/configs/monitoring'
import MixpanelProvider from 'lib/contexts/Mixpanel'
import SnackbarProvider from 'lib/contexts/SnackbarProvider'
import { PrismicProvider } from '@prismicio/react'

if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
  // setup google tag manager
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
  }

  TagManager.initialize(tagManagerArgs)
  // Error monitoring needs to only be done on
  // staging/production, that's where we set this env var
  setupMonitoring()
}
if (process.env.REACT_APP_DEPLOY_ENV === 'development') {
  // setup google tag manager
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID as string,
  }

  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        // we let sentry know that the error reached the root level
        scope.setTag('root', 'true')
      }}
      fallback={({ resetError }) => <ErrorPage resetError={resetError} />}>
      <MixpanelProvider>
        <SnackbarProvider>
          <GraphqlProvider>
            <PrismicProvider>
              <App />
            </PrismicProvider>
          </GraphqlProvider>
        </SnackbarProvider>
      </MixpanelProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
