import { ProfilePersonalProjectInput, useAddPersonalProjectMutation } from 'api'
import { FormikProvider, useFormik } from 'formik'
import HowToCard from 'pages/Profile/Shared/HowToCard'
import Input, { FormField } from 'components/InputAlternative'
import InputRichText from 'components/InputRichText'
import Modal from 'components/Modal'
import { DateRangeField, Fieldset, Form } from './Shared'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import useDraft from './useDraft'
import { useEffect } from 'react'
import TechStackMultiselect from 'components/TechStackMultiselect'

const PersonalProjectsModal = () => {
  const formik = useForm()
  const {
    values,
    handleSubmit,
    handleChange,
    setValues,
    isSubmitting,
    errors,
    touched,
  } = formik

  return (
    <FormikProvider value={formik}>
      <div
        className='w-screen h-full max-w-4xl p-6 overflow-y-scroll bg-white rounded-lg scrollbar'
        style={{ maxHeight: '90vh' }}>
        <div className='space-y-8'>
          <div className='space-y-6'>
            <div className='space-y-2'>
              <Modal.Title as='h3' className='text-1.5xl font-bold'>
                Personal Projects
              </Modal.Title>
              <p className='text-xs'>Fill your information.</p>
            </div>
            <HowToCard
              title='How to build your profile'
              link='https://www.notion.so/hiddenpole/Profile-Material-31ba37d683234c37970268f4d5fc84e4'>
              <p className='text-xxs'>
                To build your profile, we would need you to give us all the
                relevant details about your previous experiences. We will be
                helping you with questions so please make sure you answer all of
                them
              </p>
            </HowToCard>
          </div>
          <Form onSubmit={handleSubmit} isSubmitting={isSubmitting}>
            <div className='space-y-10'>
              <Fieldset legend='Project details'>
                <FormField hasError={touched.title && !!errors.title}>
                  <Input
                    value={values.title}
                    onChange={handleChange}
                    id='project-name'
                    aria-label='project-name'
                    name='title'
                    placeholder='Project name'
                  />
                </FormField>
                <DateRangeField />
              </Fieldset>
              <Fieldset legend='Project Description'>
                <FormField hasError={touched.title && !!errors.description}>
                  <InputRichText
                    defaultValue={values.description}
                    onChange={(description: string) => {
                      setValues((values) => ({ ...values, description }))
                    }}
                    id='project-description'
                    placeholder='This is a web app allowing farmers to manage their herds in a flexible way'
                  />
                </FormField>
              </Fieldset>
              <Fieldset
                legend={
                  <>
                    Links <span className='font-normal'>(optional)</span>
                  </>
                }>
                <FormField hasError={touched.demoLink && !!errors.demoLink}>
                  <Input
                    value={values.demoLink || ''}
                    onChange={handleChange}
                    id='project-demo-link'
                    aria-label='project-demo-link'
                    name='demoLink'
                    placeholder='Demo link (https://example.com)'
                  />
                </FormField>
                <FormField hasError={touched.sourceLink && !!errors.sourceLink}>
                  <Input
                    value={values.sourceLink || ''}
                    onChange={handleChange}
                    aria-label='project-source-link'
                    name='sourceLink'
                    placeholder='Source code (https://github.com/username/example)'
                  />
                </FormField>
              </Fieldset>
              <Fieldset legend='Which tech stack did you use?'>
                <TechStackMultiselect
                  values={values.techStack}
                  onChange={(techStack) =>
                    setValues((values) => ({ ...values, techStack }))
                  }
                />
              </Fieldset>
            </div>
          </Form>
        </div>
      </div>
    </FormikProvider>
  )
}

type FormValues = ProfilePersonalProjectInput & {
  ongoing: boolean
}

const validationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
  techStack: Yup.array()
    .min(1, 'Techstacks should not be empty')
    .of(Yup.string())
    .required(),
  demoLink: Yup.string(),
  sourceLink: Yup.string(),
})

const useForm = () => {
  const history = useHistory()
  const [addPersonalProject] = useAddPersonalProjectMutation()
  const draft = useDraft<FormValues>('personal-projects-form')

  const formik = useFormik<FormValues>({
    validationSchema: () => validationSchema,
    initialValues: {
      title: '',
      description: '',
      techStack: [],
      demoLink: undefined,
      sourceLink: undefined,
      startDate: '',
      endDate: undefined,
      ongoing: false,
      ...(draft.value ? draft.value : {}),
    },
    onSubmit: async ({ ongoing, ...values }) => {
      try {
        await addPersonalProject({
          variables: {
            input: {
              ...values,
              endDate: !ongoing ? values.endDate : null,
            },
          },
        })
        draft.clear()
        history.push('/profile')
      } catch (error) {
        // TODO handle errors
      }
    },
    validateOnBlur: false,
    validateOnMount: false,
  })

  useEffect(() => {
    draft.set(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  return formik
}
export default PersonalProjectsModal
