import React from 'react'
import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { authStorage, idStorage, UTMStorage } from 'lib/helpers/localStorage'
import { authLogout } from 'actions/auth'
import cache from './cache'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import { WarningEmoji } from 'assets/emojis'
import { useMixpanel } from 'lib/contexts/Mixpanel'

// Some requests don't appear unless
// we explicitly pass everything to fetch api
// more here: https://docs.logrocket.com/docs/graphql
const fetcher = (...args) => {
  return window.fetch(...args)
}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI,
  fetch: fetcher,
})

/**
 * checks if there is an unauthorized error
 * @param {array} errors - array of graphql errors
 */
const hasErrorMessage = (errors = [], message) => {
  return errors.some((error) => error.message === message)
}

const useCreateClient = () => {
  const [client, setClient] = React.useState(null)

  const showSnackbar = useSnackbar()
  const mixpanel = useMixpanel()

  React.useEffect(() => {
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = authStorage.get()
      const utmTags = UTMStorage.get(idStorage.get())
      // remove keys with value of undefined
      const utmHeaders = JSON.parse(
        JSON.stringify({
          'X-UTM-SOURCE':
            mixpanel.get_property('utm_source') || utmTags.utm_source,
          'X-UTM-CAMPAIGN':
            mixpanel.get_property('utm_campaign') || utmTags.utm_campaign,
          'X-UTM-MEDIUM':
            mixpanel.get_property('utm_medium') || utmTags.utm_medium,
          'X-UTM-TERM': mixpanel.get_property('utm_term') || utmTags.utm_term,
          'X-UTM-CONTENT':
            mixpanel.get_property('utm_content') || utmTags.utm_content,
        })
      )

      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
          ...utmHeaders,
        },
      }
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (
        (networkError && !graphQLErrors) ||
        hasErrorMessage(graphQLErrors, 'unknown_error')
      ) {
        showSnackbar({
          key: 'global_network_error',
          text: 'Something went wrong. Please, try again.',
          icon: WarningEmoji,
        })
      }
      if (!graphQLErrors?.length) return

      // logout user if token is invalid
      if (hasErrorMessage(graphQLErrors, 'invalid_token')) {
        showSnackbar({
          key: 'invalid_token_error',
          text: 'Please, try to login again.',
          icon: WarningEmoji,
        })
        authLogout(newClient)
        return
      }

      // show snackbar with unauthorized message
      if (hasErrorMessage(graphQLErrors, 'unauthorized')) {
        showSnackbar({
          key: 'unauthorized_operation',
          text: 'Permission denied.',
          icon: WarningEmoji,
        })
        return
      }

      if (process.env.NODE_ENV === 'development') {
        graphQLErrors.map(({ message, locations, path }) =>
          // eslint-disable-next-line no-console
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
    })

    const newClient = new ApolloClient({
      link: from([authLink, errorLink, httpLink]),
      cache,
    })

    setClient(newClient)
  }, [showSnackbar, mixpanel])

  return client
}

export default useCreateClient
