import LogRocket from 'logrocket'
import React from 'react'
import { MixpanelContext } from './hooks'
import mixpanel from 'mixpanel-browser'

const MixpanelProvider = ({ children }: { children: React.ReactNode }) => {
  React.useEffect(() => {
    // initializes mixpanel
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
      debug: process.env.NODE_ENV === 'development',
      persistence: 'localStorage',
      // we ignore web browser's Do Not Track setting
      ignore_dnt: true,
      cookie_domain: 'gemography.com',
      cross_subdomain_cookie: true,
    })

    // Add logrocket session to mixpanel profile
    LogRocket.getSessionURL((sessionURL) => {
      mixpanel.track('LogRocket', { sessionURL: sessionURL })
    })
  }, [])

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {children}
    </MixpanelContext.Provider>
  )
}

export * from './hooks'

export default MixpanelProvider
