import { useDeleteWorkExperienceMutation, WorkExperienceFragment } from 'api'
import { useHistory } from 'react-router-dom'
import { BadgeGroup } from 'components/Badge'
import { MenuItem } from 'components/Menu'
import { diffDate, formatDate } from 'lib/helpers/date'
import { InfoBlock, MoreButton } from './Components'
import { useDeleteConfirmation } from 'components/Confirmation'

export enum JobTypeText {
  'fullTime' = 'Full-Time',
  'partTime' = 'Part-Time',
  'freelance' = 'Freelance',
  'internship' = 'Internship',
}

const WorkExperience = ({ data }: WorkExperienceProps) => {
  const {
    _id,
    jobTitle,
    company,
    location,
    description,
    startDate,
    endDate,
    jobType,
    techStack,
    remote,
  } = data
  const history = useHistory()

  const [deleteWorkExperience, { loading }] = useDeleteWorkExperienceMutation()
  const confirmDelete = useDeleteConfirmation()

  // TODO: handle delete loading animation
  const handleDeleteWorkExperience = () => {
    if (loading) return
    try {
      confirmDelete({
        onDelete: () => deleteWorkExperience({ variables: { id: data._id } }),
        heading: 'Delete work experience',
        subHeading: 'Are you sure you want to permanently delete this item?',
      })
    } catch (err) {}
  }

  return (
    <div className='relative p-6'>
      <MoreButton label={jobTitle}>
        <MenuItem
          onClick={() => history.push(`/profile/work-experience/${_id}`)}>
          Update experience
        </MenuItem>
        <MenuItem className='text-alert' onClick={handleDeleteWorkExperience}>
          Delete experience
        </MenuItem>
      </MoreButton>
      <div className='space-y-4'>
        <h3 className='text-lg font-bold text-brand-700'>{jobTitle}</h3>
        <ul className='grid items-center justify-between grid-cols-4 gap-x-2'>
          <InfoBlock title='Company'>{company}</InfoBlock>
          <InfoBlock title='Location'>
            {remote ? `${location} (Remote)` : location}
          </InfoBlock>
          <InfoBlock title='Contract'>{JobTypeText[jobType]}</InfoBlock>
          <InfoBlock title={`Duration (${diffDate(startDate, endDate)})`}>
            <time dateTime={startDate}>{formatDate(startDate)}</time>
            {' - '}
            <time
              dateTime={endDate || new Date().toISOString().substring(0, 10)}>
              {formatDate(endDate)}
            </time>
          </InfoBlock>
        </ul>
        <div
          className='text-xs richtext'
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <BadgeGroup className='bg-brand-80 !text-gray-600' values={techStack} />
      </div>
    </div>
  )
}

interface WorkExperienceProps {
  data: WorkExperienceFragment
}
export default WorkExperience
