import { useEffect } from 'react'
import { useAuthentication } from 'actions/auth'
import { useConfirmInterviewMutation } from 'api'

import Anchor from 'components/Anchor'
import { WarningEmoji } from 'assets/emojis'

import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import useSearchParams from 'lib/hooks/useSearchParams'
import { Link } from 'react-router-dom'
import { LayoutContainer } from './LayoutContainer'
import Skeleton from 'components/Skeleton'

const ConfirmationLoading = () => (
  <LayoutContainer className='py-24 '>
    <div>
      <Skeleton height='h-6' width='w-40' />
      <div className='mt-7 space-y-4'>
        <Skeleton height='h-5' width='w-60' />
        <Skeleton height='h-5' width='w-80' />
      </div>
    </div>
  </LayoutContainer>
)

const InterviewConfirmation = () => {
  const showSnackbar = useSnackbar()
  const { authenticated } = useAuthentication()
  const { code: confirmationCode }: { code?: string } = useSearchParams()
  const [confirmInterview, { loading, error }] = useConfirmInterviewMutation()

  useEffect(() => {
    ;(async () => {
      if (!confirmationCode) return
      try {
        await confirmInterview({ variables: { confirmationCode } })
      } catch (err) {
        showSnackbar({
          key: 'interview_confirmation_fail',
          text: 'Failed to confirm interview. Please, try again.',
          icon: WarningEmoji,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationCode, confirmInterview])

  if (loading) return <ConfirmationLoading />

  if (error) {
    return (
      <LayoutContainer className='py-24'>
        <div className='space-y-7'>
          <h1 className='text-1.5xl font-bold leading-8'>
            Failed to confirm interview.
          </h1>
          <div className='space-y-3 leading-5 text-brand-900 text-opacity-70'>
            <p>We’ve sent a a confirmation link to your email.</p>
            <div className='space-y-2'>
              <p>If you don’t see it, double check your spam folder.</p>
            </div>
          </div>
          <div className='space-x-4'>
            {authenticated ? (
              <Anchor to='/interview' component={Link}>
                Go to dashboard
              </Anchor>
            ) : (
              <Anchor component={Link} to='/signin'>
                Login now
              </Anchor>
            )}
          </div>
        </div>
      </LayoutContainer>
    )
  }

  return (
    <LayoutContainer className='py-24'>
      <div className='space-y-7'>
        <span className='block text-5xl' role='img' aria-label='Check mark'>
          ✅
        </span>
        <h1 className='text-1.5xl font-bold leading-8'>
          Great! You’ve confirmed your interview!
        </h1>
        <div>
          {authenticated ? (
            <Anchor to='/interview' component={Link}>
              Go to dashboard
            </Anchor>
          ) : (
            <Anchor component={Link} to='/signin'>
              Login now
            </Anchor>
          )}
        </div>
      </div>
    </LayoutContainer>
  )
}

export default InterviewConfirmation
