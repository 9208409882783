import clsx from 'clsx'

const Badge = ({
  className,
  border = false,
  removable = false,
  onRemove,
  children,
  ...props
}: BadgeProps) => {
  return (
    <span
      className={clsx(
        'inline-flex w-max text-brand-500 bg-brand-200 rounded border',
        border ? ' border-brand-300 border-opacity-20' : 'border-transparent',
        className
      )}
      {...props}>
      <span
        className={clsx(
          'py-1.5 text-tiny tabular-nums leading-3',
          removable && onRemove ? 'pl-2' : 'px-2'
        )}>
        {children.trim() || <>&thinsp;</>}
      </span>
      {removable && onRemove && (
        <button
          type='button'
          onClick={() => onRemove(children)}
          className='px-1.5 text-xtiny leading-4 cursor-pointer focus:outline-none'>
          ✕
        </button>
      )}
    </span>
  )
}

export const BadgeGroup = ({
  className,
  values,
  border,
  removable,
  onRemove,
  renderBadge,
  children,
}: BadgeGroupProps) => (
  <ol className='flex flex-wrap text-tiny'>
    {values?.map((item, index) => (
      <li key={item + index} className='pr-2 mb-1'>
        {renderBadge ? (
          renderBadge({ border, removable, onRemove, value: item, children })
        ) : (
          <Badge
            className={clsx('block', className)}
            border={border}
            removable={removable}
            onRemove={onRemove}>
            {item}
          </Badge>
        )}
      </li>
    ))}
    {children}
  </ol>
)

interface BadgeProps extends React.HTMLProps<HTMLSpanElement> {
  /**
   * Must be a string to match against a string[] to remove a given badge
   */
  children: string
  /**
   * Whether to have a border or not
   */
  border?: boolean
  /**
   * whether to show a remove icon or not
   */
  removable?: boolean
  /**
   * function to call on remove. Must exist with removable=true
   */
  onRemove?: (item: string) => void
}

export interface RenderParams extends React.HTMLProps<HTMLSpanElement> {
  value: string
  border?: boolean
  removable?: boolean
  onRemove?: (item: string) => void
}

interface BadgeGroupProps {
  className?: string
  values: string[]
  border?: boolean
  removable?: boolean
  onRemove?: (item: string) => void
  renderBadge?: (params: RenderParams) => JSX.Element
  children?: React.ReactNode
}

export default Badge
