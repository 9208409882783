import { Candidate } from 'api'
import * as Sentry from '@sentry/browser'

export const initGroove = () => {
  if (!window.groove) return
  try {
    window.groove.widget = window.groove.createWidget()
    window.groove.widget.init(process.env.REACT_APP_GROOVE_ID, {})
  } catch (err) {
    Sentry.captureException('Failed to init groove widget', {
      extra: { err },
    })
  }
}

export const setGrooveUserProperties = ({
  firstName,
  lastName,
  email,
  phone,
  country,
}: Partial<Candidate>) => {
  if (!window.groove || !window.groove.widget) return
  const groove = window.groove
  if (email) {
    groove.widget.identifyContact('contact_email', email)
  }

  const contactObject: any = {}
  if (phone?.localNumber)
    contactObject['contact_personal_cell'] = phone.dial + phone.localNumber
  if (firstName) contactObject['contact_first_name'] = firstName
  if (lastName) contactObject['contact_last_name'] = lastName
  if (country) contactObject['contact_address'] = { country }
  /* Next, set the contact properties */
  if (Object.keys(contactObject).length) {
    groove.widget.setContact(contactObject)
  }
}

export const resetGrooveSession = () => {
  if (!window.groove || !window.groove.widget) return
  window.groove.widget.logout()
}

export const openGrooveConversation = () => {
  if (!window.groove || !window.groove.widget) return
  window.groove.widget.open()
}
