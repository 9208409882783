const theme = {
  primary: '#4D3BD7',
  secondary: '#0B0826',
  borderColor: '#DEE3EA',
  softBg: '#F7F9FC',
  // more structured color scheme
  colors: {
    primary: {
      main: '#4D3BD7',
    },
    secondary: {
      main: '#0B0826',
      light: '#0D0A28',
    },
    semantic: {
      success: '#D9FDE4',
      brand: '#4535C3',
    },
  },
  text: {
    colors: {
      main: '#0B0826',
      invert: '#fff',
    },
  },
}

export default theme
