import React, { useEffect } from 'react'
import { ProfileEducationInput, useAddEducationMutation } from 'api'
import Input, { FormField } from 'components/InputAlternative'
import Modal from 'components/Modal'
import { DateRangeField, Fieldset, Form } from './Shared'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import useDraft from './useDraft'

const EducationModal = () => {
  const formik = useForm()
  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    errors,
    touched,
  } = formik

  return (
    <FormikProvider value={formik}>
      <div className='w-screen max-w-4xl p-6 pt-10 bg-white rounded-lg'>
        <div className='space-y-8'>
          <div className='space-y-2'>
            <Modal.Title as='h3' className='text-1.5xl font-bold'>
              Education
            </Modal.Title>
            <p className='text-xs'>About your academic background.</p>
          </div>
          <Form onSubmit={handleSubmit} isSubmitting={isSubmitting}>
            <Fieldset legend='Tell us about your education here'>
              <FormField hasError={touched.diploma && !!errors.diploma}>
                <Input
                  value={values.diploma}
                  onChange={handleChange}
                  name='diploma'
                  id='education-diploma'
                  aria-label='education-diploma'
                  placeholder='Diploma/field of studies. E.g., Software Engineering Degree'
                />
              </FormField>
              <DateRangeField />
              <FormField hasError={touched.school && !!errors.school}>
                <Input
                  value={values.school}
                  onChange={handleChange}
                  name='school'
                  id='education-school'
                  aria-label='education-school'
                  placeholder='School/University'
                />
              </FormField>
              <FormField hasError={touched.location && !!errors.location}>
                <Input
                  value={values.location}
                  onChange={handleChange}
                  name='location'
                  id='education-location'
                  aria-label='education-location'
                  placeholder='Location (e.g. City, Country)'
                />
              </FormField>
            </Fieldset>
          </Form>
        </div>
      </div>
    </FormikProvider>
  )
}

type FormValues = ProfileEducationInput & {
  ongoing: boolean
}

const validationSchema = Yup.object({
  diploma: Yup.string().required(),
  location: Yup.string().required(),
  remote: Yup.boolean().required(),
  school: Yup.string().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
})

const useForm = () => {
  const history = useHistory()
  const [addEducation] = useAddEducationMutation()
  const draft = useDraft<FormValues>('education-form')

  const formik = useFormik<FormValues>({
    validationSchema: () => validationSchema,
    initialValues: {
      diploma: '',
      location: '',
      remote: false,
      school: '',
      startDate: '',
      endDate: undefined,
      ongoing: false,
      ...(draft.value ? draft.value : {}),
    },
    onSubmit: async ({ ongoing, ...values }) => {
      try {
        await addEducation({
          variables: {
            input: {
              ...values,
              endDate: !ongoing ? values.endDate : null,
            },
          },
        })
        draft.clear()
        history.push('/profile')
      } catch (error) {
        // TODO handle errors
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    draft.set(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])
  return formik
}
export default EducationModal
