import {
  PrismicProvider as PrismicIOProvider,
  PrismicToolbar,
} from '@prismicio/react'
import Anchor from 'components/Anchor'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { client, repositoryName } from './client'
import { linkResolver } from './utils'

export const PrismicProvider = ({ children }: { children: ReactNode }) => (
  <PrismicIOProvider
    client={client}
    linkResolver={linkResolver}
    internalLinkComponent={({ href, children, ...props }) => (
      <Anchor className='text-inherit' component={Link} to={href} {...props}>
        {children}
      </Anchor>
    )}
    externalLinkComponent={({ href, children, ...props }) => (
      <Anchor href={href} rel='noopener noreferrer' target='_blank' {...props}>
        {children}
      </Anchor>
    )}>
    {children}
    {process.env.REACT_APP_DEPLOY_ENV === 'staging' ||
    process.env.NODE_ENV === 'development' ? (
      <PrismicToolbar repositoryName={repositoryName} />
    ) : null}
  </PrismicIOProvider>
)

export * from './types'
export * from './Context'
