import clsx from 'clsx'

const Checkbox = ({ children, className, ...props }: CheckboxProps) => {
  return (
    <label
      className={clsx(
        'bg-white flex items-center space-x-1.5 cursor-pointer rounded py-2.5 px-2 max-w-max border shadow-input',
        className
      )}>
      <input
        type='checkbox'
        className='form-checkbox border-brand-100 shadow-input w-4 h-4 rounded text-brand-500 focus:ring-brand-500 focus:ring-1 focus:ring-offset-0 focus:ring-opacity-40'
        {...props}
      />
      <span className='text-tiny capitalize'>{children}</span>
    </label>
  )
}

type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  children: string
  checked: boolean
  onChange: (checked: boolean) => void
}

export default Checkbox
