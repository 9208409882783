import listCountries from 'lib/data/countries.json'

export type CountryType = {
  name: string
  dial: string
  code: string
}

export const getCountries = (word?: string): CountryType[] => {
  if (!word) return listCountries
  return listCountries
    .filter((country) =>
      country.name?.toLowerCase().includes(word?.toLowerCase())
    )
    .sort()
}

export const getCountryByCode = (code: string): CountryType | null => {
  const index = listCountries.findIndex(
    (country) => country.code.toLowerCase() === code.toLowerCase()
  )
  if (index === -1) return null
  return listCountries[index]
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode
}

const countriesByName: {
  [key: string]: { dial: string; name: string; code: string }
} = listCountries.reduce(
  (acc, country) => ({
    ...acc,
    [country.name.toLowerCase()]: country,
  }),
  {}
)

export const countryFlagByName = (name: string) => {
  const country = countriesByName[name.toLowerCase()]
  return country ? countryToFlag(country.code) : ''
}

// a function that takes country and return an object with code, dial and name
export const getCountryInfo = (country?: string | null) => {
  const countryInfo = listCountries.find(
    (c) => c.name.toLowerCase() === country?.toLowerCase()
  )

  if (!countryInfo) return null
  return {
    code: countryInfo.code,
    dial: countryInfo.dial,
    name: countryInfo.name,
  }
}
