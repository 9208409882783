import {
  useApolloClient,
  // eslint-disable-next-line no-unused-vars
  ApolloClient,
  makeVar,
  useReactiveVar,
} from '@apollo/client'
import { resetGrooveSession } from 'lib/configs/monitoring/groove'
import { useMixpanel } from 'lib/contexts/Mixpanel'
import { useCallback } from 'react'
import { authStorage, idStorage } from 'lib/helpers/localStorage'

export const AUTH_STATUS = {
  PENDING: 'PENDING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
  IDLE: 'IDLE',
}

export const authenticationVars = makeVar({
  authenticated: false,
  status: AUTH_STATUS.IDLE,
})

/**
 * Gets authentication state saved in apollo cache
 */
export const useAuthentication = () => {
  return useReactiveVar(authenticationVars)
}

/**
 * Hook that returns a callback to handle login
 * @return {function}
 */
export const useLogin = () => {
  return useCallback((payload) => {
    if (payload?.token) {
      authStorage.set(payload.token)
    }

    if (payload?._id) {
      idStorage.set(payload._id)
    }

    authenticationVars({
      status: AUTH_STATUS.RESOLVED,
      authenticated: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 *
 * This function's purpose is to be used outside apollo provider
 * but needs client instance at least
 * @param {ApolloClient} client
 *
 */
export const authLogout = (client) => {
  authenticationVars({
    status: AUTH_STATUS.RESOLVED,
    authenticated: false,
  })
  authStorage.clear()
  idStorage.clear()
  client.clearStore()
}

/**
 * Hook that returns a callback to handle logout
 * @return {function}
 */
export const useLogout = () => {
  const client = useApolloClient()
  const mixpanel = useMixpanel()
  const { authenticated } = useAuthentication()

  return useCallback(() => {
    // we make sure clearing and tracking is
    // only happening on actual signout
    if (authenticated) {
      mixpanel.track('Signed Out')
      // we reset mixpanel/crisp session
      resetGrooveSession()
      mixpanel.reset()
    }
    authLogout(client)
  }, [client, mixpanel, authenticated])
}
