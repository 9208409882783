import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseSVG } from 'assets/icons'
import clsx from 'clsx'
import { matchPath, useHistory, useLocation } from 'react-router-dom'

enum align {
  'top' = 'align-top',
  'middle' = 'align-middle',
}

/**
 * React-router-based modal hook
 */
export const useRouteModal = ({ base, path }: UseRouteModalProps) => {
  const history = useHistory()
  const location = useLocation()
  let destination: string | string[] = base + path

  if (Array.isArray(path)) {
    destination = path.map((p) => base + p)
  }

  return {
    open: !!matchPath(location.pathname, { path: destination }),
    onClose: () => history.push(base),
  }
}

const Modal = ({
  open,
  onClose,
  className,
  position = 'top',
  hideCloseButton,
  children,
  initialFocus,
}: ModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        static
        className='fixed inset-0 z-10'
        open={open}
        onClose={onClose}
        initialFocus={initialFocus}>
        <div className='min-h-screen text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-brand-900 bg-opacity-90' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className='inline-block align-middle smh-screen'
            aria-hidden='true'>
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter='ease-out duration-200'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-75'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div
              className={clsx(
                'inline-block text-left align-bottom transition-all transform shadow-xl max-w-max w-full',
                align[position],
                className
              )}>
              <button
                onClick={onClose}
                className={clsx(
                  'bg-white absolute p-3 z-10 rounded top-4 right-4 focus:outline-none focus-visible:ring',
                  hideCloseButton && 'sr-only'
                )}>
                <CloseSVG aria-label='close' className='w-4 h-4' />
              </button>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.Title = Dialog.Title
Modal.Description = Dialog.Description

interface ModalProps {
  open: boolean
  onClose: () => void
  /**
   * ClassName for the dialog container
   * Perfect for positioning the dialog using margin
   */
  className?: string
  /**
   * Vertical alignment
   */
  position?: 'top' | 'middle'
  /**
   * Hide the close button visually.
   */
  hideCloseButton?: boolean
  children: React.ReactNode
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined
}

interface UseRouteModalProps {
  base: string
  path: string | string[]
}

export default Modal
