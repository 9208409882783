const TopicCard = ({
  topic: { author, link, title, readTime },
}: TopicCardProps) => (
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={link}
    className='flex flex-col py-3.5 px-6 space-y-6 h-full hover:bg-brand-50 rounded-lg border border-brand-100 transition-colors duration-150 ease-in-out'>
    <div className='flex-grow space-y-10'>
      <div className='flex justify-between items-baseline'>
        <span className='text-xxs text-brand-900 text-opacity-60'>
          {readTime} min read
        </span>
        <span className='text-sm underline'>read more</span>
      </div>
      <h3 className='h-full text-lg font-medium'>{title}</h3>
    </div>
    <div className='flex space-x-2'>
      <img
        src={author.img}
        alt={author.name}
        className='flex-shrink-0 mt-1 w-5 h-5 rounded-full'
      />
      <div className='flex flex-col flex-grow mt-px space-y-px text-brand-900 text-opacity-60'>
        <span className='text-sm'>{author.name}</span>
        <span className='text-tiny'>{author.role}</span>
      </div>
    </div>
  </a>
)

export type TopicType = {
  link: string
  title: string
  readTime: number
  author: {
    img: string
    name: string
    role: string
  }
}

type TopicCardProps = {
  topic: TopicType
}

export default TopicCard
