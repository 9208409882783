import { Legend } from './Legend'
import Collapse, { CollapseButton, CollapsePanel } from 'components/Collapse'
import { LightBulbEmoji } from 'assets/emojis'
import clsx from 'clsx'

export const LegendWithCollapse = ({
  legend,
  children,
}: LegendWithCollapseProps) => (
  <Collapse>
    {({ open }) => (
      <div className={clsx(open && 'mb-2')}>
        <div className='flex justify-between'>
          <Legend>{legend}</Legend>
          <CollapseButton open={open}>
            Tips <LightBulbEmoji className='inline' width='16' />
          </CollapseButton>
        </div>
        <CollapsePanel>{children}</CollapsePanel>
      </div>
    )}
  </Collapse>
)

interface LegendWithCollapseProps {
  legend: string
  children: React.ReactNode
}
