import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { graphql, ResponseResolver, GraphQLRequest, GraphQLContext } from 'msw'
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
  /** The Email scalar type represents E-Mail addresses compliant to RFC 822. */
  Email: string;
  /** String representing datetime ISO */
  GraphQLTimeISO: any;
  /** A limited string. Has to be at least 1 characters long. */
  LimitedString: string;
  /** A limited string. Has to be between 1 and 250 characters long. */
  LimitedString2: string;
  /** A limited string. Has to be between 1 and 50 characters long. */
  LimitedString3: string;
  /** A password string. Has to be at least 8 characters long. */
  Password: string;
  /** The URL scalar type represents URL addresses. */
  URL: string;
};

export enum Ability {
  Resume = 'resume',
  Reapply = 'reapply'
}

export type Admin = {
  __typename?: 'Admin';
  _id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['Email'];
  role: Role;
  avatar?: Maybe<Scalars['String']>;
};

export type Application = {
  __typename?: 'Application';
  _id: Scalars['ID'];
  status: ApplicationStatus;
  appliedAt: Scalars['String'];
  rejectedAt?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  confirmedAt?: Maybe<Scalars['String']>;
  hiredAt?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<RejectionReason>;
  preferences?: Maybe<CandidatePreferences>;
  deliberationInfo?: Maybe<DeliberationInfo>;
  onHoldReason?: Maybe<Scalars['String']>;
  progress: Scalars['Float'];
  seenRejection?: Maybe<Scalars['Boolean']>;
  track: ApplicationTrack;
  currentAssessment?: Maybe<Assessment>;
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  assessments: Array<Assessment>;
  eligibleToBeMatched: Scalars['Boolean'];
  videoaskSubmission?: Maybe<VideoaskSubmission>;
  scorecards: Array<Scorecard>;
  source?: Maybe<Scalars['String']>;
  preOffer?: Maybe<PreOffer>;
  decryptedDeliberationInfo: DeliberationInfo;
  interviews: Array<Interview>;
  lastActivityAt: Scalars['DateTime'];
  prevAssessment?: Maybe<Assessment>;
  quizSubmissions: Array<QuizSubmission>;
};


export type ApplicationPreOfferArgs = {
  decryptionKey?: Maybe<Scalars['String']>;
};


export type ApplicationDecryptedDeliberationInfoArgs = {
  decryptionKey?: Maybe<Scalars['String']>;
};

export enum ApplicationRole {
  Frontend = 'frontend',
  Backend = 'backend',
  Fullstack = 'fullstack',
  FullstackBackend = 'fullstack_backend',
  FullstackFrontend = 'fullstack_frontend',
  Data = 'data',
  Ios = 'ios',
  Android = 'android',
  Devops = 'devops'
}

export enum ApplicationStatus {
  Active = 'active',
  Rejected = 'rejected',
  Confirmed = 'confirmed',
  Hired = 'hired'
}

export enum ApplicationTrack {
  Unknown = 'unknown',
  Fast = 'fast',
  Hyperloop = 'hyperloop'
}

export type Assessment = {
  __typename?: 'Assessment';
  _id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  stage: Stage;
  type: AssessmentType;
  status: AssessmentStatus;
  startedAt: Scalars['String'];
  endedAt?: Maybe<Scalars['String']>;
  submissions?: Maybe<Array<Maybe<Submissions>>>;
  interview?: Maybe<Interview>;
  interviewConfig?: Maybe<InterviewConfiguration>;
  candidate: Candidate;
};

export enum AssessmentStatus {
  Done = 'done',
  Pending = 'pending',
  Skipped = 'skipped',
  InReview = 'inReview',
  Upcoming = 'upcoming'
}

export enum AssessmentType {
  Assignment = 'assignment',
  Interview = 'interview'
}

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  me: Candidate;
};

export enum Availability {
  Immediate = 'immediate',
  OneWeek = 'oneWeek',
  TwoWeeks = 'twoWeeks',
  FourWeeks = 'fourWeeks',
  TwoMonths = 'twoMonths',
  ThreeMonths = 'threeMonths',
  InFewMonths = 'inFewMonths',
  JustResearching = 'justResearching'
}

export type BestMatch = {
  __typename?: 'BestMatch';
  _id: Scalars['ID'];
  score: Scalars['Float'];
  missing_data: Scalars['Boolean'];
  disqualified: Scalars['Boolean'];
};

export type BestMatchedPosition = {
  __typename?: 'BestMatchedPosition';
  position: CompanyPosition;
  score: Scalars['Float'];
  missing_data: Scalars['Boolean'];
  disqualified: Scalars['Boolean'];
};

export type CcSubmission = {
  __typename?: 'CCSubmission';
  _id?: Maybe<Scalars['ID']>;
  status: SubmissionStatus;
  submittedAt?: Maybe<Scalars['String']>;
  ccId?: Maybe<Scalars['String']>;
  submissionLink: Scalars['String'];
  cc?: Maybe<CodingChallenge>;
};

export type Candidate = {
  __typename?: 'Candidate';
  _id: Scalars['ID'];
  status?: Maybe<CandidateStatus>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  linkedinURL?: Maybe<Scalars['URL']>;
  githubURL?: Maybe<Scalars['URL']>;
  country?: Maybe<Scalars['String']>;
  subscribedToNudging?: Maybe<Scalars['Boolean']>;
  nudgingFrequency?: Maybe<Scalars['Float']>;
  lastNudgedOn?: Maybe<Scalars['DateTime']>;
  overridable: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  phone: PhoneNumber;
  blurb?: Maybe<Scalars['String']>;
  email: Scalars['Email'];
  verified: Scalars['Boolean'];
  nextEmailVerificationTimestamp?: Maybe<Scalars['Float']>;
  nextPasswordResetTimestamp?: Maybe<Scalars['Float']>;
  synced: Scalars['Boolean'];
  currentProfile?: Maybe<Profile>;
  currentApplication: Application;
  applicationHistory?: Maybe<Array<Maybe<Application>>>;
  isBanTechInterviewer?: Maybe<Scalars['Boolean']>;
  abilities?: Maybe<Array<Maybe<Ability>>>;
  canApplyIn?: Maybe<Scalars['Float']>;
  newPotentialMatches: Array<PotentialMatch>;
  activePotentialMatches: Array<PotentialMatch>;
  closedPotentialMatches: Array<PotentialMatch>;
  suggestedPotentialMatches: Array<PotentialMatch>;
  numberOfSuggestions?: Maybe<Scalars['Float']>;
  potentialMatches: Array<PotentialMatch>;
  confirmed: Scalars['Boolean'];
  flag?: Maybe<Scalars['String']>;
  meetingAvailability: Array<MeetingAvailabilityDay>;
};

export enum CandidateCategory {
  Aplus = 'Aplus',
  A = 'A',
  Bplus = 'Bplus',
  B = 'B'
}

export type CandidatePreferences = {
  __typename?: 'CandidatePreferences';
  primaryRole?: Maybe<ApplicationRole>;
  noticePeriod?: Maybe<NoticePeriod>;
  availability?: Maybe<Availability>;
  preferredLanguage?: Maybe<PreferredLanguage>;
  secondaryRoles?: Maybe<Array<ApplicationRole>>;
  techStack?: Maybe<Array<Scalars['String']>>;
  contractType?: Maybe<Array<ContractType>>;
  englishProficiency?: Maybe<LanguageProficiency>;
  frenchProficiency?: Maybe<LanguageProficiency>;
  experience?: Maybe<Scalars['GraphQLTimeISO']>;
  primarySkill?: Maybe<Skill>;
  secondarySkill?: Maybe<Skill>;
  salaryExpectations?: Maybe<SalaryExpectations>;
  notes?: Maybe<Scalars['String']>;
  workingHours?: Maybe<WorkingHours>;
  matchingPreferences?: Maybe<Scalars['String']>;
};

export type CandidatePreferencesInput = {
  primaryRole?: Maybe<ApplicationRole>;
  noticePeriod?: Maybe<NoticePeriodInput>;
  availability?: Maybe<Availability>;
  preferredLanguage?: Maybe<PreferredLanguage>;
  secondaryRoles?: Maybe<Array<ApplicationRole>>;
  techStack?: Maybe<Array<Scalars['String']>>;
  contractType?: Maybe<Array<ContractType>>;
  englishProficiency?: Maybe<LanguageProficiency>;
  frenchProficiency?: Maybe<LanguageProficiency>;
  experience?: Maybe<Scalars['GraphQLTimeISO']>;
  primarySkill?: Maybe<SkillInput>;
  secondarySkill?: Maybe<SkillInput>;
  salaryExpectations?: Maybe<SalaryExpectationsInput>;
  notes?: Maybe<Scalars['String']>;
  workingHours?: Maybe<WorkingHoursInput>;
  matchingPreferences?: Maybe<Scalars['String']>;
};

export enum CandidateStatus {
  Application = 'application',
  ProfileBuilding = 'profileBuilding',
  Matching = 'matching',
  Interviewing = 'interviewing',
  Rejected = 'rejected',
  Hired = 'hired'
}

export type CodingChallenge = {
  __typename?: 'CodingChallenge';
  _id: Scalars['ID'];
  label: Scalars['String'];
  repo: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  name: Scalars['String'];
  techStacks?: Maybe<Array<Scalars['String']>>;
  blurb?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  languages?: Maybe<Array<Scalars['String']>>;
  companyCategory?: Maybe<CompanyCategory>;
  excludedCountries?: Maybe<Array<Scalars['String']>>;
  hiringMode?: Maybe<CompanyHiringMode>;
  matchingPriority?: Maybe<CompanyMatchingPriority>;
  verified?: Maybe<Scalars['Boolean']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  outreachId?: Maybe<Scalars['Int']>;
  status?: Maybe<CompanyStatus>;
  contacts?: Maybe<Array<CompanyContact>>;
  notes?: Maybe<Array<Scalars['String']>>;
  subscribedToNudging?: Maybe<Scalars['Boolean']>;
  nudgingFrequency?: Maybe<Scalars['Float']>;
  lastNudgedOn?: Maybe<Scalars['DateTime']>;
  icpSegment?: Maybe<Scalars['String']>;
  audience?: Maybe<Array<Scalars['String']>>;
  _id: Scalars['ID'];
  salesforceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  stages: Array<CompanyStage>;
  positions: Array<CompanyPosition>;
  confirmedPotentialMatches: Array<PotentialMatch>;
  primaryContact?: Maybe<CompanyContact>;
  numberOfSuggestions?: Maybe<Scalars['Float']>;
};

export enum CompanyCategory {
  A = 'A',
  B = 'B'
}

export type CompanyContact = {
  __typename?: 'CompanyContact';
  _id: Scalars['ID'];
  salesforceId?: Maybe<Scalars['String']>;
  outreachId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  isMainContact: Scalars['Boolean'];
  preferredLanguage?: Maybe<Scalars['String']>;
};

export enum CompanyHiringMode {
  HighPriority = 'highPriority',
  OpenOrder = 'openOrder',
  AlwaysOn = 'alwaysOn',
  Client = 'client'
}

export enum CompanyMatchingPriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Unqualified = 'Unqualified'
}

export type CompanyPosition = {
  __typename?: 'CompanyPosition';
  companyId: Scalars['String'];
  title: Scalars['String'];
  role?: Maybe<CompanyPositionRole>;
  description?: Maybe<Scalars['String']>;
  minYearsOfExperience?: Maybe<Scalars['Float']>;
  minOverlapHours?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  linkToPublicJD?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  techStacks?: Maybe<Array<Scalars['String']>>;
  secondaryTechStacks?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CompanyPositionStatus>;
  priority?: Maybe<CompanyPositionPriority>;
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  salesforceId?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
};

export enum CompanyPositionPriority {
  High = 'high',
  Medium = 'medium',
  Low = 'low'
}

export enum CompanyPositionRole {
  BackendEngineer = 'BackendEngineer',
  DataEngineer = 'DataEngineer',
  FrontendEngineer = 'FrontendEngineer',
  FullStackEngineer = 'FullStackEngineer',
  MobileEngineer = 'MobileEngineer',
  DevOpsEngineer = 'DevOpsEngineer'
}

export enum CompanyPositionStatus {
  Active = 'active',
  Closed = 'closed',
  Filled = 'filled'
}

export type CompanyStage = {
  __typename?: 'CompanyStage';
  companyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  order: Scalars['Float'];
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
};

export enum CompanyStatus {
  Active = 'active',
  Archived = 'archived'
}

export enum ContractType {
  FullTime = 'fullTime',
  PartTime = 'partTime',
  Freelance = 'freelance',
  Internship = 'internship'
}

export enum Currency {
  Mad = 'mad',
  Usd = 'usd',
  Egp = 'egp',
  Brl = 'brl',
  Ars = 'ars',
  Xof = 'xof',
  Tnd = 'tnd',
  Eur = 'eur'
}

export type CustomOption = {
  __typename?: 'CustomOption';
  _id: Scalars['ID'];
  tag: Scalars['String'];
  verified: Scalars['Boolean'];
  type: CustomOptionType;
  createdAt: Scalars['DateTime'];
};

export enum CustomOptionType {
  TechStack = 'techStack'
}


export type DeliberationInfo = {
  __typename?: 'DeliberationInfo';
  candidateCategory?: Maybe<CandidateCategory>;
  signals?: Maybe<Array<Scalars['String']>>;
  potentialMatches?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  feedback?: Maybe<Scalars['String']>;
  estimatedNetOffer?: Maybe<EncryptedSalary>;
  estimatedPricingRange?: Maybe<EncryptedSalaryRange>;
};


export type EmailSendingPayload = {
  __typename?: 'EmailSendingPayload';
  timestamp?: Maybe<Scalars['Float']>;
  emailSent: Scalars['Boolean'];
};

export type EncryptedSalary = {
  __typename?: 'EncryptedSalary';
  amount: Scalars['String'];
  currency: Currency;
};

export type EncryptedSalaryRange = {
  __typename?: 'EncryptedSalaryRange';
  min: Scalars['String'];
  max: Scalars['String'];
  currency: Currency;
};

export enum GemologistLevel {
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3'
}


export type Interview = {
  __typename?: 'Interview';
  _id: Scalars['ID'];
  interviewer: Interviewer;
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  inviteeCalendlyId?: Maybe<Scalars['String']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isRescheduled?: Maybe<Scalars['Boolean']>;
  isCancelledByGemo?: Maybe<Scalars['Boolean']>;
  scorecard?: Maybe<Scorecard>;
  status: InterviewStatus;
  candidate: Candidate;
  assessment: Assessment;
};

export type InterviewConfiguration = {
  __typename?: 'InterviewConfiguration';
  _id: Scalars['ID'];
  team: Scalars['String'];
  url: Scalars['String'];
  roles: Array<ApplicationRole>;
  language: PreferredLanguage;
  reliable: Scalars['Boolean'];
  stage: Stage;
};

export enum InterviewStatus {
  NoShow = 'noShow',
  Pending = 'pending',
  AwaitingCancel = 'awaitingCancel',
  Done = 'done',
  Skipped = 'skipped',
  Scheduled = 'scheduled'
}

export type Interviewer = {
  __typename?: 'Interviewer';
  _id: Scalars['ID'];
  country?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  compensation?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<PhoneNumber>;
  preferredLanguage?: Maybe<Array<PreferredLanguage>>;
  teams?: Maybe<Array<InterviewerTeam>>;
  techstack?: Maybe<Array<Scalars['String']>>;
  personalEmail?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  seniority?: Maybe<Seniority>;
  status?: Maybe<Status>;
  level?: Maybe<GemologistLevel>;
  startedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  isTechnical?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isMerged?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Array<InterviewerNote>>;
  /** Get all notes of an interviewer */
  overallPerformance?: Maybe<Array<InterviewerPerformance>>;
};

export enum InterviewerMetric {
  CancelledInterviews = 'cancelledInterviews',
  MissingScorecards = 'missingScorecards',
  NoShows = 'noShows',
  FlaggedScorecards = 'flaggedScorecards',
  Reviews = 'reviews',
  Done = 'done'
}

export type InterviewerNote = {
  __typename?: 'InterviewerNote';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
};

export type InterviewerPerformance = {
  __typename?: 'InterviewerPerformance';
  month: Scalars['Float'];
  metric: InterviewerMetric;
  value?: Maybe<Array<Scalars['Float']>>;
};

export enum InterviewerTeam {
  Frontend = 'frontend',
  Backend = 'backend',
  Fullstack = 'fullstack',
  Mobile = 'mobile',
  Data = 'data',
  Devops = 'devops'
}

export enum LanguageProficiency {
  NoProficiency = 'noProficiency',
  Beginner = 'beginner',
  Intermediate = 'intermediate',
  Advanced = 'advanced',
  Fluent = 'fluent'
}




export type MatchingInfos = {
  __typename?: 'MatchingInfos';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  blurb?: Maybe<Scalars['String']>;
  pricingOfReference: PricingOfReference;
  finalPreOfferId: Scalars['ID'];
  talentManagerId: Scalars['ID'];
  candidateId: Scalars['ID'];
  availability?: Maybe<Availability>;
};

export type MeetingAvailabilityDay = {
  __typename?: 'MeetingAvailabilityDay';
  enabled: Scalars['Boolean'];
  spots: Array<MeetingAvailabilitySpot>;
};

export type MeetingAvailabilitySpot = {
  __typename?: 'MeetingAvailabilitySpot';
  from: MeetingAvailabilityTime;
  to: MeetingAvailabilityTime;
};

export type MeetingAvailabilityTime = {
  __typename?: 'MeetingAvailabilityTime';
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type Metadata = {
  __typename?: 'Metadata';
  bestMatch: BestMatch;
};

export type Mutation = {
  __typename?: 'Mutation';
  submitCodingChallenge: Application;
  bookInterview: Assessment;
  cancelInterview: Assessment;
  confirmInterview: Interview;
  submitQuizAssessment: Candidate;
  submitQuizAnswer: QuizSubmission;
  skipQuizQuestion: QuizSubmission;
  submitVideoask: Application;
  captureEmail: Scalars['Boolean'];
  seenRejection: Application;
  setCandidatePreferences: Candidate;
  signUp: AuthPayload;
  signIn: AuthPayload;
  googleAuth: AuthPayload;
  githubAuth: AuthPayload;
  updateCandidate: Candidate;
  changePassword: Scalars['Boolean'];
  reapply: Candidate;
  resumeApplication: Candidate;
  verifyEmail: AuthPayload;
  resendVerification?: Maybe<EmailSendingPayload>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  sendPasswordResetEmail?: Maybe<EmailSendingPayload>;
  cancelApplication: Candidate;
  pauseApplication: Candidate;
  setProfileInfo: Candidate;
  skipProfileWalkthrough: Candidate;
  toggleOfferAvailability: Profile;
  deployProfile: Profile;
  addProfileEducation: Profile;
  updateProfileEducation: Profile;
  deleteProfileEducation: Profile;
  addProfilePersonalProject: Profile;
  updateProfilePersonalProject: Profile;
  deleteProfilePersonalProject: Profile;
  addProfileWorkExperience: Profile;
  updateProfileWorkExperience: Profile;
  deleteProfileWorkExperience: Profile;
};


export type MutationSubmitCodingChallengeArgs = {
  ccId: Scalars['LimitedString'];
  submissionLink: Scalars['URL'];
};


export type MutationBookInterviewArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  interviewerName: Scalars['String'];
  inviteeCalendlyId: Scalars['String'];
  interviewerEmail: Scalars['String'];
  rescheduled?: Maybe<Scalars['Boolean']>;
};


export type MutationConfirmInterviewArgs = {
  confirmationCode: Scalars['String'];
};


export type MutationSubmitQuizAnswerArgs = {
  quizId: Scalars['LimitedString'];
  answer?: Maybe<Scalars['String']>;
  completionTime?: Maybe<Scalars['Float']>;
};


export type MutationSkipQuizQuestionArgs = {
  quizId: Scalars['LimitedString'];
};


export type MutationSubmitVideoaskArgs = {
  videoaskLink: Scalars['URL'];
};


export type MutationCaptureEmailArgs = {
  email: Scalars['Email'];
};


export type MutationSetCandidatePreferencesArgs = {
  country: Scalars['String'];
  preferences: CandidatePreferencesInput;
};


export type MutationSignUpArgs = {
  email: Scalars['Email'];
  password: Scalars['Password'];
  firstName: Scalars['LimitedString'];
  lastName: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
};


export type MutationSignInArgs = {
  email: Scalars['Email'];
  password: Scalars['Password'];
};


export type MutationGoogleAuthArgs = {
  code: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
};


export type MutationGithubAuthArgs = {
  code: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
};


export type MutationUpdateCandidateArgs = {
  email?: Maybe<Scalars['Email']>;
  firstName?: Maybe<Scalars['LimitedString']>;
  lastName?: Maybe<Scalars['LimitedString']>;
  phone?: Maybe<PhoneNumberInput>;
  country?: Maybe<Scalars['LimitedString']>;
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['Password'];
  newPassword: Scalars['Password'];
};


export type MutationVerifyEmailArgs = {
  verificationCode: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['Password'];
  code: Scalars['LimitedString'];
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['Email'];
};


export type MutationCancelApplicationArgs = {
  onHoldReason: Scalars['String'];
};


export type MutationPauseApplicationArgs = {
  onHoldReason: Scalars['String'];
};


export type MutationSetProfileInfoArgs = {
  techStacks?: Maybe<TechStacksInput>;
  role?: Maybe<Scalars['LimitedString3']>;
  bio?: Maybe<Scalars['LimitedString2']>;
  pictureURL?: Maybe<Scalars['URL']>;
  linkedinURL?: Maybe<Scalars['URL']>;
  githubURL?: Maybe<Scalars['URL']>;
  languages?: Maybe<Array<Scalars['String']>>;
  finalRate?: Maybe<Scalars['Float']>;
};


export type MutationAddProfileEducationArgs = {
  input: ProfileEducationInput;
};


export type MutationUpdateProfileEducationArgs = {
  data: ProfileEducationInput;
  id: Scalars['String'];
};


export type MutationDeleteProfileEducationArgs = {
  id: Scalars['String'];
};


export type MutationAddProfilePersonalProjectArgs = {
  input: ProfilePersonalProjectInput;
};


export type MutationUpdateProfilePersonalProjectArgs = {
  data: ProfilePersonalProjectInput;
  id: Scalars['String'];
};


export type MutationDeleteProfilePersonalProjectArgs = {
  id: Scalars['String'];
};


export type MutationAddProfileWorkExperienceArgs = {
  input: ProfileWorkExperienceInput;
};


export type MutationUpdateProfileWorkExperienceArgs = {
  data: ProfileWorkExperienceInput;
  id: Scalars['String'];
};


export type MutationDeleteProfileWorkExperienceArgs = {
  id: Scalars['String'];
};

export type NoticePeriod = {
  __typename?: 'NoticePeriod';
  number: Scalars['Float'];
  dateComponent: NoticePeriodDateComponent;
};

export enum NoticePeriodDateComponent {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months'
}

export type NoticePeriodInput = {
  number: Scalars['Float'];
  dateComponent: NoticePeriodDateComponent;
};

export type OpportunitySimilarities = {
  __typename?: 'OpportunitySimilarities';
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  similarities: OpportunitySimilarityScore;
  total_score: Scalars['Float'];
  missing_data: Scalars['Boolean'];
  disqualified: Scalars['Boolean'];
  isNudged?: Maybe<Scalars['Boolean']>;
  incluedInNudgingEmail?: Maybe<Scalars['Boolean']>;
};

export type OpportunitySimilarityScore = {
  __typename?: 'OpportunitySimilarityScore';
  role_sim?: Maybe<Scalars['Float']>;
  seniority_sim?: Maybe<Scalars['Float']>;
  tech_stack_sim?: Maybe<Scalars['Float']>;
  category_sim?: Maybe<Scalars['Float']>;
  overlap_sim?: Maybe<Scalars['Float']>;
  language_sim?: Maybe<Scalars['Float']>;
};


export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  code?: Maybe<Scalars['String']>;
  dial?: Maybe<Scalars['String']>;
  localNumber?: Maybe<Scalars['String']>;
};

export type PhoneNumberInput = {
  code: Scalars['String'];
  dial: Scalars['String'];
  localNumber?: Maybe<Scalars['Float']>;
};

export type PotentialMatch = {
  __typename?: 'PotentialMatch';
  _id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  companyId: Scalars['String'];
  candidateId: Scalars['String'];
  selectedPositionsIds?: Maybe<Array<Scalars['String']>>;
  status: PotentialMatchStatus;
  acceptedOn?: Maybe<Scalars['DateTime']>;
  suggestedOn?: Maybe<Scalars['DateTime']>;
  decisionMadeOn?: Maybe<Scalars['DateTime']>;
  rejectionReason?: Maybe<Scalars['String']>;
  clearProfileEnabled: Scalars['Boolean'];
  broadcastingEnabled: Scalars['Boolean'];
  scores?: Maybe<PotentialMatchPositionScore>;
  candidate: Candidate;
  company: Company;
  positions?: Maybe<Array<PotentialMatchPositions>>;
  bestMatchedPosition?: Maybe<BestMatchedPosition>;
  assessments?: Maybe<Array<PotentialMatchAssessment>>;
};

export type PotentialMatchAssessment = {
  __typename?: 'PotentialMatchAssessment';
  _id: Scalars['ID'];
  name: Scalars['String'];
  companyId: Scalars['String'];
  interiewDate?: Maybe<Scalars['DateTime']>;
  status: PotentialMatchAssessmentStatus;
  order: Scalars['Float'];
  potentialMatch: PotentialMatch;
};

export enum PotentialMatchAssessmentStatus {
  Pending = 'pending',
  Coordinating = 'coordinating',
  InProgress = 'inProgress',
  Skipped = 'skipped',
  Done = 'done'
}

export type PotentialMatchPositionScore = {
  __typename?: 'PotentialMatchPositionScore';
  metadata: Metadata;
  opportunities: Array<OpportunitySimilarities>;
};

export type PotentialMatchPositions = {
  __typename?: 'PotentialMatchPositions';
  companyId: Scalars['String'];
  title: Scalars['String'];
  role?: Maybe<CompanyPositionRole>;
  description?: Maybe<Scalars['String']>;
  minYearsOfExperience?: Maybe<Scalars['Float']>;
  minOverlapHours?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  linkToPublicJD?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  techStacks?: Maybe<Array<Scalars['String']>>;
  secondaryTechStacks?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Array<Scalars['String']>>;
  status?: Maybe<CompanyPositionStatus>;
  priority?: Maybe<CompanyPositionPriority>;
  id: Scalars['ID'];
  similarities: OpportunitySimilarityScore;
  total_score: Scalars['Float'];
  missing_data: Scalars['Boolean'];
  disqualified: Scalars['Boolean'];
};

export enum PotentialMatchStatus {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Suggested = 'suggested',
  InterviewGranted = 'interviewGranted',
  Interviewing = 'interviewing',
  Success = 'success',
  Failed = 'failed',
  ActiveStatuses = 'activeStatuses',
  ClosedStatuses = 'closedStatuses',
  NewStatuses = 'newStatuses',
  FromSuggested = 'fromSuggested'
}

export type PreOffer = {
  __typename?: 'PreOffer';
  _id: Scalars['ID'];
  status: PreOfferStatus;
  repliedAt?: Maybe<Scalars['DateTime']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  netSalary?: Maybe<EncryptedSalary>;
  grossSalary?: Maybe<EncryptedSalary>;
  notes?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export enum PreOfferStatus {
  Pending = 'pending',
  Sent = 'sent',
  Accepted = 'accepted',
  Declined = 'declined'
}

export enum PreferredLanguage {
  English = 'english',
  French = 'french'
}

export type PricingOfReference = {
  __typename?: 'PricingOfReference';
  hourlyRate: Scalars['Float'];
  dailyRate: Scalars['Float'];
  monthlyRate: Scalars['Float'];
  yearlyRate: Scalars['Float'];
  successFee: Scalars['Float'];
  currency: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  _id: Scalars['ID'];
  status: ProfileStatus;
  techStacks?: Maybe<TechStacks>;
  role?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  pictureURL?: Maybe<Scalars['String']>;
  linkedinURL?: Maybe<Scalars['String']>;
  githubURL?: Maybe<Scalars['String']>;
  walkthroughStatus: WalkthroughStatus;
  openToOffers: Scalars['Boolean'];
  languages?: Maybe<Array<Scalars['String']>>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  score?: Maybe<Scalars['Float']>;
  workExperiences: Array<ProfileWorkExperience>;
  workExperiencesSorting: WorkExperienceSortOption;
  candidate: Candidate;
  fullName: Scalars['String'];
  fakeName: Scalars['String'];
  /** The key to unlock the cleared version of this profile */
  clearedVersionKey: Scalars['String'];
  /** Indicates whether the data of this profile is anonymized or not */
  anonymized: Scalars['Boolean'];
  anonymousProfileURL: Scalars['String'];
  clearedProfileURL: Scalars['String'];
  matchingInfos?: Maybe<MatchingInfos>;
  /** @deprecated This will be deprecated. Please access quizSubmissions through the application field resolver.  */
  quizSubmissions: Array<ProfileQuizSubmissions>;
  personalProjects: Array<ProfilePersonalProject>;
  educations: Array<ProfileEducation>;
  application: Application;
};

export type ProfileEducation = {
  __typename?: 'ProfileEducation';
  _id: Scalars['ID'];
  profileId: Scalars['ID'];
  diploma: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  school: Scalars['String'];
  location: Scalars['String'];
  remote: Scalars['Boolean'];
};

export type ProfileEducationInput = {
  _id?: Maybe<Scalars['ID']>;
  diploma: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  school: Scalars['String'];
  location: Scalars['String'];
  remote: Scalars['Boolean'];
};

export type ProfilePersonalProject = {
  __typename?: 'ProfilePersonalProject';
  _id: Scalars['ID'];
  profileId: Scalars['ID'];
  title: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  demoLink?: Maybe<Scalars['String']>;
  sourceLink?: Maybe<Scalars['String']>;
  techStack: Array<Scalars['String']>;
};

export type ProfilePersonalProjectInput = {
  _id?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  demoLink?: Maybe<Scalars['String']>;
  sourceLink?: Maybe<Scalars['String']>;
  techStack: Array<Scalars['String']>;
};

export type ProfileQuizSubmissions = {
  __typename?: 'ProfileQuizSubmissions';
  quizName: Scalars['String'];
  numberOfQuizSubmissions: Scalars['Float'];
  rank: QuizSubmissionRank;
  score: Scalars['Float'];
};

export enum ProfileStatus {
  Pending = 'pending',
  InReview = 'inReview',
  Approved = 'approved'
}

export type ProfileWorkExperience = {
  __typename?: 'ProfileWorkExperience';
  _id: Scalars['ID'];
  profileId: Scalars['ID'];
  jobTitle: Scalars['String'];
  company: Scalars['String'];
  location: Scalars['String'];
  remote: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  ignore: Scalars['Boolean'];
  jobType: ContractType;
  techStack: Array<Scalars['String']>;
  description: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type ProfileWorkExperienceInput = {
  _id?: Maybe<Scalars['ID']>;
  jobTitle: Scalars['String'];
  company: Scalars['String'];
  location: Scalars['String'];
  remote: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  jobType: ContractType;
  techStack: Array<Scalars['String']>;
  description: Scalars['String'];
  ignore?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  pipeline?: Maybe<Array<Stage>>;
  fetchCodingChallenges?: Maybe<Array<CodingChallenge>>;
  fetchInterviewingTeamLink: Scalars['String'];
  fetchQuizCards: QuizCardList;
  quizSubmission: QuizSubmission;
  getVerifiedTechStacks: Array<CustomOption>;
  supportedCountries: Array<Scalars['String']>;
  currentApplication?: Maybe<Application>;
  me: Candidate;
  currentProfile?: Maybe<Profile>;
  profileEducation?: Maybe<ProfileEducation>;
  profilePersonalProject?: Maybe<ProfilePersonalProject>;
  profileWorkExperience?: Maybe<ProfileWorkExperience>;
};


export type QueryQuizSubmissionArgs = {
  quizId: Scalars['LimitedString'];
};


export type QueryProfileEducationArgs = {
  id: Scalars['ID'];
};


export type QueryProfilePersonalProjectArgs = {
  id: Scalars['ID'];
};


export type QueryProfileWorkExperienceArgs = {
  id: Scalars['ID'];
};

export type Quiz = {
  __typename?: 'Quiz';
  _id: Scalars['ID'];
  totalQuestionsCount: Scalars['Float'];
  totalDuration: Scalars['Float'];
  icon: Scalars['String'];
  name: Scalars['String'];
  numberOfQuizSubmissions: Scalars['Float'];
};

export type QuizCardList = {
  __typename?: 'QuizCardList';
  recommended: Array<QuizSubmission>;
  other: Array<QuizSubmission>;
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  _id?: Maybe<Scalars['ID']>;
  body?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  answers?: Maybe<Array<Scalars['String']>>;
  snippet?: Maybe<Snippet>;
};

export type QuizSubmission = {
  __typename?: 'QuizSubmission';
  _id?: Maybe<Scalars['ID']>;
  status: SubmissionStatus;
  submittedAt?: Maybe<Scalars['String']>;
  quizId?: Maybe<Scalars['String']>;
  quiz: Quiz;
  questionPosition?: Maybe<Scalars['Float']>;
  hidden: Scalars['Boolean'];
  timeRemaining?: Maybe<Scalars['Float']>;
  progress: Scalars['Float'];
  score?: Maybe<Scalars['Float']>;
  sharableLink?: Maybe<Scalars['String']>;
  stats?: Maybe<QuizSubmissionStats>;
  subScores?: Maybe<Array<QuizSubmissionSubscore>>;
  question?: Maybe<QuizQuestion>;
  totalQuestionsCount: Scalars['Float'];
};

export enum QuizSubmissionRank {
  Top5 = 'top5',
  Top10 = 'top10',
  Top20 = 'top20',
  Top30 = 'top30',
  Top50 = 'top50',
  Unranked = 'unranked'
}

export type QuizSubmissionStats = {
  __typename?: 'QuizSubmissionStats';
  rank: QuizSubmissionRank;
  numberOfQuizSubmissions: Scalars['Int'];
};

export type QuizSubmissionSubscore = {
  __typename?: 'QuizSubmissionSubscore';
  id: Scalars['ID'];
  topicName: Scalars['String'];
  score: Scalars['Float'];
};

export enum RejectionReason {
  TooJunior = 'tooJunior',
  LowQuizScore = 'lowQuizScore',
  UnsupportedCountry = 'unsupportedCountry',
  Ghosted = 'ghosted',
  Dropped = 'dropped',
  Paused = 'paused',
  Other = 'other',
  Archived = 'archived',
  FalsePositive = 'falsePositive',
  Standby = 'standby',
  AcceptedAnotherOffer = 'acceptedAnotherOffer',
  WrongSalaryExpectations = 'wrongSalaryExpectations',
  WantsToStayWithCurrentEmployer = 'wantsToStayWithCurrentEmployer',
  NotOpenToContractorEmployment = 'notOpenToContractorEmployment',
  LackingTechSkills = 'lackingTechSkills',
  LackingCommSkills = 'lackingCommSkills',
  ShowedInflatedEgo = 'showedInflatedEgo',
  FuturePotential = 'futurePotential',
  FuturePotentialNudging = 'futurePotentialNudging',
  WrongTechStack = 'wrongTechStack',
  Outdated = 'outdated',
  SeekingInternship = 'seekingInternship'
}

export enum Role {
  Candidate = 'candidate',
  SuperAdmin = 'superAdmin',
  TalentManager = 'talentManager',
  TalentCoordinator = 'talentCoordinator',
  Interviewer = 'interviewer',
  VerifiedCandidate = 'verifiedCandidate',
  ActiveCandidate = 'activeCandidate',
  ConfirmedCandidate = 'confirmedCandidate',
  CompanyUser = 'companyUser',
  UnverifiedCompanyUser = 'unverifiedCompanyUser',
  AdminRoles = 'adminRoles',
  CompanyRoles = 'companyRoles',
  SelfServeRoles = 'selfServeRoles'
}

export type SalaryExpectations = {
  __typename?: 'SalaryExpectations';
  min?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type SalaryExpectationsInput = {
  min?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type Scorecard = {
  __typename?: 'Scorecard';
  _id: Scalars['ID'];
  stage?: Maybe<Stage>;
  type: ScorecardType;
  submittedAt?: Maybe<Scalars['DateTime']>;
  attributes: Array<ScorecardAttribute>;
  questions?: Maybe<Array<ScorecardQuestion>>;
  hidden: Scalars['Boolean'];
  keyTakeaway?: Maybe<Scalars['String']>;
  verified: Scalars['Boolean'];
  isLost: Scalars['Boolean'];
  grade?: Maybe<ScorecardGrade>;
  submittedBy: Admin;
  interview?: Maybe<Interview>;
  overallRecommendation: ScorecardRecommendation;
};

export type ScorecardAttribute = {
  __typename?: 'ScorecardAttribute';
  name: Scalars['String'];
  type: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  rating?: Maybe<ScorecardAttributeRating>;
  verified: Scalars['Boolean'];
};

export enum ScorecardAttributeRating {
  StrongNo = 'strongNo',
  No = 'no',
  Mixed = 'mixed',
  Yes = 'yes',
  StrongYes = 'strongYes'
}

export type ScorecardGrade = {
  __typename?: 'ScorecardGrade';
  grade: Scalars['Float'];
  notes?: Maybe<Scalars['String']>;
};

export type ScorecardQuestion = {
  __typename?: 'ScorecardQuestion';
  question: Scalars['String'];
  answer: Scalars['String'];
};

export enum ScorecardRecommendation {
  StrongNo = 'strongNo',
  No = 'no',
  Yes = 'yes',
  StrongYes = 'strongYes',
  NoDecision = 'noDecision'
}

export enum ScorecardType {
  TechnicalInterview = 'technicalInterview',
  CultureFitInterview = 'cultureFitInterview'
}

export enum Seniority {
  Junior = 'junior',
  Middle = 'middle',
  Senior = 'senior'
}

export type Skill = {
  __typename?: 'Skill';
  name: Scalars['String'];
  yearsOfExperience: Scalars['Float'];
};

export type SkillInput = {
  name: Scalars['String'];
  yearsOfExperience: Scalars['Float'];
};

export type Snippet = {
  __typename?: 'Snippet';
  mode?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export enum Stage {
  Onboarding = 'onboarding',
  Quiz = 'quiz',
  CodingChallenge = 'codingChallenge',
  Videoask = 'videoask',
  TechnicalInterview = 'technicalInterview',
  CultureFitInterview = 'cultureFitInterview',
  Discussing = 'discussing',
  PreOffer = 'preOffer',
  BuildingProfile = 'buildingProfile',
  Matching = 'matching',
  Hired = 'hired'
}

export enum Status {
  Lost = 'lost',
  Potential = 'potential',
  Active = 'active',
  Inactive = 'inactive',
  Onboarding = 'onboarding',
  Onhold = 'onhold'
}

export enum SubmissionStatus {
  Submitted = 'submitted',
  Pending = 'pending',
  Todo = 'todo'
}

export type Submissions = QuizSubmission | CcSubmission | VideoaskSubmission;

export type TechStacks = {
  __typename?: 'TechStacks';
  proficient?: Maybe<Array<Scalars['String']>>;
  experienced?: Maybe<Array<Scalars['String']>>;
  familiar?: Maybe<Array<Scalars['String']>>;
};

export type TechStacksInput = {
  proficient?: Maybe<Array<Scalars['String']>>;
  experienced?: Maybe<Array<Scalars['String']>>;
  familiar?: Maybe<Array<Scalars['String']>>;
};


export type VideoaskSubmission = {
  __typename?: 'VideoaskSubmission';
  _id?: Maybe<Scalars['ID']>;
  status: SubmissionStatus;
  submittedAt?: Maybe<Scalars['String']>;
  videoaskLink?: Maybe<Scalars['String']>;
  reviewLink?: Maybe<Scalars['String']>;
};

export enum WalkthroughStatus {
  Pending = 'pending',
  Finished = 'finished',
  Skipped = 'skipped'
}

export enum WorkExperienceSortOption {
  DescendingStartDate = 'descendingStartDate',
  DescendingEndDate = 'descendingEndDate',
  Custom = 'custom'
}

export type WorkingHours = {
  __typename?: 'WorkingHours';
  from: WorkingHoursTime;
  to: WorkingHoursTime;
};

export type WorkingHoursInput = {
  from: WorkingHoursTimeInput;
  to: WorkingHoursTimeInput;
};

export type WorkingHoursTime = {
  __typename?: 'WorkingHoursTime';
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type WorkingHoursTimeInput = {
  hours: Scalars['Int'];
  minutes: Scalars['Int'];
};

export type ApplicationFragment = (
  { __typename?: 'Application' }
  & Pick<Application, '_id' | 'progress' | 'status' | 'track' | 'rejectedAt' | 'seenRejection' | 'rejectionReason'>
  & { prevAssessment?: Maybe<(
    { __typename?: 'Assessment' }
    & Pick<Assessment, 'stage'>
  )>, currentAssessment?: Maybe<(
    { __typename?: 'Assessment' }
    & AssessmentFragment
  )>, assessments: Array<(
    { __typename?: 'Assessment' }
    & AssessmentFragment
  )>, applicationForm?: Maybe<(
    { __typename?: 'CandidatePreferences' }
    & CandidatePreferencesFragment
  )>, preferences?: Maybe<(
    { __typename?: 'CandidatePreferences' }
    & Pick<CandidatePreferences, 'experience'>
  )> }
);

export type AssessmentFragment = (
  { __typename?: 'Assessment' }
  & Pick<Assessment, '_id' | 'type' | 'stage' | 'status'>
  & { interview?: Maybe<(
    { __typename?: 'Interview' }
    & InterviewFragment
  )>, submissions?: Maybe<Array<Maybe<(
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  ) | { __typename?: 'CCSubmission' } | { __typename?: 'VideoaskSubmission' }>>> }
);

export type CandidateFragment = (
  { __typename?: 'Candidate' }
  & Pick<Candidate, '_id' | 'name' | 'firstName' | 'lastName' | 'email' | 'verified' | 'country' | 'abilities' | 'canApplyIn' | 'flag' | 'nextEmailVerificationTimestamp' | 'confirmed'>
  & { phone: (
    { __typename?: 'PhoneNumber' }
    & PhoneNumberFragment
  ), currentApplication: (
    { __typename?: 'Application' }
    & ApplicationFragment
  ) }
);

export type CandidatePreferencesFragment = (
  { __typename?: 'CandidatePreferences' }
  & Pick<CandidatePreferences, 'primaryRole' | 'availability' | 'secondaryRoles' | 'contractType' | 'englishProficiency' | 'frenchProficiency' | 'techStack' | 'experience'>
);

export type CodingChallengeFragment = (
  { __typename?: 'CodingChallenge' }
  & Pick<CodingChallenge, '_id' | 'label' | 'repo'>
);

export type CustomOptionFragment = (
  { __typename?: 'CustomOption' }
  & Pick<CustomOption, '_id' | 'tag' | 'verified' | 'type' | 'createdAt'>
);

export type EducationFragment = (
  { __typename?: 'ProfileEducation' }
  & Pick<ProfileEducation, '_id' | 'diploma' | 'startDate' | 'endDate' | 'school' | 'location' | 'remote'>
);

export type InterviewFragment = (
  { __typename?: 'Interview' }
  & Pick<Interview, '_id' | 'from' | 'to' | 'inviteeCalendlyId' | 'status'>
  & { interviewer: (
    { __typename?: 'Interviewer' }
    & InterviewerFragment
  ) }
);

export type InterviewerFragment = (
  { __typename?: 'Interviewer' }
  & Pick<Interviewer, '_id' | 'name' | 'avatar'>
);

export type PersonalProjectFragment = (
  { __typename?: 'ProfilePersonalProject' }
  & Pick<ProfilePersonalProject, '_id' | 'description' | 'startDate' | 'endDate' | 'techStack' | 'sourceLink' | 'demoLink' | 'title'>
);

export type PhoneNumberFragment = (
  { __typename?: 'PhoneNumber' }
  & Pick<PhoneNumber, 'code' | 'dial' | 'localNumber'>
);

export type ProfileFragment = (
  { __typename?: 'Profile' }
  & Pick<Profile, '_id' | 'status' | 'role' | 'bio' | 'linkedinURL' | 'githubURL' | 'pictureURL' | 'walkthroughStatus' | 'openToOffers' | 'languages'>
  & { techStacks?: Maybe<(
    { __typename?: 'TechStacks' }
    & Pick<TechStacks, 'proficient' | 'experienced' | 'familiar'>
  )>, quizSubmissions: Array<(
    { __typename?: 'ProfileQuizSubmissions' }
    & ProfileQuizSubmissionFragment
  )> }
);

export type ProfileQuizSubmissionFragment = (
  { __typename?: 'ProfileQuizSubmissions' }
  & Pick<ProfileQuizSubmissions, 'quizName' | 'numberOfQuizSubmissions' | 'rank' | 'score'>
);

export type QuizFragment = (
  { __typename?: 'Quiz' }
  & Pick<Quiz, '_id' | 'name' | 'totalDuration' | 'totalQuestionsCount' | 'icon'>
);

export type QuizCardListFragment = (
  { __typename?: 'QuizCardList' }
  & { recommended: Array<(
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  )>, other: Array<(
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  )> }
);

export type QuizQuestionFragment = (
  { __typename?: 'QuizQuestion' }
  & Pick<QuizQuestion, '_id' | 'body' | 'duration' | 'answers'>
  & { snippet?: Maybe<(
    { __typename?: 'Snippet' }
    & Pick<Snippet, 'mode' | 'value'>
  )> }
);

export type QuizSubmissionFragment = (
  { __typename?: 'QuizSubmission' }
  & Pick<QuizSubmission, '_id' | 'quizId' | 'questionPosition' | 'totalQuestionsCount' | 'progress' | 'status' | 'timeRemaining'>
  & { quiz: (
    { __typename?: 'Quiz' }
    & Pick<Quiz, '_id' | 'name' | 'icon' | 'totalQuestionsCount' | 'totalDuration' | 'numberOfQuizSubmissions'>
  ), question?: Maybe<(
    { __typename?: 'QuizQuestion' }
    & QuizQuestionFragment
  )> }
);

export type WorkExperienceFragment = (
  { __typename?: 'ProfileWorkExperience' }
  & Pick<ProfileWorkExperience, '_id' | 'company' | 'description' | 'startDate' | 'endDate' | 'jobTitle' | 'jobType' | 'location' | 'remote' | 'techStack'>
);

export type BookInterviewMutationVariables = Exact<{
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  interviewerName: Scalars['String'];
  inviteeCalendlyId: Scalars['String'];
  interviewerEmail: Scalars['String'];
}>;


export type BookInterviewMutation = (
  { __typename?: 'Mutation' }
  & { bookInterview: (
    { __typename?: 'Assessment' }
    & AssessmentFragment
  ) }
);

export type CancelApplicationMutationVariables = Exact<{
  onHoldReason: Scalars['String'];
}>;


export type CancelApplicationMutation = (
  { __typename?: 'Mutation' }
  & { cancelApplication: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type CancelInterviewMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelInterviewMutation = (
  { __typename?: 'Mutation' }
  & { cancelInterview: (
    { __typename?: 'Assessment' }
    & AssessmentFragment
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['Password'];
  newPassword: Scalars['Password'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ConfirmInterviewMutationVariables = Exact<{
  confirmationCode: Scalars['String'];
}>;


export type ConfirmInterviewMutation = (
  { __typename?: 'Mutation' }
  & { confirmInterview: (
    { __typename?: 'Interview' }
    & Pick<Interview, '_id'>
  ) }
);

export type DeleteEducationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteEducationMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfileEducation: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { educations: Array<(
      { __typename?: 'ProfileEducation' }
      & EducationFragment
    )> }
  ) }
);

export type DeletePersonalProjectMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePersonalProjectMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfilePersonalProject: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { personalProjects: Array<(
      { __typename?: 'ProfilePersonalProject' }
      & PersonalProjectFragment
    )> }
  ) }
);

export type DeleteWorkExperienceMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteWorkExperienceMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfileWorkExperience: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { workExperiences: Array<(
      { __typename?: 'ProfileWorkExperience' }
      & WorkExperienceFragment
    )> }
  ) }
);

export type DeployProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type DeployProfileMutation = (
  { __typename?: 'Mutation' }
  & { deployProfile: (
    { __typename?: 'Profile' }
    & ProfileFragment
  ) }
);

export type GithubAuthMutationVariables = Exact<{
  code: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
}>;


export type GithubAuthMutation = (
  { __typename?: 'Mutation' }
  & { githubAuth: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { me: (
      { __typename?: 'Candidate' }
      & CandidateFragment
    ) }
  ) }
);

export type GoogleAuthMutationVariables = Exact<{
  code: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
}>;


export type GoogleAuthMutation = (
  { __typename?: 'Mutation' }
  & { googleAuth: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { me: (
      { __typename?: 'Candidate' }
      & CandidateFragment
    ) }
  ) }
);

export type PauseApplicationMutationVariables = Exact<{
  onHoldReason: Scalars['String'];
}>;


export type PauseApplicationMutation = (
  { __typename?: 'Mutation' }
  & { pauseApplication: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type ReapplyMutationVariables = Exact<{ [key: string]: never; }>;


export type ReapplyMutation = (
  { __typename?: 'Mutation' }
  & { reapply: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type ResendVerificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerificationMutation = (
  { __typename?: 'Mutation' }
  & { resendVerification?: Maybe<(
    { __typename?: 'EmailSendingPayload' }
    & Pick<EmailSendingPayload, 'timestamp' | 'emailSent'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['Password'];
  code: Scalars['LimitedString'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResumeApplicationMutationVariables = Exact<{ [key: string]: never; }>;


export type ResumeApplicationMutation = (
  { __typename?: 'Mutation' }
  & { resumeApplication: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type SeenRejectionMutationVariables = Exact<{ [key: string]: never; }>;


export type SeenRejectionMutation = (
  { __typename?: 'Mutation' }
  & { seenRejection: (
    { __typename?: 'Application' }
    & Pick<Application, '_id' | 'seenRejection'>
  ) }
);

export type SendPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['Email'];
}>;


export type SendPasswordResetEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendPasswordResetEmail?: Maybe<(
    { __typename?: 'EmailSendingPayload' }
    & Pick<EmailSendingPayload, 'timestamp' | 'emailSent'>
  )> }
);

export type SignInMutationVariables = Exact<{
  email: Scalars['Email'];
  password: Scalars['Password'];
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { me: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, '_id' | 'email' | 'verified'>
    ) }
  ) }
);

export type SignUpMutationVariables = Exact<{
  email: Scalars['Email'];
  password: Scalars['Password'];
  firstName: Scalars['LimitedString'];
  lastName: Scalars['LimitedString'];
  utmSource?: Maybe<Scalars['LimitedString']>;
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { me: (
      { __typename?: 'Candidate' }
      & Pick<Candidate, '_id' | 'email' | 'verified'>
    ) }
  ) }
);

export type SkipProfileWalkthroughMutationVariables = Exact<{ [key: string]: never; }>;


export type SkipProfileWalkthroughMutation = (
  { __typename?: 'Mutation' }
  & { skipProfileWalkthrough: (
    { __typename?: 'Candidate' }
    & { currentProfile?: Maybe<(
      { __typename?: 'Profile' }
      & Pick<Profile, '_id' | 'walkthroughStatus'>
    )> }
  ) }
);

export type SkipQuizQuestionMutationVariables = Exact<{
  quizId: Scalars['LimitedString'];
}>;


export type SkipQuizQuestionMutation = (
  { __typename?: 'Mutation' }
  & { skipQuizQuestion: (
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  ) }
);

export type SubmitCodingChallengeMutationVariables = Exact<{
  ccId: Scalars['LimitedString'];
  submissionLink: Scalars['URL'];
}>;


export type SubmitCodingChallengeMutation = (
  { __typename?: 'Mutation' }
  & { submitCodingChallenge: (
    { __typename?: 'Application' }
    & ApplicationFragment
  ) }
);

export type SubmitQuizAnswerMutationVariables = Exact<{
  quizId: Scalars['LimitedString'];
  answer: Scalars['String'];
  completionTime: Scalars['Float'];
}>;


export type SubmitQuizAnswerMutation = (
  { __typename?: 'Mutation' }
  & { submitQuizAnswer: (
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  ) }
);

export type SubmitQuizAssessmentMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitQuizAssessmentMutation = (
  { __typename?: 'Mutation' }
  & { submitQuizAssessment: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type SubmitVideoaskMutationVariables = Exact<{
  videoaskLink: Scalars['URL'];
}>;


export type SubmitVideoaskMutation = (
  { __typename?: 'Mutation' }
  & { submitVideoask: (
    { __typename?: 'Application' }
    & ApplicationFragment
  ) }
);

export type ToggleOfferAvailabilityMutationVariables = Exact<{ [key: string]: never; }>;


export type ToggleOfferAvailabilityMutation = (
  { __typename?: 'Mutation' }
  & { toggleOfferAvailability: (
    { __typename?: 'Profile' }
    & ProfileFragment
  ) }
);

export type UpdateCandidateMutationVariables = Exact<{
  country?: Maybe<Scalars['LimitedString']>;
  phone?: Maybe<PhoneNumberInput>;
  firstName?: Maybe<Scalars['LimitedString']>;
  lastName?: Maybe<Scalars['LimitedString']>;
  email?: Maybe<Scalars['Email']>;
}>;


export type UpdateCandidateMutation = (
  { __typename?: 'Mutation' }
  & { updateCandidate: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type UpdateEducationMutationVariables = Exact<{
  data: ProfileEducationInput;
  id: Scalars['String'];
}>;


export type UpdateEducationMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileEducation: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { educations: Array<(
      { __typename?: 'ProfileEducation' }
      & EducationFragment
    )> }
  ) }
);

export type UpdatePersonalProjectMutationVariables = Exact<{
  data: ProfilePersonalProjectInput;
  id: Scalars['String'];
}>;


export type UpdatePersonalProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProfilePersonalProject: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { personalProjects: Array<(
      { __typename?: 'ProfilePersonalProject' }
      & PersonalProjectFragment
    )> }
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  verificationCode: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { me: (
      { __typename?: 'Candidate' }
      & CandidateFragment
    ) }
  ) }
);

export type AddEducationMutationVariables = Exact<{
  input: ProfileEducationInput;
}>;


export type AddEducationMutation = (
  { __typename?: 'Mutation' }
  & { addProfileEducation: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { educations: Array<(
      { __typename?: 'ProfileEducation' }
      & EducationFragment
    )> }
  ) }
);

export type AddPersonalProjectMutationVariables = Exact<{
  input: ProfilePersonalProjectInput;
}>;


export type AddPersonalProjectMutation = (
  { __typename?: 'Mutation' }
  & { addProfilePersonalProject: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { personalProjects: Array<(
      { __typename?: 'ProfilePersonalProject' }
      & PersonalProjectFragment
    )> }
  ) }
);

export type AddWorkExperienceMutationVariables = Exact<{
  input: ProfileWorkExperienceInput;
}>;


export type AddWorkExperienceMutation = (
  { __typename?: 'Mutation' }
  & { addProfileWorkExperience: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { workExperiences: Array<(
      { __typename?: 'ProfileWorkExperience' }
      & WorkExperienceFragment
    )> }
  ) }
);

export type SetCandidatePreferencesMutationVariables = Exact<{
  preferences: CandidatePreferencesInput;
  country: Scalars['String'];
}>;


export type SetCandidatePreferencesMutation = (
  { __typename?: 'Mutation' }
  & { setCandidatePreferences: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type SetProfileInfoMutationVariables = Exact<{
  techStacks?: Maybe<TechStacksInput>;
  role?: Maybe<Scalars['LimitedString3']>;
  bio?: Maybe<Scalars['LimitedString2']>;
  pictureURL?: Maybe<Scalars['URL']>;
  linkedinURL?: Maybe<Scalars['URL']>;
  githubURL?: Maybe<Scalars['URL']>;
  languages?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SetProfileInfoMutation = (
  { __typename?: 'Mutation' }
  & { setProfileInfo: (
    { __typename?: 'Candidate' }
    & { currentProfile?: Maybe<(
      { __typename?: 'Profile' }
      & ProfileFragment
    )> }
  ) }
);

export type UpdateWorkExperienceMutationVariables = Exact<{
  data: ProfileWorkExperienceInput;
  id: Scalars['String'];
}>;


export type UpdateWorkExperienceMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileWorkExperience: (
    { __typename?: 'Profile' }
    & Pick<Profile, '_id'>
    & { workExperiences: Array<(
      { __typename?: 'ProfileWorkExperience' }
      & WorkExperienceFragment
    )> }
  ) }
);

export type AuthenticatedUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthenticatedUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Candidate' }
    & Pick<Candidate, '_id' | 'verified' | 'firstName' | 'lastName' | 'country' | 'confirmed' | 'email'>
    & { phone: (
      { __typename?: 'PhoneNumber' }
      & PhoneNumberFragment
    ), currentApplication: (
      { __typename?: 'Application' }
      & Pick<Application, '_id' | 'status'>
      & { currentAssessment?: Maybe<(
        { __typename?: 'Assessment' }
        & Pick<Assessment, 'stage'>
      )>, preferences?: Maybe<(
        { __typename?: 'CandidatePreferences' }
        & Pick<CandidatePreferences, 'experience'>
      )> }
    ) }
  ) }
);

export type CodingChallengeQueryVariables = Exact<{ [key: string]: never; }>;


export type CodingChallengeQuery = (
  { __typename?: 'Query' }
  & { fetchCodingChallenges?: Maybe<Array<(
    { __typename?: 'CodingChallenge' }
    & CodingChallengeFragment
  )>> }
);

export type CurrentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentProfileQuery = (
  { __typename?: 'Query' }
  & { currentProfile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'languages'>
    & { workExperiences: Array<(
      { __typename?: 'ProfileWorkExperience' }
      & WorkExperienceFragment
    )>, personalProjects: Array<(
      { __typename?: 'ProfilePersonalProject' }
      & PersonalProjectFragment
    )>, educations: Array<(
      { __typename?: 'ProfileEducation' }
      & EducationFragment
    )> }
    & ProfileFragment
  )> }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Candidate' }
    & CandidateFragment
  ) }
);

export type InterviewerLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type InterviewerLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'fetchInterviewingTeamLink'>
);

export type QuizCardsQueryVariables = Exact<{ [key: string]: never; }>;


export type QuizCardsQuery = (
  { __typename?: 'Query' }
  & { fetchQuizCards: (
    { __typename?: 'QuizCardList' }
    & QuizCardListFragment
  ) }
);

export type QuizSubmissionQueryVariables = Exact<{
  quizId: Scalars['LimitedString'];
}>;


export type QuizSubmissionQuery = (
  { __typename?: 'Query' }
  & { quizSubmission: (
    { __typename?: 'QuizSubmission' }
    & QuizSubmissionFragment
  ) }
);

export type TechStacksQueryVariables = Exact<{ [key: string]: never; }>;


export type TechStacksQuery = (
  { __typename?: 'Query' }
  & { techStacks: Array<(
    { __typename?: 'CustomOption' }
    & CustomOptionFragment
  )> }
);

export const PhoneNumberFragmentDoc = gql`
    fragment PhoneNumber on PhoneNumber {
  code
  dial
  localNumber
}
    `;
export const InterviewerFragmentDoc = gql`
    fragment Interviewer on Interviewer {
  _id
  name
  avatar
}
    `;
export const InterviewFragmentDoc = gql`
    fragment Interview on Interview {
  _id
  interviewer {
    ...Interviewer
  }
  from
  to
  inviteeCalendlyId
  status
}
    ${InterviewerFragmentDoc}`;
export const QuizQuestionFragmentDoc = gql`
    fragment QuizQuestion on QuizQuestion {
  _id
  body
  duration
  answers
  snippet {
    mode
    value
  }
}
    `;
export const QuizSubmissionFragmentDoc = gql`
    fragment QuizSubmission on QuizSubmission {
  _id
  quiz {
    _id
    name
    icon
    totalQuestionsCount
    totalDuration
    numberOfQuizSubmissions
  }
  quizId
  questionPosition
  totalQuestionsCount
  progress
  status
  timeRemaining
  question {
    ...QuizQuestion
  }
}
    ${QuizQuestionFragmentDoc}`;
export const AssessmentFragmentDoc = gql`
    fragment Assessment on Assessment {
  _id
  type
  stage
  status
  interview {
    ...Interview
  }
  submissions {
    ...QuizSubmission
  }
}
    ${InterviewFragmentDoc}
${QuizSubmissionFragmentDoc}`;
export const CandidatePreferencesFragmentDoc = gql`
    fragment CandidatePreferences on CandidatePreferences {
  primaryRole
  availability
  secondaryRoles
  contractType
  englishProficiency
  frenchProficiency
  techStack
  experience
}
    `;
export const ApplicationFragmentDoc = gql`
    fragment Application on Application {
  _id
  progress
  status
  track
  rejectedAt
  seenRejection
  rejectionReason
  prevAssessment {
    stage
  }
  currentAssessment {
    ...Assessment
  }
  assessments {
    ...Assessment
  }
  applicationForm: preferences {
    ...CandidatePreferences
  }
  preferences {
    experience
  }
}
    ${AssessmentFragmentDoc}
${CandidatePreferencesFragmentDoc}`;
export const CandidateFragmentDoc = gql`
    fragment Candidate on Candidate {
  _id
  name
  firstName
  lastName
  email
  phone {
    ...PhoneNumber
  }
  verified
  country
  abilities
  canApplyIn
  flag
  nextEmailVerificationTimestamp
  currentApplication {
    ...Application
  }
  confirmed
}
    ${PhoneNumberFragmentDoc}
${ApplicationFragmentDoc}`;
export const CodingChallengeFragmentDoc = gql`
    fragment CodingChallenge on CodingChallenge {
  _id
  label
  repo
}
    `;
export const CustomOptionFragmentDoc = gql`
    fragment CustomOption on CustomOption {
  _id
  tag
  verified
  type
  createdAt
}
    `;
export const EducationFragmentDoc = gql`
    fragment Education on ProfileEducation {
  _id
  diploma
  startDate
  endDate
  school
  location
  remote
}
    `;
export const PersonalProjectFragmentDoc = gql`
    fragment PersonalProject on ProfilePersonalProject {
  _id
  description
  startDate
  endDate
  techStack
  sourceLink
  demoLink
  title
}
    `;
export const ProfileQuizSubmissionFragmentDoc = gql`
    fragment ProfileQuizSubmission on ProfileQuizSubmissions {
  quizName
  numberOfQuizSubmissions
  rank
  score
}
    `;
export const ProfileFragmentDoc = gql`
    fragment Profile on Profile {
  _id
  status
  techStacks {
    proficient
    experienced
    familiar
  }
  role
  bio
  linkedinURL
  githubURL
  pictureURL
  walkthroughStatus
  openToOffers
  quizSubmissions {
    ...ProfileQuizSubmission
  }
  languages
}
    ${ProfileQuizSubmissionFragmentDoc}`;
export const QuizFragmentDoc = gql`
    fragment Quiz on Quiz {
  _id
  name
  totalDuration
  totalQuestionsCount
  icon
}
    `;
export const QuizCardListFragmentDoc = gql`
    fragment QuizCardList on QuizCardList {
  recommended {
    ...QuizSubmission
  }
  other {
    ...QuizSubmission
  }
}
    ${QuizSubmissionFragmentDoc}`;
export const WorkExperienceFragmentDoc = gql`
    fragment WorkExperience on ProfileWorkExperience {
  _id
  company
  description
  startDate
  endDate
  jobTitle
  jobType
  location
  remote
  techStack
}
    `;
export const BookInterviewDocument = gql`
    mutation BookInterview($from: DateTime!, $to: DateTime!, $interviewerName: String!, $inviteeCalendlyId: String!, $interviewerEmail: String!) {
  bookInterview(
    from: $from
    to: $to
    interviewerName: $interviewerName
    inviteeCalendlyId: $inviteeCalendlyId
    interviewerEmail: $interviewerEmail
  ) {
    ...Assessment
  }
}
    ${AssessmentFragmentDoc}`;
export type BookInterviewMutationFn = Apollo.MutationFunction<BookInterviewMutation, BookInterviewMutationVariables>;

/**
 * __useBookInterviewMutation__
 *
 * To run a mutation, you first call `useBookInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookInterviewMutation, { data, loading, error }] = useBookInterviewMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      interviewerName: // value for 'interviewerName'
 *      inviteeCalendlyId: // value for 'inviteeCalendlyId'
 *      interviewerEmail: // value for 'interviewerEmail'
 *   },
 * });
 */
export function useBookInterviewMutation(baseOptions?: Apollo.MutationHookOptions<BookInterviewMutation, BookInterviewMutationVariables>) {
        return Apollo.useMutation<BookInterviewMutation, BookInterviewMutationVariables>(BookInterviewDocument, baseOptions);
      }
export type BookInterviewMutationHookResult = ReturnType<typeof useBookInterviewMutation>;
export type BookInterviewMutationResult = Apollo.MutationResult<BookInterviewMutation>;
export type BookInterviewMutationOptions = Apollo.BaseMutationOptions<BookInterviewMutation, BookInterviewMutationVariables>;
export const CancelApplicationDocument = gql`
    mutation CancelApplication($onHoldReason: String!) {
  cancelApplication(onHoldReason: $onHoldReason) {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type CancelApplicationMutationFn = Apollo.MutationFunction<CancelApplicationMutation, CancelApplicationMutationVariables>;

/**
 * __useCancelApplicationMutation__
 *
 * To run a mutation, you first call `useCancelApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelApplicationMutation, { data, loading, error }] = useCancelApplicationMutation({
 *   variables: {
 *      onHoldReason: // value for 'onHoldReason'
 *   },
 * });
 */
export function useCancelApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CancelApplicationMutation, CancelApplicationMutationVariables>) {
        return Apollo.useMutation<CancelApplicationMutation, CancelApplicationMutationVariables>(CancelApplicationDocument, baseOptions);
      }
export type CancelApplicationMutationHookResult = ReturnType<typeof useCancelApplicationMutation>;
export type CancelApplicationMutationResult = Apollo.MutationResult<CancelApplicationMutation>;
export type CancelApplicationMutationOptions = Apollo.BaseMutationOptions<CancelApplicationMutation, CancelApplicationMutationVariables>;
export const CancelInterviewDocument = gql`
    mutation CancelInterview {
  cancelInterview {
    ...Assessment
  }
}
    ${AssessmentFragmentDoc}`;
export type CancelInterviewMutationFn = Apollo.MutationFunction<CancelInterviewMutation, CancelInterviewMutationVariables>;

/**
 * __useCancelInterviewMutation__
 *
 * To run a mutation, you first call `useCancelInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInterviewMutation, { data, loading, error }] = useCancelInterviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useCancelInterviewMutation(baseOptions?: Apollo.MutationHookOptions<CancelInterviewMutation, CancelInterviewMutationVariables>) {
        return Apollo.useMutation<CancelInterviewMutation, CancelInterviewMutationVariables>(CancelInterviewDocument, baseOptions);
      }
export type CancelInterviewMutationHookResult = ReturnType<typeof useCancelInterviewMutation>;
export type CancelInterviewMutationResult = Apollo.MutationResult<CancelInterviewMutation>;
export type CancelInterviewMutationOptions = Apollo.BaseMutationOptions<CancelInterviewMutation, CancelInterviewMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: Password!, $newPassword: Password!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ConfirmInterviewDocument = gql`
    mutation ConfirmInterview($confirmationCode: String!) {
  confirmInterview(confirmationCode: $confirmationCode) {
    _id
  }
}
    `;
export type ConfirmInterviewMutationFn = Apollo.MutationFunction<ConfirmInterviewMutation, ConfirmInterviewMutationVariables>;

/**
 * __useConfirmInterviewMutation__
 *
 * To run a mutation, you first call `useConfirmInterviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmInterviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmInterviewMutation, { data, loading, error }] = useConfirmInterviewMutation({
 *   variables: {
 *      confirmationCode: // value for 'confirmationCode'
 *   },
 * });
 */
export function useConfirmInterviewMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmInterviewMutation, ConfirmInterviewMutationVariables>) {
        return Apollo.useMutation<ConfirmInterviewMutation, ConfirmInterviewMutationVariables>(ConfirmInterviewDocument, baseOptions);
      }
export type ConfirmInterviewMutationHookResult = ReturnType<typeof useConfirmInterviewMutation>;
export type ConfirmInterviewMutationResult = Apollo.MutationResult<ConfirmInterviewMutation>;
export type ConfirmInterviewMutationOptions = Apollo.BaseMutationOptions<ConfirmInterviewMutation, ConfirmInterviewMutationVariables>;
export const DeleteEducationDocument = gql`
    mutation DeleteEducation($id: String!) {
  deleteProfileEducation(id: $id) {
    _id
    educations {
      ...Education
    }
  }
}
    ${EducationFragmentDoc}`;
export type DeleteEducationMutationFn = Apollo.MutationFunction<DeleteEducationMutation, DeleteEducationMutationVariables>;

/**
 * __useDeleteEducationMutation__
 *
 * To run a mutation, you first call `useDeleteEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEducationMutation, { data, loading, error }] = useDeleteEducationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEducationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEducationMutation, DeleteEducationMutationVariables>) {
        return Apollo.useMutation<DeleteEducationMutation, DeleteEducationMutationVariables>(DeleteEducationDocument, baseOptions);
      }
export type DeleteEducationMutationHookResult = ReturnType<typeof useDeleteEducationMutation>;
export type DeleteEducationMutationResult = Apollo.MutationResult<DeleteEducationMutation>;
export type DeleteEducationMutationOptions = Apollo.BaseMutationOptions<DeleteEducationMutation, DeleteEducationMutationVariables>;
export const DeletePersonalProjectDocument = gql`
    mutation DeletePersonalProject($id: String!) {
  deleteProfilePersonalProject(id: $id) {
    _id
    personalProjects {
      ...PersonalProject
    }
  }
}
    ${PersonalProjectFragmentDoc}`;
export type DeletePersonalProjectMutationFn = Apollo.MutationFunction<DeletePersonalProjectMutation, DeletePersonalProjectMutationVariables>;

/**
 * __useDeletePersonalProjectMutation__
 *
 * To run a mutation, you first call `useDeletePersonalProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonalProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonalProjectMutation, { data, loading, error }] = useDeletePersonalProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonalProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeletePersonalProjectMutation, DeletePersonalProjectMutationVariables>) {
        return Apollo.useMutation<DeletePersonalProjectMutation, DeletePersonalProjectMutationVariables>(DeletePersonalProjectDocument, baseOptions);
      }
export type DeletePersonalProjectMutationHookResult = ReturnType<typeof useDeletePersonalProjectMutation>;
export type DeletePersonalProjectMutationResult = Apollo.MutationResult<DeletePersonalProjectMutation>;
export type DeletePersonalProjectMutationOptions = Apollo.BaseMutationOptions<DeletePersonalProjectMutation, DeletePersonalProjectMutationVariables>;
export const DeleteWorkExperienceDocument = gql`
    mutation DeleteWorkExperience($id: String!) {
  deleteProfileWorkExperience(id: $id) {
    _id
    workExperiences {
      ...WorkExperience
    }
  }
}
    ${WorkExperienceFragmentDoc}`;
export type DeleteWorkExperienceMutationFn = Apollo.MutationFunction<DeleteWorkExperienceMutation, DeleteWorkExperienceMutationVariables>;

/**
 * __useDeleteWorkExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkExperienceMutation, { data, loading, error }] = useDeleteWorkExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkExperienceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkExperienceMutation, DeleteWorkExperienceMutationVariables>) {
        return Apollo.useMutation<DeleteWorkExperienceMutation, DeleteWorkExperienceMutationVariables>(DeleteWorkExperienceDocument, baseOptions);
      }
export type DeleteWorkExperienceMutationHookResult = ReturnType<typeof useDeleteWorkExperienceMutation>;
export type DeleteWorkExperienceMutationResult = Apollo.MutationResult<DeleteWorkExperienceMutation>;
export type DeleteWorkExperienceMutationOptions = Apollo.BaseMutationOptions<DeleteWorkExperienceMutation, DeleteWorkExperienceMutationVariables>;
export const DeployProfileDocument = gql`
    mutation DeployProfile {
  deployProfile {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export type DeployProfileMutationFn = Apollo.MutationFunction<DeployProfileMutation, DeployProfileMutationVariables>;

/**
 * __useDeployProfileMutation__
 *
 * To run a mutation, you first call `useDeployProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployProfileMutation, { data, loading, error }] = useDeployProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeployProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeployProfileMutation, DeployProfileMutationVariables>) {
        return Apollo.useMutation<DeployProfileMutation, DeployProfileMutationVariables>(DeployProfileDocument, baseOptions);
      }
export type DeployProfileMutationHookResult = ReturnType<typeof useDeployProfileMutation>;
export type DeployProfileMutationResult = Apollo.MutationResult<DeployProfileMutation>;
export type DeployProfileMutationOptions = Apollo.BaseMutationOptions<DeployProfileMutation, DeployProfileMutationVariables>;
export const GithubAuthDocument = gql`
    mutation GithubAuth($code: LimitedString!, $utmSource: LimitedString) {
  githubAuth(code: $code, utmSource: $utmSource) {
    token
    me {
      ...Candidate
    }
  }
}
    ${CandidateFragmentDoc}`;
export type GithubAuthMutationFn = Apollo.MutationFunction<GithubAuthMutation, GithubAuthMutationVariables>;

/**
 * __useGithubAuthMutation__
 *
 * To run a mutation, you first call `useGithubAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGithubAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [githubAuthMutation, { data, loading, error }] = useGithubAuthMutation({
 *   variables: {
 *      code: // value for 'code'
 *      utmSource: // value for 'utmSource'
 *   },
 * });
 */
export function useGithubAuthMutation(baseOptions?: Apollo.MutationHookOptions<GithubAuthMutation, GithubAuthMutationVariables>) {
        return Apollo.useMutation<GithubAuthMutation, GithubAuthMutationVariables>(GithubAuthDocument, baseOptions);
      }
export type GithubAuthMutationHookResult = ReturnType<typeof useGithubAuthMutation>;
export type GithubAuthMutationResult = Apollo.MutationResult<GithubAuthMutation>;
export type GithubAuthMutationOptions = Apollo.BaseMutationOptions<GithubAuthMutation, GithubAuthMutationVariables>;
export const GoogleAuthDocument = gql`
    mutation GoogleAuth($code: LimitedString!, $utmSource: LimitedString) {
  googleAuth(code: $code, utmSource: $utmSource) {
    token
    me {
      ...Candidate
    }
  }
}
    ${CandidateFragmentDoc}`;
export type GoogleAuthMutationFn = Apollo.MutationFunction<GoogleAuthMutation, GoogleAuthMutationVariables>;

/**
 * __useGoogleAuthMutation__
 *
 * To run a mutation, you first call `useGoogleAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleAuthMutation, { data, loading, error }] = useGoogleAuthMutation({
 *   variables: {
 *      code: // value for 'code'
 *      utmSource: // value for 'utmSource'
 *   },
 * });
 */
export function useGoogleAuthMutation(baseOptions?: Apollo.MutationHookOptions<GoogleAuthMutation, GoogleAuthMutationVariables>) {
        return Apollo.useMutation<GoogleAuthMutation, GoogleAuthMutationVariables>(GoogleAuthDocument, baseOptions);
      }
export type GoogleAuthMutationHookResult = ReturnType<typeof useGoogleAuthMutation>;
export type GoogleAuthMutationResult = Apollo.MutationResult<GoogleAuthMutation>;
export type GoogleAuthMutationOptions = Apollo.BaseMutationOptions<GoogleAuthMutation, GoogleAuthMutationVariables>;
export const PauseApplicationDocument = gql`
    mutation PauseApplication($onHoldReason: String!) {
  pauseApplication(onHoldReason: $onHoldReason) {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type PauseApplicationMutationFn = Apollo.MutationFunction<PauseApplicationMutation, PauseApplicationMutationVariables>;

/**
 * __usePauseApplicationMutation__
 *
 * To run a mutation, you first call `usePauseApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseApplicationMutation, { data, loading, error }] = usePauseApplicationMutation({
 *   variables: {
 *      onHoldReason: // value for 'onHoldReason'
 *   },
 * });
 */
export function usePauseApplicationMutation(baseOptions?: Apollo.MutationHookOptions<PauseApplicationMutation, PauseApplicationMutationVariables>) {
        return Apollo.useMutation<PauseApplicationMutation, PauseApplicationMutationVariables>(PauseApplicationDocument, baseOptions);
      }
export type PauseApplicationMutationHookResult = ReturnType<typeof usePauseApplicationMutation>;
export type PauseApplicationMutationResult = Apollo.MutationResult<PauseApplicationMutation>;
export type PauseApplicationMutationOptions = Apollo.BaseMutationOptions<PauseApplicationMutation, PauseApplicationMutationVariables>;
export const ReapplyDocument = gql`
    mutation Reapply {
  reapply {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type ReapplyMutationFn = Apollo.MutationFunction<ReapplyMutation, ReapplyMutationVariables>;

/**
 * __useReapplyMutation__
 *
 * To run a mutation, you first call `useReapplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReapplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reapplyMutation, { data, loading, error }] = useReapplyMutation({
 *   variables: {
 *   },
 * });
 */
export function useReapplyMutation(baseOptions?: Apollo.MutationHookOptions<ReapplyMutation, ReapplyMutationVariables>) {
        return Apollo.useMutation<ReapplyMutation, ReapplyMutationVariables>(ReapplyDocument, baseOptions);
      }
export type ReapplyMutationHookResult = ReturnType<typeof useReapplyMutation>;
export type ReapplyMutationResult = Apollo.MutationResult<ReapplyMutation>;
export type ReapplyMutationOptions = Apollo.BaseMutationOptions<ReapplyMutation, ReapplyMutationVariables>;
export const ResendVerificationDocument = gql`
    mutation ResendVerification {
  resendVerification {
    timestamp
    emailSent
  }
}
    `;
export type ResendVerificationMutationFn = Apollo.MutationFunction<ResendVerificationMutation, ResendVerificationMutationVariables>;

/**
 * __useResendVerificationMutation__
 *
 * To run a mutation, you first call `useResendVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationMutation, { data, loading, error }] = useResendVerificationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendVerificationMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerificationMutation, ResendVerificationMutationVariables>) {
        return Apollo.useMutation<ResendVerificationMutation, ResendVerificationMutationVariables>(ResendVerificationDocument, baseOptions);
      }
export type ResendVerificationMutationHookResult = ReturnType<typeof useResendVerificationMutation>;
export type ResendVerificationMutationResult = Apollo.MutationResult<ResendVerificationMutation>;
export type ResendVerificationMutationOptions = Apollo.BaseMutationOptions<ResendVerificationMutation, ResendVerificationMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: Password!, $code: LimitedString!) {
  resetPassword(password: $password, code: $code)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResumeApplicationDocument = gql`
    mutation ResumeApplication {
  resumeApplication {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type ResumeApplicationMutationFn = Apollo.MutationFunction<ResumeApplicationMutation, ResumeApplicationMutationVariables>;

/**
 * __useResumeApplicationMutation__
 *
 * To run a mutation, you first call `useResumeApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeApplicationMutation, { data, loading, error }] = useResumeApplicationMutation({
 *   variables: {
 *   },
 * });
 */
export function useResumeApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ResumeApplicationMutation, ResumeApplicationMutationVariables>) {
        return Apollo.useMutation<ResumeApplicationMutation, ResumeApplicationMutationVariables>(ResumeApplicationDocument, baseOptions);
      }
export type ResumeApplicationMutationHookResult = ReturnType<typeof useResumeApplicationMutation>;
export type ResumeApplicationMutationResult = Apollo.MutationResult<ResumeApplicationMutation>;
export type ResumeApplicationMutationOptions = Apollo.BaseMutationOptions<ResumeApplicationMutation, ResumeApplicationMutationVariables>;
export const SeenRejectionDocument = gql`
    mutation SeenRejection {
  seenRejection {
    _id
    seenRejection
  }
}
    `;
export type SeenRejectionMutationFn = Apollo.MutationFunction<SeenRejectionMutation, SeenRejectionMutationVariables>;

/**
 * __useSeenRejectionMutation__
 *
 * To run a mutation, you first call `useSeenRejectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeenRejectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seenRejectionMutation, { data, loading, error }] = useSeenRejectionMutation({
 *   variables: {
 *   },
 * });
 */
export function useSeenRejectionMutation(baseOptions?: Apollo.MutationHookOptions<SeenRejectionMutation, SeenRejectionMutationVariables>) {
        return Apollo.useMutation<SeenRejectionMutation, SeenRejectionMutationVariables>(SeenRejectionDocument, baseOptions);
      }
export type SeenRejectionMutationHookResult = ReturnType<typeof useSeenRejectionMutation>;
export type SeenRejectionMutationResult = Apollo.MutationResult<SeenRejectionMutation>;
export type SeenRejectionMutationOptions = Apollo.BaseMutationOptions<SeenRejectionMutation, SeenRejectionMutationVariables>;
export const SendPasswordResetEmailDocument = gql`
    mutation SendPasswordResetEmail($email: Email!) {
  sendPasswordResetEmail(email: $email) {
    timestamp
    emailSent
  }
}
    `;
export type SendPasswordResetEmailMutationFn = Apollo.MutationFunction<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;

/**
 * __useSendPasswordResetEmailMutation__
 *
 * To run a mutation, you first call `useSendPasswordResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPasswordResetEmailMutation, { data, loading, error }] = useSendPasswordResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>) {
        return Apollo.useMutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(SendPasswordResetEmailDocument, baseOptions);
      }
export type SendPasswordResetEmailMutationHookResult = ReturnType<typeof useSendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationResult = Apollo.MutationResult<SendPasswordResetEmailMutation>;
export type SendPasswordResetEmailMutationOptions = Apollo.BaseMutationOptions<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: Email!, $password: Password!) {
  signIn(email: $email, password: $password) {
    token
    me {
      _id
      email
      verified
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, baseOptions);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: Email!, $password: Password!, $firstName: LimitedString!, $lastName: LimitedString!, $utmSource: LimitedString) {
  signUp(
    email: $email
    password: $password
    firstName: $firstName
    lastName: $lastName
    utmSource: $utmSource
  ) {
    token
    me {
      _id
      email
      verified
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      utmSource: // value for 'utmSource'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, baseOptions);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SkipProfileWalkthroughDocument = gql`
    mutation SkipProfileWalkthrough {
  skipProfileWalkthrough {
    currentProfile {
      _id
      walkthroughStatus
    }
  }
}
    `;
export type SkipProfileWalkthroughMutationFn = Apollo.MutationFunction<SkipProfileWalkthroughMutation, SkipProfileWalkthroughMutationVariables>;

/**
 * __useSkipProfileWalkthroughMutation__
 *
 * To run a mutation, you first call `useSkipProfileWalkthroughMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipProfileWalkthroughMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipProfileWalkthroughMutation, { data, loading, error }] = useSkipProfileWalkthroughMutation({
 *   variables: {
 *   },
 * });
 */
export function useSkipProfileWalkthroughMutation(baseOptions?: Apollo.MutationHookOptions<SkipProfileWalkthroughMutation, SkipProfileWalkthroughMutationVariables>) {
        return Apollo.useMutation<SkipProfileWalkthroughMutation, SkipProfileWalkthroughMutationVariables>(SkipProfileWalkthroughDocument, baseOptions);
      }
export type SkipProfileWalkthroughMutationHookResult = ReturnType<typeof useSkipProfileWalkthroughMutation>;
export type SkipProfileWalkthroughMutationResult = Apollo.MutationResult<SkipProfileWalkthroughMutation>;
export type SkipProfileWalkthroughMutationOptions = Apollo.BaseMutationOptions<SkipProfileWalkthroughMutation, SkipProfileWalkthroughMutationVariables>;
export const SkipQuizQuestionDocument = gql`
    mutation SkipQuizQuestion($quizId: LimitedString!) {
  skipQuizQuestion(quizId: $quizId) {
    ...QuizSubmission
  }
}
    ${QuizSubmissionFragmentDoc}`;
export type SkipQuizQuestionMutationFn = Apollo.MutationFunction<SkipQuizQuestionMutation, SkipQuizQuestionMutationVariables>;

/**
 * __useSkipQuizQuestionMutation__
 *
 * To run a mutation, you first call `useSkipQuizQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipQuizQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipQuizQuestionMutation, { data, loading, error }] = useSkipQuizQuestionMutation({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useSkipQuizQuestionMutation(baseOptions?: Apollo.MutationHookOptions<SkipQuizQuestionMutation, SkipQuizQuestionMutationVariables>) {
        return Apollo.useMutation<SkipQuizQuestionMutation, SkipQuizQuestionMutationVariables>(SkipQuizQuestionDocument, baseOptions);
      }
export type SkipQuizQuestionMutationHookResult = ReturnType<typeof useSkipQuizQuestionMutation>;
export type SkipQuizQuestionMutationResult = Apollo.MutationResult<SkipQuizQuestionMutation>;
export type SkipQuizQuestionMutationOptions = Apollo.BaseMutationOptions<SkipQuizQuestionMutation, SkipQuizQuestionMutationVariables>;
export const SubmitCodingChallengeDocument = gql`
    mutation SubmitCodingChallenge($ccId: LimitedString!, $submissionLink: URL!) {
  submitCodingChallenge(ccId: $ccId, submissionLink: $submissionLink) {
    ...Application
  }
}
    ${ApplicationFragmentDoc}`;
export type SubmitCodingChallengeMutationFn = Apollo.MutationFunction<SubmitCodingChallengeMutation, SubmitCodingChallengeMutationVariables>;

/**
 * __useSubmitCodingChallengeMutation__
 *
 * To run a mutation, you first call `useSubmitCodingChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCodingChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCodingChallengeMutation, { data, loading, error }] = useSubmitCodingChallengeMutation({
 *   variables: {
 *      ccId: // value for 'ccId'
 *      submissionLink: // value for 'submissionLink'
 *   },
 * });
 */
export function useSubmitCodingChallengeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCodingChallengeMutation, SubmitCodingChallengeMutationVariables>) {
        return Apollo.useMutation<SubmitCodingChallengeMutation, SubmitCodingChallengeMutationVariables>(SubmitCodingChallengeDocument, baseOptions);
      }
export type SubmitCodingChallengeMutationHookResult = ReturnType<typeof useSubmitCodingChallengeMutation>;
export type SubmitCodingChallengeMutationResult = Apollo.MutationResult<SubmitCodingChallengeMutation>;
export type SubmitCodingChallengeMutationOptions = Apollo.BaseMutationOptions<SubmitCodingChallengeMutation, SubmitCodingChallengeMutationVariables>;
export const SubmitQuizAnswerDocument = gql`
    mutation SubmitQuizAnswer($quizId: LimitedString!, $answer: String!, $completionTime: Float!) {
  submitQuizAnswer(
    quizId: $quizId
    answer: $answer
    completionTime: $completionTime
  ) {
    ...QuizSubmission
  }
}
    ${QuizSubmissionFragmentDoc}`;
export type SubmitQuizAnswerMutationFn = Apollo.MutationFunction<SubmitQuizAnswerMutation, SubmitQuizAnswerMutationVariables>;

/**
 * __useSubmitQuizAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitQuizAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuizAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuizAnswerMutation, { data, loading, error }] = useSubmitQuizAnswerMutation({
 *   variables: {
 *      quizId: // value for 'quizId'
 *      answer: // value for 'answer'
 *      completionTime: // value for 'completionTime'
 *   },
 * });
 */
export function useSubmitQuizAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuizAnswerMutation, SubmitQuizAnswerMutationVariables>) {
        return Apollo.useMutation<SubmitQuizAnswerMutation, SubmitQuizAnswerMutationVariables>(SubmitQuizAnswerDocument, baseOptions);
      }
export type SubmitQuizAnswerMutationHookResult = ReturnType<typeof useSubmitQuizAnswerMutation>;
export type SubmitQuizAnswerMutationResult = Apollo.MutationResult<SubmitQuizAnswerMutation>;
export type SubmitQuizAnswerMutationOptions = Apollo.BaseMutationOptions<SubmitQuizAnswerMutation, SubmitQuizAnswerMutationVariables>;
export const SubmitQuizAssessmentDocument = gql`
    mutation SubmitQuizAssessment {
  submitQuizAssessment {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type SubmitQuizAssessmentMutationFn = Apollo.MutationFunction<SubmitQuizAssessmentMutation, SubmitQuizAssessmentMutationVariables>;

/**
 * __useSubmitQuizAssessmentMutation__
 *
 * To run a mutation, you first call `useSubmitQuizAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuizAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuizAssessmentMutation, { data, loading, error }] = useSubmitQuizAssessmentMutation({
 *   variables: {
 *   },
 * });
 */
export function useSubmitQuizAssessmentMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuizAssessmentMutation, SubmitQuizAssessmentMutationVariables>) {
        return Apollo.useMutation<SubmitQuizAssessmentMutation, SubmitQuizAssessmentMutationVariables>(SubmitQuizAssessmentDocument, baseOptions);
      }
export type SubmitQuizAssessmentMutationHookResult = ReturnType<typeof useSubmitQuizAssessmentMutation>;
export type SubmitQuizAssessmentMutationResult = Apollo.MutationResult<SubmitQuizAssessmentMutation>;
export type SubmitQuizAssessmentMutationOptions = Apollo.BaseMutationOptions<SubmitQuizAssessmentMutation, SubmitQuizAssessmentMutationVariables>;
export const SubmitVideoaskDocument = gql`
    mutation SubmitVideoask($videoaskLink: URL!) {
  submitVideoask(videoaskLink: $videoaskLink) {
    ...Application
  }
}
    ${ApplicationFragmentDoc}`;
export type SubmitVideoaskMutationFn = Apollo.MutationFunction<SubmitVideoaskMutation, SubmitVideoaskMutationVariables>;

/**
 * __useSubmitVideoaskMutation__
 *
 * To run a mutation, you first call `useSubmitVideoaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVideoaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVideoaskMutation, { data, loading, error }] = useSubmitVideoaskMutation({
 *   variables: {
 *      videoaskLink: // value for 'videoaskLink'
 *   },
 * });
 */
export function useSubmitVideoaskMutation(baseOptions?: Apollo.MutationHookOptions<SubmitVideoaskMutation, SubmitVideoaskMutationVariables>) {
        return Apollo.useMutation<SubmitVideoaskMutation, SubmitVideoaskMutationVariables>(SubmitVideoaskDocument, baseOptions);
      }
export type SubmitVideoaskMutationHookResult = ReturnType<typeof useSubmitVideoaskMutation>;
export type SubmitVideoaskMutationResult = Apollo.MutationResult<SubmitVideoaskMutation>;
export type SubmitVideoaskMutationOptions = Apollo.BaseMutationOptions<SubmitVideoaskMutation, SubmitVideoaskMutationVariables>;
export const ToggleOfferAvailabilityDocument = gql`
    mutation ToggleOfferAvailability {
  toggleOfferAvailability {
    ...Profile
  }
}
    ${ProfileFragmentDoc}`;
export type ToggleOfferAvailabilityMutationFn = Apollo.MutationFunction<ToggleOfferAvailabilityMutation, ToggleOfferAvailabilityMutationVariables>;

/**
 * __useToggleOfferAvailabilityMutation__
 *
 * To run a mutation, you first call `useToggleOfferAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOfferAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOfferAvailabilityMutation, { data, loading, error }] = useToggleOfferAvailabilityMutation({
 *   variables: {
 *   },
 * });
 */
export function useToggleOfferAvailabilityMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOfferAvailabilityMutation, ToggleOfferAvailabilityMutationVariables>) {
        return Apollo.useMutation<ToggleOfferAvailabilityMutation, ToggleOfferAvailabilityMutationVariables>(ToggleOfferAvailabilityDocument, baseOptions);
      }
export type ToggleOfferAvailabilityMutationHookResult = ReturnType<typeof useToggleOfferAvailabilityMutation>;
export type ToggleOfferAvailabilityMutationResult = Apollo.MutationResult<ToggleOfferAvailabilityMutation>;
export type ToggleOfferAvailabilityMutationOptions = Apollo.BaseMutationOptions<ToggleOfferAvailabilityMutation, ToggleOfferAvailabilityMutationVariables>;
export const UpdateCandidateDocument = gql`
    mutation UpdateCandidate($country: LimitedString, $phone: PhoneNumberInput, $firstName: LimitedString, $lastName: LimitedString, $email: Email) {
  updateCandidate(
    country: $country
    phone: $phone
    email: $email
    firstName: $firstName
    lastName: $lastName
  ) {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type UpdateCandidateMutationFn = Apollo.MutationFunction<UpdateCandidateMutation, UpdateCandidateMutationVariables>;

/**
 * __useUpdateCandidateMutation__
 *
 * To run a mutation, you first call `useUpdateCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCandidateMutation, { data, loading, error }] = useUpdateCandidateMutation({
 *   variables: {
 *      country: // value for 'country'
 *      phone: // value for 'phone'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateCandidateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCandidateMutation, UpdateCandidateMutationVariables>) {
        return Apollo.useMutation<UpdateCandidateMutation, UpdateCandidateMutationVariables>(UpdateCandidateDocument, baseOptions);
      }
export type UpdateCandidateMutationHookResult = ReturnType<typeof useUpdateCandidateMutation>;
export type UpdateCandidateMutationResult = Apollo.MutationResult<UpdateCandidateMutation>;
export type UpdateCandidateMutationOptions = Apollo.BaseMutationOptions<UpdateCandidateMutation, UpdateCandidateMutationVariables>;
export const UpdateEducationDocument = gql`
    mutation UpdateEducation($data: ProfileEducationInput!, $id: String!) {
  updateProfileEducation(data: $data, id: $id) {
    _id
    educations {
      ...Education
    }
  }
}
    ${EducationFragmentDoc}`;
export type UpdateEducationMutationFn = Apollo.MutationFunction<UpdateEducationMutation, UpdateEducationMutationVariables>;

/**
 * __useUpdateEducationMutation__
 *
 * To run a mutation, you first call `useUpdateEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationMutation, { data, loading, error }] = useUpdateEducationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateEducationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEducationMutation, UpdateEducationMutationVariables>) {
        return Apollo.useMutation<UpdateEducationMutation, UpdateEducationMutationVariables>(UpdateEducationDocument, baseOptions);
      }
export type UpdateEducationMutationHookResult = ReturnType<typeof useUpdateEducationMutation>;
export type UpdateEducationMutationResult = Apollo.MutationResult<UpdateEducationMutation>;
export type UpdateEducationMutationOptions = Apollo.BaseMutationOptions<UpdateEducationMutation, UpdateEducationMutationVariables>;
export const UpdatePersonalProjectDocument = gql`
    mutation UpdatePersonalProject($data: ProfilePersonalProjectInput!, $id: String!) {
  updateProfilePersonalProject(data: $data, id: $id) {
    _id
    personalProjects {
      ...PersonalProject
    }
  }
}
    ${PersonalProjectFragmentDoc}`;
export type UpdatePersonalProjectMutationFn = Apollo.MutationFunction<UpdatePersonalProjectMutation, UpdatePersonalProjectMutationVariables>;

/**
 * __useUpdatePersonalProjectMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalProjectMutation, { data, loading, error }] = useUpdatePersonalProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePersonalProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalProjectMutation, UpdatePersonalProjectMutationVariables>) {
        return Apollo.useMutation<UpdatePersonalProjectMutation, UpdatePersonalProjectMutationVariables>(UpdatePersonalProjectDocument, baseOptions);
      }
export type UpdatePersonalProjectMutationHookResult = ReturnType<typeof useUpdatePersonalProjectMutation>;
export type UpdatePersonalProjectMutationResult = Apollo.MutationResult<UpdatePersonalProjectMutation>;
export type UpdatePersonalProjectMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalProjectMutation, UpdatePersonalProjectMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($verificationCode: String!) {
  verifyEmail(verificationCode: $verificationCode) {
    token
    me {
      ...Candidate
    }
  }
}
    ${CandidateFragmentDoc}`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const AddEducationDocument = gql`
    mutation AddEducation($input: ProfileEducationInput!) {
  addProfileEducation(input: $input) {
    _id
    educations {
      ...Education
    }
  }
}
    ${EducationFragmentDoc}`;
export type AddEducationMutationFn = Apollo.MutationFunction<AddEducationMutation, AddEducationMutationVariables>;

/**
 * __useAddEducationMutation__
 *
 * To run a mutation, you first call `useAddEducationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEducationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEducationMutation, { data, loading, error }] = useAddEducationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEducationMutation(baseOptions?: Apollo.MutationHookOptions<AddEducationMutation, AddEducationMutationVariables>) {
        return Apollo.useMutation<AddEducationMutation, AddEducationMutationVariables>(AddEducationDocument, baseOptions);
      }
export type AddEducationMutationHookResult = ReturnType<typeof useAddEducationMutation>;
export type AddEducationMutationResult = Apollo.MutationResult<AddEducationMutation>;
export type AddEducationMutationOptions = Apollo.BaseMutationOptions<AddEducationMutation, AddEducationMutationVariables>;
export const AddPersonalProjectDocument = gql`
    mutation AddPersonalProject($input: ProfilePersonalProjectInput!) {
  addProfilePersonalProject(input: $input) {
    _id
    personalProjects {
      ...PersonalProject
    }
  }
}
    ${PersonalProjectFragmentDoc}`;
export type AddPersonalProjectMutationFn = Apollo.MutationFunction<AddPersonalProjectMutation, AddPersonalProjectMutationVariables>;

/**
 * __useAddPersonalProjectMutation__
 *
 * To run a mutation, you first call `useAddPersonalProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPersonalProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPersonalProjectMutation, { data, loading, error }] = useAddPersonalProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPersonalProjectMutation(baseOptions?: Apollo.MutationHookOptions<AddPersonalProjectMutation, AddPersonalProjectMutationVariables>) {
        return Apollo.useMutation<AddPersonalProjectMutation, AddPersonalProjectMutationVariables>(AddPersonalProjectDocument, baseOptions);
      }
export type AddPersonalProjectMutationHookResult = ReturnType<typeof useAddPersonalProjectMutation>;
export type AddPersonalProjectMutationResult = Apollo.MutationResult<AddPersonalProjectMutation>;
export type AddPersonalProjectMutationOptions = Apollo.BaseMutationOptions<AddPersonalProjectMutation, AddPersonalProjectMutationVariables>;
export const AddWorkExperienceDocument = gql`
    mutation AddWorkExperience($input: ProfileWorkExperienceInput!) {
  addProfileWorkExperience(input: $input) {
    _id
    workExperiences {
      ...WorkExperience
    }
  }
}
    ${WorkExperienceFragmentDoc}`;
export type AddWorkExperienceMutationFn = Apollo.MutationFunction<AddWorkExperienceMutation, AddWorkExperienceMutationVariables>;

/**
 * __useAddWorkExperienceMutation__
 *
 * To run a mutation, you first call `useAddWorkExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkExperienceMutation, { data, loading, error }] = useAddWorkExperienceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkExperienceMutation(baseOptions?: Apollo.MutationHookOptions<AddWorkExperienceMutation, AddWorkExperienceMutationVariables>) {
        return Apollo.useMutation<AddWorkExperienceMutation, AddWorkExperienceMutationVariables>(AddWorkExperienceDocument, baseOptions);
      }
export type AddWorkExperienceMutationHookResult = ReturnType<typeof useAddWorkExperienceMutation>;
export type AddWorkExperienceMutationResult = Apollo.MutationResult<AddWorkExperienceMutation>;
export type AddWorkExperienceMutationOptions = Apollo.BaseMutationOptions<AddWorkExperienceMutation, AddWorkExperienceMutationVariables>;
export const SetCandidatePreferencesDocument = gql`
    mutation setCandidatePreferences($preferences: CandidatePreferencesInput!, $country: String!) {
  setCandidatePreferences(preferences: $preferences, country: $country) {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;
export type SetCandidatePreferencesMutationFn = Apollo.MutationFunction<SetCandidatePreferencesMutation, SetCandidatePreferencesMutationVariables>;

/**
 * __useSetCandidatePreferencesMutation__
 *
 * To run a mutation, you first call `useSetCandidatePreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCandidatePreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCandidatePreferencesMutation, { data, loading, error }] = useSetCandidatePreferencesMutation({
 *   variables: {
 *      preferences: // value for 'preferences'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSetCandidatePreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SetCandidatePreferencesMutation, SetCandidatePreferencesMutationVariables>) {
        return Apollo.useMutation<SetCandidatePreferencesMutation, SetCandidatePreferencesMutationVariables>(SetCandidatePreferencesDocument, baseOptions);
      }
export type SetCandidatePreferencesMutationHookResult = ReturnType<typeof useSetCandidatePreferencesMutation>;
export type SetCandidatePreferencesMutationResult = Apollo.MutationResult<SetCandidatePreferencesMutation>;
export type SetCandidatePreferencesMutationOptions = Apollo.BaseMutationOptions<SetCandidatePreferencesMutation, SetCandidatePreferencesMutationVariables>;
export const SetProfileInfoDocument = gql`
    mutation SetProfileInfo($techStacks: TechStacksInput, $role: LimitedString3, $bio: LimitedString2, $pictureURL: URL, $linkedinURL: URL, $githubURL: URL, $languages: [String!]) {
  setProfileInfo(
    techStacks: $techStacks
    role: $role
    bio: $bio
    linkedinURL: $linkedinURL
    githubURL: $githubURL
    languages: $languages
    pictureURL: $pictureURL
  ) {
    currentProfile {
      ...Profile
    }
  }
}
    ${ProfileFragmentDoc}`;
export type SetProfileInfoMutationFn = Apollo.MutationFunction<SetProfileInfoMutation, SetProfileInfoMutationVariables>;

/**
 * __useSetProfileInfoMutation__
 *
 * To run a mutation, you first call `useSetProfileInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProfileInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProfileInfoMutation, { data, loading, error }] = useSetProfileInfoMutation({
 *   variables: {
 *      techStacks: // value for 'techStacks'
 *      role: // value for 'role'
 *      bio: // value for 'bio'
 *      pictureURL: // value for 'pictureURL'
 *      linkedinURL: // value for 'linkedinURL'
 *      githubURL: // value for 'githubURL'
 *      languages: // value for 'languages'
 *   },
 * });
 */
export function useSetProfileInfoMutation(baseOptions?: Apollo.MutationHookOptions<SetProfileInfoMutation, SetProfileInfoMutationVariables>) {
        return Apollo.useMutation<SetProfileInfoMutation, SetProfileInfoMutationVariables>(SetProfileInfoDocument, baseOptions);
      }
export type SetProfileInfoMutationHookResult = ReturnType<typeof useSetProfileInfoMutation>;
export type SetProfileInfoMutationResult = Apollo.MutationResult<SetProfileInfoMutation>;
export type SetProfileInfoMutationOptions = Apollo.BaseMutationOptions<SetProfileInfoMutation, SetProfileInfoMutationVariables>;
export const UpdateWorkExperienceDocument = gql`
    mutation UpdateWorkExperience($data: ProfileWorkExperienceInput!, $id: String!) {
  updateProfileWorkExperience(data: $data, id: $id) {
    _id
    workExperiences {
      ...WorkExperience
    }
  }
}
    ${WorkExperienceFragmentDoc}`;
export type UpdateWorkExperienceMutationFn = Apollo.MutationFunction<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>;

/**
 * __useUpdateWorkExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkExperienceMutation, { data, loading, error }] = useUpdateWorkExperienceMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateWorkExperienceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>) {
        return Apollo.useMutation<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>(UpdateWorkExperienceDocument, baseOptions);
      }
export type UpdateWorkExperienceMutationHookResult = ReturnType<typeof useUpdateWorkExperienceMutation>;
export type UpdateWorkExperienceMutationResult = Apollo.MutationResult<UpdateWorkExperienceMutation>;
export type UpdateWorkExperienceMutationOptions = Apollo.BaseMutationOptions<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>;
export const AuthenticatedUserDocument = gql`
    query AuthenticatedUser {
  me {
    _id
    verified
    firstName
    lastName
    country
    confirmed
    email
    phone {
      ...PhoneNumber
    }
    currentApplication {
      _id
      status
      currentAssessment {
        stage
      }
      preferences {
        experience
      }
    }
  }
}
    ${PhoneNumberFragmentDoc}`;

/**
 * __useAuthenticatedUserQuery__
 *
 * To run a query within a React component, call `useAuthenticatedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticatedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticatedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthenticatedUserQuery(baseOptions?: Apollo.QueryHookOptions<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>) {
        return Apollo.useQuery<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, baseOptions);
      }
export function useAuthenticatedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>) {
          return Apollo.useLazyQuery<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>(AuthenticatedUserDocument, baseOptions);
        }
export type AuthenticatedUserQueryHookResult = ReturnType<typeof useAuthenticatedUserQuery>;
export type AuthenticatedUserLazyQueryHookResult = ReturnType<typeof useAuthenticatedUserLazyQuery>;
export type AuthenticatedUserQueryResult = Apollo.QueryResult<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>;
export const CodingChallengeDocument = gql`
    query CodingChallenge {
  fetchCodingChallenges {
    ...CodingChallenge
  }
}
    ${CodingChallengeFragmentDoc}`;

/**
 * __useCodingChallengeQuery__
 *
 * To run a query within a React component, call `useCodingChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodingChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodingChallengeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodingChallengeQuery(baseOptions?: Apollo.QueryHookOptions<CodingChallengeQuery, CodingChallengeQueryVariables>) {
        return Apollo.useQuery<CodingChallengeQuery, CodingChallengeQueryVariables>(CodingChallengeDocument, baseOptions);
      }
export function useCodingChallengeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CodingChallengeQuery, CodingChallengeQueryVariables>) {
          return Apollo.useLazyQuery<CodingChallengeQuery, CodingChallengeQueryVariables>(CodingChallengeDocument, baseOptions);
        }
export type CodingChallengeQueryHookResult = ReturnType<typeof useCodingChallengeQuery>;
export type CodingChallengeLazyQueryHookResult = ReturnType<typeof useCodingChallengeLazyQuery>;
export type CodingChallengeQueryResult = Apollo.QueryResult<CodingChallengeQuery, CodingChallengeQueryVariables>;
export const CurrentProfileDocument = gql`
    query CurrentProfile {
  currentProfile {
    ...Profile
    workExperiences {
      ...WorkExperience
    }
    personalProjects {
      ...PersonalProject
    }
    educations {
      ...Education
    }
    languages
  }
}
    ${ProfileFragmentDoc}
${WorkExperienceFragmentDoc}
${PersonalProjectFragmentDoc}
${EducationFragmentDoc}`;

/**
 * __useCurrentProfileQuery__
 *
 * To run a query within a React component, call `useCurrentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfileQuery(baseOptions?: Apollo.QueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
        return Apollo.useQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, baseOptions);
      }
export function useCurrentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentProfileQuery, CurrentProfileQueryVariables>) {
          return Apollo.useLazyQuery<CurrentProfileQuery, CurrentProfileQueryVariables>(CurrentProfileDocument, baseOptions);
        }
export type CurrentProfileQueryHookResult = ReturnType<typeof useCurrentProfileQuery>;
export type CurrentProfileLazyQueryHookResult = ReturnType<typeof useCurrentProfileLazyQuery>;
export type CurrentProfileQueryResult = Apollo.QueryResult<CurrentProfileQuery, CurrentProfileQueryVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  me {
    ...Candidate
  }
}
    ${CandidateFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const InterviewerLinkDocument = gql`
    query InterviewerLink {
  fetchInterviewingTeamLink
}
    `;

/**
 * __useInterviewerLinkQuery__
 *
 * To run a query within a React component, call `useInterviewerLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewerLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewerLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useInterviewerLinkQuery(baseOptions?: Apollo.QueryHookOptions<InterviewerLinkQuery, InterviewerLinkQueryVariables>) {
        return Apollo.useQuery<InterviewerLinkQuery, InterviewerLinkQueryVariables>(InterviewerLinkDocument, baseOptions);
      }
export function useInterviewerLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewerLinkQuery, InterviewerLinkQueryVariables>) {
          return Apollo.useLazyQuery<InterviewerLinkQuery, InterviewerLinkQueryVariables>(InterviewerLinkDocument, baseOptions);
        }
export type InterviewerLinkQueryHookResult = ReturnType<typeof useInterviewerLinkQuery>;
export type InterviewerLinkLazyQueryHookResult = ReturnType<typeof useInterviewerLinkLazyQuery>;
export type InterviewerLinkQueryResult = Apollo.QueryResult<InterviewerLinkQuery, InterviewerLinkQueryVariables>;
export const QuizCardsDocument = gql`
    query QuizCards {
  fetchQuizCards {
    ...QuizCardList
  }
}
    ${QuizCardListFragmentDoc}`;

/**
 * __useQuizCardsQuery__
 *
 * To run a query within a React component, call `useQuizCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizCardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuizCardsQuery(baseOptions?: Apollo.QueryHookOptions<QuizCardsQuery, QuizCardsQueryVariables>) {
        return Apollo.useQuery<QuizCardsQuery, QuizCardsQueryVariables>(QuizCardsDocument, baseOptions);
      }
export function useQuizCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizCardsQuery, QuizCardsQueryVariables>) {
          return Apollo.useLazyQuery<QuizCardsQuery, QuizCardsQueryVariables>(QuizCardsDocument, baseOptions);
        }
export type QuizCardsQueryHookResult = ReturnType<typeof useQuizCardsQuery>;
export type QuizCardsLazyQueryHookResult = ReturnType<typeof useQuizCardsLazyQuery>;
export type QuizCardsQueryResult = Apollo.QueryResult<QuizCardsQuery, QuizCardsQueryVariables>;
export const QuizSubmissionDocument = gql`
    query QuizSubmission($quizId: LimitedString!) {
  quizSubmission(quizId: $quizId) {
    ...QuizSubmission
  }
}
    ${QuizSubmissionFragmentDoc}`;

/**
 * __useQuizSubmissionQuery__
 *
 * To run a query within a React component, call `useQuizSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuizSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizSubmissionQuery({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useQuizSubmissionQuery(baseOptions: Apollo.QueryHookOptions<QuizSubmissionQuery, QuizSubmissionQueryVariables>) {
        return Apollo.useQuery<QuizSubmissionQuery, QuizSubmissionQueryVariables>(QuizSubmissionDocument, baseOptions);
      }
export function useQuizSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuizSubmissionQuery, QuizSubmissionQueryVariables>) {
          return Apollo.useLazyQuery<QuizSubmissionQuery, QuizSubmissionQueryVariables>(QuizSubmissionDocument, baseOptions);
        }
export type QuizSubmissionQueryHookResult = ReturnType<typeof useQuizSubmissionQuery>;
export type QuizSubmissionLazyQueryHookResult = ReturnType<typeof useQuizSubmissionLazyQuery>;
export type QuizSubmissionQueryResult = Apollo.QueryResult<QuizSubmissionQuery, QuizSubmissionQueryVariables>;
export const TechStacksDocument = gql`
    query TechStacks {
  techStacks: getVerifiedTechStacks {
    ...CustomOption
  }
}
    ${CustomOptionFragmentDoc}`;

/**
 * __useTechStacksQuery__
 *
 * To run a query within a React component, call `useTechStacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechStacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechStacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechStacksQuery(baseOptions?: Apollo.QueryHookOptions<TechStacksQuery, TechStacksQueryVariables>) {
        return Apollo.useQuery<TechStacksQuery, TechStacksQueryVariables>(TechStacksDocument, baseOptions);
      }
export function useTechStacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechStacksQuery, TechStacksQueryVariables>) {
          return Apollo.useLazyQuery<TechStacksQuery, TechStacksQueryVariables>(TechStacksDocument, baseOptions);
        }
export type TechStacksQueryHookResult = ReturnType<typeof useTechStacksQuery>;
export type TechStacksLazyQueryHookResult = ReturnType<typeof useTechStacksLazyQuery>;
export type TechStacksQueryResult = Apollo.QueryResult<TechStacksQuery, TechStacksQueryVariables>;

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockBookInterviewMutation((req, res, ctx) => {
 *   const { from, to, interviewerName, inviteeCalendlyId, interviewerEmail } = req.variables;
 *   return res(
 *     ctx.data({ bookInterview })
 *   )
 * })
 */
export const mockBookInterviewMutation = (resolver: ResponseResolver<GraphQLRequest<BookInterviewMutationVariables>, GraphQLContext<BookInterviewMutation>, any>) =>
  graphql.mutation<BookInterviewMutation, BookInterviewMutationVariables>(
    'BookInterview',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelApplicationMutation((req, res, ctx) => {
 *   const { onHoldReason } = req.variables;
 *   return res(
 *     ctx.data({ cancelApplication })
 *   )
 * })
 */
export const mockCancelApplicationMutation = (resolver: ResponseResolver<GraphQLRequest<CancelApplicationMutationVariables>, GraphQLContext<CancelApplicationMutation>, any>) =>
  graphql.mutation<CancelApplicationMutation, CancelApplicationMutationVariables>(
    'CancelApplication',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCancelInterviewMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ cancelInterview })
 *   )
 * })
 */
export const mockCancelInterviewMutation = (resolver: ResponseResolver<GraphQLRequest<CancelInterviewMutationVariables>, GraphQLContext<CancelInterviewMutation>, any>) =>
  graphql.mutation<CancelInterviewMutation, CancelInterviewMutationVariables>(
    'CancelInterview',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockChangePasswordMutation((req, res, ctx) => {
 *   const { oldPassword, newPassword } = req.variables;
 *   return res(
 *     ctx.data({ changePassword })
 *   )
 * })
 */
export const mockChangePasswordMutation = (resolver: ResponseResolver<GraphQLRequest<ChangePasswordMutationVariables>, GraphQLContext<ChangePasswordMutation>, any>) =>
  graphql.mutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    'ChangePassword',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockConfirmInterviewMutation((req, res, ctx) => {
 *   const { confirmationCode } = req.variables;
 *   return res(
 *     ctx.data({ confirmInterview })
 *   )
 * })
 */
export const mockConfirmInterviewMutation = (resolver: ResponseResolver<GraphQLRequest<ConfirmInterviewMutationVariables>, GraphQLContext<ConfirmInterviewMutation>, any>) =>
  graphql.mutation<ConfirmInterviewMutation, ConfirmInterviewMutationVariables>(
    'ConfirmInterview',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteEducationMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteProfileEducation })
 *   )
 * })
 */
export const mockDeleteEducationMutation = (resolver: ResponseResolver<GraphQLRequest<DeleteEducationMutationVariables>, GraphQLContext<DeleteEducationMutation>, any>) =>
  graphql.mutation<DeleteEducationMutation, DeleteEducationMutationVariables>(
    'DeleteEducation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeletePersonalProjectMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteProfilePersonalProject })
 *   )
 * })
 */
export const mockDeletePersonalProjectMutation = (resolver: ResponseResolver<GraphQLRequest<DeletePersonalProjectMutationVariables>, GraphQLContext<DeletePersonalProjectMutation>, any>) =>
  graphql.mutation<DeletePersonalProjectMutation, DeletePersonalProjectMutationVariables>(
    'DeletePersonalProject',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeleteWorkExperienceMutation((req, res, ctx) => {
 *   const { id } = req.variables;
 *   return res(
 *     ctx.data({ deleteProfileWorkExperience })
 *   )
 * })
 */
export const mockDeleteWorkExperienceMutation = (resolver: ResponseResolver<GraphQLRequest<DeleteWorkExperienceMutationVariables>, GraphQLContext<DeleteWorkExperienceMutation>, any>) =>
  graphql.mutation<DeleteWorkExperienceMutation, DeleteWorkExperienceMutationVariables>(
    'DeleteWorkExperience',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockDeployProfileMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ deployProfile })
 *   )
 * })
 */
export const mockDeployProfileMutation = (resolver: ResponseResolver<GraphQLRequest<DeployProfileMutationVariables>, GraphQLContext<DeployProfileMutation>, any>) =>
  graphql.mutation<DeployProfileMutation, DeployProfileMutationVariables>(
    'DeployProfile',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGithubAuthMutation((req, res, ctx) => {
 *   const { code, utmSource } = req.variables;
 *   return res(
 *     ctx.data({ githubAuth })
 *   )
 * })
 */
export const mockGithubAuthMutation = (resolver: ResponseResolver<GraphQLRequest<GithubAuthMutationVariables>, GraphQLContext<GithubAuthMutation>, any>) =>
  graphql.mutation<GithubAuthMutation, GithubAuthMutationVariables>(
    'GithubAuth',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockGoogleAuthMutation((req, res, ctx) => {
 *   const { code, utmSource } = req.variables;
 *   return res(
 *     ctx.data({ googleAuth })
 *   )
 * })
 */
export const mockGoogleAuthMutation = (resolver: ResponseResolver<GraphQLRequest<GoogleAuthMutationVariables>, GraphQLContext<GoogleAuthMutation>, any>) =>
  graphql.mutation<GoogleAuthMutation, GoogleAuthMutationVariables>(
    'GoogleAuth',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockPauseApplicationMutation((req, res, ctx) => {
 *   const { onHoldReason } = req.variables;
 *   return res(
 *     ctx.data({ pauseApplication })
 *   )
 * })
 */
export const mockPauseApplicationMutation = (resolver: ResponseResolver<GraphQLRequest<PauseApplicationMutationVariables>, GraphQLContext<PauseApplicationMutation>, any>) =>
  graphql.mutation<PauseApplicationMutation, PauseApplicationMutationVariables>(
    'PauseApplication',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockReapplyMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ reapply })
 *   )
 * })
 */
export const mockReapplyMutation = (resolver: ResponseResolver<GraphQLRequest<ReapplyMutationVariables>, GraphQLContext<ReapplyMutation>, any>) =>
  graphql.mutation<ReapplyMutation, ReapplyMutationVariables>(
    'Reapply',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResendVerificationMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ resendVerification })
 *   )
 * })
 */
export const mockResendVerificationMutation = (resolver: ResponseResolver<GraphQLRequest<ResendVerificationMutationVariables>, GraphQLContext<ResendVerificationMutation>, any>) =>
  graphql.mutation<ResendVerificationMutation, ResendVerificationMutationVariables>(
    'ResendVerification',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResetPasswordMutation((req, res, ctx) => {
 *   const { password, code } = req.variables;
 *   return res(
 *     ctx.data({ resetPassword })
 *   )
 * })
 */
export const mockResetPasswordMutation = (resolver: ResponseResolver<GraphQLRequest<ResetPasswordMutationVariables>, GraphQLContext<ResetPasswordMutation>, any>) =>
  graphql.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    'ResetPassword',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockResumeApplicationMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ resumeApplication })
 *   )
 * })
 */
export const mockResumeApplicationMutation = (resolver: ResponseResolver<GraphQLRequest<ResumeApplicationMutationVariables>, GraphQLContext<ResumeApplicationMutation>, any>) =>
  graphql.mutation<ResumeApplicationMutation, ResumeApplicationMutationVariables>(
    'ResumeApplication',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSeenRejectionMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ seenRejection })
 *   )
 * })
 */
export const mockSeenRejectionMutation = (resolver: ResponseResolver<GraphQLRequest<SeenRejectionMutationVariables>, GraphQLContext<SeenRejectionMutation>, any>) =>
  graphql.mutation<SeenRejectionMutation, SeenRejectionMutationVariables>(
    'SeenRejection',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSendPasswordResetEmailMutation((req, res, ctx) => {
 *   const { email } = req.variables;
 *   return res(
 *     ctx.data({ sendPasswordResetEmail })
 *   )
 * })
 */
export const mockSendPasswordResetEmailMutation = (resolver: ResponseResolver<GraphQLRequest<SendPasswordResetEmailMutationVariables>, GraphQLContext<SendPasswordResetEmailMutation>, any>) =>
  graphql.mutation<SendPasswordResetEmailMutation, SendPasswordResetEmailMutationVariables>(
    'SendPasswordResetEmail',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSignInMutation((req, res, ctx) => {
 *   const { email, password } = req.variables;
 *   return res(
 *     ctx.data({ signIn })
 *   )
 * })
 */
export const mockSignInMutation = (resolver: ResponseResolver<GraphQLRequest<SignInMutationVariables>, GraphQLContext<SignInMutation>, any>) =>
  graphql.mutation<SignInMutation, SignInMutationVariables>(
    'SignIn',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSignUpMutation((req, res, ctx) => {
 *   const { email, password, firstName, lastName, utmSource } = req.variables;
 *   return res(
 *     ctx.data({ signUp })
 *   )
 * })
 */
export const mockSignUpMutation = (resolver: ResponseResolver<GraphQLRequest<SignUpMutationVariables>, GraphQLContext<SignUpMutation>, any>) =>
  graphql.mutation<SignUpMutation, SignUpMutationVariables>(
    'SignUp',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSkipProfileWalkthroughMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ skipProfileWalkthrough })
 *   )
 * })
 */
export const mockSkipProfileWalkthroughMutation = (resolver: ResponseResolver<GraphQLRequest<SkipProfileWalkthroughMutationVariables>, GraphQLContext<SkipProfileWalkthroughMutation>, any>) =>
  graphql.mutation<SkipProfileWalkthroughMutation, SkipProfileWalkthroughMutationVariables>(
    'SkipProfileWalkthrough',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSkipQuizQuestionMutation((req, res, ctx) => {
 *   const { quizId } = req.variables;
 *   return res(
 *     ctx.data({ skipQuizQuestion })
 *   )
 * })
 */
export const mockSkipQuizQuestionMutation = (resolver: ResponseResolver<GraphQLRequest<SkipQuizQuestionMutationVariables>, GraphQLContext<SkipQuizQuestionMutation>, any>) =>
  graphql.mutation<SkipQuizQuestionMutation, SkipQuizQuestionMutationVariables>(
    'SkipQuizQuestion',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitCodingChallengeMutation((req, res, ctx) => {
 *   const { ccId, submissionLink } = req.variables;
 *   return res(
 *     ctx.data({ submitCodingChallenge })
 *   )
 * })
 */
export const mockSubmitCodingChallengeMutation = (resolver: ResponseResolver<GraphQLRequest<SubmitCodingChallengeMutationVariables>, GraphQLContext<SubmitCodingChallengeMutation>, any>) =>
  graphql.mutation<SubmitCodingChallengeMutation, SubmitCodingChallengeMutationVariables>(
    'SubmitCodingChallenge',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitQuizAnswerMutation((req, res, ctx) => {
 *   const { quizId, answer, completionTime } = req.variables;
 *   return res(
 *     ctx.data({ submitQuizAnswer })
 *   )
 * })
 */
export const mockSubmitQuizAnswerMutation = (resolver: ResponseResolver<GraphQLRequest<SubmitQuizAnswerMutationVariables>, GraphQLContext<SubmitQuizAnswerMutation>, any>) =>
  graphql.mutation<SubmitQuizAnswerMutation, SubmitQuizAnswerMutationVariables>(
    'SubmitQuizAnswer',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitQuizAssessmentMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ submitQuizAssessment })
 *   )
 * })
 */
export const mockSubmitQuizAssessmentMutation = (resolver: ResponseResolver<GraphQLRequest<SubmitQuizAssessmentMutationVariables>, GraphQLContext<SubmitQuizAssessmentMutation>, any>) =>
  graphql.mutation<SubmitQuizAssessmentMutation, SubmitQuizAssessmentMutationVariables>(
    'SubmitQuizAssessment',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSubmitVideoaskMutation((req, res, ctx) => {
 *   const { videoaskLink } = req.variables;
 *   return res(
 *     ctx.data({ submitVideoask })
 *   )
 * })
 */
export const mockSubmitVideoaskMutation = (resolver: ResponseResolver<GraphQLRequest<SubmitVideoaskMutationVariables>, GraphQLContext<SubmitVideoaskMutation>, any>) =>
  graphql.mutation<SubmitVideoaskMutation, SubmitVideoaskMutationVariables>(
    'SubmitVideoask',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockToggleOfferAvailabilityMutation((req, res, ctx) => {
 *   return res(
 *     ctx.data({ toggleOfferAvailability })
 *   )
 * })
 */
export const mockToggleOfferAvailabilityMutation = (resolver: ResponseResolver<GraphQLRequest<ToggleOfferAvailabilityMutationVariables>, GraphQLContext<ToggleOfferAvailabilityMutation>, any>) =>
  graphql.mutation<ToggleOfferAvailabilityMutation, ToggleOfferAvailabilityMutationVariables>(
    'ToggleOfferAvailability',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateCandidateMutation((req, res, ctx) => {
 *   const { country, phone, firstName, lastName, email } = req.variables;
 *   return res(
 *     ctx.data({ updateCandidate })
 *   )
 * })
 */
export const mockUpdateCandidateMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateCandidateMutationVariables>, GraphQLContext<UpdateCandidateMutation>, any>) =>
  graphql.mutation<UpdateCandidateMutation, UpdateCandidateMutationVariables>(
    'UpdateCandidate',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateEducationMutation((req, res, ctx) => {
 *   const { data, id } = req.variables;
 *   return res(
 *     ctx.data({ updateProfileEducation })
 *   )
 * })
 */
export const mockUpdateEducationMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateEducationMutationVariables>, GraphQLContext<UpdateEducationMutation>, any>) =>
  graphql.mutation<UpdateEducationMutation, UpdateEducationMutationVariables>(
    'UpdateEducation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdatePersonalProjectMutation((req, res, ctx) => {
 *   const { data, id } = req.variables;
 *   return res(
 *     ctx.data({ updateProfilePersonalProject })
 *   )
 * })
 */
export const mockUpdatePersonalProjectMutation = (resolver: ResponseResolver<GraphQLRequest<UpdatePersonalProjectMutationVariables>, GraphQLContext<UpdatePersonalProjectMutation>, any>) =>
  graphql.mutation<UpdatePersonalProjectMutation, UpdatePersonalProjectMutationVariables>(
    'UpdatePersonalProject',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockVerifyEmailMutation((req, res, ctx) => {
 *   const { verificationCode } = req.variables;
 *   return res(
 *     ctx.data({ verifyEmail })
 *   )
 * })
 */
export const mockVerifyEmailMutation = (resolver: ResponseResolver<GraphQLRequest<VerifyEmailMutationVariables>, GraphQLContext<VerifyEmailMutation>, any>) =>
  graphql.mutation<VerifyEmailMutation, VerifyEmailMutationVariables>(
    'VerifyEmail',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddEducationMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ addProfileEducation })
 *   )
 * })
 */
export const mockAddEducationMutation = (resolver: ResponseResolver<GraphQLRequest<AddEducationMutationVariables>, GraphQLContext<AddEducationMutation>, any>) =>
  graphql.mutation<AddEducationMutation, AddEducationMutationVariables>(
    'AddEducation',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddPersonalProjectMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ addProfilePersonalProject })
 *   )
 * })
 */
export const mockAddPersonalProjectMutation = (resolver: ResponseResolver<GraphQLRequest<AddPersonalProjectMutationVariables>, GraphQLContext<AddPersonalProjectMutation>, any>) =>
  graphql.mutation<AddPersonalProjectMutation, AddPersonalProjectMutationVariables>(
    'AddPersonalProject',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAddWorkExperienceMutation((req, res, ctx) => {
 *   const { input } = req.variables;
 *   return res(
 *     ctx.data({ addProfileWorkExperience })
 *   )
 * })
 */
export const mockAddWorkExperienceMutation = (resolver: ResponseResolver<GraphQLRequest<AddWorkExperienceMutationVariables>, GraphQLContext<AddWorkExperienceMutation>, any>) =>
  graphql.mutation<AddWorkExperienceMutation, AddWorkExperienceMutationVariables>(
    'AddWorkExperience',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSetCandidatePreferencesMutation((req, res, ctx) => {
 *   const { preferences, country } = req.variables;
 *   return res(
 *     ctx.data({ setCandidatePreferences })
 *   )
 * })
 */
export const mockSetCandidatePreferencesMutation = (resolver: ResponseResolver<GraphQLRequest<SetCandidatePreferencesMutationVariables>, GraphQLContext<SetCandidatePreferencesMutation>, any>) =>
  graphql.mutation<SetCandidatePreferencesMutation, SetCandidatePreferencesMutationVariables>(
    'setCandidatePreferences',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockSetProfileInfoMutation((req, res, ctx) => {
 *   const { techStacks, role, bio, pictureURL, linkedinURL, githubURL, languages } = req.variables;
 *   return res(
 *     ctx.data({ setProfileInfo })
 *   )
 * })
 */
export const mockSetProfileInfoMutation = (resolver: ResponseResolver<GraphQLRequest<SetProfileInfoMutationVariables>, GraphQLContext<SetProfileInfoMutation>, any>) =>
  graphql.mutation<SetProfileInfoMutation, SetProfileInfoMutationVariables>(
    'SetProfileInfo',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockUpdateWorkExperienceMutation((req, res, ctx) => {
 *   const { data, id } = req.variables;
 *   return res(
 *     ctx.data({ updateProfileWorkExperience })
 *   )
 * })
 */
export const mockUpdateWorkExperienceMutation = (resolver: ResponseResolver<GraphQLRequest<UpdateWorkExperienceMutationVariables>, GraphQLContext<UpdateWorkExperienceMutation>, any>) =>
  graphql.mutation<UpdateWorkExperienceMutation, UpdateWorkExperienceMutationVariables>(
    'UpdateWorkExperience',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockAuthenticatedUserQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ me })
 *   )
 * })
 */
export const mockAuthenticatedUserQuery = (resolver: ResponseResolver<GraphQLRequest<AuthenticatedUserQueryVariables>, GraphQLContext<AuthenticatedUserQuery>, any>) =>
  graphql.query<AuthenticatedUserQuery, AuthenticatedUserQueryVariables>(
    'AuthenticatedUser',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCodingChallengeQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ fetchCodingChallenges })
 *   )
 * })
 */
export const mockCodingChallengeQuery = (resolver: ResponseResolver<GraphQLRequest<CodingChallengeQueryVariables>, GraphQLContext<CodingChallengeQuery>, any>) =>
  graphql.query<CodingChallengeQuery, CodingChallengeQueryVariables>(
    'CodingChallenge',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCurrentProfileQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ currentProfile })
 *   )
 * })
 */
export const mockCurrentProfileQuery = (resolver: ResponseResolver<GraphQLRequest<CurrentProfileQueryVariables>, GraphQLContext<CurrentProfileQuery>, any>) =>
  graphql.query<CurrentProfileQuery, CurrentProfileQueryVariables>(
    'CurrentProfile',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockCurrentUserQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ me })
 *   )
 * })
 */
export const mockCurrentUserQuery = (resolver: ResponseResolver<GraphQLRequest<CurrentUserQueryVariables>, GraphQLContext<CurrentUserQuery>, any>) =>
  graphql.query<CurrentUserQuery, CurrentUserQueryVariables>(
    'CurrentUser',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockInterviewerLinkQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ fetchInterviewingTeamLink })
 *   )
 * })
 */
export const mockInterviewerLinkQuery = (resolver: ResponseResolver<GraphQLRequest<InterviewerLinkQueryVariables>, GraphQLContext<InterviewerLinkQuery>, any>) =>
  graphql.query<InterviewerLinkQuery, InterviewerLinkQueryVariables>(
    'InterviewerLink',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockQuizCardsQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ fetchQuizCards })
 *   )
 * })
 */
export const mockQuizCardsQuery = (resolver: ResponseResolver<GraphQLRequest<QuizCardsQueryVariables>, GraphQLContext<QuizCardsQuery>, any>) =>
  graphql.query<QuizCardsQuery, QuizCardsQueryVariables>(
    'QuizCards',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockQuizSubmissionQuery((req, res, ctx) => {
 *   const { quizId } = req.variables;
 *   return res(
 *     ctx.data({ quizSubmission })
 *   )
 * })
 */
export const mockQuizSubmissionQuery = (resolver: ResponseResolver<GraphQLRequest<QuizSubmissionQueryVariables>, GraphQLContext<QuizSubmissionQuery>, any>) =>
  graphql.query<QuizSubmissionQuery, QuizSubmissionQueryVariables>(
    'QuizSubmission',
    resolver
  )

/**
 * @param resolver a function that accepts a captured request and may return a mocked response.
 * @see https://mswjs.io/docs/basics/response-resolver
 * @example
 * mockTechStacksQuery((req, res, ctx) => {
 *   return res(
 *     ctx.data({ getVerifiedTechStacks })
 *   )
 * })
 */
export const mockTechStacksQuery = (resolver: ResponseResolver<GraphQLRequest<TechStacksQueryVariables>, GraphQLContext<TechStacksQuery>, any>) =>
  graphql.query<TechStacksQuery, TechStacksQueryVariables>(
    'TechStacks',
    resolver
  )
