import clsx from 'clsx'
import Button from 'components/Button'

export const Form = ({
  className,
  children,
  isSubmitting = false,
  ...props
}: FormProps) => (
  <form className={clsx('space-y-5', className)} {...props}>
    <div>{children}</div>
    <div className='flex justify-end'>
      <Button
        variant='secondary'
        hasIcon={false}
        type='submit'
        disabled={isSubmitting}
        loading={isSubmitting}>
        Save
      </Button>
    </div>
  </form>
)

type FormProps = { isSubmitting?: boolean } & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>
