import React from 'react'
import Callout from 'components/Callout'
import { zack } from 'assets/people'
import ProgressCard from '../Shared/ProgressCard'
import AssessmentsCard from './Cards/AssessmentsCard'
import HowToCard from '../Shared/HowToCard'
import { TalentAgentIllustration } from 'assets/illustrations'
import { ProfileStatus, useCurrentProfileQuery } from 'api'
import { useReadUser } from 'actions/user'
import { TopicCard } from 'components/TopicCard'

const topic = {
  link: 'https://www.gemography.com/blog/dos-and-donts-when-working-from-home',
  readTime: 4,
  title: "Do's and Don'ts when working from home",
  author: { ...zack, position: 'VP of Engineering' },
}

const Dashboard = () => {
  const { data, error, loading } = useCurrentProfileQuery()

  const { me } = useReadUser()

  if (loading) return null
  if (error || !data?.currentProfile) {
    const err = error || new Error('failed to fetch current profile')
    throw err
  }

  const { currentProfile } = data

  return (
    <div className='space-y-7'>
      {/* Header  */}
      <Callout icon='👋'>
        <Callout.Body>
          <p>
            <span className='font-semibold'>Hey {me.firstName}</span>,
            Congratulations on succeeding our hiring process. This step is all
            about building your profile so that we can start matching you with
            our Startup network.
          </p>
        </Callout.Body>
      </Callout>
      {/* Cards  */}
      {currentProfile?.status === ProfileStatus.InReview ? (
        <div className='relative p-6 h-64 rounded-lg border border-brand-100 border-solid shadow-sidebar'>
          <div className='w-2/3'>
            <p className='text-xl text-cardGray-50'>
              Your Talent Agent will reach out to you when you'll get interview
              requests.
            </p>
          </div>
          <div className='absolute right-9 bottom-0'>
            <TalentAgentIllustration />
          </div>
        </div>
      ) : (
        <div className='flex flex-col lg:flex-row items-center lg:items-stretch space-y-4 lg:space-y-0 lg:space-x-8'>
          <ProgressCard />
          <div
            style={{ maxWidth: '292px' }}
            className='flex flex-col flex-shrink-0 space-y-4'>
            <HowToCard
              title='How to build your profile'
              link='https://www.notion.so/hiddenpole/Profile-Material-31ba37d683234c37970268f4d5fc84e4'>
              <p className='text-xxs'>
                To build your profile, we would need you to give us all the
                relevant details about your previous experiences. We will be
                helping you with questions so please make sure you answer all of
                them
              </p>
            </HowToCard>
          </div>
        </div>
      )}

      <AssessmentsCard assessments={currentProfile?.quizSubmissions} />
      <div className='grid grid-cols-2 gap-7'>
        <TopicCard topic={topic} />
      </div>
    </div>
  )
}

export default Dashboard
