import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import React, { CSSProperties, useCallback, useRef, useState } from 'react'
import Badge, { BadgeGroup, RenderParams } from '../Badge'
import SelectContextProvider, {
  getBools,
  useFilteredData,
  useSelect,
  useSelectionEvents,
} from './utils'
import techStack from 'lib/helpers/techstack.json'

const Multiselect = ({
  values,
  options = techStack,
  placeholder = 'Add stack...',
  searchPlaceHolder = 'e.g., React, Java, Angular, C++',
  onChange,
  renderSelectListBadge,
  renderCreateListBadge,
  renderSelectedValueBadge,
  selectListLabel = 'Select a tech stack or create one',
  selectButtonClassName,
}: MultiselectProps) => {
  return (
    <Popover className='relative' role='listbox'>
      {({ open }) => (
        <>
          <SelectButton
            className={selectButtonClassName}
            stack={values}
            renderSelectedValueBadge={renderSelectedValueBadge}
            placeholder={placeholder}
          />
          <Transition
            show={open}
            enter='transition-opacity duration-100'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Select
              placeholder={searchPlaceHolder}
              stack={values}
              setStack={onChange}
              options={options}
              renderSelectListBadge={renderSelectListBadge}
              renderCreateListBadge={renderCreateListBadge}
              renderSelectedValueBadge={renderSelectedValueBadge}
              selectListLabel={selectListLabel}
            />
          </Transition>
        </>
      )}
    </Popover>
  )
}

const Select = ({
  stack,
  setStack,
  options,
  renderSelectListBadge,
  renderCreateListBadge,
  renderSelectedValueBadge,
  selectListLabel,
  placeholder,
  listStyle,
}: SelectProps) => {
  // search, bound to the input in SelectInput
  const [search, setSearch] = useState('')

  // select is a number used to track the current selected item from the filtered list.
  // It also syncs with the UI and is used to retrieve the right item from the filtered list (useFilteredList)
  const [selected, setSelected] = useState(0)

  // ref to hook closing events (click/tap away and clicking escape)
  const menuRef = useRef<HTMLDivElement | null>(null)

  // Filters the original tech stack array using our current `search`
  const filteredData = useFilteredData({
    search,
    stack,
    options,
  })

  // defines selection-related events, pressing tab, enter, arrowup, arrowdown
  useSelectionEvents({
    filteredData,
    selectionState: [selected, setSelected],
    stackState: [stack, setStack],
    searchState: [search, setSearch],
  })

  // syncs SelectInput with search state (if the string is not a literal space)
  const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSelected(0)
    setSearch(value.trim() === '' ? '' : value)
  }

  return (
    <SelectContextProvider
      value={{
        stack,
        setStack,
        options,
        search,
        setSearch,
        selected,
        setSelected,
        filteredData,
        renderSelectListBadge,
        renderCreateListBadge,
        renderSelectedValueBadge,
      }}>
      <Popover.Panel
        className='absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md focus:outline-none'
        style={{ top: '-0.5625rem', left: '-0.5625rem', ...listStyle }}
        ref={menuRef}>
        <SelectInput onInput={handleInput} placeholder={placeholder} />
        <span className='px-2 text-xtiny text-brand-900 text-opacity-40'>
          {selectListLabel}
        </span>
        <ol className='divide-y divide-gray-300'>
          <SelectList />
          <SelectCreate />
        </ol>
      </Popover.Panel>
    </SelectContextProvider>
  )
}

const SelectButton = ({
  stack,
  renderSelectedValueBadge,
  placeholder,
  className,
}: SelectButtonProps) => {
  return (
    <Popover.Button
      type='button'
      className={clsx(
        'font-inter w-full focus:outline-none focus-visible:ring-1 ',
        className
          ? className
          : 'p-2 -ml-2 text-left rounded hover:bg-brand-40 ',
        stack.length && 'pb-1.5'
      )}>
      {!stack.length ? (
        <div className='text-sm text-gray-500'>{placeholder}</div>
      ) : (
        <BadgeGroup values={stack} renderBadge={renderSelectedValueBadge} />
      )}
    </Popover.Button>
  )
}

const SelectInput = ({ onInput, placeholder }: SelectInputProps) => {
  const { stack, setStack, search, renderSelectedValueBadge } = useSelect()

  const callbackRef = useCallback(
    (inputElement) => {
      setTimeout(() => {
        if (inputElement) {
          inputElement.focus()
        }
      }, 1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stack]
  )

  const handleBadgeRemove = (badge: string) => {
    const index = stack.indexOf(badge)
    setStack([...stack.slice(0, index), ...stack.slice(index + 1)])
  }

  return (
    <div className='p-2 pt-4'>
      <BadgeGroup
        removable
        border
        values={stack}
        onRemove={handleBadgeRemove}
        renderBadge={renderSelectedValueBadge}>
        <li
          className='flex justify-start flex-1 mb-1'
          style={{ minWidth: '50px' }}>
          <input
            placeholder={placeholder}
            value={search}
            onInput={onInput}
            ref={callbackRef}
            className='w-full text-sm leading-4 bg-transparent focus:outline-none'
            type='text'
          />
        </li>
      </BadgeGroup>
    </div>
  )
}

const SelectList = () => {
  const {
    selected,
    setSelected,
    filteredData,
    options,
    stack,
    setStack,
    setSearch,
    renderSelectListBadge,
  } = useSelect()

  const handleClick = () => {
    const toBeAddedStack = filteredData[selected].trim()
    if (!stack.includes(toBeAddedStack)) {
      setStack([...stack, toBeAddedStack])
    }
    setSearch('')
  }

  const values = filteredData.filter((item) => options.includes(item))

  return (
    <div className='px-1 pb-1'>
      {values.map((item, index) => (
        <li
          onMouseOver={() => setSelected(index)}
          onClick={handleClick}
          role='button'
          key={item + index}
          className={clsx(
            'px-1 py-1 cursor-pointer rounded focus:outline-none',
            selected === index && 'bg-brand-40'
          )}>
          {renderSelectListBadge ? (
            renderSelectListBadge({
              value: item,
              border: true,
            })
          ) : (
            <Badge className='block' border>
              {item}
            </Badge>
          )}
        </li>
      ))}
    </div>
  )
}

const SelectCreate = () => {
  const {
    selected,
    filteredData,
    search,
    options,
    stack,
    setSearch,
    setStack,
    renderCreateListBadge,
  } = useSelect()

  if (stack.map((s) => s.toLowerCase()).includes(search.toLowerCase())) {
    return null
  }

  const handleClick = () => {
    setStack([...stack, search])
    setSearch('')
  }
  // used to hide the whole create element when search is in all the tech stack arrays
  const { hasSearchItem: techStackHasSearchData } = getBools({
    data: options,
    search,
  })

  // used to highlight the created element when search is in the filtered array
  const { hasSearchItem: filteredDataHasSearchItem, searchIsEmpty } = getBools({
    data: filteredData,
    search,
  })

  return searchIsEmpty || techStackHasSearchData ? null : (
    <div className='p-1' role='button' onClick={handleClick}>
      <div
        className={clsx(
          'p-1 text-xtiny',
          filteredDataHasSearchItem &&
            selected === filteredData.length - 1 &&
            'bg-brand-40'
        )}>
        Create{' '}
        {renderCreateListBadge ? (
          renderCreateListBadge({ border: true, value: search })
        ) : (
          <Badge className='inline-block whitespace-pre' border>
            {search}
          </Badge>
        )}
      </div>
    </div>
  )
}

export interface MultiselectProps {
  values: string[]
  onChange: (values: string[]) => void
  options?: string[]
  selectListLabel?: string
  renderSelectListBadge?: (params: RenderParams) => JSX.Element
  renderCreateListBadge?: (params: RenderParams) => JSX.Element
  renderSelectedValueBadge?: (params: RenderParams) => JSX.Element
  placeholder?: string
  searchPlaceHolder?: string
  selectButtonClassName?: string
  listStyle?: CSSProperties
}

interface SelectProps {
  stack: string[]
  setStack: (values: string[]) => void
  options: string[]
  selectListLabel: string
  renderSelectListBadge?: (params: RenderParams) => JSX.Element
  renderCreateListBadge?: (params: RenderParams) => JSX.Element
  renderSelectedValueBadge?: (params: RenderParams) => JSX.Element
  placeholder: string
  listStyle?: CSSProperties
}

interface SelectButtonProps {
  stack: string[]
  renderSelectedValueBadge?: (params: RenderParams) => JSX.Element
  placeholder: string
  className?: string
}

interface SelectInputProps {
  onInput: (e: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder: string
}

export default Multiselect
