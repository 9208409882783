import { useState, useEffect, createContext, useContext } from 'react'
import { RenderParams } from 'components/Badge'

// context to move state around the subcomponents to avoid too many props
const SelectContext = createContext<SelectContextValue>(null)

// hook to consume our context
export const useSelect = () => {
  const context = useContext(SelectContext)
  if (!context) {
    throw new Error('useSelect should be used within a SelectContext.Provider')
  }
  return context
}

export const getBools = ({
  data,
  search,
}: {
  data: string[]
  search: string
}) => {
  return {
    hasSearchItem: data
      .map((d) => d.toLowerCase())
      .includes(search.toLowerCase()),
    searchIsEmpty: search === '',
  }
}

export const useFilteredData = ({
  search,
  stack,
  options,
}: UserFilteredDataProps) => {
  const [filteredData, setFilteredData] = useState<string[]>([])

  useEffect(() => {
    setFilteredData(() => {
      const filteredTechStack = options
        .filter((d) => !stack.includes(d))
        .filter((d) => d.toLowerCase().includes(search.toLowerCase()))
        .sort()
        .slice(0, 5)

      const { searchIsEmpty, hasSearchItem } = getBools({
        data: filteredTechStack,
        search,
      })

      if (searchIsEmpty || hasSearchItem) {
        return filteredTechStack
      }
      return [...filteredTechStack, search]
    })
  }, [search, stack, options])

  return filteredData
}

/**
 * Selection-related events such as pressing Enter, Tab, and up and down arrows
 */
export const useSelectionEvents = ({
  filteredData,
  selectionState,
  stackState,
  searchState,
}: UseSelectionEventsProps) => {
  useEffect(() => {
    const [selected, setSelected] = selectionState
    const [stack, setStack] = stackState
    const [search, setSearch] = searchState

    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        // if search and filtered data are empty, don't do anything on 'Enter'
        if (search.trim() === '' && filteredData.length === 0) return

        let toBeAddedStack = filteredData[selected]?.trim()

        if (!toBeAddedStack) {
          return
        }

        if (!stack.includes(toBeAddedStack)) {
          setStack([...stack, toBeAddedStack])
        }

        // reset search
        setSearch('')
      }

      if (e.key === 'Tab') {
        e.preventDefault()
        // set search to current selected filtered data item and set selected index to 0 (to match the filtered data)
        setSearch(filteredData[selected])
        setSelected(0)
      }

      if (e.key === 'Backspace') {
        if (search.trim() === '') {
          setStack(stack.slice(0, stack.length - 1))
        }
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault()
        // this modulo is a trick to rotate around the array and be back at index 0
        setSelected((index) => (index + 1) % filteredData.length)
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault()
        setSelected((index) =>
          index - 1 === -1 ? filteredData.length - 1 : index - 1
        )
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [filteredData, searchState, selectionState, stackState])
}

/**
 * Close events that happen on click/tab away or clicking escape. Closes the multiselect menu.
 */
export const useCloseEvents = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  handler: () => void
) => {
  useEffect(() => {
    const listener = (e: any) => {
      if (
        (!ref.current || ref.current.contains(e.target)) &&
        e.key !== 'Escape'
      ) {
        return
      }
      handler()
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('keyup', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('keyup', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

type SelectContextValue = {
  stack: string[]
  setStack: (values: string[]) => void
  options: string[]
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  selected: number
  setSelected: React.Dispatch<React.SetStateAction<number>>
  filteredData: string[]
  renderSelectListBadge?: (params: RenderParams) => JSX.Element
  renderCreateListBadge?: (params: RenderParams) => JSX.Element
  renderSelectedValueBadge?: (params: RenderParams) => JSX.Element
} | null

type UseSelectionEventsProps = {
  filteredData: string[]
  selectionState: [number, React.Dispatch<React.SetStateAction<number>>]
  stackState: [string[], (values: string[]) => void]
  searchState: [string, React.Dispatch<React.SetStateAction<string>>]
}

type UserFilteredDataProps = {
  search: string
  stack: string[]
  options: string[]
}

export default SelectContext.Provider
