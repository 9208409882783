import { Months } from 'api'

/**
 * Formats dates.
 * @example
 * // returns 'Jan. 2020'
 * formatDate('05-01-2020')
 * @example
 * // returns 'Today'
 * formatDate(undefined)
 * @example
 * // returns 'To date'
 * formatDate(undefined, { presentText: 'To date' })
 */
export const formatDate: FormatDateType = (
  date,
  options = { presentText: 'Today' }
) => {
  if (!date) return options.presentText
  const dateObject = new Date(date)
  const month = Months[dateObject.getMonth()]
  const year = dateObject.getFullYear()
  return month + '. ' + year.toString()
}

/**
 * Diffs between two dates (if endDate is undefined, then between startDate and new Date())
 */
export const diffDate: DiffDateType = (startDate, endDate) => {
  const startDateObject = new Date(startDate)
  const endDateObject = endDate ? new Date(endDate) : new Date()

  const monthCount =
    endDateObject.getMonth() -
    startDateObject.getMonth() +
    12 * (endDateObject.getFullYear() - startDateObject.getFullYear())

  const years = Math.floor(monthCount / 12)
  const months = monthCount % 12

  const yearsString = years ? `${years} y ` : ''
  const monthsString = months
    ? `${months} month${months === 1 ? '' : 's'}`
    : '1 month'

  return yearsString + monthsString
}

type FormatDateType = (
  date?: string | null,
  options?: { presentText: string; dot?: boolean }
) => string

type DiffDateType = (startDate: string, endDate?: string | null) => string
