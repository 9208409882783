import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useUpdateCandidateMutation } from 'api'

const formSchema = Yup.object({
  firstName: Yup.string().trim().required('First name is required.'),
  lastName: Yup.string().trim().required('Last name is required.'),
})

const useForm = () => {
  const [updateCandidate] = useUpdateCandidateMutation()

  return useFormik({
    initialValues: { firstName: '', lastName: '' },
    validationSchema: () => formSchema,
    // being explicit
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true)
      try {
        const { firstName, lastName } = values
        await updateCandidate({
          variables: {
            firstName,
            lastName,
          },
        })
      } catch (error) {
        setErrors({ global: error })
      } finally {
        setSubmitting(false)
      }
    },
  })
}

export default useForm
