import PersonalProjectsModal from './PersonalProjects'
import WorkExperienceModal from './WorkExperience'
import WorkExperienceUpdateModal from './WorkExperienceUpdate'
import { Route, Switch, useHistory } from 'react-router-dom'
import PersonalProjectsUpdateModal from './PersonalProjectsUpdate'
import EducationModal from './Education'
import EducationUpdateModal from './EducationUpdate'
import Modal from 'components/Modal'

const modals = [
  {
    path: '/profile/work-experience',
    component: WorkExperienceModal,
  },
  {
    path: '/profile/work-experience/:id',
    component: WorkExperienceUpdateModal,
  },
  {
    path: '/profile/personal-projects',
    component: PersonalProjectsModal,
  },
  {
    path: '/profile/personal-projects/:id',
    component: PersonalProjectsUpdateModal,
  },
  {
    path: '/profile/education',
    component: EducationModal,
  },
  {
    path: '/profile/education/:id',
    component: EducationUpdateModal,
  },
]

const modalPaths = modals.map((m) => m.path)

const ProfileModals = () => {
  const history = useHistory()

  return (
    <Route path={modalPaths} exact>
      <Modal
        className='mt-16'
        onClose={() => {
          history.push('/profile')
        }}
        open>
        <Switch>
          {modals.map(({ component: Component, path }) => (
            <Route key={path} path={path} exact>
              <Component />
            </Route>
          ))}
        </Switch>
      </Modal>
    </Route>
  )
}

export default ProfileModals
