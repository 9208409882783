import React from 'react'
import Layout, { Right } from 'layouts'
import Navigation from 'components/Navigation'
import { isEmpty } from 'lodash'
import Anchor from 'components/Anchor'
import Preview from 'components/Preview'
import { useSkipProfileWalkthroughMutation } from 'api'
import { TechStackFragment } from './Steps/TechStack'
import { useReadUser } from 'actions/user'
import { FormikProvider } from 'formik'
import RoleStep from './Steps/Role'
import TechStackStep from './Steps/TechStack'
import BioStep from './Steps/Bio'
import useEditProfileInfo from 'lib/hooks/useEditProfileInfo'

const STEPS: Array<{ key: 'role' | 'techStacks' | 'bio'; title: string }> = [
  {
    key: 'role',
    title: 'Job role',
  },
  {
    key: 'techStacks',
    title: 'Tech stacks',
  },
  {
    key: 'bio',
    title: 'Bio',
  },
]

function ProfileWalkthrough() {
  const formik = useEditProfileInfo()
  const { values, handleSubmit, isSubmitting } = formik

  const {
    me: { country, flag },
  } = useReadUser()

  const [skipWalkthrough] = useSkipProfileWalkthroughMutation()
  const [step, setStep] = React.useState(0)

  const { key: stepKey, title: stepTitle } = STEPS[step]
  const stepValue = values[stepKey]

  const handleNextStep = async () => {
    if (step + 1 < STEPS.length) {
      setStep((step) => step + 1)
    } else {
      await handleSubmit()
    }
  }

  const handleBack = () => {
    if (step > 0) {
      setStep((step) => step - 1)
    }
  }

  return (
    <Layout steps={STEPS.length} step={step + 1}>
      <Right>
        <Anchor
          component='button'
          className='text-tiny'
          onClick={() => skipWalkthrough()}>
          SKIP AND EDIT LATER
        </Anchor>
      </Right>
      <div className='flex flex-row pt-10 space-x-8'>
        <FormikProvider value={formik}>
          <div className='max-w-xl w-screen space-y-10'>
            <div className='space-y-2'>
              <h1 className='text-xl font-bold text-black'>Introduction</h1>
              <h3 className='text-xs font-normal text-brand-900 opacity-70'>
                We need you to provide some information about you
              </h3>
            </div>
            {stepKey === 'role' && <RoleStep />}
            {stepKey === 'techStacks' && <TechStackStep />}
            {stepKey === 'bio' && <BioStep />}
            <Navigation
              loading={isSubmitting}
              key={stepKey}
              stepIndex={step}
              stepsLength={STEPS.length}
              primaryCtaOnClick={handleNextStep}
              primaryCtaDisabled={
                isSubmitting ||
                (typeof stepValue === 'string'
                  ? isEmpty(stepValue)
                  : isEmpty(stepValue?.proficient) &&
                    isEmpty(stepValue?.familiar) &&
                    isEmpty(stepValue?.experienced))
              }
              shouldDisplaySecondaryCta={stepKey !== 'role'}
              secondaryCtaOnClick={handleBack}
            />
          </div>
          <div className='max-w-min'>
            <h3 className='text-center text-brand-900 text-sm mb-4 opacity-60'>
              Preview
            </h3>
            <Preview
              step={stepKey}
              country={country}
              role={values.role}
              bio={values.bio}
              flag={flag}
              techStacks={values.techStacks}>
              {stepKey === 'role' ? (
                stepValue
              ) : (
                <div className='space-y-4 mx-2'>
                  <div className='text-sm font-semibold text-brand-900'>
                    {stepTitle}
                  </div>
                  <div className='text-sm text-brand-gray-200'>
                    {stepKey === 'techStacks' ? (
                      <div className='space-y-4 pointer-events-none'>
                        <TechStackFragment
                          title='PROFICIENT IN?'
                          type='proficient'
                          isPreview={true}
                        />
                        <TechStackFragment
                          title='PEXPERIENCED WITH?'
                          type='experienced'
                          isPreview={true}
                        />
                        <TechStackFragment
                          title='FAMILIAR WITH?'
                          type='familiar'
                          isPreview={true}
                        />
                      </div>
                    ) : (
                      stepValue
                    )}
                  </div>
                </div>
              )}
            </Preview>
          </div>
        </FormikProvider>
      </div>
    </Layout>
  )
}

export default ProfileWalkthrough
