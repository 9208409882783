import ConfirmAuth0Info from 'pages/ConfirmAuth0Info'
import EmailVerification from 'pages/EmailVerification'
import { Route, Redirect, Switch } from 'react-router-dom'
import Application from 'pages/Application'
import Profile from 'pages/Profile'
import { ApplicationStatus, Stage, useCurrentUserQuery } from 'api'
import SplashScreen from 'layouts/SplashScreen'
import { UTMStorage } from 'lib/helpers/localStorage'
import { useMixpanel } from 'lib/contexts/Mixpanel'
import { useIdentifyUser } from 'lib/configs/monitoring'
import { useEffect } from 'react'

const AuthenticatedApp = () => {
  const mixpanel = useMixpanel()
  const identifyUser = useIdentifyUser()
  const { data, loading, error } = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  })

  useEffect(() => {
    if (loading || error || !data) return

    UTMStorage.set(data.me._id, {
      utm_source: mixpanel.get_property('utm_source'),
      utm_campaign: mixpanel.get_property('utm_campaign'),
      utm_medium: mixpanel.get_property('utm_medium'),
      utm_term: mixpanel.get_property('utm_term'),
      utm_content: mixpanel.get_property('utm_content'),
    })
    const { currentApplication, ...candidate } = data.me
    identifyUser(candidate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error])

  if (loading) return <SplashScreen />

  if (error || !data) {
    const err = error || new Error('Error loading current user')
    throw err
  }

  const { verified, firstName, lastName, currentApplication } = data.me

  if (!verified) {
    const isOnboardingStage =
      currentApplication.currentAssessment?.stage === Stage.Onboarding
    const isRejected = currentApplication.status === ApplicationStatus.Rejected
    const preferencesSubmitted = currentApplication?.preferences !== null

    if (
      // we only show the verification if the current stage isn't
      // the onboarding and the application is currently rejected
      !isOnboardingStage ||
      (isOnboardingStage && isRejected) ||
      (isOnboardingStage && preferencesSubmitted)
    ) {
      return (
        <Switch>
          <Route path='/verify' component={EmailVerification} />
          <Redirect to='/verify' />
        </Switch>
      )
    }
  }

  if (!firstName || !lastName) {
    return (
      <Switch>
        <Route path='/confirm-info' component={ConfirmAuth0Info} />
        <Redirect to='/confirm-info' />
      </Switch>
    )
  }

  const isConfirmedStage =
    !!currentApplication.currentAssessment?.stage &&
    [Stage.BuildingProfile, Stage.Matching, Stage.Hired].includes(
      currentApplication.currentAssessment?.stage
    )

  if (isConfirmedStage) {
    return <Profile />
  }

  return <Application />
}

export default AuthenticatedApp
