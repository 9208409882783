import { ServerErrorSVG } from 'assets/illustrations'
import { OutSVG, RetrySVG } from 'assets/icons'
import Anchor from 'components/Anchor'
import { BaseLayout } from 'layouts'
import { openGrooveConversation } from 'lib/configs/monitoring/groove'

const ErrorPage = ({ resetError, logout }: ErrorPageProps) => {
  return (
    <BaseLayout>
      <BaseLayout.Header>
        {logout ? (
          <Anchor
            component='button'
            className='ml-auto text-tiny text-brand-900'
            onClick={() => {
              resetError()
              logout()
            }}>
            <OutSVG className='inline-block p-px mr-1 w-3 h-3' />
            Logout
          </Anchor>
        ) : null}
      </BaseLayout.Header>

      <div className='flex flex-wrap-reverse gap-10 justify-center items-start mx-auto mt-40'>
        <div className='flex-shrink-0 mb-8 space-y-10'>
          <div className='flex flex-col space-y-2 text-brand-gray-200'>
            <span className='text-[40px] font-normal leading-10'>Oops!</span>
            <span className='text-2xl font-medium'>Something went wrong</span>
          </div>
          <div className='flex space-x-8 text-brand-900'>
            <div className='flex items-center space-x-1'>
              <RetrySVG className='w-4 h-4' />
              <Anchor
                component='button'
                className='after:block after:h-px text-base font-medium no-underline after:bg-brand-900'
                onClick={() => {
                  resetError()
                }}>
                Reset
              </Anchor>
            </div>
            <Anchor
              component='button'
              className='after:block after:h-px text-base font-medium no-underline after:bg-brand-900'
              onClick={openGrooveConversation}>
              Report issue
            </Anchor>
          </div>
        </div>
        <ServerErrorSVG className='flex-shrink-0 w-52' />
      </div>
    </BaseLayout>
  )
}

type ErrorPageProps = {
  resetError: () => void
  logout?: () => void
}

export default ErrorPage
