import { FunctionComponent, ReactElement, SVGProps } from 'react'
import clsx from 'clsx'
import { TadaSVG } from 'assets/icons'
import Button from '../Button'

const Congratulation = ({
  icon: Icon = TadaSVG,
  title,
  body,
  bgColor = 'bg-brand-500',
  cta,
  onCtaClick,
}: CongratulationProps) => {
  return (
    <div
      className={clsx(
        bgColor,
        'flex flex-col items-center p-6 sm:max-w-xs text-white rounded-lg'
      )}>
      <div className='mb-4 ml-2'>
        <Icon />
      </div>
      <div className='mb-6 text-1.5xl font-bold text-center'>{title}</div>
      <div className='mb-10 text-sm font-normal'>{body}</div>
      <div className='flex-grow m-auto'>
        <Button
          variant='custom'
          className='py-2 w-48 text-brand-900 bg-white'
          onClick={onCtaClick}>
          {cta}
        </Button>
      </div>
    </div>
  )
}

export interface CongratulationProps {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string | ReactElement
  body: ReactElement | string
  bgColor?: string
  cta: string
  onCtaClick: () => void
}

export default Congratulation
