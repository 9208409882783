import { useState, useEffect } from 'react'
import clsx from 'clsx'
import EditProfile from './EditProfile'
import EditAccountSettings from './EditAccountSettings'
import { Route } from 'react-router-dom'
import Modal, { useRouteModal } from 'components/Modal'
import useSearchParams from 'lib/hooks/useSearchParams'

function EditModal() {
  const { type } = useSearchParams()
  const [selected, setSelected] = useState<
    'accountSettings' | 'profile' | null
  >(null)

  const modalOptions = useRouteModal({
    base: '/profile/dashboard',
    path: '/edit-profile',
  })

  useEffect(() => {
    setSelected(type as 'accountSettings' | 'profile')
  }, [type])

  if (!selected) return null
  return (
    <Route to='/profile/dashboard/edit-profile'>
      <Modal {...modalOptions} className='mt-16'>
        <div
          className='flex felex-row bg-white max-w-3xl w-screen rounded-lg overflow-hidden'
          style={{ maxHeight: '90vh' }}>
          <div className='w-2/5 rounded-tl-lg rounded-bl-lg bg-brand-50'>
            <div className='mt-6 ml-6 mb-8'>
              <span className='font-bold text-1.5xl text-black'>Settings</span>
            </div>
            <ul className='mx-4'>
              <li>
                <button
                  type='button'
                  onClick={() => setSelected('profile')}
                  className={clsx(
                    'p-2 w-full rounded-md text-sm font-roboto text-brand-900 text-left focus:outline-none',
                    selected === 'profile' && 'bg-brand-80'
                  )}>
                  Edit profile
                </button>
              </li>
              <li>
                <button
                  type='button'
                  onClick={() => setSelected('accountSettings')}
                  className={clsx(
                    'p-2 w-full rounded-md text-sm font-roboto text-brand-900 text-left focus:outline-none',
                    selected === 'accountSettings' && 'bg-brand-80'
                  )}>
                  Account settings
                </button>
              </li>
            </ul>
          </div>
          <div className='w-full py-5.5 overflow-y-scroll'>
            {selected === 'profile' ? <EditProfile /> : <EditAccountSettings />}
          </div>
        </div>
      </Modal>
    </Route>
  )
}

export default EditModal
