import React, { ReactElement, ReactNode } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import { CloseSVG } from 'assets/icons'
import { createStorage } from 'lib/helpers/localStorage'
import { Transition } from '@headlessui/react'

const styles = {
  submit: {
    container: 'text-white bg-brand-500',
    icon: 'mr-4 mt-1 mb-auto',
    body: 'text-sm mr-10',
  },
  info: {
    container: 'text-black bg-brand-50',
    icon: 'mr-4 mt-1 mb-auto',
    body: 'text-sm w-full',
  },
  success: {
    container: 'text-black bg-[#F5FAF5]',
    icon: 'mr-4 mt-1 mb-auto',
    body: 'text-sm w-full leading-4.5',
  },
}

const Body = ({ children }: { children: ReactNode }) => <>{children}</>

const Action = ({
  onClick,
  className = '',
  asChild = false,
  children,
}: ActionProps) => {
  if (asChild) return <>{children}</>
  return (
    <div className={clsx('md:ml-auto w-full md:w-auto', className)}>
      <Button
        variant='custom'
        className='py-2 px-0 w-full md:min-w-[140px] text-white bg-brand-900'
        style={{ padding: '0.5rem 0' }}
        onClick={onClick}>
        {children}
      </Button>
    </div>
  )
}

const Callout = ({
  icon,
  variant = 'info',
  className = '',
  children,
}: CalloutProps) => {
  const elements = Array.isArray(children) ? children : [children]
  const body = elements.find((el) => el.type === Body)
  const action = elements.find((el) => el.type === Action)

  return (
    <div
      className={clsx(
        'flex flex-col md:flex-row gap-y-2 items-start md:items-center p-4 md:p-6 rounded-lg',
        styles[variant].container,
        className
      )}>
      <div className={styles[variant].icon}>{icon}</div>
      <div className={styles[variant].body}>{body}</div>
      {action}
    </div>
  )
}

Callout.Body = Body
Callout.Action = Action

const DismissibleCallout = ({
  id,
  children,
  className,
  ...props
}: CalloutProps & { id: string; children: ReactElement }) => {
  const storage = createStorage(id, 'true')
  const [shouldShow, setShouldShow] = React.useState(storage.get() === 'true')

  const handleHideCallout = () => {
    setShouldShow(false)
    storage.set('false')
  }
  return (
    <Transition
      show={shouldShow}
      enter='transition-opacity duration-75'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-150'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'>
      <Callout className={clsx('relative', className)} {...props}>
        {children}
        <Callout.Action asChild>
          <span className='absolute right-0 mr-4 top-0 mt-4'>
            <Button variant='custom' onClick={handleHideCallout}>
              <CloseSVG className='self-center w-4 h-4 text-brand-900' />
            </Button>
          </span>
        </Callout.Action>
      </Callout>
    </Transition>
  )
}

interface CalloutProps {
  icon?: ReactNode
  title?: string
  className?: string
  variant?: 'submit' | 'info' | 'success'
  children: ReactElement[] | ReactElement
}

interface ActionProps {
  onClick?: () => void
  className?: string
  variant?: 'submit' | 'info'
  children: ReactNode
  asChild?: boolean
}

export default Callout
export { DismissibleCallout }
