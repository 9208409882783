import * as Yup from 'yup'
import { useFormik } from 'formik'
import { PhoneNumberUtil } from 'google-libphonenumber'
import {
  useUpdateCandidateMutation,
  useChangePasswordMutation,
  useReadUserQuery,
} from 'api'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import { CheckMarkEmoji } from 'assets/emojis'
import { EmailSentSVG } from 'assets/icons'
import { getCountries } from 'lib/helpers/getCountries'
import { useMemo } from 'react'

const phoneUtil = PhoneNumberUtil.getInstance()

const basicInfoSchema = Yup.object({
  firstName: Yup.string()
    // this tests email existance
    .required('Please enter a first name'),
  lastName: Yup.string()
    // this tests email existance
    .required('Please enter a last name'),
  phone: Yup.object().test(
    'Phone number validation',
    'Number is not valid.',
    ({ code, dial, localNumber }) => {
      if (!localNumber) return true
      try {
        const phone = phoneUtil.parse(dial + localNumber, code)
        return phoneUtil.isValidNumber(phone)
      } catch (err) {
        return false
      }
    }
  ),
})

export const useEditBasicInfo = ({ country, ...props }) => {
  const [updateCandidate] = useUpdateCandidateMutation()
  const showSnackbar = useSnackbar()

  const countryObj = useMemo(() => getCountries(country)[0], [country])
  const intialPhone = props.phone?.localNumber
    ? props.phone
    : {
        code: countryObj.code,
        dial: countryObj.dial,
        localNumber: '',
      }

  return useFormik({
    initialValues: {
      ...props,
      phone: intialPhone,
    },
    validationSchema: () => basicInfoSchema,
    // being explicit
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: async ({ country, ...values }, { resetForm, setSubmitting }) => {
      try {
        const res = await updateCandidate({
          variables: {
            ...values,
            phone: {
              code: values.phone.code,
              dial: values.phone.dial,
              localNumber: values.phone.localNumber
                ? Number(values.phone.localNumber)
                : undefined, // backend expects a number
            },
          },
        })
        let { firstName, lastName, phone } = res.data.updateCandidate
        resetForm({ values: { firstName, lastName, phone } })
        showSnackbar({
          key: 'profile_update_success',
          text: 'Account updated successfully.',
          icon: CheckMarkEmoji,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })
}

const EmailForm = Yup.object({
  email: Yup.string()
    .email('Please enter a valid address email.')
    .required('Please enter a valid address email.'),
})

export const useChangeEmail = (onSuccess = () => {}) => {
  const {
    me: { email },
  } = useReadUserQuery()
  const [updateCandidate] = useUpdateCandidateMutation()
  const showSnackbar = useSnackbar()

  return useFormik({
    initialValues: {
      email,
    },
    validationSchema: () => EmailForm,
    // explicitly validate on change and blur
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    onSubmit: async (values, { setSubmitting, setStatus }) => {
      if (email === values.email) return
      setStatus(null)
      try {
        setSubmitting(true)
        await updateCandidate({
          variables: { email: values.email },
        })
        onSuccess()

        showSnackbar({
          key: 'email_update_success',
          text: 'Confirmation email sent to you inbox.',
          icon: EmailSentSVG,
          // we don't hide this snackbar automatically
          disableAutoHide: true,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })
}

const PasswordForm = Yup.object({
  password: Yup.string().required('Please enter your old password.'),
  newPassword: Yup.string()
    .min(8, 'The password should have at least 8 characters')
    .notOneOf([Yup.ref('password'), null], 'New password should be different.')
    .required('Please enter a new password.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], "Passwords don't match")
    .required('Please repeat your new password.'),
})

export const useChangePassword = (onSuccess = () => {}) => {
  const [changePassword] = useChangePasswordMutation()
  const showSnackbar = useSnackbar()

  return useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: () => PasswordForm,
    // explicitly validate on change and blur
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
    onSubmit: async (values, { setStatus, resetForm }) => {
      setStatus(null)
      try {
        await changePassword({
          variables: {
            oldPassword: values.password,
            newPassword: values.newPassword,
          },
        })
        onSuccess()
        resetForm()

        showSnackbar({
          key: 'password_update_success',
          text: 'Password updated successfully.',
          icon: CheckMarkEmoji,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })
}
