import Collapse, { CollapseButton, CollapsePanel } from 'components/Collapse'
import Card from 'components/Card'
import { useProfileData } from 'lib/hooks/useEditProfileInfo'
import { Textarea } from 'components/InputAlternative'

const BioStep = () => {
  const { values, setFieldValue } = useProfileData()

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue('bio', e.target.value)
  }

  return (
    <Card className={'px-4 py-6 bg-white w-full shadow-onboardingCard'}>
      <div className='flex flex-col space-y-4'>
        <label className='ml-2 text-sm font-semibold text-brand-900'>Bio</label>
        <div className='grid grow-wrap' data-replicated-value={values.bio}>
          <Textarea
            id='bio'
            name='bio'
            onChange={handleChange}
            className='overflow-hidden resize-none'
            value={values.bio}
            placeholder='Please describe yourself in 3 sentences. This summary will be viewed first in your profile and should give a general idea about your background, motivations and goals.'
            rows={4}
            maxLength={250}
          />
        </div>
        <Collapse as='div'>
          {({ open }) => (
            <>
              <div className='flex justify-between'>
                <CollapseButton open={open} />
                <span className='text-xxs text-brand-150'>250 characters</span>
              </div>
              <CollapsePanel>
                Full-stack developer with more than 2.5 years experience,
                passionate and comfortable wearing multiple hats.
              </CollapsePanel>
            </>
          )}
        </Collapse>
      </div>
    </Card>
  )
}
export default BioStep
