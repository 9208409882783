import Layout from 'layouts'

const UnsupportedScreen = () => {
  return (
    <Layout className='p-4 py-10'>
      <div className='flex flex-col items-center justify-center gap-y-20'>
        {/* illustration goes here */}
        <div className='text-center'>
          <h1 className='font-medium'>This screen size is not supported yet</h1>
          <p className='text-xxs leading-4.5'>
            Please switch to your desktop or laptop to use profile building
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default UnsupportedScreen
