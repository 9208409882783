import clsx from 'clsx'
import Menu, { MenuButton, MenuItems } from 'components/Menu'

export const InfoBlock = ({ title, children }: InfoBlockProps) => (
  <li className='self-start space-y-1'>
    <h4 className='text-brand-150 text-xtiny'>{title}</h4>
    <p className='text-tiny text-brand-900 text-opacity-80'>{children}</p>
  </li>
)

export const MoreButton = ({
  className = 'right-4 top-4',
  label,
  children,
}: MoreButtonProps) => (
  <Menu as='div' className={clsx('absolute', className)}>
    <div className='relative'>
      <MenuButton
        className={clsx(
          'p-2 flex space-x-1 rounded-full',
          'focus:outline-none focus-visible:ring-1 focus-visible:ring-brand-300'
        )}>
        {Array.from({ length: 3 }).map((_, index) => (
          <svg
            key={index}
            aria-label={`menu for ${label}`}
            className='fill-current text-brand-900'
            viewBox='0 0 4 4'
            width='4'>
            <circle cx='2' cy='2' r='2' />
          </svg>
        ))}
      </MenuButton>
      <MenuItems className='right-0 top-full'>
        <div className='p-2'>{children}</div>
      </MenuItems>
    </div>
  </Menu>
)

interface InfoBlockProps {
  title: string
  children: React.ReactNode
}

interface MoreButtonProps {
  className?: string
  label: string
  children: React.ReactNode
}
