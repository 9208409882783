import { InfoSVG } from 'assets/icons'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

interface ToolTipProps {
  text?: string
}

function ToolTip({ text }: ToolTipProps) {
  const tooltipRef = React.useRef<HTMLDivElement>(null)
  const [isHovered, setisHovered] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current?.contains(event.target as HTMLElement)) return
      setisHovered(false)
    }
    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='relative'>
      <InfoSVG
        className='w-4 h-4 text-brand-gray-200'
        onClick={() => setisHovered(!isHovered)}
        role='button'
      />
      {isHovered && text && (
        <div
          ref={tooltipRef}
          className={clsx(
            'absolute top-1/2 left-full ml-3 transform-gpu -translate-y-1/2 z-10 filter drop-shadow'
          )}>
          <svg
            className={clsx(
              'absolute h-3 w-3 top-1/2 -mt-2 -left-3 text-brand-50 '
            )}
            x='0px'
            y='0px'
            viewBox='0 0 255 255'>
            <polygon
              className='fill-current'
              points='255,0 127.5,127.5 255,255'
            />
          </svg>
          <div className={clsx('p-4 rounded-lg w-max max-w-sm bg-brand-50')}>
            <div className={clsx('text-tiny text-brand-gray opacity-70')}>
              {text}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ToolTip
