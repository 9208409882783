import {
  UPDATE_CANDIDATE_MUTATION,
  RESEND_VERIFICATION,
  VERIFY_EMAIL,
} from './mutations'
import { useApolloClient, useMutation } from '@apollo/client'
import { GET_CURRENT_USER } from './fragments'
import { useCurrentUserQuery } from 'api'

/**
 * Writes candidate query to the apollo cache
 * @returns {Function}
 */
export const useWriteCandidateQuery = () => {
  const client = useApolloClient()

  /**
   *
   * @param {Candidate} candidate
   */
  const update = (candidate) => {
    if (!candidate) throw new Error('No candidate was provided')

    client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        currentUser: {
          me: candidate,
        },
      },
    })
  }
  return update
}

export const useUpdateCandidateMutation = () => {
  return useMutation(UPDATE_CANDIDATE_MUTATION)
}

// UPDATE: this should be useMutation
export const useResendVerification = () => {
  return useMutation(RESEND_VERIFICATION, {
    refetchQueries: [{ query: GET_CURRENT_USER }],
  })
}

/**
 * @param {string} verificationCode - verification code.
 */
export const useVerifyEmail = (verificationCode) => {
  return useMutation(VERIFY_EMAIL, { variables: { verificationCode } })
}

/**
 * @return {UserData}
 */
export const useReadUser = () => {
  // NOTE: this works perfectly if we make sure to update
  // the cache after each mutation
  const { data, error } = useCurrentUserQuery({
    returnPartialData: true,
  })
  if (error || !data) {
    const err = error || new Error('No user data was found')
    throw err
  }
  return data
}
