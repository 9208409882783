import { gql } from '@apollo/client'
import { APPLICATION_FRAGMENT } from './../application/fragments'
// NOTE: if this gets too large we can split them to
// hooks/queries/mutations/fragments
// also we might want to later create some naming convention

export const CANDIDATE_FRAGMENT = gql`
  fragment Candidate on Candidate {
    _id
    name
    firstName
    lastName
    email
    phone {
      dial
      code
      localNumber
    }
    verified
    country
    abilities
    canApplyIn
    nextEmailVerificationTimestamp
    currentApplication {
      ...Application
    }
  }
  ${APPLICATION_FRAGMENT}
`

export const GET_CURRENT_USER = gql`
  query currentUser {
    me {
      ...Candidate
    }
  }
  ${CANDIDATE_FRAGMENT}
`
