import React from 'react'
import { StyleBase, Nav, MidWrapper, RightWrapper } from './style'
import { useNavBarState } from '../../lib/contexts/navBar'
import { GemographyUpdatedSVG } from '../../assets/icons'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const { middleContent, rightContent, styles } = useNavBarState()

  return (
    <StyleBase style={styles}>
      <Nav className='flex justify-between px-4 md:px-20 max-w-screen-xl'>
        <Link className='justify-self-start' to='/'>
          <GemographyUpdatedSVG height='22' aria-label='back' />
        </Link>
        <MidWrapper className='hidden sm:block justify-self-center'>
          {middleContent}
        </MidWrapper>
        {rightContent && (
          <RightWrapper className='justify-self-end'>
            {rightContent}
          </RightWrapper>
        )}
      </Nav>
    </StyleBase>
  )
}

Navbar.propTypes = {
  loadingMessage: PropTypes.string,
}

export default Navbar
