import React, { ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ id = 'portal', children }: Props) => {
  const mount = document.getElementById(id)
  const el = React.useMemo(() => document.createElement('div'), [])

  if (!mount) {
    throw new Error(`No element with id ${id}`)
  }

  useEffect(() => {
    mount.appendChild(el)
    return () => {
      if (mount.contains(el)) {
        mount.removeChild(el)
      }
    }
  }, [el, mount])

  return createPortal(children, el)
}

interface Props {
  children: ReactNode
  id?: 'portal' | 'dropdown'
}

export default Portal
