import { useRef } from 'react'
import debounce from 'lodash/debounce'

const getStored = (key: string) => {
  try {
    return window.localStorage.getItem(key)
  } catch (err) {
    return null
  }
}

const clearStored = (key: string) => {
  try {
    window.localStorage.removeItem(key)
  } catch (err) {}
}

/**
 *
 * @param name modal name
 * @param data form data
 */
const useDraft = <T>(name: string) => {
  const draftName = `draft__${name}`

  const stored = getStored(draftName)
  const ref = useRef<T | null>(stored ? (JSON.parse(stored) as T) : null)

  // NOTE: this assumes name won't change
  // TODO: recreate debounced function when 'draftName' changes
  const debouncedSet = useRef(
    debounce((value: T) => {
      // NOTE: we can also have deep equality here to
      // reduce unnecessary set
      window.localStorage.setItem(draftName, JSON.stringify(value))
      ref.current = value
    }, 1000)
  )

  return {
    /**
     * drafted value
     */
    value: ref.current,
    /**
     * clears current draft
     */
    clear: () => clearStored(draftName),
    /**
     * updates draft value
     */
    set: debouncedSet.current,
  }
}

export default useDraft
