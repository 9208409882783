import { useCurrentUserQuery } from 'api'

export const useReadUserQuery = () => {
  const { data, error } = useCurrentUserQuery({ returnPartialData: true })
  if (error || !data) {
    const err = error || new Error('failed to read current user')
    throw err
  }
  return data
}

export * from './generated'
export * from './types'
export * from './custom'
