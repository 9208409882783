import { useApolloClient } from '@apollo/client'
import { GET_CURRENT_USER } from 'actions/user'
import { createStorage } from 'lib/helpers/localStorage'

export const feedbackStorage = {
  ...createStorage('hasSeenFlash'),
  hide() {
    this.set('true')
  },
}

// NOTE: This is messy and shouldn't be like this as it's
// supposed to be in the backend. All of this could be removed if
// the backend creates a new assessment when moving back the pipeline.
// that way we can use the assessment._id as the storage id
export const useResetFeedback = () => {
  const client = useApolloClient()

  client
    .watchQuery({
      query: GET_CURRENT_USER,
      // we only watch on cache change, because we read
      // user from cache throughout the app
      fetchPolicy: 'cache-only',
    })
    .subscribe({
      next: ({ data: { me } }) => {
        try {
          if (!me || !me?.currentApplication?.prevAssessment) return

          const {
            currentApplication: { prevAssessment },
          } = me

          const prevStage = window.localStorage.getItem('prevStage')
          // we reset if the prevStage changes
          if (prevAssessment?.stage !== prevStage) {
            feedbackStorage.clear()
          }
          // we keep the prevStage to compare it later
          if (prevAssessment?.stage) {
            window.localStorage.setItem('prevStage', prevAssessment?.stage)
          }
        } catch (err) {}
      },
    })
}
