import React from 'react'
import { Redirect } from 'react-router-dom'
import { FormField } from 'components/Inputs'
import useForm from './useForm'
import { useReadUser } from 'actions/user'

import Button from 'components/Button'
import Anchor from 'components/Anchor'
import { ayoub, hamza } from 'assets/people'
import { GemographyUpdatedSVG, WaveLineSVG } from 'assets/icons'

const ConfirmAuth0Info = () => {
  const { me: candidate } = useReadUser()
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useForm()

  if (candidate?.firstName && candidate?.lastName) {
    return <Redirect to='/' exact />
  }

  return (
    <div className='relative flex-grow'>
      <div className='min-h-screen flex'>
        <div className='flex-1 flex flex-col py-10 px-4 sm:px-6'>
          <div className='mx-auto w-full max-w-sm lg:w-96 space-y-8'>
            <GemographyUpdatedSVG className='w-52 pb-10' />
            <h2 className='font-semibold text-xl text-black'>
              Finish signing up
            </h2>
            <form className='space-y-10' onSubmit={handleSubmit}>
              <div className='space-y-4.5'>
                <FormField
                  id='email'
                  name='email'
                  label='Email Address'
                  placeholder='your@email.com'
                  defaultValue={candidate?.email}
                  disabled
                />

                <FormField
                  id='firstName'
                  name='firstName'
                  label='First name'
                  placeholder='John'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={touched.firstName && errors.firstName}
                />

                <FormField
                  id='lastName'
                  name='lastName'
                  label='Last name'
                  placeholder='Doe'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={touched.lastName && errors.lastName}
                />

                <p className='text-sm'>
                  By signing up, you agree to our{' '}
                  <Anchor
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://www.iubenda.com/terms-and-conditions/73337060'
                    className='text-sm'>
                    Terms of Service
                  </Anchor>
                  ,
                  <br />
                  and have read and acknowledge the{' '}
                  <Anchor
                    rel='noopener noreferrer'
                    target='_blank'
                    href='https://www.iubenda.com/privacy-policy/71571784'
                    className='text-sm'>
                    Privacy Policy
                  </Anchor>
                  .
                </p>
              </div>

              <Button
                variant='secondary'
                type='submit'
                className='w-full mt-7'
                disabled={isSubmitting}
                loading={isSubmitting}>
                <span>Continue</span>
              </Button>
            </form>
          </div>
        </div>
        <div className='hidden md:flex justify-center items-center flex-col w-2/4 min-h-screen p-12 bg-brand-900'>
          <div className='text-center max-w-lg 2xl:max-w-xl'>
            <div className='space-y-5'>
              <p className='text-sm 2xl:text-lg leading-6 text-[#D0D0D0]'>
                "Gemography experience is refreshing, to say the least. Lots of
                growth, lots of opportunities for ownership, with an emphasis on
                work/life balance."
              </p>
              <div className='flex justify-center items-center space-x-2'>
                <img
                  src={ayoub.img}
                  alt={ayoub.name}
                  className='w-7 h-7 2xl:w-8 2xl:h-8 rounded-full'
                />
                <div>
                  <span className='font-semibold text-white text-xs'>
                    {ayoub.name + ', '}
                  </span>
                  <span className='font-medium text-[#D0D0D0] text-xs'>
                    {ayoub.role}
                  </span>
                </div>
              </div>
            </div>
            <WaveLineSVG className='w-1/3 mt-14 mb-10 mx-auto' />
            <div className='space-y-5'>
              <p className='text-sm 2xl:text-lg leading-6 text-[#D0D0D0]'>
                "Gemography helped me develop my career by providing different
                opportunities and operations for me to participate in."
              </p>
              <div className='flex justify-center items-center space-x-2'>
                <img
                  src={hamza.img}
                  alt={hamza.name}
                  className='w-7 h-7 2xl:w-8 2xl:h-8 rounded-full'
                />
                <div>
                  <span className='font-semibold text-white text-xs'>
                    {hamza.name + ', '}
                  </span>
                  <span className='font-medium text-[#D0D0D0] text-xs'>
                    {hamza.role}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmAuth0Info.propTypes = {}

export default ConfirmAuth0Info
