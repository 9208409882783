export const initialState = {
  styles: {},
  middleContent: '',
  rightContent: '',
}

export const navBarActionTypes = {
  SET_MIDDLE_CONTENT: 'SET_MIDDLE_CONTENT',
  SET_RIGHT_CONTENT: 'SET_RIGHT_CONTENT',
  SET_STYLES: 'SET_STYLES',
}

const reducer = (state, action) => {
  switch (action.type) {
    case navBarActionTypes.SET_MIDDLE_CONTENT: {
      return {
        ...state,
        middleContent: action.payload,
      }
    }
    case navBarActionTypes.SET_RIGHT_CONTENT: {
      return {
        ...state,
        rightContent: action.payload,
      }
    }
    case navBarActionTypes.SET_STYLES: {
      return {
        ...state,
        styles: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export default reducer
