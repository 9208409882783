import styled from 'styled-components'

export const BaseStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

export const Content = styled.div`
  flex-grow: 1;
  position: relative;
`

export const Wrapper = styled.div`
  --padding: 16px;
  max-width: calc(1140px + 2 * var(--padding));
  width: 100%;
  padding: 40px var(--padding) 0;
  margin: 0 auto;
`

export const LoadingMessage = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  font-weight: 600;
  opacity: 0.35;
`
