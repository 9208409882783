import Input, { FormField } from 'components/InputAlternative'
import Checkbox from 'components/Checkbox'
import { useFormikContext } from 'formik'

export const LocationField = () => {
  const { values, touched, errors, handleChange } = useFormikContext<{
    location: string
    remote: boolean
  }>()
  return (
    <div className='flex -mr-2'>
      <FormField
        className='flex-grow'
        hasError={touched.location && !!errors.location}>
        <Input
          // we need to keep the value so that the can have if they uncheck
          value={values.location}
          onChange={handleChange}
          name='location'
          placeholder='Location (e.g. City, Country)'
        />
      </FormField>
      <Checkbox
        className='z-20'
        name='remote'
        checked={values.remote}
        onChange={handleChange}>
        Remote
      </Checkbox>
    </div>
  )
}
