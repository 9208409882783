import { createContext, ReactNode, useContext } from 'react'
import { PageDocument } from './types'

const PrismicContext = createContext<PageDocument | undefined>(undefined)

export const PrismicDocProvider = ({
  children,
  document,
}: {
  children: ReactNode
  document: PageDocument
}) => {
  return (
    <PrismicContext.Provider value={document}>
      {children}
    </PrismicContext.Provider>
  )
}

export const usePrismicDoc = () => {
  const context = useContext(PrismicContext)
  if (!context) {
    throw new Error('usePrismicDoc must be used within a PrismicDocProvider')
  }
  return context
}
