import { useMixpanel } from 'lib/contexts/Mixpanel'
import { useEffect } from 'react'
import useSearchParams from './useSearchParams'

/**
 * registers utm tags with last touch approach
 * check here for more details:
 * https://mixpanel.com/blog/community-tip-last-touch-utm-tags/
 */
const useTrackLastTouchUTMTags = () => {
  const {
    utm_source,
    utm_campaign,
    utm_medium,
    utm_term,
    utm_content,
  } = useSearchParams()
  const mixpanel = useMixpanel()

  useEffect(() => {
    mixpanel.register(
      // remove keys with value of undefined
      JSON.parse(
        JSON.stringify({
          utm_source,
          utm_campaign,
          utm_medium,
          utm_term,
          utm_content,
        })
      )
    )
  }, [utm_source, utm_campaign, utm_medium, utm_term, utm_content, mixpanel])
}

export default useTrackLastTouchUTMTags
