import { useAuthentication, useLogout } from 'actions/auth'
import { GemographyUpdatedSVG } from 'assets/icons'
import clsx from 'clsx'
import Anchor from 'components/Anchor'
import Card from 'components/Card'
import Skeleton from 'components/Skeleton'
import { ComponentProps, PropsWithChildren } from 'react'

export const PublicPageLayout = ({ children }: PropsWithChildren<{}>) => {
  const logout = useLogout()
  const { authenticated } = useAuthentication()
  return (
    <div className='px-4 pt-7 md:pt-10 pb-16 max-w-5xl w-full mx-auto'>
      <header className='mb-11 flex justify-between'>
        <div className='md:w-1/3 text-base font-bold select-none'>
          <a href='http://gemography.com'>
            <GemographyUpdatedSVG aria-label='home page' height='30' />
          </a>
        </div>

        {authenticated ? (
          <Anchor
            component='button'
            className='uppercase text-tiny text-brand-900'
            onClick={() => logout()}>
            Logout
          </Anchor>
        ) : null}
      </header>
      <main className=''>{children}</main>
    </div>
  )
}

export const PublicPageTitle = ({ children }: ComponentProps<'h1'>) => (
  <h1 className='text-brand-900 font-medium text-2xl md:text-[32px] text-center mb-4 leading-9'>
    {children}
  </h1>
)

export const PublicPageSubTitle = ({ children }: ComponentProps<'p'>) => (
  <div className='text-center text-sm md:text-base leading-6 text-brand-900 text-opacity-80'>
    {children}
  </div>
)

export const PublicPageCardContainer = ({
  className,
  ...props
}: ComponentProps<typeof Card>) => (
  <Card
    className={clsx('bg-brand-50 overflow-x-hidden', className)}
    flex=''
    {...props}
  />
)

export const PublicPageLayoutSkeleton = () => {
  return (
    <PublicPageLayout>
      <div className='max-w-2xl mx-auto mb-10'>
        <Skeleton
          className='max-w-full mb-4 mx-auto'
          height='h-9'
          width='w-96'
        />
        <div className='space-y-2 w-full'>
          <Skeleton className='max-w-full' height='h-5' width='w-full' />
          <Skeleton
            className='sm:max-w-[80%] mx-auto'
            height='h-5'
            width='w-full'
          />
        </div>

        <div className='text-brand-900 font-medium text-2xl md:text-[32px] text-center mb-4 leading-9' />
        <div className='text-center text-sm md:text-base leading-6 text-brand-900 text-opacity-80' />
      </div>
    </PublicPageLayout>
  )
}
