import { useAddWorkExperienceMutation, ContractType } from 'api'
import HowToCard from 'pages/Profile/Shared/HowToCard'
import { Link, useHistory } from 'react-router-dom'
import Anchor from 'components/Anchor'
import Modal from 'components/Modal'

import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import useDraft from './useDraft'
import { useEffect } from 'react'
import WorkExperienceForm, {
  WorkExperienceFormValues,
} from './WorkExperienceForm'

const WorkExperienceModal = () => {
  const formik = useForm()

  return (
    <div
      className='w-screen max-w-4xl p-6 pt-10 overflow-y-scroll bg-white rounded-lg scrollbar'
      style={{ maxHeight: '90vh' }}>
      <div className='space-y-8'>
        <div className='space-y-6'>
          <div className='space-y-2'>
            <Modal.Title as='h3' className='text-1.5xl font-bold'>
              Work Experience
            </Modal.Title>
            <p className='text-xs'>
              Or you can provide a{' '}
              <Anchor
                component={Link}
                className='text-xs'
                to='/profile/personal-projects'>
                personal project
              </Anchor>{' '}
              instead.
            </p>
          </div>

          <HowToCard
            title='How to build your profile'
            link='https://www.notion.so/hiddenpole/Profile-Material-31ba37d683234c37970268f4d5fc84e4'>
            <p className='text-xxs'>
              To build your profile, we would need you to give us all the
              relevant details about your previous experiences. We will be
              helping you with questions so please make sure you answer all of
              them
            </p>
          </HowToCard>
        </div>
        <FormikProvider value={formik}>
          <WorkExperienceForm />
        </FormikProvider>
      </div>
    </div>
  )
}

const validationSchema = Yup.object({
  jobTitle: Yup.string().required(),
  company: Yup.string().required(),
  // location field
  location: Yup.string().required(),
  remote: Yup.boolean().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
  jobType: Yup.mixed<ContractType>()
    .oneOf(Object.values(ContractType))
    .required(),

  description: Yup.string().required(),
  techStack: Yup.array()
    .min(1, 'Techstacks should not be empty')
    .of(Yup.string())
    .required(),
})

const useForm = () => {
  const [addworkExperience] = useAddWorkExperienceMutation()

  const draft = useDraft<WorkExperienceFormValues>('work-experience-form')
  const history = useHistory()

  const formik = useFormik<WorkExperienceFormValues>({
    validationSchema: () => validationSchema,
    initialValues: {
      jobTitle: '',
      company: '',
      location: '',
      remote: false,
      // it's okay to set this to string since formik
      // will not validate it on submit
      startDate: '',
      endDate: undefined,
      // This will later be validated by formik onSubmit
      jobType: ('' as unknown) as ContractType,
      techStack: [],
      // custom form values 👇
      description: '',
      ongoing: false,
      ignore: false,
      ...(draft.value ? draft.value : {}),
    },
    onSubmit: async ({ jobType, ongoing, ...values }, { setErrors }) => {
      try {
        if (!jobType) {
          // we are setting the error here because 'jobType' is
          // not nullable :(
          setErrors({ jobType: 'Job type is required' })
          return
        }
        await addworkExperience({
          variables: {
            input: {
              ...values,
              jobType,
            },
          },
        })
        // we clear the draft
        draft.clear()
        history.push(`/profile`)
      } catch (error) {
        // TODO handle errors
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
  })

  useEffect(() => {
    draft.set(formik.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  return formik
}
export default WorkExperienceModal
