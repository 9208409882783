import { APPLICATION_ROUTES } from 'pages/Application/routes'
import { AUTH_ROUTES } from 'pages/Authentication'

export const linkResolver = (doc: any) => {
  switch (doc.type) {
    case 'signup':
      return AUTH_ROUTES.signUp
    case 'signin':
      return AUTH_ROUTES.signIn
    case 'application_onboarding':
      return APPLICATION_ROUTES.onboarding
    default:
      return '/'
  }
}

export const isEmptyField = (field: any) => {
  return (
    field && // 👈 null and undefined check
    Object.keys(field).length === 0 &&
    Object.getPrototypeOf(field) === Object.prototype
  )
}
