import { ApplicationRole } from 'api'
import { createStorage } from 'lib/helpers/localStorage'
import useSearchParams from 'lib/hooks/useSearchParams'
import {
  createContext,
  useState,
  useContext,
  ReactElement,
  useEffect,
} from 'react'

export interface RoleState {
  role: ApplicationRole | undefined
}

const modalInitialState: RoleState = {
  role: undefined,
}

const RoleContext = createContext<RoleState>(modalInitialState)

const roleStorage = createStorage('role_param')

const useApplicationRoleContext = () => {
  const context = useContext(RoleContext)
  if (context === undefined) {
    throw new Error(
      'useApplicationRole must be used within a RoleContextProvider'
    )
  }
  return context
}

const ApplicationRoleProvider = ({ children }: { children: ReactElement }) => {
  const params = useSearchParams()
  const roleParam = params.role as ApplicationRole | undefined
  const [role, setRole] = useState<ApplicationRole | undefined>(roleParam)

  useEffect(() => {
    if (roleParam) {
      roleStorage.set(roleParam)
      setRole(roleParam)
    } else {
      const storedRole = roleStorage.get() as ApplicationRole | undefined
      if (storedRole) setRole(storedRole)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RoleContext.Provider value={{ role }}>{children}</RoleContext.Provider>
  )
}

export { ApplicationRoleProvider, useApplicationRoleContext }
