import { useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { useSetProfileInfoMutation } from 'api'
import { authStorage } from 'lib/helpers/localStorage'
import avatarSVG from 'assets/illustrations/avatar.svg'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import { CheckMarkEmoji } from 'assets/emojis'

const validationSchema = () =>
  Yup.object({
    step: Yup.number(),
    role: Yup.string().max(50, 'Role should not exceed 50 characters'),
    techStacks: Yup.object({
      proficient: Yup.array(),
      experienced: Yup.array(),
      familiar: Yup.array(),
    }),
    Bio: Yup.string().max(255, 'Bio should not exceed 255 characters'),
    githubURL: Yup.string().url().optional(),
    linkedinURL: Yup.string().url().optional(),
    languages: Yup.array(),
    image: Yup.mixed()
      .test(
        'imageSize',
        "Image shouldn't be larger than 9 Mb",
        (value?: File) => (value ? value.size <= 9_000_000 : true)
      )
      .test('imageType', 'Unsupported image type', (value?: File) =>
        value ? 'image/jpg image/jpeg image/png'.includes(value?.type) : true
      ),
  })

const AVATAR_ENDPOINT = process.env.REACT_APP_AVATAR_ENDPOINT
/**
 * uploads image to static file to get signed url
 * @param {File} image - image to upload
 * @returns {Object} data object containing 'url'
 */
const getSignedUrl = async (image: File) => {
  if (!AVATAR_ENDPOINT) {
    return Promise.reject(new Error("'REACT_APP_AVATAR_ENDPOINT' is not set"))
  }
  const res = await fetch(AVATAR_ENDPOINT, {
    method: 'POST',
    body: image,
    headers: {
      Authorization: `Bearer ${authStorage.get()}`,
    },
  })
  return await res.json()
}

// Reason for any as type for techStack array is that codegen do generates
// types that wrapped with Maybe type which makes it hard to extract the real type
export interface ProfileDataType {
  role?: string
  techStacks?: {
    proficient: any[]
    experienced: any[]
    familiar: any[]
  }
  bio?: string
  githubURL?: string
  linkedinURL?: string
  pictureURL?: string | null
  languages?: string[]
  image?: File
}

export const useProfileData = () => useFormikContext<ProfileDataType>()

const useEditProfileInfo = (profile?: ProfileDataType) => {
  const [setProfileInfoMutation] = useSetProfileInfoMutation()
  const showSnackbar = useSnackbar()

  return useFormik<ProfileDataType>({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      role: undefined,
      techStacks: {
        proficient: [],
        experienced: [],
        familiar: [],
      },
      bio: undefined,
      linkedinURL: undefined,
      githubURL: undefined,
      pictureURL: avatarSVG,
      ...(profile || {}),
    },
    validationSchema,
    onSubmit: async ({ image, ...values }, { setStatus, resetForm }) => {
      setStatus(false)
      let nonEmptyValues = objectDefined(values)

      try {
        let pictureURL: string | null = null
        if (image) {
          const data = await getSignedUrl(image)
          pictureURL = data.url as string
        }
        await setProfileInfoMutation({
          variables: {
            ...nonEmptyValues,
            pictureURL,
          },
        })

        showSnackbar({
          key: 'profile_update_success',
          text: 'Profile updated successfully.',
          icon: CheckMarkEmoji,
        })
      } catch (error) {
        console.error(error)
      }
    },
  })
}

/**
 * NOTE: we shouldn't be dependant on this
 * removes empty values from object (one level only)
 * @param obj
 */
const objectDefined = <T>(obj: T): T => {
  const acc: Partial<T> = {}
  for (const key in obj) {
    // @ts-ignore
    if (obj[key] !== undefined && obj[key] !== '') acc[key] = obj[key]
  }
  return acc as T
}

export default useEditProfileInfo
