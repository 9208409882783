import Routes from './routes'
import RootProvider from 'lib/contexts/RootProvider'
import * as Sentry from '@sentry/react'
import ErrorPage from 'pages/Error'
import { useAuthentication, useLogout } from 'actions/auth'

const App = () => {
  const logout = useLogout()
  const { authenticated } = useAuthentication()

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => (
        <ErrorPage
          resetError={resetError}
          logout={authenticated ? () => logout() : undefined}
        />
      )}>
      <RootProvider>
        <Routes />
      </RootProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
