import React from 'react'

import ChangeBasicInfo from './ChangeBasicInfo'
import ChangeEmail from './ChangeEmail'
import ChangePassword from './ChangePassword'

const EditProfile = () => {
  return (
    <div className='w-screen max-w-xl px-2 bg-white rounded-lg'>
      <div
        className='px-6 py-10 overflow-y-scroll divide-y divide-gray-300 scroll'
        style={{ height: '90vh' }}>
        <ChangeBasicInfo />
        <ChangeEmail />
        <ChangePassword />
      </div>
    </div>
  )
}

export default EditProfile
