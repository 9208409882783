import { WarningEmoji, WaveHandEmoji } from 'assets/emojis'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import { useEffect } from 'react'

const useNetworkStatusFeedback = () => {
  const showSnackbar = useSnackbar()

  useEffect(() => {
    const handleOffline = () => {
      showSnackbar({
        key: 'offline_status',
        text: 'You are currently offline.',
        // we are allowing the user to hide the
        // snackbar themselves by not hiding it
        // after the default duration
        disableAutoHide: true,
        icon: WarningEmoji,
      })
    }

    const handleOnline = () => {
      showSnackbar({
        key: 'online_status',
        text: 'You are back online.',
        icon: WaveHandEmoji,
      })
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [showSnackbar])
}

export default useNetworkStatusFeedback
