import React, { useState } from 'react'

import Button from 'components/Button'
import { FormField } from 'components/Inputs'
import Anchor from 'components/Anchor'
import Callout from 'components/Callout'

import { useChangeEmail } from './useForm'

const ChangeEmail = (props) => {
  const [editMode, setEditMode] = useState(false)
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    dirty,
  } = useChangeEmail(() => setEditMode(false))

  return (
    <form onSubmit={handleSubmit} className='my-6'>
      <div className='my-4'>
        <div className='flex justify-between'>
          <label className='text-xs text-brand-150'>Email</label>
          {editMode && (
            <Anchor
              className='text-xs'
              component='span'
              onClick={() => {
                if (!(touched.email && errors.email)) {
                  setEditMode(false)
                }
              }}>
              Cancel
            </Anchor>
          )}
        </div>

        {editMode ? (
          <FormField
            name='email'
            id='email'
            onChange={handleChange}
            onBlur={handleBlur}
            className='w-4/6'
            value={values.email}
            error={errors.email && touched.email ? errors.email : undefined}
          />
        ) : (
          <>
            <div className='flex justify-between mt-4'>
              <p className='text-sm'> {values.email} </p>
              <Anchor
                className='text-xs'
                component='span'
                onClick={() => setEditMode(true)}>
                Change email
              </Anchor>
            </div>
          </>
        )}
      </div>

      <Callout icon='💡'>
        <Callout.Body>
          You'll have to verify the new email to continue your application.
        </Callout.Body>
      </Callout>

      <div className='mt-6 w-full flex justify-end'>
        <Button
          style={{ minWidth: '110px' }}
          type='submit'
          disabled={isSubmitting || !dirty}
          loading={isSubmitting}
          hasIcon={false}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default ChangeEmail
