import { useTechStacksQuery } from 'api'
import Multiselect from './Multiselect'
import type { MultiselectProps } from './Multiselect'
import { useMemo } from 'react'

const TechStackMultiselect = (props: MultiselectProps) => {
  const { data } = useTechStacksQuery({
    // get the intial data from the server.
    fetchPolicy: 'network-only',
    // get the data from the cache if it's available.
    nextFetchPolicy: 'cache-first',
  })

  const techStackOptions = useMemo(() => {
    if (!data?.techStacks) return []
    return data?.techStacks?.map(({ tag }) => tag)
  }, [data?.techStacks])

  return (
    <Multiselect
      placeholder='Add your stack'
      {...props}
      options={techStackOptions}
    />
  )
}

export default TechStackMultiselect
