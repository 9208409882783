import React from 'react'
import ChangeBasicInfo from 'layouts/Sidebar/EditProfile/ChangeBasicInfo'
import ChangeEmail from 'layouts/Sidebar/EditProfile/ChangeEmail'
import ChangePassword from 'layouts/Sidebar/EditProfile/ChangePassword'

function EditAccountSettings() {
  return (
    <div className='px-10 divide-y divide-gray-300 pb-5'>
      <ChangeBasicInfo />
      <ChangeEmail />
      <ChangePassword />
    </div>
  )
}

export default EditAccountSettings
