import clsx from 'clsx'
import { ElementType, ReactNode } from 'react'
import { PolymorphicComponentProps } from 'types/polymorphic'

const Card = <Polymorphic extends ElementType = 'div'>({
  className,
  component,
  flex = 'inline-flex flex-col',
  custom = false,
  ...props
}: PolymorphicComponentProps<Polymorphic, CardProps>) => {
  const Component = component || 'div'
  return (
    <Component
      className={clsx(
        flex,
        'rounded-lg',
        !custom && 'border border-gray-200',
        className
      )}
      {...props}
    />
  )
}

export const CardBody = <Polymorphic extends ElementType = 'div'>({
  className,
  component,
  ...props
}: PolymorphicComponentProps<Polymorphic, CardBodyProps>) => {
  const Component = component || 'div'
  return (
    <Component className={clsx('flex-grow py-4 px-5', className)} {...props} />
  )
}

Card.Body = CardBody

export const CardAction = <Polymorphic extends ElementType = 'div'>({
  className,
  component,
  ...props
}: PolymorphicComponentProps<Polymorphic, CardActionProps>) => {
  const Component = component || 'div'
  return (
    <Component
      className={clsx(
        'px-5 py-3.5 rounded-b-lg focus:outline-none group',
        className
      )}
      {...props}
    />
  )
}

Card.Action = CardAction

interface CardProps {
  /**
   * override flex classnames
   */
  flex?: string
  /**
   * Removes default border styles if `true`
   */
  custom?: boolean
  children: ReactNode
}

export interface CardBodyProps {
  /**
   * Root class name
   */
  className?: string
  children: ReactNode
}

interface CardActionProps {
  /**
   * Root class name
   */
  className?: string
  children: ReactNode
}

export default Card
