import { useLocation } from 'react-router-dom'
import qs from 'qs'

/**
 * parses search params and returns parsed
 */
const useSearchParams = () => {
  const location = useLocation()

  return qs.parse(location.search, {
    // this will ignore '?'
    ignoreQueryPrefix: true,
  })
}

export default useSearchParams
