import React from 'react'
import ProfileSidebar from 'layouts/Profile'
import { Redirect, Route, Switch } from 'react-router-dom'
import Dashboard from '../Dashboard'
import ProfilePage from '../Profile'

function ProfileHome() {
  return (
    <ProfileSidebar>
      <Switch>
        <Route
          path={['/profile/dashboard', '/profile/dashboard/edit-profile']}
          exact>
          <Dashboard />
        </Route>
        <Route
          path={[
            '/profile',
            '/profile/work-experience',
            '/profile/personal-projects',
            '/profile/education',
            '/profile/work-experience/:id',
            '/profile/personal-projects/:id',
            '/profile/education/:id',
          ]}
          exact>
          <ProfilePage />
        </Route>
        <Redirect to='/profile/dashboard' />
      </Switch>
    </ProfileSidebar>
  )
}

export default ProfileHome
