import { ReactElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import clsx from 'clsx'

import useSearchParams from 'lib/hooks/useSearchParams'
import {
  useResendVerificationMutation,
  useVerifyEmailMutation,
  useReadUserQuery,
} from 'api'

import Layout from 'layouts'
import Anchor from 'components/Anchor'

import { AirplaneSVG, LoadingSpinnerUpdatedSVG } from 'assets/icons'

const Container = ({
  className,
  ...props
}: {
  className?: string
  children?: ReactElement
}) => {
  return (
    <Layout>
      <div
        className={clsx('px-4 xs:px-11 mx-auto w-full max-w-md', className)}
        {...props}
      />
    </Layout>
  )
}

const EmailVerification = () => {
  // verify the code search param if it exists
  // get code from url
  const { code } = useSearchParams()
  const [
    verifyEmail,
    { loading: verifyingEmail, called: calledVerifyEmail },
  ] = useVerifyEmailMutation()
  useEffect(() => {
    if (code && typeof code === 'string') {
      verifyEmail({ variables: { verificationCode: code } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  // make verification resendable after we reach the timestamp from the backend
  const [
    resendVerification,
    { data: resendVerificationData, loading: resendingVerification },
  ] = useResendVerificationMutation()
  const [verificationResent, setVerificationResent] = useState(false)
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    // emailSent being true or false means the backend did actually send an email, but we don't care, it just has to exist.
    if (resendVerificationData?.resendVerification?.emailSent !== undefined) {
      // if we have emailSent, set verification resent to true and display "Sent successfully" in the UI.
      setVerificationResent(true)
    }
    if (resendVerificationData?.resendVerification?.timestamp) {
      timer = setTimeout(() => {
        // after we reach in time the timestamp coming from the backend, set verification reset to false and display "Resend verification" in the UI.
        setVerificationResent(false)
      }, resendVerificationData.resendVerification.timestamp - Date.now())
    }
    // clear our timer
    return () => clearTimeout(timer)
  }, [resendingVerification, resendVerificationData])

  // redirect to dashboard after 10 seconds when me.verified is true
  const user = useReadUserQuery()
  const history = useHistory()

  // if the user has not gone through the onboarding
  // send them directly there.
  const redirectionPath = user?.me?.currentApplication?.applicationForm
    ? '/application'
    : '/application/introduction'

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (user?.me?.verified) {
      timer = setTimeout(() => {
        history.push(redirectionPath)
      }, 10000)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // if we have a `code` param, show nothing until we stop verifying the email.
  // TODO: show an indicator
  if (code && (!calledVerifyEmail || verifyingEmail)) return <Container />

  if (user?.me?.verified)
    return (
      <Container className='py-24'>
        <div className='space-y-7'>
          <span
            className='block text-5xl'
            role='img'
            aria-label='clapping hands'>
            👏
          </span>
          <h1 className='text-1.5xl font-bold leading-8'>
            Great! You’ve verified your email – now, let’s get going!
          </h1>
          <p className='text-xs'>
            You'll be automatically redirected in 10 seconds.{' '}
            <Anchor component={Link} to={redirectionPath}>
              Skip now
            </Anchor>
            .
          </p>
        </div>
      </Container>
    )

  return (
    <Container className='py-12'>
      <div className='space-y-10 text-xs'>
        <AirplaneSVG width='100' height='80' />
        <div className='space-y-6 font-roboto'>
          <h1 className='text-1.5xl font-bold leading-7 whitespace-pre-wrap'>
            Verify your email{'\n'}to continue
          </h1>
          <div className='space-y-3 leading-5 text-brand-900 text-opacity-70'>
            <p>
              We’ve sent an email to{' '}
              <b className='text-brand-900 text-opacity-100'>
                {user?.me?.email}
              </b>
            </p>
            <div className='space-y-2'>
              <p>If you don’t see it, double check your spam folder.</p>
            </div>
          </div>
        </div>
        Didn’t recieve it?{' '}
        <Anchor
          onClick={() => resendVerification()}
          disabled={verificationResent}
          className='text-xs text-brand-900 disabled:text-opacity-80'
          component='button'>
          {verificationResent
            ? 'Sent successfully'
            : 'Resend verification email'}
          {resendingVerification && (
            <LoadingSpinnerUpdatedSVG className='inline-block mb-px ml-1 w-3.5 text-brand-900 animate-spin' />
          )}
        </Anchor>
      </div>
    </Container>
  )
}

export default EmailVerification
