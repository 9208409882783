import ReactDOM from 'react-dom'
import { ReactNode } from 'react'
import Modal from 'components/Modal'

const confirmRoot = document.createElement('div')
const body = document.querySelector('body')
body?.appendChild(confirmRoot)

const cleanup = () => {
  ReactDOM.unmountComponentAtNode(confirmRoot)
}
/**
 * @todo: enable transition
 */
const useConfirmationPopup = () => {
  /**
   * @param {Function} close - a function that closes the popup
   */
  return (content: (close: () => void) => ReactNode) => {
    ReactDOM.render(
      <Modal className='mt-32' hideCloseButton open onClose={() => cleanup()}>
        {/* this is where we show the user content */}
        {content(cleanup)}
      </Modal>,
      confirmRoot
    )
  }
}

export default useConfirmationPopup
