import clsx from 'clsx'
import { ExclamationSolidSVG } from 'assets/icons'

const styles = [
  'text-sm text-brand-900 rounded placeholder-cardGray-50 hover:bg-brand-gray-100',
  'p-2 -mx-2',
  'scrollbar',
  'focus:outline-none',
  'border border-transparent focus:border-brand-100',
  'relative focus:z-10 focus:bg-white focus:shadow-input',
]

/**
 * Wrapper around form elements
 * - shows error indicator
 */
export const FormField: React.FC<{
  hasError?: boolean
  className?: string
}> = ({ hasError = false, className, children }) => {
  return (
    <div className={clsx('relative', className)}>
      {children}
      {hasError ? (
        <ExclamationSolidSVG className='h-4 w-4 absolute top-2.5 right-2 z-20 text-alert' />
      ) : null}
    </div>
  )
}

const Input = ({ className, ...props }: InputProps) => {
  return (
    <input
      className={clsx(styles, className)}
      // change this inline style to a utility class if you ever need to override width on this component
      style={{ width: 'calc(100% + 1rem)' }}
      {...props}
    />
  )
}

export const Textarea = ({ className, ...props }: TextAreaProps) => {
  return (
    <textarea
      className={clsx(styles, className)}
      {...props}
      // change this inline style to a utility class if you ever need to override width on this component
      style={{ width: 'calc(100% + 1rem)' }}
    />
  )
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}
interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

export default Input
