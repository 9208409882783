import { AssessmentFragment, AssessmentStatus, Stage, StageTitle } from 'api'

import { LightningEmoji } from 'assets/emojis'
import {
  CheckSVG as CheckInProgressSVG,
  CheckDoneSVG,
  CheckUpcomingSVG,
  LockSVG,
  SkipSVG,
  CheckFailedSVG,
} from 'assets/icons'
import clsx from 'clsx'

export const HorizontalStep = (props: StepProps) => {
  const { assessment } = props
  const {
    isLast,
    isSkipped,
    isUpcoming,
    isThrough,
    isNextThrough,
    showInProgressIcon,
    showDoneIcon,
    showUpcomingIcon,
    showFailedIcon,
    showGrayedoutText,
  } = getAssessmentStatus(props)

  if (isLast) return null

  return (
    <div
      className={clsx(
        'flex md:hidden flex-col items-center',
        isThrough ? 'text-brand-500' : 'text-brand-100'
      )}
      key={assessment._id}>
      <div className='flex items-center w-full'>
        {showInProgressIcon && <CheckInProgressSVG className='w-5' />}
        {showDoneIcon && <CheckDoneSVG className='w-5' />}
        {showUpcomingIcon && <CheckUpcomingSVG className='w-5' />}
        {showFailedIcon && <CheckFailedSVG className='w-5' />}
        {!isLast && (
          <Line
            className={
              isNextThrough
                ? 'border-brand-500 border-dashed'
                : 'border-brand-100'
            }
          />
        )}
      </div>
      <div
        className={clsx(
          'pt-4 pr-6 text-sm whitespace-nowrap',
          showGrayedoutText ? 'text-brand-gray-800' : 'text-black'
        )}>
        <StepName
          stage={props.assessment.stage}
          isSkipped={isSkipped}
          isUpcoming={isUpcoming}
        />
      </div>
    </div>
  )
}

export const VerticalStep = (props: StepProps) => {
  const {
    isFirst,
    isLast,
    isSkipped,
    isUpcoming,
    isThrough,
    showSkipNotice,
    showInProgressIcon,
    showDoneIcon,
    showUpcomingIcon,
    showFailedIcon,
    showGrayedoutText,
  } = getAssessmentStatus(props)

  return (
    <div
      className={clsx(
        'hidden md:grid grid-rows-2 gap-x-2.5 px-4',
        isThrough ? 'text-brand-500' : 'text-brand-100'
      )}
      style={{ gridTemplateColumns: '20px 1fr', gridTemplateRows: '1fr 20px' }}>
      <div className='justify-self-center'>
        {!isFirst && (
          <Line
            className={
              isThrough ? 'border-brand-500 border-dashed' : 'border-brand-100'
            }
          />
        )}
      </div>
      <div>{showSkipNotice && <SkipNotice />}</div>
      <span className='w-5 h-5'>
        {!isLast && (
          <>
            {showInProgressIcon && <CheckInProgressSVG />}
            {showDoneIcon && <CheckDoneSVG />}
            {showUpcomingIcon && <CheckUpcomingSVG />}
            {showFailedIcon && <CheckFailedSVG />}
          </>
        )}
      </span>
      <span
        className={clsx(
          'text-sm',
          showGrayedoutText ? 'text-brand-gray-800' : 'text-black'
        )}>
        {!isLast && (
          <StepName
            stage={props.assessment.stage}
            isSkipped={isSkipped}
            isUpcoming={isUpcoming}
          />
        )}
      </span>
    </div>
  )
}

const Line = ({ className = 'border-brand-100' }: { className: string }) => (
  <div
    className={clsx(
      'w-full md:w-px h-px border-r border-t md:h-full md:min-h-[32px]',
      className
    )}></div>
)

const SkipNotice = () => (
  // the -ml-2.5 should be the same as the gap-x-2.5 we defined for the grid.
  <div className='relative p-2 mt-2 mb-4 -ml-2.5 text-xtiny text-brand-yellow-800 bg-brand-yellow-200 rounded-md'>
    Based on your quiz score, you might be able to skip️ through steps and go
    straight to interviewing
    <LightningEmoji width='10' className='absolute right-4 bottom-1.5' />
  </div>
)

const StepName = ({ stage, isSkipped, isUpcoming }: StepNameProps) => (
  <>
    {StageTitle[stage as Stage]}{' '}
    {isSkipped && (
      <span className='text-brand-gray-800' style={{ fontSize: '10px' }}>
        (skipped)
        <SkipSVG className='inline-block ml-1 w-2.5 h-2.5' />
      </span>
    )}
    {isUpcoming && <LockSVG className='inline-block mb-0.5 ml-1 h-2.5' />}
  </>
)

interface StepNameProps {
  stage: Stage
  isSkipped: boolean
  isUpcoming: boolean
}

interface StepProps {
  assessments: AssessmentFragment[]
  assessment: AssessmentFragment
  index: number
  isRejected: boolean
}

const getAssessmentStatus = ({
  assessment,
  assessments,
  isRejected,
  index,
}: StepProps) => {
  // first assessment (create account)
  const isFirst = index === 0
  // last assessment (pre-offer)
  const isLast = index === Object.keys(assessments).length - 1

  // assessment statuses
  const isPending = assessment.status === AssessmentStatus.Pending
  const isDone = assessment.status === AssessmentStatus.Done
  const isInReview = assessment.status === AssessmentStatus.InReview
  const isSkipped = assessment.status === AssessmentStatus.Skipped
  const isUpcoming = assessment.status === AssessmentStatus.Upcoming

  // pending status while candidate iss rejected
  const isRejectedAndisPending = isRejected && isPending

  // candidate is through to next assessment
  const isThrough = !isUpcoming && !isRejectedAndisPending

  const nextAssessment = assessments[index + 1]

  const isNextThrough =
    nextAssessment?.status !== AssessmentStatus.Upcoming &&
    !(isRejected && nextAssessment?.status === AssessmentStatus.Pending)

  // skip notice should be shown when:
  // - quiz is not in upcoming
  // - coding challenge is in upcoming
  // - candidate is not rejected
  const showSkipNotice =
    !isRejected &&
    assessment.stage === Stage.CodingChallenge &&
    assessments.find((el) => el.stage === Stage.Quiz)?.status !==
      AssessmentStatus.Upcoming &&
    assessments.find((el) => el.stage === Stage.CodingChallenge)?.status ===
      AssessmentStatus.Upcoming

  // done icon when: - assessment is done or skipped
  const showDoneIcon = isDone || isSkipped
  // progress icon when: - assessment is pending or in review - candidate is not rejected
  const showInProgressIcon =
    (isPending || isInReview) && !isRejectedAndisPending
  // failed icon when: - assessment is pending - candidate is rejected
  const showFailedIcon = isRejectedAndisPending
  // upcoming icon: - assessment is upcoming
  const showUpcomingIcon = isUpcoming
  // gray out text when: - assessment is skipped or upcoming - assessment is pending and candidate is rejected
  const showGrayedoutText = isSkipped || isUpcoming || isRejectedAndisPending

  return {
    isFirst,
    isLast,
    isSkipped,
    isUpcoming,
    isThrough,
    isNextThrough,
    showSkipNotice,
    showInProgressIcon,
    showDoneIcon,
    showUpcomingIcon,
    showFailedIcon,
    showGrayedoutText,
  }
}
