import { CirclePlusSVG } from 'assets/icons'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import Card from 'components/CardAlternative'

const Section = ({ title, link, children }: SectionProps) => {
  return (
    <section className='space-y-6'>
      <h2 id={title} className='text-base text-brand-900'>
        {title}
      </h2>
      <Card className='max-w-xl'>
        {children}
        <Link
          to={link}
          className={clsx(
            'flex items-center p-6 space-x-2 text-xs rounded-lg text-brand-500 focus:outline-none focus-visible:ring-1 focus-visible:ring-brand-500',
            children && 'rounded-t-none'
          )}>
          <CirclePlusSVG className='w-4.5 h-4.5' role='presentation' />
          <span>Add {title.toLowerCase()}</span>
        </Link>
      </Card>
    </section>
  )
}

interface SectionProps {
  title: string
  /**
   * Link to go to when you click Add {title}
   */
  link: string
  children: React.ReactElement | React.ReactElement[] | null
}
export default Section
