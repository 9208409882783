import Input, { FormField } from 'components/InputAlternative'
import {
  DateRangeField,
  Fieldset,
  Form,
  JobTypeSelect,
  LegendWithCollapse,
  LocationField,
} from './Shared'

import InputRichText from 'components/InputRichText'
import { useFormikContext } from 'formik'
import { ProfileWorkExperienceInput } from 'api'
import TechStackMultiselect from 'components/TechStackMultiselect'

const WorkExperienceForm = () => {
  const {
    values,
    touched,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormikContext<WorkExperienceFormValues>()
  return (
    <Form onSubmit={handleSubmit} isSubmitting={isSubmitting}>
      <div className='space-y-10'>
        <Fieldset legend='Job details'>
          <FormField hasError={touched.jobTitle && !!errors.jobTitle}>
            <Input
              id='job-role'
              name='jobTitle'
              placeholder='Job role'
              onChange={handleChange}
              value={values.jobTitle}
            />
          </FormField>

          <FormField hasError={touched.company && !!errors.company}>
            <Input
              id='company-name'
              name='company'
              placeholder='Company name'
              onChange={handleChange}
              value={values.company}
            />
          </FormField>
          <LocationField />
          <DateRangeField />
          <FormField hasError={!!errors.jobType}>
            <JobTypeSelect
              value={values.jobType}
              onChange={(jobType) =>
                setValues((values) => ({
                  ...values,
                  jobType,
                }))
              }
            />
          </FormField>
        </Fieldset>

        <fieldset>
          <LegendWithCollapse legend='Job description'>
            <p>
              Briefly describe the business idea behind the project you’ve
              worked on (from an end-user point of view). Then, describe which
              areas you were involved in and how you contributed to those areas
              in bullets.
            </p>
            <h6 className='my-2 font-semibold'>Example:</h6>
            <div className='pl-4 border-l-4 font-roboto'>
              COMPANY is a platform that supports teams managing construction
              projects.
              <br />
              Achievements:
              <ul className='pl-5 space-y-2 list-disc rounded-tr-none'>
                <li>
                  Improved the CI runtime to go from 90 min to less than 30 min
                  by optimizing the workflow steps and project dependencies.
                </li>
                <li>
                  Refactored 70% of the legacy code to make the codebase cleaner
                  and implemented performance by using newer React/React Native
                  functionalities.
                </li>
                <li>
                  Created a dashboard for admins to manage users' input and
                  illustrate projects' progress.
                </li>
                <li>
                  Migrated the web app's views from Rails to React Native and
                  implemented a new design system using Material UI.
                </li>
                <li>
                  Set up and maintained self-hosted services like Redash and
                  Jitsi using Docker in an AWS infrastructure.
                </li>
                <li>
                  Wrote tests both for the Front-End (Unit and E2E using
                  Cypress) and the Back-End (using RSpec).
                </li>
              </ul>
            </div>
          </LegendWithCollapse>
          <FormField hasError={!!errors.description}>
            <InputRichText
              defaultValue={values.description}
              placeholder='Describe your experience while on this job'
              onChange={(description) =>
                setValues((values) => ({
                  ...values,
                  description,
                }))
              }
            />
          </FormField>
        </fieldset>

        <Fieldset legend='Which tech stack did you use?'>
          <FormField hasError={!!errors.techStack}>
            <TechStackMultiselect
              values={values.techStack}
              onChange={(techStack) =>
                setValues((values) => ({ ...values, techStack }))
              }
            />
          </FormField>
        </Fieldset>
      </div>
    </Form>
  )
}

export type WorkExperienceFormValues = ProfileWorkExperienceInput & {
  ongoing: boolean
}

export default WorkExperienceForm
