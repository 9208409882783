import Layout, { Right } from 'layouts'
import { Link } from 'react-router-dom'
import Anchor from 'components/Anchor'
import WalkthroughSteps from './WalkthroughSteps'

const Walkthrough = () => {
  return (
    <Layout>
      <Right>
        <Anchor component={Link} to='/profile/introduction'>
          SKIP
        </Anchor>
      </Right>
      <div className='rounded-md max-w-xl w-screen mt-10'>
        <WalkthroughSteps />
      </div>
    </Layout>
  )
}

export default Walkthrough
