import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import useSearchParams from 'lib/hooks/useSearchParams'
import { use0AuthMutation, useLogin, useLogout } from 'actions/auth'
import { authStorage } from 'lib/helpers/localStorage'
import { useMixpanel } from 'lib/contexts/Mixpanel'

/**
 * checks if current route is an auth0 redirect
 * @param {string} pathname - current location pathname
 */
const is0AuthCallback = (pathname) =>
  !!matchPath(pathname, {
    path: '/session/oauth/callback',
    exact: true,
  })

const useHandleAutoAuth = () => {
  const location = useLocation()
  const { code, type, error } = useSearchParams()
  const [authMutation] = use0AuthMutation(type)
  const login = useLogin()
  const logout = useLogout()
  const mixpanel = useMixpanel()

  const handle0Auth = async () => {
    if (error || !code || !type) {
      return logout()
    }
    try {
      const utmSource = mixpanel.get_property('UTM Source')
      const { data } = await authMutation({ variables: { code, utmSource } })

      const key = type === 'github' ? 'githubAuth' : 'googleAuth'
      const { token, me } = data[key]

      login({ token, _id: me._id })
    } catch (err) {
      console.error(err)
      logout()
    }
  }

  React.useEffect(() => {
    if (is0AuthCallback(location.pathname)) {
      handle0Auth()
    } else {
      const token = authStorage.get()
      if (token) {
        login()
      } else {
        logout()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useHandleAutoAuth
