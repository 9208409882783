/*
  Skeleton component used as a placeholder with a pulse animation.
*/

import clsx from 'clsx'

function Skeleton({
  width = 'max-w-xs',
  height = 'h-2',
  rounded = 'rounded-lg',
  color = 'bg-brand-50',
  animate = true,
  className,
  ...props
}: SkeletonProps) {
  return (
    <div
      className={clsx(
        animate && `animate-pulse`,
        width,
        height,
        rounded,
        color,
        className
      )}
      {...props}
    />
  )
}

export interface SkeletonProps extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Disable/enable animation
   */
  animate?: boolean
  width?: string
  height?: string
  rounded?: string
  color?: string
  opacity?: string
  className?: string
}

export default Skeleton
