import clsx from 'clsx'
import { ReactNode } from 'react'
import UserDropdown from 'components/UserDropdown'

const SidebarDropdown = ({
  username,
  children,
  canApplyIn,
}: UserDropdownProps) => {
  return (
    <div
      className={clsx(
        'relative w-full bg-white rounded-sxl select-none',
        'border border-brand-100 shadow-sidebar'
      )}>
      <div className='divide-y divide-brand-100'>
        <div className='p-6 space-y-1'>
          <div className='flex justify-between items-start space-x-1'>
            <div className='overflow-hidden font-medium'>{username}</div>
            <UserDropdown className='top-12 right-4'>{children}</UserDropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

interface UserDropdownProps {
  /**
   * Direct children of UserDropdown
   * will have dividers between them.
   */
  children: ReactNode
  username: string
  canApplyIn: number | null | undefined
}

// interface ApplicationStatusProps {
//   className?: string
//   canApplyIn: UserDropdownProps['canApplyIn']
// }

export default SidebarDropdown
