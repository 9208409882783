import { matchPath } from 'react-router-dom'
import { Stage } from 'api'
import { redirectWhiteList, applicationRoutes } from './routes'

export const isWhitelistedRoute = (pathname: string) => {
  return !!matchPath(pathname, {
    path: redirectWhiteList,
    exact: true,
  })
}

export const matchStagePath = (pathname: string, stage: Stage) => {
  return applicationRoutes.find((route) => {
    return (
      route.stage === stage &&
      !!matchPath(pathname, {
        path: route.path,
        exact: !!route.exact,
      })
    )
  })
}
