import React from 'react'
import Navbar from 'depricated-components/Navbar'
import { BaseStyle, Content, Wrapper } from './style'

const FocusBox = ({ children }) => {
  return (
    <BaseStyle>
      <Navbar />
      <Content>{children}</Content>
    </BaseStyle>
  )
}

// we give the ability to optionally wrap this
// and not just overide it
FocusBox.Wrapper = Wrapper

export default FocusBox
