import { useAuthentication, useLogout } from 'actions/auth'
import clsx from 'clsx'
import Anchor from 'components/Anchor'
import Layout, { Right } from 'layouts'

import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

export const LayoutContainer = ({
  className,
  ...props
}: {
  className?: string
  children?: ReactElement
}) => {
  const logout = useLogout()
  const { authenticated } = useAuthentication()
  return (
    <Layout className=''>
      <Right>
        {authenticated ? (
          <Anchor
            component='button'
            className='text-tiny text-brand-900 uppercase'
            onClick={() => logout()}>
            Logout
          </Anchor>
        ) : (
          <Anchor
            component={Link}
            to='/signin'
            className='text-tiny text-brand-900 uppercase'>
            Login
          </Anchor>
        )}
      </Right>
      <div
        className={clsx('px-4 xs:px-11 mx-auto w-full max-w-md', className)}
        {...props}
      />
    </Layout>
  )
}
