import React from 'react'
import { ApolloProvider } from '@apollo/client'
import useCreateClient from './useCreateClient'

const GraphqlProvider = ({ children }) => {
  const client = useCreateClient()
  if (!client) return null
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphqlProvider
