import { useWriteCandidateQuery } from '../user'
import { useMutation } from '@apollo/client'
import {
  GITHUB_AUTH_MUTATION,
  GOOGLE_AUTH_MUTATION,
  RESET_PASSWORD_MUTATION,
  SEND_PASSWORD_RESET_EMAIL_MUTATION,
  SIGN_IN_MUTATION,
  SIGN_UP_MUTATION,
} from './mutations'
import useSearchParams from '../../lib/hooks/useSearchParams'
import { useMixpanel } from 'lib/contexts/Mixpanel'

const GITHUB_TYPE = 'github'
const GOOGLE_TYPE = 'google'

/**
 *
 * @param {string} type - 0auth type (google|github)
 * @param {object} data - auth0 mutation result data
 */
const get0AuthResultData = (type, data) => {
  switch (type) {
    case GITHUB_TYPE:
      return data?.githubAuth
    case GOOGLE_TYPE:
      return data?.googleAuth
    default:
      throw new Error(`unknown 0auth type=${type}`)
  }
}

export const use0AuthMutation = () => {
  const { code, type } = useSearchParams()
  const mixpanel = useMixpanel()

  const mutationType =
    type === GITHUB_TYPE ? GITHUB_AUTH_MUTATION : GOOGLE_AUTH_MUTATION
  const [authMutation, mutationResult] = useMutation(mutationType)
  const writeCandidateQuery = useWriteCandidateQuery()

  const mutation = () => {
    const utmSource = mixpanel.get_property('utm_source')
    return authMutation({
      variables: { code, utmSource },
      update: (_, mutRes) => {
        const data = get0AuthResultData(type, mutRes.data)
        if (!data) return
        const { me } = data

        writeCandidateQuery(me)
      },
    })
  }

  return [mutation, mutationResult]
}

export const useSignInMutation = () => {
  const [mutation, mutationResult] = useMutation(SIGN_IN_MUTATION)

  const writeCandidate = useWriteCandidateQuery()

  const newMutation = (variables) => {
    return mutation({
      variables,
      update: (_, { data: { signIn } }) => {
        if (!signIn) return

        writeCandidate(signIn.me)
      },
    })
  }

  return [newMutation, mutationResult]
}

export const useSignUpMutation = () => {
  const [mutation, mutationResult] = useMutation(SIGN_UP_MUTATION)

  const writeCandidate = useWriteCandidateQuery()

  const newMutation = (variables) => {
    return mutation({
      variables,
      update: (_, { data: { signUp } }) => {
        if (!signUp) return

        writeCandidate(signUp.me)
      },
    })
  }

  return [newMutation, mutationResult]
}

export const useSendResetPasswordEmail = () => {
  return useMutation(SEND_PASSWORD_RESET_EMAIL_MUTATION)
}

export const useResetPassword = () => {
  return useMutation(RESET_PASSWORD_MUTATION)
}
