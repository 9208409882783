import ConfirmDelete, { ConfirmDeleteProps } from './ConfirmDelete'
import useConfirmationPopup from './useConfirmation'

export const useDeleteConfirmation = () => {
  const confirmation = useConfirmationPopup()
  return (options: Omit<ConfirmDeleteProps, 'onClose'>) => {
    confirmation((onClose) => <ConfirmDelete {...options} onClose={onClose} />)
  }
}

export default useConfirmationPopup
