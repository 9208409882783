import clsx from 'clsx'
import { forwardRef } from 'react'

import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { Dropcursor } from '@tiptap/extension-dropcursor'
import { History } from '@tiptap/extension-history'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Text } from '@tiptap/extension-text'
import { EditorContent, useEditor, PureEditorContent } from '@tiptap/react'

const InputRichText = forwardRef<PureEditorContent, RichTextProps>(
  (
    {
      className,
      defaultValue,
      placeholder = 'Write something...',
      onChange,
      ...props
    },
    ref
  ) => {
    const editor = useEditor({
      extensions: [
        Document,
        Paragraph, // <p>
        BulletList, // <ul>
        ListItem, // <li>
        Link, // <a> + pasting links
        Bold, // <strong> + ctrl-b
        Italic, // <em> + ctrl-i
        Dropcursor, // drag and drop cursor
        History, // adds ctrl-em functionality
        Placeholder.configure({ placeholder }), // adds placeholder via CSS and `data-placeholder`
        Text,
      ],
      content: defaultValue,
      onUpdate: ({ editor }) => {
        if (onChange) onChange(editor.getHTML())
      },
    })

    return (
      <EditorContent
        className={clsx('richtext', className)}
        data-cy='editor'
        editor={editor}
        ref={ref}
        {...props}
      />
    )
  }
)

type RichTextProps = {
  defaultValue?: string
  onChange: (html: string) => void
  placeholder?: string
  className?: string
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>

export default InputRichText
