import { Stage, StageTitle } from 'api'
import React from 'react'
import {
  InterviewSVG,
  QuizSVG,
  CodingChallengeSVG,
  CultureSVG,
  VideoAskSVG,
} from 'assets/illustrations'

type StagesStepContent = {
  stage: Stage
  title: string
  subtitle: string
  hint: string | null
  illustration: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
    height: number
    width: number
  }
}

export const STAGES_STEP_COTENT: StagesStepContent[] = [
  {
    stage: Stage.Quiz,
    title: StageTitle.quiz,
    subtitle: 'Take a short quiz (or more) to put your skills to the test.',
    hint:
      'You might be able to skip️ some steps, depending on how you score on the quiz.',
    illustration: {
      icon: QuizSVG,
      height: 150,
      width: 210,
    },
  },
  {
    stage: Stage.CodingChallenge,
    title: StageTitle.codingChallenge,
    subtitle:
      'During this step, we ask you to work on one of the coding challenges below, or share with us a personal side project instead.',
    hint: null,
    illustration: {
      icon: CodingChallengeSVG,
      height: 150,
      width: 163,
    },
  },
  {
    stage: Stage.Videoask,
    title: StageTitle.videoask,
    subtitle:
      'Tell us about your most challenging project to show us how well you can communicate your thoughts.',
    hint: null,
    illustration: {
      icon: VideoAskSVG,
      height: 150,
      width: 215,
    },
  },
  {
    stage: Stage.TechnicalInterview,
    title: StageTitle.technicalInterview,
    subtitle:
      'Schedule a 1-hour interview with one of our team to discuss your background and technical experiences.',
    hint: null,
    illustration: {
      icon: InterviewSVG,
      height: 150,
      width: 217,
    },
  },
  {
    stage: Stage.CultureFitInterview,
    title: StageTitle.cultureFitInterview,
    subtitle:
      'Schedule a 1-hour interview with one of our team to discuss your approach to collaboration and communication.',
    hint: null,
    illustration: {
      icon: CultureSVG,
      height: 150,
      width: 188,
    },
  },
]
