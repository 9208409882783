import { zack, najlae } from 'assets/people'
import TopicCard from './TopicCard'
import type { TopicType } from './TopicCard'

const TopicsList = ({ topics = topicsList }: TopicsListProps) => (
  <ul className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4'>
    {topics.map((topic, index) => (
      <li key={index}>
        <TopicCard topic={topic} />
      </li>
    ))}
  </ul>
)
type TopicsListProps = {
  topics?: TopicType[]
  className?: string
}

export default TopicsList

const topicsList = [
  {
    link:
      'https://www.gemography.com/blog/8-hacks-for-your-next-technical-interview',
    title: '8 Hacks For Your Next Technical Interview',
    readTime: 3,
    author: zack,
  },
  {
    link:
      'https://www.gemography.com/blog/common-misconceptions-about-applying-to-gemography',
    title: 'Common Misconcep-tions About Applying To Gemography',
    readTime: 2,
    author: najlae,
  },
  {
    link: 'https://www.gemography.com/blog/how-we-interview-at-gemography',
    title: 'How we interview at Gemography',
    readTime: 4,
    author: zack,
  },
]
