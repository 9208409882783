import { FormField } from 'components/InputAlternative'
import Checkbox from 'components/Checkbox'
import { useFormikContext } from 'formik'
import DateRangeInput from 'components/DateRangeInput'

export const DateRangeField = () => {
  const {
    values,
    touched,
    errors,
    handleChange,
    setValues,
  } = useFormikContext<{
    startDate: string
    endDate?: string
    ongoing: boolean
  }>()
  return (
    <div className='flex -mr-2'>
      <FormField
        className='flex-grow'
        hasError={
          touched.startDate && (!!errors.startDate || !!errors.endDate)
        }>
        <DateRangeInput
          value={{
            start: values.startDate ? new Date(values.startDate) : undefined, // undefined causes React warning
            end: values.endDate ? new Date(values.endDate) : undefined,
          }}
          onChange={({ start, end }) =>
            setValues((values) => ({
              ...values,
              // since start might be undefined we make sure to fallback
              // to a valid type
              startDate: start?.toISOString() || values.startDate,
              endDate: end?.toISOString(),
            }))
          }
          endDisabled={values.ongoing}
        />
      </FormField>
      <Checkbox
        className='z-20'
        name='ongoing'
        checked={values.ongoing}
        onChange={handleChange}>
        ongoing
      </Checkbox>
    </div>
  )
}
