import React, { createContext, useReducer, useContext } from 'react'
import reducer, { initialState, navBarActionTypes } from './reducer'

const NavBarStateContext = createContext(initialState)
const NavBarDispatchContext = createContext(() => {})

const useNavBarState = () => {
  const context = useContext(NavBarStateContext)
  if (context === undefined) {
    throw new Error('useNavBarState must be used within a NavBarProvider')
  }
  return context
}

const useNavBarDispatch = () => {
  const context = useContext(NavBarDispatchContext)
  if (context === undefined) {
    throw new Error('useNavBarDispatch must be used within a NavBarProvider')
  }
  return context
}

const useSetNavbarContent = (callback, type, dependencies = []) => {
  const dispatch = useNavBarDispatch()

  React.useEffect(() => {
    if (typeof callback !== 'function') {
      throw new Error("callback provided wasn't a function")
    }
    if (!(type in navBarActionTypes)) {
      throw new Error('unrecognized type provided')
    }

    const payload = callback()

    // set content
    dispatch({
      type,
      payload,
    })

    // we cleanup the slot of the side bar
    return () => {
      dispatch({ type, payload: null })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, JSON.stringify(dependencies)]) // deep equal useEffect
}

const NavBarProvider = ({ children }) => {
  const [value, setCount] = useReducer(reducer, initialState)
  return (
    <NavBarStateContext.Provider value={value}>
      <NavBarDispatchContext.Provider value={setCount}>
        {children}
      </NavBarDispatchContext.Provider>
    </NavBarStateContext.Provider>
  )
}

export { navBarActionTypes } from './reducer'

export {
  NavBarProvider,
  useNavBarState,
  useNavBarDispatch,
  useSetNavbarContent,
}
