import { ProfileQuizSubmissionFragment } from 'api'
import clsx from 'clsx'

import styled from 'styled-components'

const StyleBase = styled.div<StyleBaseProps>`
  position: relative;

  .circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 100%;
    max-height: 100px;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
  }

  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 4;
  }

  .circle {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
  }

  .circular-chart.blue .circle {
    stroke: #6050dc;
  }

  .percentage {
    fill: #000;
    font-size: 0.5em;
    font-weight: 600;
    text-anchor: middle;
  }
`

const ProgressRing = ({
  progress,
  viewBox = '0 0 36 36',
  width = '100px',
  height = '100px',
}: ProgressRingProps) => {
  return (
    <StyleBase width={width} height={height}>
      <svg viewBox={viewBox} className='circular-chart blue'>
        <path
          className='circle-bg'
          d='M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <path
          className='circle'
          strokeDasharray={`${progress}, 100`}
          d='M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831'
        />
        <text x='18' y='20.35' className='percentage'>
          {progress}%
        </text>
      </svg>
    </StyleBase>
  )
}

const AssessmentsCard = ({ assessments }: AssessmentsCardProps) => (
  <div className='rounded-lg border border-brand-100 shadow-sidebar'>
    <div className='p-6 pb-0'>
      <h2 className='font-semibold text-lg'>Assessments</h2>
    </div>
    {assessments?.map(
      ({ quizName, score, numberOfQuizSubmissions, rank }, index) => {
        const height = index === 0 ? '100px' : '60px'
        return (
          <div
            key={quizName}
            className={clsx(
              'flex justify-between items-center',
              index === 0 ? 'p-6' : 'px-6 py-5',
              index < assessments.length - 1 && 'border-b border-brand-100'
            )}>
            <div>
              <h3 className='text-base font-medium'>{quizName}</h3>
              {rank === 'unranked' ? (
                <p className='text-sm'>
                  This submission doesn't rank you well among other quiz
                  submissions.
                </p>
              ) : (
                <p className='text-sm'>
                  You seem to be ranked among the{' '}
                  <span className='text-brand-500'>
                    Top {rank.replace(/[^0-9]/g, '')}%
                  </span>{' '}
                  of all quiz submissions.
                </p>
              )}
            </div>
            <div style={{ width: '100px', height }}>
              <ProgressRing height={height} progress={score} />
            </div>
          </div>
        )
      }
    )}
  </div>
)

interface StyleBaseProps {
  height?: string
  width?: string
}
interface ProgressRingProps {
  progress: number
  viewBox?: string
  width?: string
  height?: string
}
interface AssessmentsCardProps {
  assessments?: ProfileQuizSubmissionFragment[]
}

export default AssessmentsCard
