import { gql } from '@apollo/client'

const QUIZ_FRAGMENT = gql`
  fragment Quiz on Quiz {
    _id
    name
    totalDuration
    totalQuestionsCount
    icon
  }
`

export const ALL_QUIZZES_QUERY = gql`
  query {
    allQuizzes {
      ...Quiz
    }
  }
  ${QUIZ_FRAGMENT}
`

export const QUIZ_QUESTION_FRAGMENT = gql`
  fragment QuizQuestion on QuizQuestion {
    _id
    body
    duration
    answers
    snippet {
      mode
      value
    }
  }
`

export const QUIZ_SUBMISSION_FRAGMENT = gql`
  fragment QuizSubmission on QuizSubmission {
    _id
    quiz {
      name
      icon
    }
    quizId
    questionPosition
    totalQuestionsCount
    status
    timeRemaining
    progress
    question {
      ...QuizQuestion
    }
  }
  ${QUIZ_QUESTION_FRAGMENT}
`

export const QUIZ_SUBMISSION_QUERY = gql`
  query quizSubmission($quizId: LimitedString!) {
    quizSubmission(quizId: $quizId) {
      ...QuizSubmission
    }
  }
  ${QUIZ_SUBMISSION_FRAGMENT}
`
