import ahaiImg from './ahai.jpg'
import najlaeImg from './najlae.png'
import yekaImg from './yeka.jpg'
import zackImg from './zack.png'
import serrahImg from './serrah.png'
import hamzaImg from './hamza.png'
import ayoubImg from './ayoub.png'

const ahai = {
  img: ahaiImg,
  name: 'Adil Haizoune',
  role: 'Chief Technical Officer',
}
const najlae = {
  img: najlaeImg,
  name: 'Najlae El Khomsi',
  role: 'Talent Coordinator',
}
const yeka = {
  img: yekaImg,
  name: 'Yassine El Kachchani',
  role: 'Chief Executive Officer',
}
const zack = { img: zackImg, name: 'Zack Braksa', role: 'VP of Engineering' }
const serrah = {
  img: serrahImg,
  name: 'Serrah Menif',
  role: 'People Ops Manager',
}
const hamza = {
  img: hamzaImg,
  name: 'Hamza SALAH',
  role: 'Senior Frontend Engineer',
}
const ayoub = {
  img: ayoubImg,
  name: 'Ayoub BOUTAIEB',
  role: 'Data Engineer',
}

export { ahai, najlae, yeka, zack, serrah, hamza, ayoub }
