import React from 'react'
import { OverridedMixpanel } from 'mixpanel-browser'
import { Candidate } from 'api'

export const MixpanelContext = React.createContext<OverridedMixpanel | null>(
  null
)

/**
 * Hook that allows usage of same mixpanel instance
 * through the app (instead of imports)
 */
export const useMixpanel = () => {
  const ctx = React.useContext(MixpanelContext)
  if (!ctx) {
    throw new Error('You need to call useMixpanel within MixpanelProvider.')
  }
  return ctx
}

/**
 * Took to track page visits with mixpanel.
 * This needs to be placed in the top level
 * component of the page to track
 */
export const useTrackPageVisit = (pageName: string) => {
  const mixpanel = useMixpanel()

  React.useEffect(() => {
    mixpanel.track(`View ${pageName} Page`)
  }, [mixpanel, pageName])
}

/**
 * hook that returns a function to set
 * mixpanel user properties
 * @returns {function}
 */
export const useSetMixpanelUserProps = () => {
  const mixpanel = useMixpanel()

  return (candidate: Partial<Candidate>) => {
    mixpanel.identify(candidate._id)
    mixpanel.people.set({
      // $ are reserved props
      $email: candidate?.email,
      $distinct_id: candidate?._id,
      $first_name: candidate?.firstName,
      $last_name: candidate?.lastName,
    })
  }
}
