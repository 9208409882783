import React from 'react'

import { ActiveDotSVG, InActiveDotSVG } from 'assets/icons'

interface DotProps {
  active: boolean
}

const Dot = ({ active = false }: DotProps) => {
  const Icon = active ? ActiveDotSVG : InActiveDotSVG
  return <Icon />
}

export default Dot
