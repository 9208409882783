import { Redirect, Route, Switch } from 'react-router-dom'
import { loadableHeaderLayout } from './loadableRoutes'
import AuthenticationPage from 'pages/Authentication'

const ResetPasswordPage = loadableHeaderLayout(
  () => import(/* webpackChunkName: "ResetPassword" */ 'pages/ResetPassword')
)

const UnauthenticatedApp = () => {
  return (
    <Switch>
      <Route
        path={['/signin', '/signup']}
        component={AuthenticationPage}
        exact
      />
      <Route path='/reset-password' component={ResetPasswordPage} exact />
      <Redirect to='/signin' exact />
    </Switch>
  )
}

export default UnauthenticatedApp
