import clsx from 'clsx'
import DateInput from './DateInput'

const DateRangeInput = ({
  onChange,
  value: { start, end } = {},
  className,
  hasError = false,
  endDisabled = false,
}: DateRangeInputProps) => {
  return (
    <fieldset
      className={clsx(
        'flex',
        'text-sm text-brand-900 rounded  hover:bg-brand-gray-100',
        'p-2 -mx-2',
        'border border-transparent',
        hasError ? 'border-alert' : 'focus-within:border-brand-100',
        'relative focus-within:bg-white focus-within:shadow-input focus-within:z-10',
        className
      )}>
      <legend className='sr-only'>date range</legend>
      <DateInput
        value={start}
        onChange={(newStart) => onChange({ start: newStart })}
        placeholder='Start date (MM/YYYY)'
      />
      {!endDisabled ? (
        <>
          <span className='text-cardGray-50 mx-2'>—</span>
          <DateInput
            value={end}
            onChange={(newEnd) => onChange({ end: newEnd })}
            placeholder='End date (MM/YYYY)'
          />
        </>
      ) : null}
    </fieldset>
  )
}

type DateRange = { start?: Date; end?: Date }

type DateRangeInputProps = {
  value?: DateRange
  onChange: (value: DateRange) => void
  className?: string
  hasError?: boolean
  endDisabled?: boolean
}
export default DateRangeInput
