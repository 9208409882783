import { MutationResult } from '@apollo/client'
import { WarningEmoji } from 'assets/emojis'
import { useSnackbar } from 'lib/contexts/SnackbarProvider'
import { authStorage } from 'lib/helpers/localStorage'
import { useState } from 'react'
import { useSubmitVideoaskMutation } from './generated'
import * as Sentry from '@sentry/browser'

if (!process.env.REACT_APP_API_BASE_URI) {
  throw new Error('REACT_APP_API_BASE_URI is not set')
}

const UPLOAD_URL = `${process.env.REACT_APP_API_BASE_URI}/videoask/upload`

const uploadVideoAsk = async (file: File) => {
  const formData = new FormData()
  formData.append('audio', file)
  const response = await fetch(UPLOAD_URL, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${authStorage.get()}`,
    },
  })
  if (response.status < 200 || response.status >= 300) {
    throw new Error(await response.text())
  }
  return response.text()
}

export const useVideoaskUpload = (): [
  (file: File) => Promise<void>,
  MutationResult
] => {
  const showSnackbar = useSnackbar()

  const [state, setState] = useState({
    loading: false,
  })
  const [submitVideoask, result] = useSubmitVideoaskMutation()

  const mutation = async (file: File) => {
    setState({ loading: true })
    try {
      const data = await uploadVideoAsk(file)
      await submitVideoask({
        variables: { videoaskLink: data },
      })
    } catch (err) {
      Sentry.captureException(err)
      showSnackbar({
        key: 'interview_cancel_error',
        text: 'Failed to upload your audio file. Please, try again.',
        icon: WarningEmoji,
      })
    } finally {
      setState({ loading: false })
    }
  }
  return [
    mutation,
    {
      ...result,
      loading: state.loading || result.loading,
    },
  ]
}
