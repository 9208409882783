import { useLogout as _useLogout } from 'actions/auth'
import { CurrentUserQuery, useReadUserQuery as _useReadUserQuery } from 'api'
import clsx from 'clsx'
import { NavLink, NavLinkProps } from 'react-router-dom'
import Sidebar from './Sidebar'

const NavItem = ({
  to,
  className,
  activeClassName,
  children,
  ...props
}: NavLinkProps) => (
  <NavLink
    className={clsx('text-base font-semibold', className)}
    activeClassName={clsx('text-brand-900', activeClassName)}
    to={to}
    {...props}>
    {children}
  </NavLink>
)

const ProfileSidebar = ({
  children,
  useReadUserQuery = _useReadUserQuery,
  useLogout = _useLogout,
}: SidebarProps) => {
  const user = useReadUserQuery()
  const logout = useLogout()
  return (
    <div className='pt-5 border-t-3 border-brand-500'>
      <div
        className='grid max-w-6xl mx-auto px-1.5 gap-7'
        style={{ gridTemplateColumns: '360px 1fr' }}>
        <Sidebar user={user} logout={logout} />
        <div className='space-y-10'>
          <nav className='space-x-6 text-brand-150'>
            <NavItem to='/profile/dashboard'>Dashboard</NavItem>
            <NavItem to='/profile' exact>
              Profile
            </NavItem>
          </nav>
          <div className='pb-10'>{children}</div>
        </div>
      </div>
    </div>
  )
}

interface SidebarProps {
  children: React.ReactNode
  /**
   * for testing and storybook only.
   */
  useLogout?: () => Function
  /**
   * for testing and storybook only.
   */
  useReadUserQuery?: () => CurrentUserQuery
}

export default ProfileSidebar
