import { Route } from 'react-router-dom'
import {
  loadableHeaderLayout,
  loadablePublicLayout,
} from 'routes/loadableRoutes'

const SignInPage = loadableHeaderLayout(
  () => import(/* webpackChunkName: "SignIn" */ './SignIn')
)

const SignUpPage = loadablePublicLayout(
  () => import(/* webpackChunkName: "SignUp" */ './Signup')
)

export const AUTH_ROUTES = {
  signIn: '/signin',
  signUp: '/signup',
}

const Authentication = () => {
  return (
    <>
      <Route path={AUTH_ROUTES.signIn} exact>
        <SignInPage />
      </Route>
      <Route path={AUTH_ROUTES.signUp} exact>
        <SignUpPage />
      </Route>
    </>
  )
}

export default Authentication
