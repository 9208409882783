import clsx from 'clsx'
import avatarSVG from 'assets/illustrations/avatar.svg'
import { useState } from 'react'
import { Switch } from '@headlessui/react'
import {
  CurrentUserQuery,
  useCurrentProfileQuery,
  useToggleOfferAvailabilityMutation,
} from 'api'
import { BadgeGroup } from 'components/Badge'
import { GemographyUpdatedSVG, OutSVG } from 'assets/icons'
import UserDropdown, { DropdownButton } from 'components/UserDropdown'
import ToolTip from 'components/ToolTip'
import EditModal from 'pages/Profile/EditModal'
import { useHistory } from 'react-router-dom'
import { countryFlagByName } from 'lib/helpers/getCountries'

const Sidebar = ({ className, user, logout, ...props }: SidebarProps) => {
  const {
    me: { name, country },
  } = user

  const history = useHistory()

  const { data, error, loading } = useCurrentProfileQuery({
    returnPartialData: true,
  })
  const [toggleOfferAvailabilityMutation] = useToggleOfferAvailabilityMutation()

  if (loading) return null
  if (error || !data) {
    const err = error || new Error('failed to fetch current profile')
    throw err
  }

  const { currentProfile } = data

  return (
    <div className='space-y-10'>
      <EditModal />
      <aside
        className={clsx('py-5 space-y-6 rounded-lg bg-brand-50', className)}
        {...props}>
        <header className='relative flex items-start justify-between px-5'>
          <div className='space-y-2'>
            <h1 className='text-base font-bold'>{name}</h1>
            <p className='flex items-center space-x-2.5'>
              {country ? (
                <span className='text-tiny'>{countryFlagByName(country)}</span>
              ) : null}
              <span className='font-medium text-xxs'>
                {currentProfile?.role}
              </span>
            </p>
          </div>
          <div className='flex items-center justify-between flex-shrink-0 space-x-1'>
            <img
              alt='profile'
              key={currentProfile?.pictureURL}
              src={currentProfile?.pictureURL || avatarSVG}
              className='w-10 h-10 rounded-full bg-brand-900'
            />
            <UserDropdown className='right-0 top-8'>
              <ul className='p-2'>
                <li>
                  <DropdownButton
                    onClick={() =>
                      history.push({
                        pathname: '/profile/dashboard/edit-profile',
                        search: '?type=profile',
                      })
                    }>
                    Edit profile
                  </DropdownButton>
                </li>
                <li>
                  <DropdownButton
                    onClick={() =>
                      history.push({
                        pathname: '/profile/dashboard/edit-profile',
                        search: '?type=accountSettings',
                      })
                    }>
                    Account settings
                  </DropdownButton>
                </li>
              </ul>
              <div className='p-2'>
                <DropdownButton
                  className='w-full underline text-tiny'
                  onClick={() => logout()}>
                  <OutSVG className='inline-block w-3 h-3 p-px mr-1' />
                  Logout
                </DropdownButton>
              </div>
            </UserDropdown>
          </div>
        </header>
        <OfferSwitch
          defaultValue={currentProfile?.openToOffers}
          onSwitch={toggleOfferAvailabilityMutation}
        />
        <section className='px-5 space-y-6'>
          <div className='space-y-4'>
            <h3 className='font-semibold first-letter:uppercase'>Bio</h3>
            <p className='text-xs'>{currentProfile?.bio}</p>
          </div>
          <div className='space-y-4'>
            <h3 className='font-semibold first-letter:uppercase'>
              Tech stacks
            </h3>
            <ul className='space-y-3'>
              {[
                {
                  title: 'Proficient in',
                  stack: currentProfile?.techStacks?.proficient,
                  tooltip:
                    'The technologies you feel you are mastering (have experience with and know how it works under the hood).',
                },
                {
                  title: 'Experienced with',
                  stack: currentProfile?.techStacks?.experienced,
                  tooltip:
                    'The technologies you used in at least 1 or 2 projects.',
                },
                {
                  title: 'Familiar with',
                  stack: currentProfile?.techStacks?.familiar,
                  tooltip:
                    'The technologies you read about and you have slightly basics of it.',
                },
              ].map(({ title, stack, tooltip }, i) => (
                <li key={title + i} className='space-y-2'>
                  <div className='flex flex-row items-center space-x-1'>
                    <h4 className='uppercase text-tiny text-brand-150'>
                      {title}
                    </h4>
                    <ToolTip text={tooltip} />
                  </div>

                  <BadgeGroup values={stack as string[]} />
                </li>
              ))}
            </ul>
          </div>
          <div className='space-y-10'>
            <section className='space-y-2'>
              <h3 className='font-semibold first-letter:uppercase'>
                languages
              </h3>
              <div className='flex items-center space-x-2'>
                <div className='text-cardGray-100 text-sm'>
                  {currentProfile?.languages?.join(', ') || ''}
                </div>
              </div>
            </section>
            <section className='space-y-2'>
              {[
                {
                  title: 'Work experience',
                  count: currentProfile?.workExperiences.length || 0,
                },
                {
                  title: 'Personal projects',
                  count: currentProfile?.personalProjects.length || 0,
                },
                {
                  title: 'Education',
                  count: currentProfile?.educations.length || 0,
                },
              ].map(({ title, count }, i) => (
                <div key={title + i} className='flex justify-between'>
                  <h3 className='text-sm font-semibold'>{title}</h3>
                  <div className='grid w-6 h-6 bg-gray-200 bg-opacity-50 rounded text-brand-150 place-items-center text-tiny tabular-nums'>
                    {count}
                  </div>
                </div>
              ))}
            </section>
          </div>
        </section>
      </aside>
      <footer className='flex items-center font-extrabold text-xxs space-x-2.5 text-brand-150'>
        <GemographyUpdatedSVG className='h-5' />
        <span>&copy;{new Date().getFullYear()}</span>
      </footer>
    </div>
  )
}

const OfferSwitch = ({ defaultValue = false, onSwitch }: OfferSwitchProps) => {
  const [enabled, setEnabled] = useState(defaultValue)

  const handleSwitch = () => {
    onSwitch()
    setEnabled((state) => !state)
  }

  return (
    <Switch.Group
      as='div'
      className='relative flex items-center justify-between p-4 mx-2 overflow-hidden rounded-lg bg-opacity-30 text-brand-150 bg-brand-100'>
      {enabled ? <Waves /> : null}
      <Switch.Label
        as='span'
        className='flex flex-col flex-grow space-y-2'
        passive>
        <span className={clsx('text-xl font-bold', enabled && 'text-black')}>
          {enabled ? 'Ready to interview' : 'Closed to offers'}
        </span>
        <span className='text-sm text-gray-500 whitespace-pre-line text-opacity-70'>
          {enabled
            ? 'We are broadcasting your profile to our Startup network.'
            : 'We are not broadcasting your profile to our Startup network.'}
        </span>
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={handleSwitch}
        className={clsx(
          enabled ? 'bg-brand-500' : 'bg-brand-150',
          'relative self-start inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-brand-100 focus-visible:ring-indigo-500'
        )}>
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={clsx(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

const Waves = () => (
  <svg
    aria-hidden='true'
    width='360'
    height='360'
    className='absolute pointer-events-none'
    style={{
      bottom: '-240px',
      right: '-190px',
    }}
    viewBox='0 0 360 360'
    fill='none'>
    <g opacity='.15' className='stroke-current text-brand-500' strokeWidth='30'>
      {[
        {
          opacity: '.8',
          r: '50',
        },
        {
          opacity: '.6',
          r: '90',
        },
        {
          opacity: '.4',
          r: '130',
        },
        {
          opacity: '.2',
          r: '170',
        },
      ].map(({ r, opacity }, i) => (
        <circle key={i + r + opacity} opacity={opacity} cx='188' cy='146' r={r}>
          <animate
            attributeName='r'
            from='50'
            to={r}
            begin='0s'
            dur='0.2s'
            values={`0;${r}`}
            calcMode='linear'
            repeatCount='0'
          />
        </circle>
      ))}
    </g>
  </svg>
)

interface SidebarProps extends React.HTMLAttributes<HTMLElement> {
  user: CurrentUserQuery
  logout: Function
}

interface OfferSwitchProps {
  defaultValue?: boolean
  onSwitch: () => void
}

export default Sidebar
