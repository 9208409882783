import { ReactElement } from 'react'
import { NavBarProvider } from './navBar'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router } from 'react-router-dom'
import theme from '../../styles/theme'
import { PrismicProvider } from './Prismic'
import { ApplicationRoleProvider } from './RoleProvider'

const RootProvider = ({ children }: { children: ReactElement }) => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <PrismicProvider>
          <NavBarProvider>
            <ApplicationRoleProvider>{children}</ApplicationRoleProvider>
          </NavBarProvider>
        </PrismicProvider>
      </ThemeProvider>
    </Router>
  )
}

export default RootProvider
