import { AUTH_STATUS, useAuthentication } from 'actions/auth'
import useHandleAutoAuth from 'lib/hooks/useHandleAutoAuth'
import useNetworkStatusFeedback from 'lib/hooks/useNetworkStatusFeedback'
import useSearchParams from 'lib/hooks/useSearchParams'
import useTrackLastTouchUTMTags from 'lib/hooks/useTrackLastTouchUTMTags'
import InterviewConfirmation from 'pages/InterviewConfirmation'
import React from 'react'
import { useLocation } from 'react-router'
import SplashScreen from '../layouts/SplashScreen'
import AuthenticatedApp from './AuthenticatedApp'
import UnauthenticatedApp from './UnauthenticatedApp'

const Routes = () => {
  const { status, authenticated } = useAuthentication()
  const location = useLocation()
  const params = useSearchParams()

  // handles initial authentication
  useHandleAutoAuth()
  // informs the user whether they are offline/online
  useNetworkStatusFeedback()
  // we track utm last touch utm tags
  useTrackLastTouchUTMTags()

  if (status === AUTH_STATUS.IDLE || status === AUTH_STATUS.PENDING) {
    return <SplashScreen />
  }

  if (location.pathname === '/confirm-interview' && params.code) {
    return <InterviewConfirmation />
  }

  return authenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />
}

export default React.memo(Routes)
