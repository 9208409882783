import { ContractType } from 'api'
import Select, { SelectOption } from 'components/SelectAlternative'

export enum JobType {
  'fullTime' = 'Full-time',
  'partTime' = 'Part-time',
  'freelance' = 'Freelance',
  'internship' = 'Internship',
}

export const JobTypeSelect = ({ value, onChange }: JobTypeSelectProps) => (
  <Select
    value={value}
    transformValue={(value: keyof typeof JobType) => JobType[value]}
    placeholder='Job type'
    onChange={(value: string) => onChange(value as ContractType)}>
    <SelectOption value='fullTime'>Full-time</SelectOption>
    <SelectOption value='partTime'>Part-time</SelectOption>
    <SelectOption value='freelance'>Freelance</SelectOption>
    <SelectOption value='internship'>Internship</SelectOption>
  </Select>
)

interface JobTypeSelectProps {
  value: ContractType
  onChange: (value: ContractType) => void
}
