import React, { useState } from 'react'
import clsx from 'clsx'

import Button from 'components/Button'
import { CheckDoneSVG } from 'assets/icons'
import Modal from 'components/Modal'
import Congratulation from 'components/Modal/Congratulation'
import { Link } from 'react-router-dom'
import {
  ProfileStatus,
  useCurrentProfileQuery,
  useDeployProfileMutation,
  useReadUserQuery,
} from 'api'
import isEmpty from 'lodash/isEmpty'

const ProgressBar = ({ progress }: ProgressBarProps) => (
  <svg
    className='w-full'
    viewBox='0 0 410 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect y='9' width='100%' height='5' rx='2.5' fill='#DEE3EA' />
    <rect y='9' width={`${progress}%`} height='5' rx='2.5' fill='#4D3BD7' />
    <circle
      cx='398'
      cy='12'
      r='11'
      fill='white'
      stroke={progress === 100 ? '#4D3BD7' : '#DEE3EA'}
      strokeWidth='2'
    />
    <path
      d='M403.963 10.5267C404.051 10.809 403.978 11.1189 403.775 11.3261L401.429 13.7152L401.983 17.0884C402.031 17.3809 401.915 17.6766 401.686 17.8511C401.556 17.9498 401.402 18 401.247 18C401.128 18 401.009 17.9704 400.9 17.9104L398 16.3178L395.1 17.9104C394.991 17.9704 394.872 18 394.753 18H394.751C394.339 17.9992 394.006 17.6503 394.006 17.2202C394.006 17.1604 394.012 17.102 394.024 17.046L394.571 13.7152L392.225 11.3261C392.022 11.1189 391.949 10.809 392.037 10.5267C392.124 10.2444 392.358 10.0386 392.639 9.99601L395.881 9.50391L397.331 6.43466C397.456 6.16849 397.716 6 398 6C398.284 6 398.543 6.16854 398.669 6.43466L400.119 9.50391L403.361 9.99601C403.642 10.0387 403.876 10.2445 403.963 10.5267Z'
      fill={progress === 100 ? '#4D3BD7' : '#DEE3EA'}
    />
  </svg>
)

const Task = ({ title, done, to }: TaskType) => (
  <Link to={to} className={clsx('space-x-4 flex', !done && 'cursor-pointer')}>
    <div
      className={clsx(
        'w-5 h-5 rounded-full',
        done ? 'bg-brand-600' : 'border border-brand-100 border-solid'
      )}>
      {done && <CheckDoneSVG className='fill-current text-brand-500' />}
    </div>
    <span className={clsx('text-sm', done ? 'line-through' : 'underline')}>
      {title}
    </span>
  </Link>
)

const ProgressCard = () => {
  const [open, setOpen] = useState(false)
  const { data, error, loading } = useCurrentProfileQuery()
  const {
    me: { firstName },
  } = useReadUserQuery()
  const [deployProfile, { loading: deploying }] = useDeployProfileMutation({
    onCompleted: () => {
      setOpen(true)
    },
  })

  if (loading) return null
  if (error || !data?.currentProfile) {
    const err = error || new Error('failed to fetch current profile')
    throw err
  }

  const {
    currentProfile: {
      workExperiences,
      personalProjects,
      educations,
      languages,
      techStacks,
      bio,
      role,
      status,
    },
  } = data

  const tasks: TaskType[] = [
    {
      title: 'Add your basic information',
      done:
        !isEmpty(languages) &&
        !isEmpty(bio) &&
        !isEmpty(role) &&
        (!isEmpty(techStacks?.proficient) ||
          !isEmpty(techStacks?.familiar) ||
          !isEmpty(techStacks?.experienced)),
      required: true,
      to: '/profile/dashboard/edit-profile?type=profile',
    },
    {
      title: 'Add your work experience',
      done: workExperiences.length > 0,
      required: personalProjects.length === 0,
      to: '/profile/work-experience',
    },
    {
      title: 'Add your personal projects',
      done: personalProjects.length > 0,
      required: workExperiences.length === 0,
      to: '/profile/personal-projects',
    },
    {
      title: 'Add your education',
      done: educations.length > 0,
      required: false,
      to: '/profile/education',
    },
  ]

  const count = tasks.filter((task) => task.done).length
  const progress = Math.floor((count / tasks.length) * 100)
  const readyToDeploy = tasks
    .filter((task) => task.required)
    .every((task) => task.done)
  const isDeployed = status === ProfileStatus.Approved

  return (
    <div className='flex flex-col justify-center border-solid border border-brand-100 shadow-navigation rounded-lg'>
      <Modal
        className='mt-28'
        open={open}
        onClose={() => setOpen(false)}
        hideCloseButton>
        <Congratulation
          title='Profile Deployed Successfully'
          body={`${firstName}, your profile has been deployed successfully. One of our Talent Managers will review it and reached back if there are any changes. If all is good, we will start broadcasting your profile to our Startup network if you opted-in to receiving offers.`}
          cta='Close'
          onCtaClick={() => setOpen(false)}
        />
      </Modal>
      {(status === ProfileStatus.Pending || !readyToDeploy) && (
        <div className={'p-6 border-b border-brand-100 border-solid space-y-4'}>
          <div className='space-y-2'>
            <h3 className='text-lg font-semibold'>
              Your profile is {progress}% complete ({count}/{tasks.length})
            </h3>
            <div>
              <ProgressBar progress={progress} />
            </div>
          </div>

          <div className='space-y-4'>
            {tasks.map((task) => (
              <Task key={task.title} {...task} />
            ))}
          </div>
        </div>
      )}

      <div
        className={clsx(
          'p-6 space-y-6',
          readyToDeploy && status === ProfileStatus.Pending
            ? 'bg-brand-500 text-white rounded-b-lg'
            : 'text-brand-900'
        )}>
        <div className={clsx(isDeployed ? 'space-y-4 pr-12' : 'space-y-2')}>
          <div className={clsx(isDeployed ? 'text-xs' : 'text-xxs')}>
            {readyToDeploy
              ? 'Your profile is ready to be deployed'
              : 'You have pending tasks'}
          </div>
          <div
            className={clsx('font-medium', isDeployed ? 'text-xl' : 'text-sm')}>
            {readyToDeploy
              ? 'Deploy when you’re ready to notify your Talent Manager'
              : 'The tasks above are required prior to deploying your profile'}
          </div>
        </div>

        <Button
          hasIcon={false}
          variant='secondary'
          disabled={!readyToDeploy}
          center
          onClick={() => {
            deployProfile()
          }}
          loading={deploying}
          className='w-full disabled:bg-brand-50 disabled:text-brand-150'>
          {isDeployed ? 'Re-deploy' : 'Deploy'}
        </Button>
      </div>
    </div>
  )
}

interface ProgressBarProps {
  progress: number
}

type TaskType = {
  title: string
  done: boolean
  to: string
  required: boolean
}

export default ProgressCard
