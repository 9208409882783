import { AssessmentFragment, AssessmentStatus, Stage } from 'api'
import { TadaEmoji } from 'assets/emojis'
import { InfoSVG, LockSVG } from 'assets/icons'
import clsx from 'clsx'
import { walkthroughStorage } from 'lib/helpers/localStorage'
import StagesInfo from 'layouts/Sidebar/StagesInfo'
import { useState } from 'react'
import { HorizontalStep, VerticalStep } from './variants'

const Steps = ({ assessments, isRejected }: StepsProps) => {
  const [open, setOpen] = useState(walkthroughStorage.get() !== 'true')

  const isPreOfferDone =
    assessments.find((assessment) => assessment.stage === Stage.PreOffer)
      ?.status !== AssessmentStatus.Upcoming

  return (
    <>
      <div className='bg-white rounded-lg border border-brand-100 shadow-sidebar'>
        <div className='space-y-4'>
          <div className='px-4 pt-4 space-y-8'>
            <div className='flex justify-between items-center'>
              <span className='text-sm leading-4 uppercase'>Your progress</span>
              <InfoSVG
                className='w-4 h-4 text-brand-900'
                role='button'
                onClick={() => setOpen(true)}
              />
            </div>
          </div>
          <div className='flex md:block overflow-x-scroll md:overflow-x-auto pr-4 md:pr-0 no-scrollbar'>
            <div className='flex md:block items-center pb-4 md:pb-0 pl-4 md:pl-0 mt-4 md:-mb-5'>
              {assessments.map((assessment, index) => (
                <VerticalStep
                  key={assessment.stage + assessment._id}
                  assessments={assessments}
                  {...{ assessment, index, isRejected }}
                />
              ))}
              {assessments.map((assessment, index) => (
                <HorizontalStep
                  key={assessment.stage + assessment._id}
                  assessments={assessments}
                  {...{ assessment, index, isRejected }}
                />
              ))}
            </div>
            <div>
              <div
                className={clsx(
                  'flex items-center py-4 px-5 space-x-2.5 text-sm whitespace-nowrap rounded-lg md:rounded-t-none',
                  isPreOfferDone
                    ? 'text-white bg-brand-500'
                    : 'text-brand-gray-800 bg-brand-50'
                )}>
                <TadaEmoji
                  width='16'
                  style={{
                    filter: !isPreOfferDone
                      ? 'grayscale(100%) opacity(0.8)'
                      : '',
                  }}
                />
                <span>Application complete!</span>
                {!isPreOfferDone && (
                  <LockSVG className='inline-block mb-0.5 ml-1 h-2.5' />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <StagesInfo open={open} onClose={() => setOpen(false)} />
    </>
  )
}

interface StepsProps {
  assessments: AssessmentFragment[]
  isRejected: boolean
}

export default Steps
