import { ChevronSVG } from 'assets/icons'
import clsx from 'clsx'
import Dropdown, {
  DropdownToggle,
  DropdownContent,
  DropdownClose,
} from 'components/Dropdown'

const UserDropdown = ({ className, children }: UserDropdownProps) => {
  return (
    <Dropdown>
      <DropdownToggle
        className={clsx(
          'flex flex-grow flex-shrink-0 justify-end',
          'text-brand-900 focus:outline-none'
        )}>
        <ChevronSVG className='w-4 h-4' />
      </DropdownToggle>
      <DropdownContent
        component='nav'
        className={clsx(
          'bg-white rounded-sxl shadow-sidebar',
          'w-full max-w-xxs text-tiny',
          'border border-brand-100',
          'divide-y divide-brand-70',
          className
        )}>
        {children}
      </DropdownContent>
    </Dropdown>
  )
}

export const DropdownButton = ({
  className,
  ...props
}: DropdownButtonProps) => {
  return (
    <DropdownClose
      component='button'
      type='button'
      className={clsx(
        'py-2.5 px-3 w-full rounded-md',
        'text-xxs text-left',
        'hover:bg-brand-70',
        'focus-within:bg-brand-70 focus:outline-none',
        className
      )}
      {...props}
    />
  )
}

interface UserDropdownProps {
  className?: string
  children: React.ReactNode
}

interface DropdownButtonProps {
  className?: string
  onClick: () => void
  children: React.ReactNode
}

export default UserDropdown
