import { memo } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import {
  APPLICATION_ROUTES,
  applicationRoutes,
  applicationSharedRoutes,
  RejectionPage,
  stagePathname,
} from './routes'
import DashboardLayout from 'layouts/dashboard'
import { isWhitelistedRoute } from './utils'
import { useResetFeedback } from 'components/Modal/utils'
import { useReadUserQuery } from 'api'

const Application = memo(() => {
  const location = useLocation()
  const data = useReadUserQuery()

  useResetFeedback()

  const {
    me: {
      currentApplication: { status, currentAssessment },
      ...candidate
    },
  } = data

  if (status === 'rejected') {
    return (
      <Switch>
        <Route
          component={RejectionPage}
          path={APPLICATION_ROUTES.rejection}
          exact={true}
        />
        <Redirect to={APPLICATION_ROUTES.rejection} />
      </Switch>
    )
  }

  if (!currentAssessment) {
    throw new Error(`User id=${candidate._id} has no current assessment`)
  }
  const { stage } = currentAssessment

  // we check if the current route is whitelisted to prevent redirecting
  // to a stge route.
  const redirectTo = !isWhitelistedRoute(location.pathname)
    ? stagePathname[stage]
    : null

  return (
    <Switch>
      {applicationRoutes
        .filter((route) => route.stage === stage)
        .map(({ component: Component, stage, path, exact }) => (
          <Route key={`${stage}-${path}`} exact={!!exact} path={path}>
            <DashboardLayout>
              <Component />
            </DashboardLayout>
          </Route>
        ))}

      {redirectTo && <Redirect to={redirectTo} />}

      {applicationSharedRoutes.map(({ component: Component, path, exact }) => {
        return (
          <Route key={path} exact={!!exact} path={path}>
            <Component />
          </Route>
        )
      })}
    </Switch>
  )
})

export default Application
