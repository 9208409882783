import React, { ChangeEvent } from 'react'
import clsx from 'clsx'
import { TrashSVG, CameraSVG } from 'assets/icons'
import avatarSVG from 'assets/illustrations/avatar.svg'
import { TextArea, Input, FieldMessage } from 'components/Inputs'
import useProfilePictureUpload from './useProfilePictureUpload'
import { Maybe, useCurrentProfileQuery } from 'api'
import TechStackStep from 'pages/Profile/Introduction/Steps/TechStack'
import useEditProfileInfo, {
  useProfileData,
  ProfileDataType,
} from 'lib/hooks/useEditProfileInfo'
import { FormikProvider } from 'formik'
import Languages from './Languages'
import Button from 'components/Button'

interface InputFieldProps {
  label: string
  hint?: string
  as: 'input' | 'textarea'
  id: keyof Pick<ProfileDataType, 'role' | 'bio' | 'linkedinURL' | 'githubURL'>
  className?: string
  value?: Maybe<string> | undefined
  error?: string
  placeholder?: string
}

export const InputField = ({
  label,
  hint,
  as,
  id,
  className,
  error,
  placeholder,
}: InputFieldProps) => {
  const { values, setFieldValue } = useProfileData()
  const inputProps = as === 'textarea' && { rows: 4, maxLength: 250 }
  const InputComp = as === 'textarea' ? TextArea : Input

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue(id, e.target.value)
  }

  return (
    <div className='flex flex-col space-y-2'>
      <div className='flex justify-between'>
        <label className='text-brand-gray-200 text-xxs'>{label}</label>
        <label className='text-brand-gray-200 text-sm'>{hint}</label>
      </div>
      <div className={clsx(className, 'font-normal')}>
        <InputComp
          placeholder={placeholder}
          hasError={!!error}
          id={id}
          name={id}
          className={clsx(as === 'textarea' && 'resize-none overflow-hidden')}
          value={values[id]}
          onChange={handleChange}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            padding: as === 'textarea' ? '16px' : '10px 16px',
          }}
          {...inputProps}
        />
        {!!error ? (
          <FieldMessage className='text-alert'>{error}</FieldMessage>
        ) : null}
      </div>
    </div>
  )
}

function EditProfile() {
  const { data, error } = useCurrentProfileQuery()
  const {
    imageRef,
    showOpenFileDialog,
    handleChange,
    userImage,
    selectedFile,
    clearImage,
  } = useProfilePictureUpload({
    userImage: data?.currentProfile?.pictureURL,
    defaultImage: avatarSVG,
  })

  if (error || !data || !data.currentProfile) {
    const err = error || new Error('failed to read current profile')
    throw err
  }
  const {
    currentProfile: {
      bio,
      role,
      linkedinURL,
      githubURL,
      techStacks,
      languages,
    },
  } = data

  const formik = useEditProfileInfo({
    bio: bio || '',
    role: role || '',
    linkedinURL: linkedinURL || '',
    githubURL: githubURL || '',
    techStacks: {
      proficient: techStacks?.proficient || [],
      experienced: techStacks?.experienced || [],
      familiar: techStacks?.familiar || [],
    },
    languages: (languages as string[]) || [],
  })

  const { handleSubmit, isSubmitting, errors, dirty, setValues } = formik

  // NOTE: this should technically be done in one place
  React.useEffect(() => {
    setValues((values) => ({
      ...values,
      image: selectedFile,
    }))
  }, [selectedFile, setValues])

  return (
    <div className='px-10 pb-5'>
      <div className='space-y-10 '>
        <FormikProvider value={formik}>
          <div className='space-y-6'>
            <span className='text-base text-brand-900'>Profile Picture</span>
            <div className='space-y-7'>
              <div className='space-y-3'>
                <div className='relative h-20 w-20'>
                  <img
                    src={userImage}
                    alt=''
                    className='bg-brand-900 rounded-full h-full w-full object-contain'
                  />
                  <button
                    onClick={() => {
                      clearImage()
                      setValues((values) => ({
                        ...values,
                        image: undefined,
                      }))
                    }}
                    className={clsx(
                      'flex items-center justify-center',
                      'absolute top-1/2 left-2/3 mt-2',
                      'rounded-full h-8 w-8 bg-white',
                      'focus:outline-none hover:bg-brand-80'
                    )}>
                    <TrashSVG className='absolute w-4 h-4' />
                  </button>
                </div>
                <button
                  onClick={showOpenFileDialog}
                  className={clsx(
                    'flex items-center justify-around',
                    'bg-brand-80 rounded-lg p-2 space-x-1',
                    'focus:outline-none hover:bg-brand-50'
                  )}>
                  <CameraSVG className='w-4 h-4' />
                  <span className='text-brand-900 font-medium text-xs'>
                    Upload photo
                  </span>
                  <input
                    type='file'
                    multiple={false}
                    accept='image/*'
                    className='hidden'
                    ref={imageRef}
                    onChange={handleChange}
                  />
                </button>
                <FieldMessage className='text-alert !ml-0'>
                  {errors.image}
                </FieldMessage>
              </div>
              <InputField
                label='How do you describe your role?'
                as='input'
                placeholder='e.g. Full stack Engineer'
                id='role'
                className='w-4/6'
                error={errors.role}
              />
              <InputField
                label='Short bio'
                hint='250 characters'
                as='textarea'
                id='bio'
                placeholder='Please describe yourself in 3 sentences. This summary will be viewed first in your profile and should give a general idea about your background, motivations and goals.'
                error={errors.bio}
              />
            </div>
          </div>
          <div className='space-y-6'>
            <span className='text-base text-brand-900'>Social profiles</span>
            <div className='space-y-5'>
              <InputField
                label='Linkedin'
                as='input'
                placeholder='https://www.linkedin.com/in/username/'
                id='linkedinURL'
                error={errors.linkedinURL}
              />
              <InputField
                label='Github'
                as='input'
                placeholder='https://github.com/username'
                id='githubURL'
                error={errors.githubURL}
              />
            </div>
          </div>
          <div className='space-y-6'>
            <span className='text-base text-brand-900'>Tech stacks</span>
            <TechStackStep />
          </div>
          <div className='space-y-6 mb-10'>
            <span className='text-base text-brand-900'>Languages</span>
            <Languages />
          </div>
          <div className='flex justify-end'>
            <Button
              type='submit'
              onClick={() => handleSubmit()}
              variant='secondary'
              loading={isSubmitting}
              hasIcon={false}
              disabled={!dirty || isSubmitting}>
              Save
            </Button>
          </div>
        </FormikProvider>
      </div>
    </div>
  )
}

export default EditProfile
