import React from 'react'
import Button from './Button'
import Card from './Card'
import Dot from 'components/Dot'

const Navigation = ({
  stepsLength = 4,
  stepIndex = 0,
  primaryCtaText = 'Next',
  primaryCtaDisabled = true,
  primaryCtaOnClick,
  shouldDisplaySecondaryCta = false,
  secondaryCtaText = 'Back',
  secondaryCtaOnClick,
  loading = false,
}: NavigationProps) => {
  return (
    <Card
      flex='flex items-center justify-between px-6 py-4'
      className='shadow-navigation'>
      <div className='flex items-center space-x-1'>
        {stepsLength > 1
          ? [...Array(stepsLength)].map((_, index) => (
              <Dot active={stepIndex === index} key={index} />
            ))
          : null}
      </div>
      <div className='space-x-8'>
        {shouldDisplaySecondaryCta && (
          <Button
            variant='custom'
            disabled={loading}
            className='no-underline text-sm'
            onClick={secondaryCtaOnClick}>
            {secondaryCtaText}
          </Button>
        )}
        <Button
          loading={loading}
          className='px-4 py-3 w-28'
          onClick={primaryCtaOnClick}
          disabled={primaryCtaDisabled}>
          {primaryCtaText}
        </Button>
      </div>
    </Card>
  )
}

interface NavigationProps {
  stepsLength?: number
  stepIndex: number
  primaryCtaText?: string
  primaryCtaDisabled?: boolean
  primaryCtaOnClick: () => void
  shouldDisplaySecondaryCta?: boolean
  secondaryCtaText?: string
  secondaryCtaOnClick?: () => void
  loading?: boolean
}

export default Navigation
