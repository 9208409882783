import {
  ProfileEducation,
  ProfileEducationInput,
  useCurrentProfileQuery,
  useUpdateEducationMutation,
} from 'api'
import Input, { FormField } from 'components/InputAlternative'
import Modal from 'components/Modal'
import { DateRangeField, Fieldset, Form } from './Shared'
import * as Yup from 'yup'
import { FormikProvider, useFormik } from 'formik'
import { Redirect, useHistory, useParams } from 'react-router-dom'

const EducationUpdateModal = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, called } = useCurrentProfileQuery({
    returnPartialData: true,
  })

  // TODO: create educatioQuery on the backend
  const currentEducation = data?.currentProfile?.educations.find(
    (edu) => edu._id === id
  )

  const formik = useForm(currentEducation)

  const {
    values,
    handleSubmit,
    handleChange,
    isSubmitting,
    errors,
    touched,
  } = formik

  // TODO: create loading skeleton
  if (loading) return null

  if (called && !currentEducation) {
    return <Redirect to='/profile/education' />
  }

  return (
    <FormikProvider value={formik}>
      <div className='w-screen max-w-4xl p-6 pt-10 bg-white rounded-lg'>
        <div className='space-y-8'>
          <div className='space-y-2'>
            <Modal.Title as='h3' className='text-1.5xl font-bold'>
              Education
            </Modal.Title>
            <p className='text-xs'>About your academic background.</p>
          </div>

          <Form onSubmit={handleSubmit} isSubmitting={isSubmitting}>
            <Fieldset legend='Tell us about your education here'>
              <FormField hasError={touched.diploma && !!errors.diploma}>
                <Input
                  value={values.diploma}
                  onChange={handleChange}
                  name='diploma'
                  id='education-diploma'
                  aria-label='education-diploma'
                  placeholder='Diploma/field of studies. E.g., Software Engineering Degree'
                />
              </FormField>
              <DateRangeField />
              <FormField hasError={touched.school && !!errors.school}>
                <Input
                  value={values.school}
                  onChange={handleChange}
                  name='school'
                  id='education-school'
                  aria-label='education-school'
                  placeholder='School/University'
                />
              </FormField>
              <FormField hasError={touched.location && !!errors.location}>
                <Input
                  value={values.location}
                  onChange={handleChange}
                  name='location'
                  id='education-location'
                  aria-label='education-location'
                  placeholder='Location (e.g. City, Country)'
                />
              </FormField>
            </Fieldset>
          </Form>
        </div>
      </div>
    </FormikProvider>
  )
}

const validationSchema = Yup.object({
  diploma: Yup.string().required(),
  location: Yup.string().required(),
  remote: Yup.boolean().required(),
  school: Yup.string().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
})

type FormValues = ProfileEducationInput & {
  ongoing: boolean
}

const useForm = (initialValues?: Partial<ProfileEducation>) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [updateEducation] = useUpdateEducationMutation()

  return useFormik<FormValues>({
    validationSchema: () => validationSchema,
    enableReinitialize: true,
    initialValues: {
      diploma: initialValues?.diploma || '',
      location: initialValues?.location || '',
      remote: !!initialValues?.remote,
      school: initialValues?.school || '',
      startDate: initialValues?.startDate || '',
      endDate: initialValues?.endDate,
      ongoing: !initialValues?.endDate,
    },
    onSubmit: async ({ ongoing, ...values }) => {
      try {
        await updateEducation({
          variables: {
            data: {
              ...values,
              endDate: !ongoing ? values.endDate : null,
            },
            id,
          },
        })
        history.push('/profile')
      } catch (error) {
        // TODO handle errors
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  })
}
export default EducationUpdateModal
