import { Listbox } from '@headlessui/react'
import clsx from 'clsx'

const Select = ({
  placeholder,
  value,
  onChange,
  transformValue = String,
  children,
}: DropdownProps) => {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div
          className={clsx(
            'text-sm rounded',
            '-mx-2',
            'scrollbar',
            'focus:outline-none',
            'border border-transparent',
            'focus-within:border-brand-100',
            open && 'relative z-10 bg-white shadow-input border-brand-100'
          )}
          style={{ width: 'calc(100% + 1rem)' }}>
          <Listbox.Button
            className={clsx(
              'p-2 w-full',
              'rounded',
              'text-left hover:bg-brand-gray-100',
              'focus:outline-none',
              open && 'bg-brand-40',
              !!value ? 'text-brand-900 ' : 'text-cardGray-50'
            )}>
            {!!value ? transformValue(value) : placeholder}
          </Listbox.Button>
          <Listbox.Options className='absolute z-20 w-full ring-1 ring-brand-100 shadow-input focus:outline-none'>
            {children}
          </Listbox.Options>
        </div>
      )}
    </Listbox>
  )
}

export const SelectOption = ({
  className,
  children,
  ...props
}: SelectOptionProps) => (
  <Listbox.Option className='focus:outline-none' {...props}>
    {({ active, selected }) => (
      <span
        className={clsx(
          'block w-full p-2',
          'cursor-pointer',
          active || selected ? 'bg-brand-40' : 'bg-white',
          className
        )}>
        {children}
      </span>
    )}
  </Listbox.Option>
)

interface DropdownProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
  /**
   * used to change how values appear in button
   * from fullTime to Full Time for instance
   */
  transformValue?: (value: any) => string
  children: React.ReactNode
}

interface SelectOptionProps {
  className?: string
  value: unknown
  children: React.ReactNode
}

export default Select
