import {
  ProfilePersonalProject,
  ProfilePersonalProjectInput,
  useCurrentProfileQuery,
  useUpdatePersonalProjectMutation,
} from 'api'
import { FormikProvider, useFormik } from 'formik'
import Input, { FormField } from 'components/InputAlternative'
import InputRichText from 'components/InputRichText'
import Modal from 'components/Modal'
import { DateRangeField, Fieldset, Form } from './Shared'
import * as Yup from 'yup'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import TechStackMultiselect from 'components/TechStackMultiselect'

const PersonalProjectsUpdateModal = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading, called } = useCurrentProfileQuery({
    returnPartialData: true,
  })

  // TODO: create workExperienceQuery on the backend
  const currentPersonalProject = data?.currentProfile?.personalProjects.find(
    (project) => project._id === id
  )

  const formik = useForm(currentPersonalProject)
  const {
    values,
    handleSubmit,
    handleChange,
    setValues,
    isSubmitting,
    errors,
    touched,
  } = formik

  // TODO: create loading skeleton
  if (loading) return null

  if (called && !currentPersonalProject) {
    return <Redirect to='/profile/personal-projects' />
  }

  return (
    <FormikProvider value={formik}>
      <div
        className='w-screen h-full max-w-4xl p-6 overflow-y-scroll bg-white rounded-lg scrollbar'
        style={{ maxHeight: '90vh' }}>
        <div className='space-y-8'>
          <div className='space-y-6'>
            <div className='space-y-2'>
              <Modal.Title as='h3' className='text-1.5xl font-bold'>
                Personal Projects
              </Modal.Title>
              <p className='text-xs'>Fill your information.</p>
            </div>
          </div>
          <Form onSubmit={handleSubmit} isSubmitting={isSubmitting}>
            <div className='space-y-10'>
              <Fieldset legend='Project details'>
                <FormField hasError={touched.title && !!errors.title}>
                  <Input
                    value={values.title}
                    onChange={handleChange}
                    id='project-name'
                    aria-label='project-name'
                    name='title'
                    placeholder='Project name'
                  />
                </FormField>
                <DateRangeField />
              </Fieldset>
              <Fieldset legend='Project Description'>
                <FormField
                  hasError={touched.description && !!errors.description}>
                  <InputRichText
                    defaultValue={values.description}
                    onChange={(description: string) =>
                      setValues((values) => ({ ...values, description }))
                    }
                    id='project-description'
                    placeholder='This is a web app allowing farmers to manage their herds in a flexible way'
                  />
                </FormField>
              </Fieldset>
              <Fieldset
                legend={
                  <>
                    Links <span className='font-normal'>(optional)</span>
                  </>
                }>
                <FormField hasError={touched.demoLink && !!errors.demoLink}>
                  <Input
                    value={values.demoLink || ''}
                    onChange={handleChange}
                    id='project-demo-link'
                    aria-label='project-demo-link'
                    name='demoLink'
                    placeholder='Demo link (https://example.com)'
                  />
                </FormField>
                <FormField hasError={touched.sourceLink && !!errors.sourceLink}>
                  <Input
                    value={values.sourceLink || ''}
                    onChange={handleChange}
                    aria-label='project-source-link'
                    name='sourceLink'
                    placeholder='Source code (https://github.com/username/example)'
                  />
                </FormField>
              </Fieldset>
              <Fieldset legend='Which tech stack did you use?'>
                <FormField hasError={touched.techStack && !!errors.techStack}>
                  <TechStackMultiselect
                    values={values.techStack}
                    onChange={(techStack) =>
                      setValues((values) => ({ ...values, techStack }))
                    }
                  />
                </FormField>
              </Fieldset>
            </div>
          </Form>
        </div>
      </div>
    </FormikProvider>
  )
}

type FormValues = ProfilePersonalProjectInput & {
  ongoing: boolean
}

const validationSchema = Yup.object({
  title: Yup.string().required(),
  description: Yup.string().required(),
  // date field
  startDate: Yup.date().required(),
  endDate: Yup.date()
    .min(Yup.ref('startDate'))
    .when('ongoing', {
      is: false,
      then: Yup.date().min(Yup.ref('startDate')).required(),
    }),
  ongoing: Yup.boolean(),
  techStack: Yup.array()
    .min(1, 'Techstacks should not be empty')
    .of(Yup.string())
    .required(),
  demoLink: Yup.string(),
  sourceLink: Yup.string(),
})

const useForm = (initialValues?: Partial<ProfilePersonalProject>) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [updatePersonalProject] = useUpdatePersonalProjectMutation()

  return useFormik<FormValues>({
    validationSchema: () => validationSchema,
    initialValues: {
      title: initialValues?.title || '',
      description: initialValues?.description || '',
      techStack: initialValues?.techStack || [],
      demoLink: initialValues?.demoLink || undefined,
      sourceLink: initialValues?.sourceLink || undefined,
      startDate: initialValues?.startDate || '',
      endDate: initialValues?.endDate,
      ongoing: !initialValues?.endDate,
    },
    onSubmit: async ({ ongoing, ...values }) => {
      try {
        await updatePersonalProject({
          variables: {
            data: {
              ...values,
              endDate: !ongoing ? values.endDate : null,
            },
            id,
          },
        })
        history.push('/profile')
      } catch (error) {
        // TODO handle errors
      }
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  })
}
export default PersonalProjectsUpdateModal
