import clsx from 'clsx'

export const Legend = ({ className, ...props }: LegendProps) => {
  return (
    <legend
      className={clsx('text-brand-900 text-sm font-semibold', className)}
      {...props}
    />
  )
}

type LegendProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLLegendElement>,
  HTMLLegendElement
>
