import Card from 'components/Card'
import { useProfileData } from 'lib/hooks/useEditProfileInfo'
import Input from 'components/InputAlternative'

const RoleStep = () => {
  const { values, setFieldValue } = useProfileData()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('role', e.target.value)
  }

  return (
    <Card className={'p-6 bg-white w-full shadow-onboardingCard'}>
      <div className='flex flex-col space-y-4'>
        <label className='text-sm font-semibold text-brand-900'>
          How do you describe your role?
        </label>
        <Input
          id='role'
          type='text'
          name='role'
          onChange={handleChange}
          value={values.role}
          placeholder='e.g. Full stack Engineer'
        />
      </div>
    </Card>
  )
}
export default RoleStep
