import {
  LoadingSpinnerUpdatedSVG as Spinner,
  RightArrowUpdatedSVG as Arrow,
} from 'assets/icons'
import clsx from 'clsx'
import { ElementType, FunctionComponent, ReactNode, SVGProps } from 'react'
import { PolymorphicComponentProps } from 'types/polymorphic'

const allButtons = `relative disabled:cursor-not-allowed
  focus:outline-none focus-visible:ring-2
  transition-colors duration-150 ease-in-out
  rounded-lg`

const allVariants = `text-white min-w-140
  py-3 px-9 truncate leading-6
  disabled:bg-gray-100 disabled:text-gray-400`

const variants = {
  primary: `bg-brand-500 hover:bg-brand-600 ${allVariants}`,
  secondary: `bg-brand-900 hover:bg-brand-800 ${allVariants}`,
  custom: null, // custom has only default classes
}

const allIcons = `absolute inset-y-0 right-0
  flex items-center justify-center
  h-full mt-px
  pointer-events-none`

// TODO: disable button when loading
const Button = <Polymorphic extends ElementType = 'button'>({
  component,
  variant = 'primary',
  className,
  center = true,
  loading = false,
  icon: Icon = Arrow,
  hasIcon,
  iconClassName = 'p-4',
  children,
  ...props
}: PolymorphicComponentProps<Polymorphic, Props>) => {
  const Component = component || 'button'
  /**
   * Show the icon when variant is secondary and hasIcon is unset
   */
  const showIcon =
    (hasIcon || (hasIcon === undefined && variant === 'secondary')) && !loading

  return (
    <Component
      className={clsx(
        allButtons,
        variants[variant],
        className,
        center || 'pl-5 text-left'
      )}
      {...props}>
      {children}
      {loading && (
        <Spinner className={clsx(allIcons, 'animate-spin', iconClassName)} />
      )}
      {showIcon && <Icon className={clsx(allIcons, iconClassName)} />}
    </Component>
  )
}

interface Props {
  /**
   * Button variants. `primary`, `secondary`, or `custom`.
   * `custom` variant removes all styles other than focus styles.
   */
  variant?: 'primary' | 'secondary' | 'custom'
  /**
   * Add additional classNames to the Button element.
   * Not every class is overridable. It depends on their specificity in TW.
   */
  className?: string
  /**
   * Whether the button is centered or not.
   * Defaults to true.
   */
  center?: boolean
  /**
   * Whether to show a loading indicator or not.
   * Defaults to false.
   */
  loading?: boolean
  /**
   * Icon to show
   * An SVG React Element
   */
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  /**
   * Allows you to control loading icon size
   */
  iconClassName?: string
  /**
   * Whether to show an icon or not.
   * If hasIcon is unset, secondary variant
   * will show an icon.
   */
  hasIcon?: boolean
  /**
   * Button content.
   */
  children: ReactNode
}

export default Button
