import { Disclosure as Collapse } from '@headlessui/react'
import { LightBulbEmoji } from 'assets/emojis'
import clsx from 'clsx'
import { ElementType } from 'react'

const CollapseButton = ({
  className,
  open,
  children = (
    <>
      Example <LightBulbEmoji className='inline' width='16' />
    </>
  ),
}: CollapseButtonProps) => {
  return (
    <Collapse.Button
      className={clsx(
        'max-w-max',
        'py-1 px-2 font-medium focus:outline-none focus-visible:ring-2 rounded',
        'text-xxs text-brand-150 bg-brand-50 bg-opacity-90',
        open && 'rounded-b-none',
        className
      )}>
      {children}
    </Collapse.Button>
  )
}

const CollapsePanel = ({
  className = 'rounded-tl-none',
  as,
  children,
  ...props
}: CollapsePanelProps) => {
  return (
    <Collapse.Panel
      as={as}
      className={clsx('bg-brand-50 p-4', 'rounded rounded-t-none', className)}
      {...props}>
      <div className='text-xs text-brand-900 opacity-60 leading-6'>
        {children}
      </div>
    </Collapse.Panel>
  )
}

interface CollapseButtonProps {
  className?: string
  open: boolean
  children?: React.ReactNode
}

interface CollapsePanelProps {
  /**
   * ⚠️ important!
   * not all edges of the collapse panel are rounded.
   * but, we do not know which side will be unrounded ahead of time.
   * do pass `rounded-tl-none` or `rounded-tr-none` when you use this.
   */
  className?: 'rounded-tl-none' | 'rounded-tr-none' | string
  children: React.ReactNode
  as?: ElementType<any>
}

export { Collapse as default, CollapseButton, CollapsePanel }
